angular.module('user').factory('Company',
  function($http, $q, Data, Modal) {
    'ngInject';

    return {

      get: (id) => {
        return $http.get(`${Data.apiUrl}/v2/companies/${id}`).then((result) => {
          return result.data;
        });
      },

      create: (data) => {
        return $http.post(`${Data.apiUrl}/v2/companies`,data).then((result) => {
          return result.data;
        });
      },

      modals: {
        // None yet
      }

    };

  });
