angular.module('messages').component('messageList', {
  templateUrl: '/templates/messages/message-list.html',
  bindings: {
    move: '<',
  },
  controller: function($rootScope, $timeout, $q, User, Messages, Move, Typing, _, debounce) {
    'ngInject';

    if(User.get().role == 'guest') return;

    let cxList = false;
    let perPage = 50;
    let element = angular.element('#scroll-messages');
    const listeners = [];

    // initialize
    this.$onInit = () => {}

    // cleanup
    this.$onDestroy = () => listeners.forEach(deregister => deregister());

    this.$onChanges = changes => {
      if(changes.move) {
        this.ready = false;
        this.messages = [];
        this.messagesCount = false;
        this.customer = false;
        loadMessages(true);
        markRead();
      }
    };

    // function to load messages
    const loadMessages = (init) => {
      if(!this.move || this.messagesCount && this.messages.length >= this.messagesCount) return;
      // save current top element
      let topMessage = (this.messages && this.messages.length) ?
        this.messages[this.messages.length - 1] : false;
      let topElement = topMessage ? angular.element('#message-'+topMessage.id)[0] : false;

      // set the customer if we can
      if(!this.customer && _.get(this.move,'move_users')) {
        this.customer = Move.findCustomer(this.move);
      }

      // get next set of messages
      let promises = {
        messages: Messages.get({
          where: { move: this.move.id },
          per_page: perPage,
          page: Math.floor(this.messages.length / perPage) + 1,
          sort: {created_at:true}
        }),
      };
      // hacky way of recovering move_users.last_read for read receipts
      if(init && !this.customer) promises.move_users = Move.moveUser.get({
        where: { move:this.move.id }
      });
      if(needsSender() && !cxList) promises.cxList = User.getCXList();
      $q.all(promises).then((results) => {
        // on first load, cache the cxList;
        if(results.cxList) cxList = results.cxList;
        // init typing users list;
        if(cxList && init) loadTypingUsers();

        if(results.move_users) {
          // hacky way of recovering move_users.last_read for read receipts
          this.customer = results.move_users.find(mu => mu.user.id === this.move.user.id);
          this.customer.user = this.move.user;
        }

        // save current top element offset
        let offset = topElement ? topElement.offsetTop - element.scrollTop() : 0;

        // insert messages
        results.messages.messages.forEach(message => addMessage(message));

        // update total message count
        this.messagesCount = results.messages.total;
        setMoverLastRead();

        // done with first load
        $timeout(() => this.ready = true, 300);

        // scrollposition to previous top element
        if(init || !topElement) return;
        element.scrollTop(topElement.offsetTop - offset);
        $timeout(() => element.scrollTop(topElement.offsetTop - offset),0);

      });
    };

    const addMessage = (message,live) => {
      let exists = this.messages.find((m) => m.id == message.id);
      if(exists) return angular.merge(exists,message);
      if(needsSender()) appendSender(message);
      if(!live) return this.messages.unshift(message);
      this.messages.push(message);
      this.messagesCount++;
    };

    const needsSender = () => ['concierge','channel-partner'].includes(User.get().role);
    const appendSender = (message) => {
      if(!message.sender.firstname || !message.sender.role) {
        let sender = Move.findUser(this.move,message.sender.id);
        if(sender) angular.merge(message.sender,sender.user);
        else {
          sender = cxList ? cxList.find(user => user.id == message.sender.id) : false;
          if(sender) angular.merge(message.sender,sender);
        }
      }
    };

    const setMoverLastRead = () => {
      if(!this.move || !this.messages) return;
      let lastRead = new Date(this.customer.last_read);
      this.messages
        .filter(message => message.sender.id != this.customer.user.id)
        .forEach((message,index,list) => {
          let nextMessage = list[index+1];
          if((nextMessage && new Date(nextMessage.created_at) >= lastRead &&
            new Date(message.created_at) < lastRead) ||
            (!nextMessage && new Date(message.created_at) < lastRead))
              message.moverLastRead = lastRead;
          else message.moverLastRead = false;
        });
    }

    // bind load previous messages
    const watchScroll = () => { if(element.scrollTop() <= 75) loadMessages(); };
    element.on('scroll', debounce(watchScroll,200));

    // utility to determine the classes for a given message
    this.messageClasses = (message,index) => {
      var classes = [];
      if(message.type === 1) classes.push('system');
      else classes.push(isIncoming(message) ? 'incoming' : 'outgoing');
      if(index == this.messages.length-1) classes.push('last');
      else {
        let next = this.messages[index+1];
        if(next) classes.push('next-'+(isIncoming(next) ? 'incoming' : 'outgoing'));
      }
      return classes.join(' ');
    };
    const isIncoming = (message) => User.get().role == 'concierge' ?
      message.sender.id == this.customer.user.id :
      message.sender.id != User.get().id;

    // jump to bottom of message list
    this.scrollBottom = (animate, retry) => {
      let lastMessage = angular.element('.message',element).last();
      let offset = lastMessage && lastMessage.length ? lastMessage[0].offsetTop : false;
      if(!offset) return retry || $timeout(() => this.scrollBottom(animate, true),100); // chrome rendering scrollbar issue fix
      if(animate) element.animate({scrollTop: offset},400);
      else $timeout(() => element.scrollTop(offset), 0);
    };

    const markRead = () => {
      let moveUser = Move.findSelf(this.move);
      if(!moveUser) return; // allow cx to view without being assigned
      Messages.markRead(this.move.id).then((result) => {
        // update activeMove
        moveUser.last_read = result;
        moveUser.unread = 0;
      });
    };

    const loadTypingUsers = () => {
      let userList = Typing.getUsers(this.move.id);
      if(userList) this.typing = userList.map(findUser);
      else this.typing = [];
    };

    // listen for newMessage broadcast
    listeners.push($rootScope.$on('move.message',(e,id,m,del) => {
      if(id !== this.move.id || !this.ready) return;
      del ? removeMessage(m) : newMessage(m);
    }));

    // deletes a message
    const removeMessage = (message) => {
      let index = this.messages.findIndex(m => m.id == message.id);
      if(index !== -1) {
        this.messages.splice(index,1);
        this.messagesCount--;
      }
    };

    // handles new message
    const newMessage = (message) => {
      if(!this.ready) return $timeout(() => newMessage(message), 200);
      if(this.move.id != message.move.id) return; // might have changed during timeout
      addMessage(message,true);
      markRead();
      $timeout(() => this.scrollBottom(true),0);
    };

    // listen for move.user broadcast
    listeners.push($rootScope.$on('move.user',(e,id,m,del) => {
      if(id !== this.move.id || del) return;
      updateMoveUser(m);
    }));
    const updateMoveUser = (moveUser) => {
      if(moveUser.id === this.customer.id) {
        angular.merge(this.customer,moveUser);
        setMoverLastRead();
      }
      if(!this.move.move_users) return; // cx-dash no longer supports move_users
      let exists = _.get(this.move, 'move_users', []).find(mu => mu.id == moveUser.id);
      if(exists) angular.merge(exists,moveUser);
      else this.move.move_users.push(moveUser);
    };

    listeners.push($rootScope.$on('move.typing',(event,id,userList) => {
      if(id !== this.move.id) return;
      if(userList) this.typing = userList.map(findUser);
      else this.typing = [];
    }));
    const findUser = user => {
      let moveUser = _.get(this.customer, 'user.id') === user.id ? this.customer : false;
      if(moveUser) return moveUser.user;
      else return cxList ? cxList.find(cx => cx.id == user.id) : false;
    };

  }
});
