angular.module('payment').controller('transactionCreateModalController',
function ($scope, $uibModalInstance, $q, $timeout,
  Access, Transaction, Vendor, Messages, Move, move, moveTask, existing) {
  'ngInject';

  if(Access.limit('concierge', function() { $uibModalInstance.dismiss('unauthorized'); })) return;

  $scope.move = move;
  $scope.moveTask = moveTask;
  $scope.existing = existing;
  $scope.onSave = () => $uibModalInstance.close(true);

});
