// declare module routes
angular.module('payment').config(['$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('stripe-payment', {
        parent:'layout-default',
        url: '/book/{token:[A-Za-z0-9_\.\-]+}',
        views: {
          'main': {
            controller: 'stripePaymentController',
            templateUrl: '/templates/payment/stripe-payment.html'
          },
          'footer': {
            template:''
          }
        },
        data: {
          name: 'Payment'
        }
      })
      .state('stripeRedirect', {
        parent:'app',
        url: '/stripe_connect?scope&code&error&state',
        views: {
          'special@': {
            controller: 'stripeRedirectController',
            template: ''
          }
        },
        data: {
          name: 'Stripe Connect'
        }
      });

}]);
