// create module & set dependencies
angular.module('tasks', [
  'ngCookies',
  'ngAnimate',
  'ngSanitize',
  'ngProgress',
  'ui.router',
  'ui.bootstrap',
  'ui.select',
  'stripe.checkout',
  'ngclipboard',
  'field',
  'user'
]);
