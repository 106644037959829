angular.module('messages')
  .filter('emojify', ['$sce',
    ($sce) => {
      return (data) => {
        if(!data) return '';
        return $sce.trustAsHtml(emojify.replace(data));
      };
    }
  ])
  .filter('cleanHTML', () => {
    var block = "div,p,h1,h2,h3,h4,h5,h6,blockquote,code,dd,dt,li,ul,form,"+
      "table,th,tr,td,pre";

    return (item, options) => {
      var defaults = {
        multiline: false,
        trimWhiteSpace: true
      }
      options = angular.extend({},defaults,options);
      if(!item) return;
      var element = angular.element('<div/>').html(item);

      // remove redundant &nbsp; and edge white space
      if(options.trimWhiteSpace)
        element.html(element.html().replace(/(&nbsp;)+/g,' ').trim());

      // replace block wrappers with br tags
      element.find(block).each(function() {
        angular.element(this).prepend('<br>').append('<br>').contents().unwrap();
      });

			// remove extra inline wrappers
      element.find('*').not('br').contents().unwrap();

      // remove consecutive br tags
      var consecutive = 2;
      element.contents().each(function() {
        let $this=angular.element(this);
        if($this.is('br') && (consecutive >= 2)) {
          $this.remove();
        } else if ($this.is('br')) {
          consecutive++;
        } else if ($.trim($this.text())==="") {
          $this.remove();
        } else {
          consecutive = 1;
        }
      });

      // remove trailing br
      if(element.contents().last().is('br')) element.contents().last().remove();

      if(!options.multiline)
        element.html(element.html().replace(/<br(\s?\/)?>/g,'\n'));

      return element.html();

    };
  })
  .filter('parseUrl', () => {
    var replacements = [
      {
        //URLs starting with http://, https://, or ftp://
        search: /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
        replace: '<a href="$1" target="_blank">$1</a>'
      },
      {
        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        search: /(^|[^\/])(www\.[\S]+(\b|$))/gim,
        replace: '$1<a href="http://$2" target="_blank">$2</a>'
      },
      {
        //Change email addresses to mailto:: links.
        search: /(\s|^)(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim,
        replace: '$1<a href="mailto:$2">$2</a>'
      }
    ];

    return (text) => {
      if(!text) return;
      for (var i = 0; replacements.length > i; i++) {
        text = text.replace(replacements[i].search, replacements[i].replace);
      }
      return text;
    };
});
