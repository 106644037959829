angular.module('payment').controller('vendorModalController',
function ($scope, $uibModalInstance, $timeout, $q, $state,
  Access, Alerts, User, AddressUtil, Field, Vendor, Token, params) {
  'ngInject';

  if (Access.limit('concierge', () => $uibModalInstance.dismiss('unauthorized'))) return;

  // TODO: eventually have all fields on this screen declaratively created here.
  $scope.fields = {
    headquarters: {
      type: 'address',
      label: 'Headquarters',
      name: 'headquarters',
      required: false
    }
  };
  const initFields = () => {
    angular.forEach($scope.fields, field => {
      if(field.type == 'address') {
        field.value = ($scope.vendor && $scope.vendor.vendor_addresses) ?
            $scope.vendor.vendor_addresses.find(a => a.name == field.name) : {};
        field.formatted = AddressUtil.getFormatted(field.value);
      } else {
        field.value = this.vendor[field.name];
      }
      Field.addDefaults(field);
    });
    $scope.vendor.send_account_invite = false;
    if(!angular.isDefined($scope.vendor.commission))
      $scope.vendor.commission = 0.15;
  };

  if (params.vendor && params.vendor.user) {
    $q.all({
      user: User.lookup(params.vendor.user.id),
      details: Vendor.details.get(false,{where:{vendor:params.vendor.id}})
    }).then(results => {
      // update vendor initial data
      $scope.vendor = angular.copy(params.vendor);
      $scope.vendor.email = results.user.email;
      $scope.vendor.details = results.details;
      initFields();
      $scope.ready = true;
    });
  } else {
    $scope.vendor = angular.copy(params.vendor) || {};
    initFields();
    $scope.ready = true;
  }

  $scope.editVendorDetails = () => Vendor.details.modals.edit($scope.vendor, {overlay:true});

  let valid = () => {
    $scope.vendorCreateForm.$setSubmitted();
    return !$scope.vendorCreateForm.$invalid;
  };

  $scope.submit = (form) => {
    if ($scope.loading) return;
    if(!valid(form)) return error();
    $scope.loading = true;

    let data = {
      name: $scope.vendor.name,
      commission: parseFloat($scope.vendor.commission),
      default_description: $scope.vendor.default_description,
      review_link: $scope.vendor.review_link,
      activate_auto_quotes: $scope.vendor.activate_auto_quotes,
      generate_auto_quotes: $scope.vendor.generate_auto_quotes,
      approve_auto_quotes: $scope.vendor.approve_auto_quotes,
      cx_auto_approve: $scope.vendor.cx_auto_approve
    };

    // handle field data
    angular.forEach($scope.fields, (field) => {
      if(field.type == 'address' && field.value) {
        data.vendor_addresses = data.vendor_addresses || [];
        field.value.name = field.name;
        data.vendor_addresses.push(AddressUtil.filterFields(field.value));
      } else {
        data[field.name] = field.value;
      }
    });

    let promise = $scope.vendor.id ? Vendor.update($scope.vendor.id, data) :
      Vendor.create(data);
    promise.then(result => {
      if ($scope.vendor.send_account_invite) $scope.sendAccountInvite(result);
      else $timeout(() => { $uibModalInstance.close(result); }, 300);
    }, () => error('Error updating vendor'));
  };

  $scope.sendAccountInvite = (vendor) => {
    Token.inviteVendor(vendor.id, {email: $scope.vendor.email}).then(result => {
      Alerts.success({ msg: 'Invitation sent to ' + $scope.vendor.email});
      $timeout(() => { $uibModalInstance.close(vendor); }, 300);
    }, () => error('Error inviting vendor'));
  };

  $scope.proxyAsUser = () => {
    Alerts.success({msg:'With great power comes great responsibility.'});
    User.proxy(params.vendor.user.id).then(() => $state.go('dashboard.vendor.jobs'));
  };

  const error = (msg) => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    if (msg) Alerts.error({ msg: msg, delay: 3 });
  };

  // listen for vendor details broadcast
  $scope.$on('vendor.details', (event, id, data) => updateVendorDetails(id,data));
  const updateVendorDetails = (id,data) => {
    if(!$scope.vendor || id !== $scope.vendor.id) return;
    angular.merge($scope.vendor.details, data);
  };

});
