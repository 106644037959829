angular.module('cx').component('moveSearchItem', {
  templateUrl: '/templates/cx/move-search-item.html',
  bindings: {
    move: '<',
    activeSort: '<',
  },
  controller: function(Move, Messages) {
    'ngInject';

    this.$onChanges = (changes) => {
      if(changes.move && this.move) this.customer = Move.findCustomer(this.move).user;
    };

    this.previewMessage = () => {
      return Messages.getPreview(this.move.last_customer_message || this.move.last_message);
    };

  }
});
