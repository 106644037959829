angular.module('core').component('svgSprite', {
  bindings: {
    source: '@',
    icon: '@',
    alt: '@'
  },
  controller: function($sce) {
    this.$onInit = () => {
      this.source = this.source || 'icons';
      this.link = $sce.trustAsResourceUrl(`/images/sprites/${this.source}.svg#${this.icon}`);
    };
  },
  template: `
    <svg role="img" alt="{{$ctrl.alt}}">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href=""
        ng-attr-xlink:href="{{$ctrl.link}}"></use>
    </svg>`
});
