// declare module routes
angular.module('customer').config(function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('dashboard.customer', {
      views: {
        'main@layout-default': {
          templateUrl: '/templates/customer/dashboard.html',
          controller: 'dashboardController'
        }
      },
      params: {}
    });
});
