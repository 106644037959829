angular.module('vendor').constant('AutoQuoteFlow', {

  screens: [{
    title: `Set your general pricing rules.`,
    type: 'auto-quote-data',
    name: 'volume_pricing',
    label: 'General Pricing',
    fields: [{
      placeholder: 'Cost Per Cubic Foot',
      name: 'cost_per_cubic_foot',
      tooltip: `This is the base rate you charge customers. You can also impose
        a job minimum as well as a percentage increase for peak days (below).`,
      type: 'currency',
      allowDecimals: true,
      default: 125,
      required: true
    },{
      placeholder: 'Minimum Job Price',
      name: 'min_price',
      tooltip: `This is the lowest amount you're willing to accept for a job.
        Any jobs calculated under this amount will be rounded up.`,
      type: 'currency',
      allowDecimals: true,
      default: 29500,
      required: true
    },{
      label: 'Peak Days at End of Month',
      name: 'peak_days_end_of_month',
      tooltip: `Which days of the month do you qualify as "peak"? These dates
        will factor in the percentage increase you specify below.`,
      type: 'select',
      default: 2,
      required: true,
      options: [
        { value: 0, label: 'None' },
        { value: 1, label: 'Last day of the month' },
        { value: 2, label: 'Last two days of the month' },
        { value: 3, label: 'Last three days of the month' },
        { value: 4, label: 'Last four days of the month' },
        { value: 5, label: 'Last five days of the month' }
      ]
    },{
      label: 'Peak Days at Start of Month',
      name: 'peak_days_start_of_month',
      type: 'select',
      default: 2,
      required: true,
      options: [
        { value: 0, label: 'None' },
        { value: 1, label: 'First day of the month' },
        { value: 2, label: 'First two days of the month' },
        { value: 3, label: 'First three days of the month' },
        { value: 4, label: 'First four days of the month' },
        { value: 5, label: 'First five days of the month' }
      ]
    },{
      label: 'Peak Days Increase (%)',
      name: 'peak_increase',
      tooltip: `On peak days, both your cost per cubic foot and minimum job
        price will increase by this amount.`,
      type: 'number',
      default: 40,
      required: true
    }]

  },{
    title: `Set your travel pricing rules.`,
    type: 'auto-quote-data',
    name: 'travel_pricing',
    label: 'Travel Pricing',
    fields: [{
      placeholder: 'Cost Per Mile',
      name: 'cost_per_mile',
      tooltip: `This is the base rate you charge per mile. You can also provide
        a certain number of miles for free (below).`,
      type: 'currency',
      allowDecimals: true,
      default: '300',
      required: true
    },{
      name: 'include_distance_to_headquarters',
      label: 'Include Distance to Headquarters',
      type: 'toggle',
      description: `If enabled, when calculating your travel pricing, this will factor the distance from your 
        headquarters to the pickup location, and from the drop off location back to your headquarters.`
    },{
      label: 'Number of Miles Free',
      name: 'miles_free',
      tooltip: `Many companies provide the first few miles for free; typically
        around 10. This means local moves will be cheaper.`,
      type: 'integer',
      default: 10
    },{
      placeholder: 'Cost Per Toll',
      name: 'cost_per_toll',
      tooltip: `Set a flat rate for how much you'd like to pay per toll. We're
        working on getting the exact amount for each toll - in the meantime,
        set a price you're comfortable with.`,
      type: 'currency',
      allowDecimals: true,
      default: 1000,
      required: true
    },{
      placeholder: 'Cost Per Flight of Stairs Per Cubic Foot',
      name: 'cost_per_stairs_per_cubic_foot',
      tooltip: `The price per flight is also based on the move's cubic footage.
        Most companies set a price between 5 and 10 cents per flight per
        cubic foot.`,
      type: 'currency',
      allowDecimals: true,
      default: 8,
      required: true
    },{
      label: 'Flights of Stairs Free',
      name: 'stairs_free',
      tooltip: `Some companies provide the first few flights of stairs for free`,
      type: 'integer',
      default: 0
    },{
      placeholder: 'Cost Per Extra Stop',
      name: 'cost_per_extra_stop',
      tooltip: `Enter the amount you'd like to charge the customer for
        including an extra stop.`,
      type: 'currency',
      allowDecimals: true,
      default: 10000,
      required: true
    }]

  },{
    title: 'Set your professional packing prices. Prices should include the price of the packing materials.',
    type: 'auto-quote-packing',
    name: 'professional_packing',
    label: 'Packing Pricing',
    model: 'boxes'

  },{
    title: `Do you provide storage?`,
    type: 'auto-quote-storage',
    name: 'storage_pricing',
    label: 'Storage Pricing',
    fields: [{
      name: 'storage_provided',
      type: 'switch',
      values: [{
        label: 'Yes',
        value: true
      },{
        label: 'No',
        value: false
      }],
      default: true
    },{
      label: 'Number of Nights Free',
      name: 'nights_free',
      tooltip: `Many companies provide the first night or two for free.
        This is an option but not required.`,
      type: 'integer',
      default: 1
    },{
      placeholder: 'Cost Per Cubic Foot Per Night',
      name: 'cost_per_cubic_foot_per_night',
      tooltip: `This is the base rate you charge customers per night, based on
        cubic footage. This is calculated for any nights past the free nights
        specified above.`,
      type: 'currency',
      allowDecimals: true,
      default: '60',
      required: true
    },{
      placeholder: 'Minimum Price',
      name: 'min_storage_price',
      tooltip: `This is the lowest amount you're willing to accept for a
        night of storage. This would not be imposed for any nights you provide
        for free (specified above).`,
      type: 'currency',
      allowDecimals: true,
      default: 4000,
      required: true
    },{
      label: 'Maximum Nights',
      name: 'max_nights',
      tooltip: `If you can only keep items in storage for so long, specify a
        maximum number of nights here. If not, select 'Unlimited'.`,
      type: 'select',
      default: false,
      required: true,
      options: [
        { value: false, label: 'Unlimited' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
        { value: 13, label: '13' },
        { value: 14, label: '14' },
        { value: 15, label: '15' },
        { value: 16, label: '16' },
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
        { value: 23, label: '23' },
        { value: 24, label: '24' },
        { value: 25, label: '25' },
        { value: 26, label: '26' },
        { value: 27, label: '27' },
        { value: 28, label: '28' },
        { value: 29, label: '29' },
        { value: 30, label: '30' }
      ]
    }]

  },{
    title: `Let us know if you have special handling rules for certain items.`,
    type: 'auto-quote-special-pricing',
    name: 'special_items_pricing',
    label: 'Special Items Pricing',
    model: 'inventory_items'
  },{
    title: `What administrative fee would you like to charge?`,
    subtitle: `Once a quote is calculated, you can add this percentage before
      sending the final value to the customer. This is used to cover general
      overhead and administrative costs you have.`,
    type: 'auto-quote-data',
    name: 'administrative',
    label: 'Administrative',
    saveDirect: true,
    fields: [{
      label: 'Admin Fee (%)',
      name: 'admin_fee',
      type: 'number',
      default: '15',
      required: true
    }]

  },{
    title: `Set your service range.`,
    type: 'auto-quote-locations',
    name: 'service_area',
    label: 'Service Area',
    saveDirect: true
  },{
    title: `Thanks! Your auto-quotes are now set up.`,
    subtitle: `We will begin generating quotes using your pricing rules. You
      can update your prices at any time by clicking "Auto Quote Pricing" at
      the top left of your screen. If you have any questions, feel free to
      contact your Moved representative. Happy Moving!`,
    type: 'auto-quote-complete',
    name: 'complete',
    disableBack: true,
    hideFromSummary: true
  }]

});
