angular.module('tasks').component('screenPacking', {
  templateUrl: '/templates/tasks/screen-packing.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&',
    onClose: '&'
  },
  controller: function($timeout,User,Move,Task,Field) {
    'ngInject';

    let cacheBoxes; // store latest set of box data for update comparisons

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      Task.getItemList('boxes').then((boxes) => {
        this.boxes = boxes;
        updateEstimates();
        this.ready = true;
      }).catch(error);
      this.screen.value = Field.getValue(this.screen.fields[0],this.task);
    };

    this.$doCheck = () => {
      if(!angular.equals(cacheBoxes,this.task.data.boxes)) updateEstimates();
    }

    const updateEstimates = () => {
      if(!this.boxes) return;
      cacheBoxes = angular.copy(this.task.data.boxes);
      this.estimates = {
        packing: 0,
        packing_unpacking: 0
      };
      this.boxes.forEach((box)=> {
        box.type = 'integer';
        box.parent = 'boxes';
        box.count = Field.getValue(box,this.task);
        angular.forEach(this.estimates,(value,name) => {
          if(!box.included_for_packing) return;
          this.estimates[name] += box.count * box[`default_${name}_cost`];
        });
      });
    };

    this.cardClasses = (card) => {
      let classList = [card.class];
      if(this.screen.value == card.value) classList.push('selected');
      return classList;
    }

    this.onClick = (value) => {
      this.screen.value = value;
      if(this.screen.onChange) this.screen.onChange(buildData());
      if(!this.screen.summary) this.next(value);
    }

    this.next = (value) => {
      if(this.loading) return;
      this.loading = true;
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      data = data || {};
      Field.buildSaveData({ name: this.screen.name, value: this.screen.value }, data);
      return data;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }
});
