angular.module('vendor').controller('moveSummaryModalController',
  function($scope, $filter, Move, Field, Transaction, Alerts, Payment, AddressUtil, params) {
  'ngInject';

  $scope.move = params.transaction.move;
  $scope.task = params.transaction.move_task;
  $scope.mover = Move.findCustomer($scope.move).user;

  $scope.sections = {
    top: {
      fields: [{
        label: 'Move date',
        name: 'move_date'
      }, {
        name: 'time_preference',
        isHidden: () => angular.isObject(params.transaction.arrival_window) &&
            !angular.equals(params.transaction.arrival_window, {})
      }, {
        label: 'Arrival window',
        name: 'arrival_window',
        value: params.transaction.arrival_window,
        type: 'arrival_window'
      }, {
        label: 'Phone number',
        name: 'contact_phone',
        type: 'phone_number',
        value: Field.getValue(Field.get('contact_phone','book-movers'),$scope.task) || $scope.mover.phone
      }, {
        label: 'Email address',
        name: 'contact_email',
        type: 'email',
        value: Field.getValue(Field.get('contact_email','book-movers'),$scope.task) || $scope.mover.email
      },{
        label: (params.transaction.is_hourly && params.transaction.status !== 'final') ? 'Rate' : 'Total',
        name: 'rate',
        type: 'text', // override currency type to support '/hour' string and custom formatting below
        value: (params.transaction.is_hourly && params.transaction.status !== 'final') ?
            (params.transaction.rate ? `${$filter('currency')(params.transaction.rate/100)}/hour` : '&mdash;') :
            (params.transaction.total ? $filter('currency')(params.transaction.total/100) : '&mdash;')
      }]
    },
    middle: {
      label: 'Addresses',
      fields : [{
        name: 'from_address',
        display: 'condensed'
      }, {
        name: 'stops',
        display: 'condensed',
        isHidden: () => {
          let field = Field.get('stops','book-movers');
          return !field.list.filter(item => {
            return Field.getValue(angular.merge({},field,item), $scope.task);
          }).length;
        }
      }, {
        name: 'to_address',
        display: 'condensed'
      }]
    },
    bottom: {
      label: 'Details',
      fields : [{
        label: 'Move Type',
        name: 'move_size'
      }, {
        label: 'Inventory',
        name: 'inventory',
        type: 'calculate',
        labelValue: true
      }, {
        label: 'Boxes',
        name: 'boxes',
        type: 'calculate',
        labelValue: true
      }, {
        name: 'professional_packing'
      }, {
        label: 'Additional insurance',
        name: 'additional_insurance'
      }]
    }
  };

  let price_breakdown = (params.transaction.data && params.transaction.data.price_breakdown) ?
    params.transaction.data.price_breakdown : {};

  // get field defaults values
  angular.forEach($scope.sections, (section, key) => {
    section.fields.forEach(field =>
      angular.merge(field,Field.get(field.name,'book-movers'),angular.copy(field)));
    if(key === 'top') {
      if(params.transaction.is_hourly) {
        $scope.sections[key].fields.push({
          name: 'total_hours',
          label: 'Number of Hours',
          type: 'number',
          value: price_breakdown.total_hours || 0
        },{
          name: 'additional_fees',
          label: 'Additional Fees',
          type: 'currency',
          value: (() => {
              // backward compatibility (reduce if array, return value if not)
              if(!price_breakdown.additional_fees) return 0;
              if(Array.isArray(price_breakdown.additional_fees))
                return price_breakdown.additional_fees.reduce((sum,fee) => sum + fee.amount,0);
              else return price_breakdown.additional_fees;
            })() || 0
        },{
          name: 'hourly_rate',
          label: 'Hourly Rate',
          type: 'currency',
          value: price_breakdown.hourly_rate || 0
        });
      } else {
        $scope.sections[key].fields.push({
          name: 'volume_price',
          label: 'Volume',
          type: 'currency',
          value: price_breakdown.volume_price || 0
        },{
          name: 'travel_price',
          label: 'Travel',
          type: 'currency',
          value: price_breakdown.travel_price || 0
        },{
          name: 'tolls_price',
          label: 'Tolls',
          type: 'currency',
          value: price_breakdown.tolls_price || 0
        },{
          name: 'stairs_price',
          label: 'Stairs',
          type: 'currency',
          value: price_breakdown.stairs_price || 0
        },{
          name: 'inventory_price',
          label: 'Special Items',
          type: 'currency',
          value: price_breakdown.inventory_price || 0
        },{
          name: 'storage_price',
          label: 'Storage',
          type: 'currency',
          value: price_breakdown.storage_price || 0
        },{
          name: 'packing_price',
          label: 'Packing',
          type: 'currency',
          value: price_breakdown.packing_price || 0,
          isHidden: () => params.transaction.data ? params.transaction.data.packing_option != 'packing' : true
        },{
          name: 'packing_unpacking_price',
          label: 'Packing & Unpacking',
          type: 'currency',
          value: price_breakdown.packing_unpacking_price || 0,
          isHidden: () => params.transaction.data ? params.transaction.data.packing_option != 'packing_unpacking' : true
        });
      }
    }
  });

  // function to return display value for move status
  $scope.getStatus = () => {
    if(params.transaction.status == 'final') return 'Delivered';
    else if(params.transaction.status == 'declined') return 'Processing';
    else if(['authorized','approved','charged'].includes(params.transaction.status)) {
      let move_date = moment(Field.getValue(Field.get('move_date','book-movers'),$scope.task));
      if (move_date.isSame(new Date(), 'day') ||
        move_date.isBefore(new Date(), 'day')) return 'In Transit';
      else return 'Booked';
    }
    return 'Pending';
  };

  // add submit function to mark as delivered
  $scope.submit = () => {
    // If Stripe isn't connected, open the connect modal
    if(!params.vendor.has_stripe) return Payment.modals.stripeConnect(params.vendor, true);
    // Otherwise open the finalize transaction modal
    Transaction.modals.finalize(params.transaction).result.then(result => {
      Alerts.success({ msg: 'Transaction finalized', delay: 3 });
      $uibModalInstance.close();
    }).catch(angular.noop);

  };

});
