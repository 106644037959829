angular.module('user').controller('accountController',
function ($scope, $state, $timeout, $q, $filter, $sce, moment,
  User, Image, Token, Access, Alerts, Move, Task, Field, MoveQuote,
  Vendor, VendorAddresses, AddressUtil, Transaction) {
  'ngInject';

  if(Access.limit('user', () => $state.reload())) return;

  $scope.user = User.get();
  $scope.$on('userUpdated',() => $scope.user = User.get());

  $scope.panels = [{
    name:'profile',
    label:'Summary',
    sections: [{
      name: 'details',
      label: 'Your Details',
      show: () => true,
      rows: [{
        label: 'Profile Image',
        icon: 'account_circle',
        staticValue: $sce.trustAsHtml('<profile-image></profile-image>'),
        edit: () => Image.modals.upload('user-profile').result.then(response =>
          User.update(User.get().id,{ profile_pic: response.url })).catch(angular.noop),
        class: 'profile-row'
      },{
        label: 'Name',
        icon: 'person',
        value: () => User.get().fullname,
        edit: () => User.modals.editUser({
          windowClass:'bounce', data: { edit: { name: true } }
        }).result.then(() => Alerts.success({msg:'Updated!',delay:3})).catch(angular.noop)
      },{
        label: 'Email',
        icon: 'email',
        value: () => User.get().email,
        edit: () => User.modals.editUser({
          windowClass:'bounce', data: { edit: { email: true } }
        }).result.then(() => Alerts.success({msg:'Updated!',delay:3})).catch(angular.noop)
      /* Not needed right now
      },{
        label: 'Phone',
        icon: 'call',
        value: () => $filter('phonenumber')(User.get().phone),
        edit: () => User.modals.editUser({
          windowClass:'bounce', data: { edit: { phone: true } }
        }).result.then(() => Alerts.success({msg:'Updated!',delay:3})).catch(angular.noop)
      */
      }]
    },{
      name: 'headquarters',
      label: 'Locations',
      show: () => ($scope.user.role === 'vendor'),
      rows: [{
        label: 'Headquarters',
        icon: 'domain',
        description: 'This address is used to calculate your service area and to determine travel pricing for auto quotes.',
        value: () => {
          let address = ($scope.vendor && $scope.vendor.vendor_addresses) ? $scope.vendor.vendor_addresses.find(a => a.name == 'headquarters') : false;
          return address ? AddressUtil.getFormatted(address) : 'Enter your address';
        },
        edit: () => VendorAddresses.modals.editVendorHq($scope.vendor).result.then((res) => Alerts.success({msg:res,delay:3})).catch(angular.noop)
      }]
    }]
  }];

  $scope.forms = {};

  if($scope.user.role === 'vendor')
    Vendor.get($scope.user.vendor.id).then(results => {
      $scope.vendor = results;
      $scope.ready = true
    }).catch(angular.noop);
  else $scope.ready = true;

  /* pubnub vendorData integration */
  $scope.$on('vendor.data',(event,id,message) => updateVendorData(id,message));
  function updateVendorData(id,vendor) {
    if(!$scope.vendor || id !== $scope.vendor.id) return;
    angular.merge($scope.vendor, vendor);
  }

  /* NOT NEEDED YET */
  $scope.selectPanel = (panel) => {
    if(panel.inactive) return;
    $scope.activePanel = panel.name;
  };
  $scope.selectPanel($scope.panels[0]);

  const initPasswordForm = (reset) => {
    if(reset) {
      $scope.forms.passwordForm.$setPristine();
      $scope.forms.passwordForm.loading = false;
    }
    $scope.password = {
      password:'',
      confirm:''
    };
  };
  initPasswordForm();

  $scope.matchPasswords = () => {
    if($scope.password.password != $scope.password.confirm) {
      $scope.forms.passwordForm.confirm.$setValidity('match',false);
    } else {
      $scope.forms.passwordForm.confirm.$setValidity('match',true);
    }
  };

  var valid = (form) => {
    form.$setSubmitted();
    if(form.inviteVendor) validateVendor(form);
    if(form.$invalid) return false;
    return true;
  };

  $scope.changePassword = () => {
    if($scope.forms.passwordForm.loading) return;
    if(!valid($scope.forms.passwordForm)) return false;
    $scope.forms.passwordForm.loading = true;
    let data = {
      password:$scope.password.password
    };
    User.update($scope.user.id, data).then((result) => {
      Alerts.success({
        msg: 'Your password has been successfully updated.'
      });
      $scope.showPasswordForm = false;
      $timeout(() => initPasswordForm(true), 300);
    }, (result) => {
      $scope.forms.passwordForm.loading = false;
      if(!result || !result.data) Alerts.error();
      switch (result.data.message) {
        case 'Password must be at least 8 characters in length.':
          Alerts.error({ msg: `'Your password does not meet the minimum length
            requirement (8 characters).` });
          break;
        default:
          Alerts.error();
      }
    });
  };

  // Update email preferences
  $scope.$watch('user.new_message_notification',(newVal, oldVal) => {
    if(newVal != oldVal)
      User.update($scope.user.id, {new_message_notification:newVal});
  });

  // update user allow-sms-notification preferences
  $scope.$watch('user.allow_sms_notification',(newVal, oldVal) => {
    if(newVal != oldVal)
      User.update($scope.user.id, {allow_sms_notification:newVal});
  });

  if(User.get().role == 'mover') User.getReferralLink().then((result) => {
      if(result) $scope.referralLink = result.url;
    }).catch(angular.noop);

  if(User.get().role == 'concierge') {
    // Concierge Invitation form
    $scope.inviteTypes = ['channel-partner'];
    if(User.get().level == 2) $scope.inviteTypes.push('vendor','concierge');
    $scope.invite = {
      type: $scope.inviteTypes[0],
      email: ''
    };
    $scope.sendInvite = (form) => {
      if(form.loading) return;
      // validate the form
      if(!valid(form)) return false;
      form.loading = true;
      // submit the email token request
      Token.create($scope.invite).then((result) => {
        // clear the form
        Alerts.success({ msg: 'Invitation sent to '+$scope.invite.email});
        $scope.invite.email = '';
        form.loading = false;
        form.$setPristine();
      }, (result) => {
        form.loading = false;
        if(result.data.meta.error.message == 'email already in use')
          Alerts.error({msg:'That email address is already registered, please try a different one.'});
        else Alerts.error();
      });
    };

    $scope.sendVendorInvite = (form) => {
      if(form.loading) return;
      // validate the form
      if(!valid(form)) return false;
      form.loading = true;
      Token.inviteVendor($scope.invite.vendor.id,{email:$scope.invite.email}).then((result) => {
        // clear the form
        Alerts.success({ msg: 'Invitation sent to '+$scope.invite.email});
        $scope.invite.email = '';
        $scope.invite.vendor = false;
        form.loading = false;
        form.$setPristine();
      }, (result) => {
        form.loading = false;
        if(result.data && result.data.message) Alerts.error({msg:result.data.message});
        else Alerts.error();
      });
    };

    $scope.updateVendor = (vendor) => $scope.invite.vendor = vendor;

    $scope.$watch(() => $scope.invite.type, () => {
      if($scope.invite.type != 'vendor') delete $scope.invite.vendor;
    });

    // offline hours functionality
    $scope.offline = {
      start: {
        name:'offlineStart',
        value: User.get().data && User.get().data.away ? moment(User.get().data.away.start,'HH:mmZ').format('h:mma') : null,
        label: 'Start',
        appendToBody: false,
        custom: { interval: '15min' }
      },
      end: {
        name:'offlineEnd',
        label: 'End',
        value: User.get().data && User.get().data.away ? moment(User.get().data.away.end,'HH:mmZ').format('h:mma') : null,
        appendToBody: false,
        custom: { interval: '15min' }
      }
    };

    $scope.changeOffline = (form) => {
      if(form.loading) return;
      if(!valid(form)) return false;
      form.loading = true;
      let data = { data: angular.copy(User.get().data) || {} };
      data.data.away = {
        start: moment($scope.offline.start.value,'h:mma').utc().format('HH:mm[Z]'),
        end: moment($scope.offline.end.value,'h:mma').utc().format('HH:mm[Z]')
      };
      User.update(User.get().id,data).then(result => {
        Alerts.success({msg: 'Your offline hours have been successfully updated.'});
        User.get().data = data.data;
        form.loading = false;
        $scope.offline.show = false;
      }, () => {
        form.loading = false;
        Alerts.error();
      });
    };

  }

});
