angular.module('core').component('sortTable', {
  templateUrl: '/templates/core/sort-table.html',
  bindings: {
    columns: '<', // array of columns with options
    data: '<', // array of data objects
    sort: '<', // table active sort order
    actions: '<?actions' // table actions object
  },
  controller: function() {
    'ngInject';

    // initialize the component
    this.$onInit = () => {
      // default options
      this.actions = angular.merge({},{
        sort: false,
        rowOverlay: false,
        rowClick: false
      },this.actions);

      // wrap action handlers
      this.click = {
        sort: (field) => {
          if(field.sortable && this.actions.sort) this.actions.sort(field);
        },
        row: (row) => {
          if(this.actions.rowClick) this.actions.rowClick(row);
        },
        rowAction: (row,event) => {
          event.stopPropagation();
          if(this.actions.rowOverlay) this.actions.rowOverlay.click(row);
        }
      };
      if(this.onInit) this.onInit();
    };

    // watch for changes
    this.$onChanges = () => {
      if(this.onUpdate) this.onUpdate();
    };

  }
});
