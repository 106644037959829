angular.module('core').factory('Alerts', function($timeout) {
  'ngInject';

  let timer = false;

  // set a timer to close the current alert
  var setTimer = () => {
    timer = $timeout(() => alerts.close(0), (alerts.queue[0].delay||6)*1000);
  };

  // API functions
  var alerts = {
    queue:[],
    set: (data) => {
      // if msg is already queued, exit.
      if(alerts.queue.find((alert) => alert.msg == data.msg)) return;
      // push the alert
      alerts.queue.push(data);
      // set the timer
      if(!timer) setTimer();
    },
    close: (index) => {
      if(typeof(index) !== "undefined") {
        // clear this alert
        alerts.queue.splice(index,1);
        // reset timers
        if(index === 0) {
          $timeout.cancel(timer);
          if(alerts.queue.length) setTimer();
          else timer = false;
        }
      } else {
        // clear queue & timers
        alerts.queue = [];
        $timeout.cancel(timer);
        timer = false;
      }
    },

    // Shortcut functions for common alerts
    error: (data) => {
      var defaults = {
        type: 'danger',
        msg: 'Wow! We really broke something... '+
          'You might want to try turning if off and on again.',
        delay: 6
      };
      alerts.set(angular.extend({},defaults,data));
    },
    warn: (data) => {
      var defaults = { type: 'warning' };
      alerts.set(angular.extend({},defaults,data));
    },
    info: (data) => {
      var defaults = { type: 'info' };
      alerts.set(angular.extend({},defaults,data));
    },
    success: (data) => {
      var defaults = { type: 'success' };
      alerts.set(angular.extend({},defaults,data));
    }

  };

  return alerts;

});
