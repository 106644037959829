angular.module('move').factory('Move',
function($rootScope, $http, Data, Modal, User) {
  'ngInject';

  const appList = [{
    label: 'Direct',
    name: 'direct',
  },{
    label: 'Consumer',
    name: 'consumer',
  },{
    label: 'Resident',
    name: 'resident',
  },{
    label: 'Storage',
    name: 'storage',
  }];

  const statusList = [{
      label: 'Core',
      name: 'core',
      id: 0
    },{
      label: 'Active',
      name: 'active',
      id: 1
    },{
      label: 'Inactive',
      name: 'inactive',
      id: 2
    },{
      label: 'Archived',
      name: 'archived',
      id: 3
    }];

  const API = {
    get: (id) => {
      return $http.get(`${Data.apiUrl}/v2/moves`+(id ? `/${id}` : '')).then((result) => {
        return id ? result.data : result.data[0];
      });
    },
    getAll: (data) => {
      return $http.get(`${Data.apiUrl}/v2/moves`,{params:data}).then((result) => {
        result.data.forEach(m => m.task_data = m.task_data || {});
        return {
          data: result.data,
          total: angular.isDefined(result.headers('X-Total-Count')) ? result.headers('X-Total-Count') : 0
        };
      });
    },
    search: (data) => $http.post(`${Data.apiUrl}/cx/move-summaries/search`,data).then(r => r.data),
    searchById: (id) => $http.get(`${Data.apiUrl}/cx/move-summaries/${id}`).then(r => r.data.data),
    find: (data) => {
      return $http.get(`${Data.apiUrl}/v2/moves`,{params:data}).then((result) => result.data[0]);
    },
    getIDs: (data) => {
      return $http.get(`${Data.apiUrl}/v2/moves/ids`,{params:data}).then((result) => {
        return result.data;
      });
    },
    update: (id,data) => {
      if(angular.equals(data.task_data,{})) delete data.task_data;
      return $http.patch(`${Data.apiUrl}/v2/moves/${id}`,data).then((result) => {
        return result.data;
      });
    },
    /* hopefully one of the last few changes to this app ever! */
    /* exit the angular eco-system! */
    createConsumerMove: (userId) => {
      return $http.post(`${Data.apiUrl}/residents/users/${userId}/moves`).then(r => r.data.data.move);
    },
    getSoldOutDates: (id) => {
      return $http.get(`${Data.apiUrl}/moves/${id}/sold-out-dates`).then(r => r.data.data.sold_out_dates);
    },
    findCustomer: (move) => {
      if(move && move.user) return { user: move.user }; // wrap in fake move_user
      let moveRoles = ['mover','channel-partner'];
      return move.move_users.find((moveUser) => (moveRoles.indexOf(moveUser.user.role) != -1 && moveUser.primary));
    },
    findCXM: (move) => {
      let moveRoles = ['admin','concierge'];
      return move.move_users.find((moveUser) => (moveRoles.indexOf(moveUser.user.role) != -1 && moveUser.primary));
    },
    findSelf: (move) => {
      return move.move_users ? move.move_users.find((moveUser) => moveUser.user.id == User.get().id) : false;
    },
    findUser: (move, user_id) => {
      return move.move_users ? move.move_users.find((moveUser) => moveUser.user.id == user_id) : false;
    },
    findMoveTask: (id, move) => {
      return move.move_tasks ? move.move_tasks.find(task => task.id == id) : false;
    },
    findMoveTasks: (name, move) => {
      return move.move_tasks ? move.move_tasks.filter(task => task.task.name == name) : [];
    },
    findMoveTransaction: (id, move) => {
      return move.move_transactions ? move.move_transactions.find(transaction => transaction.id == id) : false;
    },
    getSource: (move) => {
      return move.source && move.source.channel ? move.source.channel.toLowerCase() : false;
    },
    isDirectApp: (move) => {
      return move.app === 'direct' || move.app === null;
    },
    appList: appList,
    statusList: statusList,
    moveUser: {
      get: (data) => $http.get(`${Data.apiUrl}/v2/move_users`,{params:data}).then(r => r.data),
      create: (data) => $http.post(`${Data.apiUrl}/v2/move_users`,data).then(r => r.data),
      update: (id, data) => $http.patch(`${Data.apiUrl}/v2/move_users/${id}`,data).then(r => r.data),
      delete: (id) => $http.delete(`${Data.apiUrl}/v2/move_users/${id}`).then(r => r.data)
    },
    modals: {
      summary: (transaction, vendor, options) => {
        return Modal.open(angular.merge({
          controller: 'moveSummaryModalController',
          templateUrl: '/templates/vendor/move-summary-modal.html',
          windowClass: 'bounce wide',
          resolve: {
            params: () => ({
              transaction: transaction,
              vendor: vendor
            })
          }
        }, options));
      },
      editSource: (move, options) => {
        return Modal.open(angular.merge({
          controller: 'moveSourceModalController',
          templateUrl: '/templates/cx/move-source-modal.html',
          resolve: {
            params: () => ({
              move: move
            })
          }
        }, options));
      },
      initiateCancelMove: (task, options) => {
        return Modal.open(angular.merge({
          controller: 'cancelMoveModalController',
          templateUrl: '/templates/move/cancel-move-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            params: () => ({ task })
          }
        }, options));
      },
      repeatMove: (user, options) => {
        return Modal.open(angular.merge({
          controller: 'repeatMoveModalController',
          templateUrl: '/templates/move/repeat-move-modal.html',
          windowClass: 'bounce',
          resolve: {
            params: () => ({
              user: user
            })
          }
        }, options));
      }
    }
  };

  return API;

});
