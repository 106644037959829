angular.module('payment').factory('VendorAddresses',
function(Modal) {
  'ngInject';

  return {
    modals: {
      editVendorHq: (vendor, options) => {
        return Modal.open(angular.merge({
          controller: 'vendorHqModalController',
          templateUrl: '/templates/payment/vendor-hq-modal.html',
          windowClass: 'bounce',
          resolve: {
            params: () => ({
              vendor: vendor
            })
          }
        },options));
      }
    }
  };

});
