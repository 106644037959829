angular.module('core').component('navigation', {
  template: `
    <nav-item item="item"
      ng-repeat="item in $ctrl.nav | orderBy: 'order'"
      ng-class="item.classes"
      ng-if="item.show ? item.show() : true"></nav-item>
  `,
  bindings: {
    name: '@' // menu name
  },
  controller: function($rootScope, Menu, User) {
    'ngInject';

    this.$onInit = () => {
      this.nav = Menu.get(this.name);
    };

    $rootScope.$on('userUpdated', () => {
      this.nav = Menu.get(this.name);
    });

  }
});
