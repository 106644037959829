angular.module('core').directive('compile',
function($compile,$timeout) {
  'ngInject';

  return {
    restrict: 'A',
    link: (scope, element, attr) => {
      scope.$watch(attr.ngBindHtml, () => {
        $compile(element.contents())(scope);
      }, true);
    }
  };
});
