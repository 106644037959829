angular.module('user').controller('welcomeModalController',
function($rootScope,$scope,$uibModalInstance,$cookies,move) {
  'ngInject';

  if(move.partner && move.partner.metadata && move.partner.metadata.custom_copy)
    $scope.partnerCopy = move.partner.metadata.custom_copy.welcome;

  $scope.close = () => {
    let task = 'book-movers';
    if($cookies.get('triggerWelcome')) {
      if(JSON.parse($cookies.get('triggerWelcome')) !== true)
        task = JSON.parse($cookies.get('triggerWelcome'));
      $cookies.remove('triggerWelcome');
    }
    $rootScope.$broadcast('trigger:task',task);
    $uibModalInstance.close();
  }

});
