angular.module('move').constant('CleaningFlow', {

  clean: {
    flows: {
      default: [
        { name: 'clean_address' },
        { name: 'clean_rooms' },
        { name: 'clean_bathrooms' },
        { name: 'clean_addons' },
        { name: 'clean_date' },
        { name: 'clean_time' },
        { name: 'review' },
      ]
    },
    screens: {

      clean_address: {
        title: 'Where would you like the cleaning?',
        editTitle: 'Where would you like the cleaning?',
        type: 'address-list',
        name: 'clean_address',
        model: 'address',
        label: 'Address',
        editable: true,
        fields: [{
          name: 'address',
          type: 'complete-address',
          label: 'Address',
          required: true,
          include: {
            type: true,
            unit: true,
            floor: true
          }
        }]
      },

      clean_rooms: {
        title: 'Got it. How many bedrooms need to be cleaned?',
        editTitle: 'How many bedrooms need to be cleaned?',
        type: 'custom',
        name: 'clean_rooms',
        label: 'Bedroom Count',
        editable: true,
        fields: [{
          name: 'rooms',
          type: 'select',
          label: 'Bedroom Count',
          options: [{
            value: 1,
            label: '1'
          },{
            value: 2,
            label: '2'
          },{
            value: 3,
            label: '3'
          },{
            value: 4,
            label: '4'
          },{
            value: 5,
            label: '5'
          },{
            value: 6,
            label: '6'
          },{
            value: 7,
            label: '7'
          },{
            value: 8,
            label: '8'
          },{
            value: 9,
            label: '9'
          },{
            value: 10,
            label: '10'
          }],
          required: true
        }]
      },

      clean_bathrooms: {
        title: 'And how many bathrooms need to be cleaned?',
        editTitle: 'How many bathrooms need to be cleaned?',
        type: 'custom',
        name: 'clean_bathrooms',
        label: 'Bathroom Count',
        editable: true,
          fields: [{
          name: 'bathrooms',
          type: 'select',
          label: 'Bathroom Count',
          options: [{
            value: 1,
            label: '1'
          },{
            value: 2,
            label: '2'
          },{
            value: 3,
            label: '3'
          },{
            value: 4,
            label: '4'
          },{
            value: 5,
            label: '5'
          },{
            value: 6,
            label: '6'
          },{
            value: 7,
            label: '7'
          },{
            value: 8,
            label: '8'
          },{
            value: 9,
            label: '9'
          },{
            value: 10,
            label: '10'
          }],
          required: true
        }]
      },

      clean_addons: {
        title: 'Any add-on services?',
        editTitle: 'Any add-on services?',
        type: 'custom',
        name: 'clean_addons',
        label: 'Addons',
        editable: true,
          fields: [{
          name: 'addons',
          type: 'checklist',
          label: 'Add-ons',
          list: [
            { label: 'Inside Oven', value: 'Inside Oven' },
            { label: 'Inside Windows', value: 'Inside Windows' },
            { label: 'Inside Walls', value: 'Inside Walls' },
            { label: 'Inside Fridge', value: 'Inside Fridge' },
            { label: 'Inside Cabinets', value: 'Inside Cabinets' },
            { label: 'Blinds', value: 'Blinds' }
          ]
        }]
      },

      clean_date: {
        title: 'When would you like to schedule this cleaning?',
        editTitle: 'When would you like to schedule this cleaning?',
        type: 'custom',
        name: 'clean_date',
        label: 'Date',
        editable: true,
          fields: [{
          label: 'Cleaning date',
          name: 'date',
          type: 'date',
          required:true,
          custom: { limit: 'future' }
        }]
      },

      clean_time: {
        title: 'Last question. What time of day would you like the cleaning?',
        editTitle: 'What time of day would you like the cleaning?',
        name: 'clean_time',
        label: 'Time Preference',
        editable: true,
        type: 'custom',
        fields: [{
          label: 'Time preference',
          name: 'time_preference',
          type: 'select',
          options: [{
            value: 'Morning',
            label: 'Morning'
          },{
            value: 'Afternoon',
            label: 'Afternoon'
          },{
            value: 'No preference',
            label: 'No preference'
          }],
          required: true
        }]
      },

      review: {
        title: 'Summary',
        type: 'review',
        name: 'review',
        hideSummary: true,
        back: false,
        sections: [{
          fields: [{
            name: 'address',
            display: 'summary'
          },{
            name: 'rooms'
          },{
            name: 'bathrooms'
          },{
            name: 'addons'
          },{
            name: 'date'
          },{
            name: 'time_preference'
          }]
        }]
      },

      // Complete
      complete: {
        type: 'complete',
        name: 'complete',
        title: 'Finished!',
        subtitle: 'Your assistant is on it and will send you an update shortly.',
        button: 'Done',
        back: 'review',
      },


    }
  },

});
