angular.module('payment').factory('Transaction',
function($http, $q, Data, Modal) {
  'ngInject';

  let promise;

  const defaultTransactionMap = {
    'book-movers': 1,
    'packing-materials': 2,
    'storage': 3,
    'update-address': 7,
    'donate': 5,
    'dispose': 4,
    'sell': 6,
    'clean': 12,
    'paint': 13,
    'custom': 16
  };

  const statusList = [{
    label: 'Pending',
    value: 'pending',
    canRequestRequote: true
  }, {
    label: 'Authorized',
    value: 'authorized',
    canRequestRequote: true
  }, {
    label: 'Approved',
    value: 'approved',
    canRequestRequote: true
  }, {
    label: 'Charged',
    value: 'charged',
    canRequestRequote: true
  }, {
    label: 'Final',
    value: 'final'
  }, {
    label: 'Declined',
    value: 'declined'
  }, {
    label: 'Never Booked',
    value: 'never_booked'
  }, {
    label: 'Customer Cancelled',
    value: 'customer_cancelled'
  }, {
    label: 'Vendor Cancelled',
    value: 'vendor_cancelled'
  }];

  const API = {
    getTypes: () => {
      if(!promise) promise = $http.get(`${Data.apiUrl}/v2/transactions`);
      return promise.then(r => r.data);
    },
    getAll: (data) => {
      let promises = {
        transactions: promise ? promise.then((r) => r.data) : API.getTypes(),
        move_transactions: $http.get(`${Data.apiUrl}/v2/move_transactions`,
          {params:data})
      };
      return $q.all(promises).then((results) => {
        results.move_transactions.data.forEach((mt) => {
          mt.transaction = results.transactions.find((t) => t.id == mt.transaction.id);
        });
        return {
          data: results.move_transactions.data,
          total: angular.isDefined(results.move_transactions.headers('X-Total-Count')) ?
            results.move_transactions.headers('X-Total-Count') : 0
        };
      });
    },
    get: (id) => {
      let promises = {
        transactions: promise ? promise.then((r) => r.data) : API.getTypes(),
        move_transaction: $http.get(`${Data.apiUrl}/v2/move_transactions/${id}`)
          .then((result) => result.data)
      };
      return $q.all(promises).then((results) => {
        results.move_transaction.transaction = results.transactions.find((t) =>
          t.id == results.move_transaction.id);
      });
    },
    create: (data) => {
      return $http.post(`${Data.apiUrl}/v2/move_transactions`, data).then(r => r.data);
    },
    update: (id, data) => {
      return $http.patch(`${Data.apiUrl}/v2/move_transactions/${id}`, data).then(r => r.data);
    },
    finalize: (id, data) => {
      return $http.post(`${Data.apiUrl}/v2/move_transactions/${id}/finalize`, data).then(r => r.data);
    },
    refund: (id, data) => {
      return $http.post(`${Data.apiUrl}/v2/move_transactions/${id}/refund`, data).then(r => r.data);
    },
    checkCancellationFee: (id) => {
      return $http.get(`${Data.apiUrl}/v2/move_transactions/${id}/check_cancellation_fee`).then(r => r.data);
    },
    cancel: (id,data) => {
      return $http.post(`${Data.apiUrl}/v2/move_transactions/${id}/cancel`, data).then(r => r.data);
    },
    delete: (id) => $http.delete(`${Data.apiUrl}/v2/move_transactions/${id}`),
    resendConfirmationMail: (id) => $http.post(`${Data.apiUrl}/v2/move_transactions/${id}/resend_booking_confirmation`),
    getDefault: (task_name) => defaultTransactionMap[task_name],
    getStatusList: () => statusList,
    modals: {
      create: (move, moveTask, options) => {
        return Modal.open(angular.merge({},{
          controller: 'transactionCreateModalController',
          templateUrl: '/templates/payment/transaction-create-modal.html',
          resolve: {
            move: () => move,
            moveTask: () => moveTask,
            existing: () => false
          }
        },options));
      },
      edit: (move, moveTask, transaction, options) => {
        return Modal.open(angular.merge({},{
          controller: 'transactionCreateModalController',
          templateUrl: '/templates/payment/transaction-create-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            move: () => move,
            moveTask: () => moveTask,
            existing: () => transaction
          }
        },options));
      },
      viewByTask: (moveTask, move, onDelete,options) => {
        return Modal.open(angular.merge({},{
          controller: 'transactionsViewModalController',
          templateUrl: '/templates/payment/transactions-view-modal.html',
          resolve: {
            move: () => move,
            moveTask: () => moveTask,
            onDelete: () => onDelete
          }
        },options));
      },
      finalize: (transaction, options) => {
        return Modal.open(angular.merge({
          controller: 'transactionFinalizeModalController',
          templateUrl: '/templates/vendor/transaction-finalize-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            move_transaction: () => transaction
          }
        }, options));
      },
      refund: (transaction, options) => {
        return Modal.open(angular.merge({
          controller: 'transactionRefundModalController',
          templateUrl: '/templates/payment/transaction-refund-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            move_transaction: () => transaction
          }
        }, options));
      },
      confirm: (transaction, options) =>  {
        return Modal.open(angular.merge({
          controller: 'nonStripeConfirmModalController',
          templateUrl: '/templates/payment/non-stripe-confirm-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            transaction: () => transaction
          }
        }, options));
      },
      cancellationFee: (transaction, options) => {
        return Modal.open(angular.merge({
          controller: 'cancellationFeeModalController',
          templateUrl: '/templates/payment/cancellation-fee-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            transaction: () => transaction
          }
        }, options));
      }
    }
  };

  return API;

});
