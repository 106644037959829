angular.module('tasks').constant('BookMoversFlow', {

  'book-movers': {
    flows: {
      default: [
        { name: 'move_size' },
        { name: 'move_date' },
        { name: 'addresses' }, // onboarding entry point
        { name: 'inventory' },
        { name: 'boxes' },
        { name: 'professional_packing' },
        { name: 'time_preference' },
        { name: 'contact_info' },
        { name: 'booking', markComplete: true},
      ],
      external: [
        { name: 'contact_info' },
        { name: 'move_size', hideExternalSource: ['MMS'] },
        { name: 'move_date' },
        { name: 'addresses' },
        { name: 'inventory' },
        { name: 'boxes' },
        { name: 'professional_packing', hideExternalSource: ['MMS']  },
        { name: 'time_preference' },
        { name: 'move_transaction', markComplete: true}
      ],
      storage: [
        { name: 'move_size' },
        { name: 'move_date' },
        { name: 'addresses' }, // API user entry point
        { name: 'special_items' },
        { name: 'time_preference' },
        { name: 'contact_info' },
        { name: 'booking', markComplete: true}
      ],
      // support iOS and old move data
      legacy: [
        { name: 'move_size' },
        { name: 'move_date' },
        { name: 'addresses' },
        { name: 'inventory' },
        { name: 'boxes' },
        { name: 'professional_packing' },
        { name: 'time_preference' },
        { name: 'contact_info' },
        { name: 'verify' },
        { name: 'move_options' },
        { name: 'booking' },
        { name: 'origin_requirements' },
        { name: 'destination_requirements' },
        { name: 'additional_insurance', markComplete: true}
      ]
    },
    screens: {
      // Move Size
      move_size: {
        title: 'What kind of move is this?',
        type: 'task-data',
        name: 'move_size',
        label: 'Move Size',
        editable: true,
        fields: [{
          label: 'Move Size',
          name: 'move_size',
          type: 'optionslist',
          options: [{
            value: 'Studio',
            label: 'Studio'
          },{
            value: '1 Bedroom',
            label: '1 Bedroom'
          },{
            value: '2 Bedrooms',
            label: '2 Bedrooms'
          },{
            value: '3 Bedrooms',
            label: '3 Bedrooms'
          },{
            value: '4+ Bedrooms',
            label: '4+ Bedrooms'
          },{
            value: 'Office Move',
            label: 'Office Move'
          },{
            value: 'Storage',
            label: 'Storage',
            flowSpecific: ['storage','external']
          }],
          required: true
        },{
          label: 'Storage Size',
          name: 'storage_size',
          type: 'text',
          placeholder: 'Storage Size',
          custom: {
            hidden: {
              boolean: 'not',
              value: 'Storage',
              compare: {
                field: 'move_size'
              }
            }
          }

        }]
      },

      // Move Date
      move_date: {
        title: 'When will you be moving?',
        type: 'move-date',
        name: 'move_date',
        label: 'Move Date',
        editable: true,
        fields: [{
          label: 'Moving Date',
          name: 'move_date',
          type: 'date',
          required: true,
          custom: { limit: 'future' }
        },{
          label: 'Delivery date',
          name: 'delivery_date',
          type: 'date',
          hidden: true
        },{
          label: 'The date is flexible',
          name: 'is_date_flexible',
          type: 'toggle',
          description: `Don't have an exact move date? No problem! Enter your best
            guess and click the toggle to the right so it turns green.`
        }]
      },

      // Addresses
      addresses: {
        title: 'Where are you moving?',
        subtitle: 'Let us know where the movers need to go.',
        label: 'Addresses',
        type: 'addresses',
        name: 'addresses',
        editable: true,
        fields: [{
          name: 'from_address',
          type: 'complete-address',
          required: true,
          placeholder: 'Add Origin Address',
          label: 'Origin Address',
          include: {
            unit: true,
            type: true,
            floor: true,
            flights_of_stairs: true,
            deed: true
          }
        },{
          name: 'to_address',
          type: 'complete-address',
          required: true,
          placeholder: 'Add Destination Address',
          label: 'Destination Address',
          include: {
            unit: true,
            type: true,
            floor: true,
            flights_of_stairs: true,
            deed: true
          }
        },{
          name: 'stops',
          type: 'address-list',
          label: 'Other stops',
          list: [
            {name:'stop1',label:'Stop 1',type:'complete-address'},
            {name:'stop2',label:'Stop 2',type:'complete-address'},
            {name:'stop3',label:'Stop 3',type:'complete-address'}
          ],
          include: {
            unit: true,
            type: true,
            floor: true,
            flights_of_stairs: true
          }
        }]
      },

      // Inventory
      inventory: {
        title: 'What needs to be moved?',
        type: 'grouped-item-list',
        name: 'inventory',
        model: 'inventories',
        label: 'Inventory',
        editable: true,
        fields: [{
          label: 'Inventory List',
          name: 'inventory_list',
          class: 'additional-items',
          placeholder: 'Paste inventory here',
          type: 'textarea'
        }],
      },

      // Special Handling
      special_items: {
        title: 'Are you moving any of these items?',
        subtitle: `The below items require special handling. Do you have any that need to be moved?
          Let us know and we’ll make sure the moving company is equipped for the job.`,
        type: 'special_items',
        name: 'special_items',
        parent: 'inventory',
        model: 'inventories',
        label: 'Special Items',
        editable: true,
        // fields are not rendered on this screen type, just surfaces this for cx
        // needed to show items from api externals who became internals
        fields: [{
          label: 'Inventory List',
          name: 'inventory_list',
          class: 'additional-items',
          placeholder: 'Paste inventory here',
          type: 'textarea',
          hidden: true,
        }],
      },

      // Boxes
      boxes: {
        title: 'Roughly how many boxes need to be moved?',
        subtitle: `Enter your best guess now to get the most accurate moving quote.
          Don’t worry - we’ll confirm the final count before moving day.`,
        type: 'item-list',
        name: 'boxes',
        model: 'boxes',
        label: 'Boxes',
        editable: true,
        fields: [{
          label: 'Do you need to order these items?',
          name: 'order_boxes',
          type: 'switch',
          values: [{
            label: 'Yes, thanks!',
            value: 'Yes'
          },{
            label: 'Nope, I\'m set.',
            value: 'No'
          }],
          required: true
        }]
      },

      // Professional Packing
      professional_packing: {
        title: 'Would you like help packing your personal items into boxes?',
        subtitle: `Moving companies provide wrapping and protection for your furniture
          and larger items as part of their standard quotes. For an additional fee,
          they'll <em>also</em> help pack your items into boxes &mdash; the prices below
          are estimates based on the number of boxes you'll have.`,
        type: 'packing',
        name: 'professional_packing',
        label: 'Professional Packing',
        icon: 'icon-box',
        editable: true,
        fields: [{
          name: 'professional_packing',
          label: 'Professional Packing',
          type: 'select',
          options: [{
            value: 'packing',
            label: 'Packing'
          },{
            value: 'packing_unpacking',
            label: 'Packing & Unpacking'
          },{
            value: 'none',
            label: `Nope, I'll pack myself!`
          }]
        }],
        cards: [{
          title: 'Packing',
          name: 'packing',
          description: `The movers provide the boxes and pack all your personal items.`,
          value: 'packing'
        },{
          title: 'Packing & Unpacking',
          name: 'packing_unpacking',
          description: `The movers provide the boxes, pack everything, and unpack it all at your new home.`,
          value: 'packing_unpacking'
        },{
          title: `Nope, I'll pack my own boxes.`,
          name: 'none',
          description: `The movers will still include wrapping & padding for bigger furniture items.`,
          value: 'none'
        }]
      },

      // Time Preference
      time_preference: {
        title: 'When would you like the movers to arrive?',
        type: 'task-data',
        name: 'time_preference',
        label: 'Time Preference',
        editable: true,
        fields: [{
          label: 'Time preference',
          name: 'time_preference',
          type: 'optionslist',
          options: [{
            value: 'Morning',
            label: 'Morning'
          },{
            value: 'Afternoon',
            label: 'Afternoon'
          },{
            value: 'No preference',
            label: 'No preference'
          }],
          required: true
        }]
      },

      // Contact Info
      contact_info: {
        title: 'Verify your contact info is accurate.',
        type: 'task-data',
        name: 'contact_info',
        label: 'Contact Info',
        editable: true,
        subtitle: 'Why is this needed?',
        subtitleTooltip: `The moving company needs to have this on file so the crew
          can contact you on the day of the move.`,
        fields: [{
          name: 'contact_name',
          type: 'text',
          label: 'Full Name',
          placeholder: 'Full Name',
          required: true,
          custom: {
            default: {
              source: 'user',
              field: 'fullname'
            }
          }
        },{
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
          placeholder: 'Email Address',
          required: true,
          custom: {
            default: {
              source: 'user',
              field: 'email'
            }
          }
        },{
          name: 'contact_phone',
          type: 'phone_number',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          required: true,
          custom: {
            default: {
              source: 'user',
              field: 'phone'
            },
            // TODO: temporarily disabled
            // required: {
            //   boolean: 'eq',
            //   value: true,
            //   compare: {
            //     field: 'allow_sms_notification'
            //   }
            // }
          }
        },{
          label: 'Text notifications',
          name: 'allow_sms_notification',
          type: 'toggle',
          model: 'user',
          description: `Send me text notifications about my quotes, move booking details, and move status`,
          default: true
        }]
      },

      // Quotes
      booking: {
        type: 'quotes',
        name: 'booking',
        back: 'review',
        markComplete: true,
        hideSummary: true
      },

      // Review
      review: {
        title: 'Your Move Summary',
        type: 'review',
        name: 'review',
        back: false,
        sections: [{
          fields: [{
            name: 'move_size'
          },{
            name: 'move_date'
          },{
            name: 'time_preference'
          },{
            name: 'professional_packing'
          }]
        },{
          label: 'Moving From',
          fields: [{
            name: 'from_address',
            label: 'Address',
            saveOnEdit: true,
            display: 'summary'
          }]
        },{
          label: 'Moving To',
          fields: [{
            name: 'to_address',
            label: 'Address',
            saveOnEdit: true,
            display: 'summary'
          }]
        },{
          label: 'Other Stops',
          fields: [{
            name: 'stops',
            saveOnEdit: true,
            display: 'summary'
          }]
        },{
          label: 'Inventory',
          fields : [{
            label: 'Inventory',
            name: 'inventory',
            type: 'calculate',
            screen: 'inventory',
            labelValue: true
          },{
            label: 'Boxes',
            name: 'boxes',
            type: 'calculate',
            screen: 'boxes',
            labelValue: true
          }]
        }]
      },

      // Complete
      complete: {
        title: 'Your request is submitted!',
        subtitle: `You'll receive a confirmation of your request by email
          shortly. Just make sure all of the details are in there!<br/><br>
          Once confirmed that the movers are available for your move, we'll put
          them in touch with you to coordinate a few final details.`,
        type: 'complete',
        name: 'complete',
        button: 'Done',
        back: 'review',
        fields: [{
          name: 'move_date'
        },{
          name: 'arrival_window',
          label: 'Arrival Window',
          type: 'arrival_window',
          model: 'move_transaction',
        },{
          name: 'time_preference',
          custom: {
            statusFilter: ['pending','authorized','approved','charged','declined']
          }
        },{
          name: 'name',
          label: 'Company',
          type: 'text',
          model: 'move_transaction.vendor'
        },{
          name: 'crew_size',
          label: 'Crew Size',
          type: 'text',
          model: 'move_transaction',
        },{
          name: 'rate',
          label: 'Rate',
          type: 'currency',
          model: 'move_transaction',
          custom: {
            is_hourly: true
          }
        },{
          name: 'minimum',
          label: 'Minimum',
          type: 'currency',
          model: 'move_transaction',
          custom: {
            statusFilter: ['pending','authorized','approved','charged','declined']
          }
        },{
          name: 'total',
          label: 'Total',
          type: 'currency',
          model: 'move_transaction',
          custom: {
            statusFilter: ['final']
          }
        }]
      },

      // Internal Transaction Creation
      move_transaction: {
        type: 'move-transaction',
        name: 'move_transaction',
        hidden: true,
        markComplete: true,
      },

      // LEGACY SCREENS (Deprecated but needed for iOS and showing old customers)
      move_options: {
        title: 'What kind of move are you planning?',
        type: 'cards',
        name: 'move_options',
        fieldName: 'quote_option',
        default: 'full_service',
        label: 'Quote Option',
        editable: true,
        fields: [{
          name: 'quote_option',
          label: 'Quote Option',
          type: 'select',
          options: [{
            value: 'full_service',
            label: 'Full Service Movers'
          },{
            value: 'budget',
            label: 'Truck Rental'
          },{
            value: 'labor',
            label: 'Moving Labor'
          }]
        }],
        cards: [{
          title: 'Full Service Movers',
          description: 'They pickup, drive, and drop off.',
          // icon: '$$',
          value: 'full_service'
        },{
          title: 'Truck Rental',
          description: 'Rent a truck and move yourself.',
          value: 'budget'
        },{
          title: 'Moving Labor',
          description: 'Get helping hands only, to load and unload.',
          value: 'labor'
        }]
      },

      verify: {
        title: 'Everything look okay?',
        editTitle: 'Everything look okay?',
        subtitle: `Please verify this information before continuing to your quotes.`,
        type: 'review',
        name: 'verify',
        hideSummary: true,
        sections: [{
          fields: [{
            name: 'move_date'
          },{
            name: 'move_size'
          },{
            name: 'time_preference'
          },{
            name: 'professional_packing'
          }]
        },{
          label: 'Moving From',
          fields: [{
            name: 'from_address',
            label: 'Address',
            saveOnEdit: true,
            display: 'summary'
          }]
        },{
          label: 'Moving To',
          fields: [{
            name: 'to_address',
            label: 'Address',
            saveOnEdit: true,
            display: 'summary'
          }]
        },{
          label: 'Other Stops',
          fields: [{
            name: 'stops',
            saveOnEdit: true,
            display: 'summary'
          }]
        },{
          label: 'Inventory',
          fields : [{
            label: 'Inventory',
            name: 'inventory',
            type: 'calculate',
            screen: 'inventory',
            labelValue: true
          },{
            label: 'Boxes',
            name: 'boxes',
            type: 'calculate',
            screen: 'boxes',
            labelValue: true
          }]
        }]
      },

      origin_requirements: {
        title: 'What is required at the starting location?',
        editTitle: 'What is required at the starting location?',
        type: 'task-data',
        name: 'origin_requirements',
        label: 'Origin Requirements',
        editable: true,
        skip: {
          button: 'I\'m not sure'
        },
        fields: [{
          name: 'from_coi_required',
          label: 'COI required',
          type: 'toggle',
          description: `COI = Certificate of Insurance. Your building may require
            paperwork to be filled out by the movers. This is common and easy to
            handle.`
        },{
          name: 'from_reserve_elevator',
          label: 'Must reserve elevator',
          type: 'toggle'
        },{
          name: 'from_reserve_parking',
          label: 'Must reserve parking',
          type: 'toggle'
        }]
      },

      destination_requirements: {
        title: 'What is required at the ending location?',
        editTitle: 'What is required at the ending location?',
        type: 'task-data',
        name: 'destination_requirements',
        label: 'Destination Requirements',
        editable: true,
        skip: {
          button: 'I\'m not sure'
        },
        fields: [{
          name: 'to_coi_required',
          label: 'COI required',
          type: 'toggle',
          description: `COI = Certificate of Insurance. Your building may require
            paperwork to be filled out by the movers. This is common and easy to
            handle.`
        },{
          name: 'to_reserve_elevator',
          label: 'Must reserve elevator',
          type: 'toggle'
        },{
          name: 'to_reserve_parking',
          label: 'Must reserve parking',
          type: 'toggle'
        }]
      },

      additional_insurance: {
        title: 'Would you like additional moving insurance?',
        type: 'task-data',
        name: 'additional_insurance',
        label: 'Additional Insurance',
        editable: true,
        markComplete: true,
        subtitle: 'What\'s this?',
        subtitleTooltip: `All quotes you receive will be from licensed and insured
          moving companies. If you'd like additional coverage beyond what they
          provide, your assistant can secure that for you. We recommend this for any
          high-value items.`,
        fields: [{
          label: 'Additional insurance',
          name: 'additional_insurance',
          type: 'select',
          options: [{
            value: 'Yes',
            label: 'Yes'
          },{
            value: 'No',
            label: 'No'
          },{
            value: 'Decide Later',
            label: 'Decide Later'
          }],
          required: true
        }]
      },

    }
  }

});
