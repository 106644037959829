angular.module('partner').controller('inviteMoverModalController',
function ($scope, $timeout, Token, $uibModalInstance, Alerts) {
  'ngInject';

  $scope.email = '';
  $scope.incoming = true;

  var valid = () => {
    $scope.$broadcast('$validate');
    $scope.inviteForm.$setSubmitted();
    return !$scope.inviteForm.$invalid;
  };

  $scope.submit = () => {
    if($scope.loading) return;
    if(!valid()) return error();
    $scope.loading = true;
    Token.create({
      email: $scope.email,
      type: 'channel-mover',
      data: {link:($scope.incoming ? 'destination' : 'origin')}
    }).then((data) => {
      $scope.loading = false;
      Alerts.success({msg: `Invitation sent!` });
      $uibModalInstance.close();
    }, (result) => {
      $scope.loading = error();
      if(result.data.meta.error.message == 'email already in use')
        Alerts.error({msg:'That email address is already registered, please try a different one.'});
      else Alerts.error();
    });
  };

  function error() {
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    return false;
  }

});
