angular.module('core')
  .provider('Bugsnag', function() {
    var _bugsnagClient = false;

    this.init = (config) => {
      if(!config.apiKey) throw new Error('Bugsnag API key is required');
      _bugsnagClient = window.bugsnag(config);
      return this;
    };

    this.$get = () => {
      return _bugsnagClient;
    };
  })
  .factory('$exceptionHandler', function($log,Bugsnag) {
    'ngInject';
    return (exception, cause) => {
      if(Bugsnag && cause) Bugsnag.notify(exception, {
        beforeSend: (report) => report.updateMetaData('angular', { cause: cause })
      });
      $log.error(exception);
    };
  });
