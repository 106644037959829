angular.module('core')
  .directive('scrollHide', ['$window','$animate', function($window,$animate) {
    return {
      restrict: 'A',
      link: function($scope, elem, attr) {
        var limit = attr.scrollShow || 1;
        var hidden = false;
        var triggerHideShow = function(init) {
          var hide = $window.pageYOffset >= limit;
          if(hide!=hidden) {
            $animate[hide ? 'addClass' : 'removeClass'](elem,'ng-hide', {
              tempClasses: 'ng-hide-animate'
            });
            if(!init) $scope.$apply();
          }
          hidden = hide;
        };
        triggerHideShow(true);
        angular.element($window).bind('scroll.scrollHide', function() {
          triggerHideShow();
        });
        $scope.$on('$destroy', function() {
          angular.element($window).unbind('.scrollHide');
        });
      }
    };
  }])
  .directive('scrollShow', ['$window','$animate', function($window,$animate) {
    return {
      restrict: 'A',
      link: function($scope, elem, attr) {
        var limit = attr.scrollShow || 1;
        var hidden = false;
        var triggerHideShow = function(init) {
          var hide = $window.pageYOffset < limit;
          if(hide!=hidden) {
            $animate[hide ? 'addClass' : 'removeClass'](elem,'ng-hide', {
              tempClasses: 'ng-hide-animate'
            });
            if(!init) $scope.$apply();
          }
          hidden = hide;
        };
        triggerHideShow(true);
        angular.element($window).bind('scroll.scrollShow', function() {
          triggerHideShow();
        });
        $scope.$on('$destroy', function() {
          angular.element($window).unbind('.scrollShow');
        });
      }
    };
  }]);
