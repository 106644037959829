angular.module('user').factory('User',
function($rootScope, $q, $state, $http, $cookies,
  debounce, Data, Modal, Auth, Alerts, Bugsnag) {
  'ngInject';

  let cache = {};

  let User = { role: 'guest' };

  // private utility function
  function updateInfo(data,dataOnly) {
    if(data || dataOnly) angular.extend(User,data);
    else User = { role: 'guest' };
    $rootScope.$broadcast('userUpdated',dataOnly); // publish userUpdate event
    if(Bugsnag) Bugsnag.user = User;
  }

  $rootScope.$on('userData',(event,data) => updateInfo(data,true));

  // watch for invalid session broadcast
  $rootScope.$on('invalidSession',debounce(forceLogout,100,true));

  function forceLogout() {
    API.logout();
    Alerts.error({
      msg: 'You have been logged out automatically.',
      delay: 3
    });
  }

  // set exposed APIs
  var API =  {
    init: () => Auth.refreshToken().then(isAuthenticated => {
      if(isAuthenticated) return API.refresh(true);
      else return User;
    }),
    get: () => User,
    refresh: (reload) => {
      return $http.get(`${Data.apiUrl}/v2/users/me`).then((result) => {
        updateInfo(result.data,!reload);
        return User;
      });
    },
    getCXList: () => {
      if(!cache.cxList) cache.cxList = $http.get(`${Data.apiUrl}/v2/users`, {params:{where:{type:3},per_page:999}});
      return cache.cxList.then((result) => result.data);
    },
    find: (data) => {
      return $http.get(`${Data.apiUrl}/v2/users`, {params:data}).then((result) => {
        return result.data;
      });
    },
    lookup: (id) => {
      return $http.get(`${Data.apiUrl}/v2/users/${id}`).then((result) => {
        return result.data;
      });
    },
    getMoves: (id) => {
      return $http.get(`${Data.apiUrl}/v2/users/${id}/get_move_ids`).then(r => r.data);
    },

    getActiveMove: () => {
      return $http.get(`${Data.apiUrl}/moves/active-move-id`).then(r => r.data.data['active-move-id']);
    },

    referralCodeLookup: (code) => {
      return $http.get(`${Data.apiUrl}/v2/users/email_by_refcode?refcode=${code}`).then((result) => {
        return result.data;
      });
    },
    getReferralLink: () => {
      if(!User.id) return false;
      return $http.get(`${Data.apiUrl}/v2/users/${User.id}/referral_link`).then((result) => {
        return result.data;
      });
    },
    register: (data) => Auth.create(data)
      .then(() => API.refresh(true)),
    create: (data) => Auth.create(data,true),
    login: (data) => Auth.login(data)
      .then(() => API.refresh(true)),
    socialLogin: (data) => Auth.oauthLogin(data)
      .then((result) => {
        updateInfo(result.user);
        return result.newUser;
      }),
    logout: () => Auth.logout()
      .then(() => updateInfo(false)),
    forgotPassword: (email) => $http.get(`${Data.apiUrl}/users/password-reset`,{params:{email:email}}),

    // ONLY USED IN STRIPE PAYMENT LANDING PAGE
    setPasswordLegacy: (accessToken,password,userId) => {
      const data = {
        password: password,
        external: false,
        new_message_notification: true
      };
      return API.update(userId, data, { headers: { Authorization: `Bearer ${accessToken}` }})
        .then((result) => {
          Auth.setToken(accessToken);
          updateInfo(result);
        });
    },

    // USED IN EMAIL BASED FLOW (ANGULAR AND REACT USERS)
    setPasswordViaToken: (data) => Auth.setPasswordViaToken(data)
      .then(() => API.refresh(true)),

    update: (id,data,config) => {
      return $http.patch(`${Data.apiUrl}/v2/users/${id}`, data, config).then((result) => {
        if(id == User.id) updateInfo(result.data, true);
        return result.data;
      });
    },

    proxy: (id) => Auth.proxyStart(id)
      .then(() => API.refresh(true)),
    exitProxy: () => Auth.proxyEnd()
      .then(() => {
        let proxyUserEmail = (User.role === 'mover') ? angular.copy(User.email) : null;
        return API.refresh(true).then(() => proxyUserEmail);
      }),
    isProxy: Auth.isProxy,

    modals: {
      sign: (options) => {
        if(User.id) return Alerts.info({msg:`I'm sorry ${User.firstname}, I'm afraid I can't do that.`});
        return Modal.open(angular.merge({
          controller: 'signModalController',
          templateUrl: '/templates/user/sign-modal.html'
        },options));
      },
      forgotPassword: (options) => {
        return Modal.open(angular.merge({
          controller: 'passwordForgotModalController',
          templateUrl: '/templates/user/password-forgot-modal.html',
          windowClass: 'bounce',
          overlay: true
        },options));
      },
      setPassword: (token, options) => {
        return Modal.open(angular.merge({
          controller: 'passwordSetModalController',
          templateUrl: '/templates/user/password-set-modal.html',
          data: { token }
        },options));
      },
      editUser: (options) => {
        return Modal.open(angular.merge({
          controller: 'editUserModalController',
          templateUrl: '/templates/user/edit-user-modal.html'
        },options));
      },
      welcome: (move,options) => {
        return Modal.open(angular.merge({
          controller: 'welcomeModalController',
          templateUrl: '/templates/user/welcome-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            move: () => move
          },
        },options));
      },
      explainer: (options) => {
        return Modal.open(angular.merge({
          templateUrl: '/templates/user/explainer-modal.html'
        },options));
      },
      create: (options) => {
        const allowed = ['admin','concierge'];
        if(allowed.indexOf(User.role) == -1) return Alerts.info({msg:`I'm sorry ${User.firstname}, I'm afraid I can't do that.`});
        return Modal.open(angular.merge({
          controller: 'createMoverModalController',
          templateUrl: '/templates/user/create-mover-modal.html'
        },options));
      }
    }
  };
  return API;

});
