angular.module('field')
.component('fieldPhotos', {
  template: `
    <image-upload images="$ctrl.field.unsaved" options="$ctrl.options" form="$ctrl.form"
      ng-class="{'existing':$ctrl.field.unsaved.length || $ctrl.field.value.length}"></image-upload>
    <label class="error" ng-if="$ctrl.options.required &&
      $ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      Required.
    </label>
    <div class="image-list" slide-toggle="$ctrl.field.unsaved.length || $ctrl.field.value.length">
      <div class="preview" ng-style="{'background-image':'url({{image.url}})'}"
        ng-repeat="image in $ctrl.field.value">
        <svg-sprite source="material-icons" icon="close" alt="remove" class="image-remove"
          ng-click="$ctrl.removeImage($index)"></svg-sprite>
      </div>
      <div class="preview unsaved" ngf-background="image" ng-repeat="image in $ctrl.field.unsaved">
        <svg-sprite source="material-icons" icon="close" alt="remove" class="image-remove"
          ng-click="$ctrl.discardImage($index)"></svg-sprite>
      </div>
    </div>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function(Image) {
    'ngInject';

    this.$onInit = () => {
      this.field.unsaved = null;
      this.options = {
        name: this.field.name,
        multiple: true,
        maxFiles: maxFiles(),
        required: isRequired()
      };
    };

    this.removeImage = (index) => {
      this.field.value.splice(index,1);
      this.field.onChange();
      this.options.maxFiles = maxFiles();
      this.options.required = isRequired();
    };

    this.discardImage = (index) => {
      this.field.unsaved.splice(index,1);
      if(!this.field.unsaved.length) this.field.unsaved = null;
    };

    const maxFiles = () => {
      return 6 - (this.field.value ? this.field.value.length : 0);
    };

    const isRequired = () => {
      return this.field.required ? (!this.field.value || !this.field.value.length) : false
    }

    // open a modal to view a set of photos
    this.photosModal = (index) => {
      let images = this.field.value.map((photo) => photo.url);
      Image.modals.view(images,{initial:index});
    };

  }
});
