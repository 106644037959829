angular.module('user').directive('profileImage',
function(User) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      user: '<?'
    },
    template: `
      <div class="profile-image" ng-bind="initials()" ng-if="user"
        ng-style="{'background-image':'url(' + (user.profile_pic ? user.profile_pic : '') + ')'}">
      </div>`,
    link: (scope, element, attrs) => {

      scope.user = scope.user || User.get();
      if(scope.user.id == User.get().id) scope.$on('userUpdated',() => scope.user = User.get());

      scope.initials = () => {
        if(scope.user.role == 'guest') return;
        if(scope.user.profile_pic) return;
        return scope.user.firstname.charAt(0)+scope.user.lastname.charAt(0);
      };

    }
  };
});
