angular.module('core').factory('Menu',
function($rootScope,$state,$window,Modal,User) {
  'ngInject';


  const menus = {
    main: [{
      label: 'Dashboard',
      state: 'dashboard',
      order: 10,
      roles: ['mover','vendor'],
      classes: 'hide-on-mobile'

    },{
      label: 'Dashboard',
      state: `dashboard({panel: 'taskList'})`,
      order: 20,
      roles: ['mover'],
      classes: 'hide-on-desktop'
    },{
      label: 'Conversation',
      state: `dashboard({panel: 'conversation'})`,
      order: 30,
      roles: ['mover'],
      classes: 'hide-on-desktop'

    },{
      label: 'Dashboard',
      state: 'dashboard.vendor.moves',
      order: 20,
      roles: ['vendor'],
      classes: 'hide-on-desktop'
    },{
      label: 'Calendar',
      state: 'dashboard.vendor.calendar',
      order: 30,
      roles: ['vendor'],
      classes: 'hide-on-desktop'
    },{
      label: 'Win the Move',
      state: 'dashboard.vendor.quotes',
      order: 40,
      roles: ['vendor'],
      classes: 'hide-on-desktop'

    },{
      label: 'Dashboard',
      state: 'dashboard',
      order: 10,
      roles: ['concierge','channel-partner']
    },{
      label: 'My Account',
      state: 'account',
      order: 40,
      roles: ['mover','concierge','vendor','channel-partner']
    }]
  };

  $rootScope.$on('$stateChangeStart', () => {
    angular.forEach(menus,(menu) => menu.mobileShow = false);
  });

  return {
    get: (name) => {
      return menus[name].filter((item) => item.roles.indexOf(User.get().role) != -1);
    },
    add: (name, menu) => {
      menus[name] = menu;
    },
    toggleMobileNav: (name) => {
      if($window.innerWidth > 767) return;
      menus[name].mobileShow = !menus[name].mobileShow;
    },
    showMobileNav: (name) => {
      if($window.innerWidth > 767) return false;
      return menus[name].mobileShow;
      // return !!($window.innerWidth <= 767) && (menus[name].mobileShow);
    }
  };

});
