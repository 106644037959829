angular.module('core')
  .directive('slideToggle', ['$parse',function($parse) {
    return {
      restrict: 'A',
      multiElement:true,
      link: function(scope, element, attr) {
        var initState = $parse(attr.slideToggle)(scope);
        var duration = parseInt(attr.slideToggleDuration, 10) || 300;

        if(!initState) element.hide();
        scope.$watch(attr.slideToggle, function(value,old) {
          if(value) element.stop().slideDown(duration);
          else element.stop().slideUp(duration);
        });
      }
    };
  }]);
