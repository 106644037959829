angular.module('payment').controller('stripeConnectModalController',
function ($scope, $uibModalInstance, Data, Access, vendor, markAsDelivered) {
  'ngInject';

  if(Access.limit('vendor', function() { $uibModalInstance.dismiss('unauthorized'); })) return;

  $scope.vendor = vendor;
  $scope.markAsDelivered = markAsDelivered;

  $scope.oauthLink = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write` +
    `&client_id=${Data.stripeClientId}&redirect_uri=${Data.appUrl}/stripe_connect`;

});
