angular.module('field')
.component('fieldCheckoutItem', {
  template: `
    <div class="img-wrapper" ng-if="$ctrl.field.image_url">
      <img ng-src="{{$ctrl.field.image_url}}" alt="{{$ctrl.field.label}}" />
    </div>
    <div class="details">
      <div class="label" ng-if="$ctrl.field.label"
        ng-bind-html="$ctrl.field.label"></div>
      <div class="description" ng-if="$ctrl.field.description"
        ng-bind-html="$ctrl.field.description"></div>
      <div class="price" ng-if="$ctrl.field.price"
        ng-bind="$ctrl.field.price/100 | currency"></div>
    </div>
    <stepper value="$ctrl.field.value" min="$ctrl.field.min" max="$ctrl.field.max"
      on-change="$ctrl.field.onChange()"></stepper>`,
  bindings: {
    field: '=',
  }
});
