angular.module('core').controller('footerController',
function ($scope,$state,$window,User,Modal) {
  'ngInject';

  let user = User.get();
  $scope.$on('userUpdated', () => user = User.get());

  $scope.navigation = [{
    label: 'Site',
    mobileShow: false,
    links: [{
      label: 'About Us',
      onClick: () => $scope.aboutModal(),
      order: 10
    },{
      label: 'Contact',
      state: 'contact',
      order: 20
    },{
      label: 'FAQ',
      state: 'faq',
      order: 30
    }]
  },{
    label: 'Legal',
    mobileShow: false,
    links: [{
      label: 'Privacy Policy',
      href: '/privacy',
      target: '_blank',
      order: 20
    },{
      label: 'Terms of Use',
      href: '/terms',
      target: '_blank',
      order: 30
    }]
  },{
    label: 'Social',
    mobileShow: false,
    links: [{
      label: 'Instagram',
      href: 'https://instagram.com/movedapp',
      target: '_blank',
      order: 10
    },{
      label: 'Facebook',
      href: 'https://facebook.com/movedapp',
      target: '_blank',
      order: 20
    },{
      label: 'Twitter',
      href: 'https://twitter.com/movedapp',
      target: '_blank',
      order: 30
    },{
      label: 'Blog',
      href: 'https://blog.moved.com/',
      target: '_blank',
      order: 40
    }]
  }];

  $scope.mobileNavToggle = (index) => {
    if($window.innerWidth > 767) return;
    $scope.navigation[index].mobileShow = !$scope.navigation[index].mobileShow;
  }
  $scope.mobileNavShow = (index) => {
    if($window.innerWidth > 767) return true;
    return $scope.navigation[index].mobileShow;
  }

  $scope.aboutModal = () => Modal.open({
    templateUrl: '/templates/website/about-us-modal.html'
  });

  $scope.getYear = () => {
    return new Date().getFullYear();
  };

});
