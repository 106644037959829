angular.module('tasks').controller('taskFlowModalController',
function($scope, $uibModalInstance, $state, $q, $timeout, User, Move, Task, params) {
  'ngInject';

  $scope.task = angular.copy(params.task);
  $scope.move = params.move;

  $scope.close = () => {
    $uibModalInstance.close();
  };

});
