angular.module('core').controller('imageUploadModalController',
function ($scope, $uibModalInstance, Alerts, S3, Access, bucket) {
  'ngInject';

  if(Access.limit('user')) return $uibModalInstance.dismiss('unauthorized');

  $scope.image = null;
  $scope.options = {
    name: 'image-upload',
    multiple: false,
    icon: true
  }

  // scope function to upload an image
  $scope.upload = function() {
    if($scope.loading || !$scope.image) return;
    $scope.loading = true;
    S3.uploadImage($scope.image,bucket).then((result) => {
      $uibModalInstance.close(result);
    }, function(result) {
      $scope.loading = false;
      Alerts.error();
    });
  };

  // scope function to remove existing image
  $scope.remove = () => {
    $scope.image = null;
  };

});
