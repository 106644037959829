angular.module('core').controller('alertMessageController',
function(Alerts) {
  'ngInject';
  this.alerts = Alerts.queue;
  this.closeAlert = (index,event) => {
    if(event.target && event.target.tagName.toLowerCase() != 'a') Alerts.close(index);
  };
})
.component('alertMessage', {
  controller: 'alertMessageController',
  template: `<div ng-repeat="alert in $ctrl.alerts" type="{{alert.type}}"
      class="alert {{alert.type}}" ng-bind-html="alert.msg" compile ng-if="$first"
      ng-click="$ctrl.closeAlert($index,$event)"></div>`
});
