angular.module('core').directive('repeatComplete',
function ($timeout,$parse,debounce) {
  return {
    restrict: 'A',
    link: function (scope, element, attr) {
      if(scope.$last === true) {
        scope.$watch(
          () => element.children().length,
          debounce(() => scope.$evalAsync(() => $parse(attr.repeatComplete)(scope)),0)
        );
      }
    }
  };
});
