angular.module('core').factory('iOSApp',
function($rootScope,$state,$window,User) {
  'ngInject';

  const url = 'itunes.apple.com/us/app/moved-your-personal-moving/id1118785735?ls=1&mt=8';

  const urlProtocol = {
    mobile:'itms://',
    desktop:'https://'
  }

  const getUrl = () => {
    return urlProtocol[($window.innerWidth > 767 ? 'desktop' : 'mobile')] + url;
  };

  return {
    getUrl: getUrl,
    open: () => {
      $window.open(getUrl(), '_blank');
    }
  };

});
