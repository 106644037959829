angular.module('tasks').component('screenTimedLoader', {
  template: `<progress-bar on-complete="$ctrl.onNext()" start="$ctrl.start" delay="$ctrl.delay"></progress-bar>
    <div class="title" ng-bind="$ctrl.title"></div>`,
  bindings: {
    screen: '<',
    move: '<',
    onNext: '&'
  },
  controller: function(Task, $timeout) {
    'ngInject';

    this.delay = 5000;
    this.$onInit = () => {
      this.title = this.screen.title || 'Magic is happening...';
      $timeout(() => this.start = true, 0);
    };

  }

});
