angular.module('field').component('fieldSpecialItemPrices', {
  template: `
    <div class="item-name" ng-bind="$ctrl.item.label"></div>
    <div class="item-price" ng-repeat="field in $ctrl.fields track by field.name"
      field-generator="field"></div>`,
  bindings: {
    item: '='
  },
  controller: function(Field) {
    'ngInject';

    this.fields = [{
      label: 'Cost',
      name: 'disassembly_cost',
      type: 'currency',
      allowDecimals: true,
      default: 0
    },{
      label: 'Cost',
      name: 'reassembly_cost',
      type: 'currency',
      allowDecimals: true,
      default: 0
    },{
      label: 'Cost',
      name: 'handling_fee',
      type: 'currency',
      allowDecimals: true,
      default: 0
    }];

    this.$onInit = () => {
      this.fields.forEach((field) => {
        Field.addDefaults(field,this.item);
        field.value = this.item.value[field.name] || field.default;
        // keep local copy updated as values change
        field.onChange = () => {
          this.item.value[field.name] = field.value;
          if(this.item.onChange) this.item.onChange(this.item.value);
        };
      });
    };

  }
});
