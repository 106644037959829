angular.module('field').factory('AddressUtil',
function($filter,USStates) {
  'ngInject';

  const googleDataMap = {
    street_number: 'short_name',
    route: 'short_name',
    locality: 'short_name',
    sublocality_level_1: 'short_name',
    administrative_area_level_3: 'short_name',
    neighborhood: 'short_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name'
  };

  const API = {

    getFormatted: (address,options) => {
      options = angular.merge({},{
        street: true,
        unit: true,
        city: true,
        state: true,
        zipcode: true
      },options);
      address = address || {};
      return [
        (options.street ? address.street : null),
        (options.unit ? address.unit : null),
        (options.city ? address.city : null),[
          (options.state ? API.getStateAbbr(address.state) : null),
          (options.zipcode ? address.zipcode : null)
        ].filter((v)=>v).join(' ')
      ].filter((v)=>v).join(', ');
    },

    updateComponents: (details, field) => {
      if(!details) {
        field.formatted = '';
        return field.value = {
          street: null,
          city: null,
          state: null,
          zipcode: null,
          google_place_id: null
        };
      }
      let mappedAddress = {}
      details.address_components.forEach((component) => {
        let type = component.types[0];
        if(googleDataMap[type]) mappedAddress[type] = component[googleDataMap[type]];
      });
      field.value = {
        street: [mappedAddress.street_number, mappedAddress.route]
          .filter((v) => v).join(' ') || '',
        city: mappedAddress.locality || mappedAddress.sublocality_level_1 ||
          mappedAddress.administrative_area_level_3 || mappedAddress.neighborhood || '',
        state:mappedAddress.administrative_area_level_1 || '',
        zipcode:mappedAddress.postal_code || '',
        google_place_id: details.place_id || ''
      };
      field.formatted = API.getFormatted(field.value);
    },

    getRouteLink: (origin,destination,stops) => {
      if(!origin || !destination) return false;
      let format = (value) => $filter('uriEncode')(API.getFormatted(value, {unit:false}));
      let link = `https://www.google.com/maps/dir/?api=1&travelmode=driving&`+
        `origin=${format(origin)}&origin_place_id=${origin.google_place_id}&`+
        `destination=${format(destination)}&destination_place_id=${destination.google_place_id}`;
      if(stops) link += `&waypoints=${stops.map(format).join('|')}` +
        `&waypoint_place_ids=${stops.map(stop=>stop.google_place_id).join('|')}`;
      return link;
    },

    getOrdinal: $filter('ordinal'),

    ownership: [{
      label: 'Own',
      value: 'own'
    }, {
      label: 'Rent',
      value: 'rent'
    }],
    getOwnership: (address) => {
      address = address || {};
      let match = API.ownership.find((value) => value.value == address.deed);
      return match ? match.label : '';
    },

    stairs: [
      { value: true, label: 'Elevator' },
      { value: 0, label: 'No Stairs' },
      { value: 1, label: '1 Flight of Stairs' },
      { value: 2, label: '2 Flights of Stairs' },
      { value: 3, label: '3 Flights of Stairs' },
      { value: 4, label: '4 Flights of Stairs' },
      { value: 5, label: '5 Flights of Stairs' },
      { value: 6, label: '6 Flights of Stairs' }
    ],
    getStairs: (address) => {
      address = address || {};
      let match = API.stairs.find(value => value.value ===
        address.has_elevator || value.value === address.flights_of_stairs);
      return match ? match.label : '';
    },

    types: [{
      label: 'House',
      value: 'house',
      icon: 'home'
    },{
      label: 'Apartment',
      value: 'apartment',
      icon: 'location_city'
    },{
      label: 'Storage',
      value: 'storage',
      icon: 'business'
    },{
      label: 'Other',
      value: 'other',
      icon: 'store'
    }],
    getType: (address) => {
      address = address || {};
      let match = API.types.find(value => value.value == address.type);
      return match ? match.label : '';
    },

    floors: [
      { value: 'basement', label: 'Basement' },
      { value: 'ground', label: 'Ground Floor' }
    ].concat(Array.from({length: 60}, (v, i) => ({
      value:(i+1).toString(),
      label:`${$filter('ordinal')(i+1)} Floor`,
      requireElevator: (i+1) > 7
    }))),
    getFloor: (address) => {
      address = address || {};
      let match = API.floors.find(value => value.value == address.floor);
      return match ? match.label : '';
    },

    getStateAbbr: (name) => {
      name = name || '';
      let match = USStates.find((state) => state.name.toLowerCase() == name.toLowerCase());
      return match ? match.abbreviation : name;
    },

    filterFields: (address) => {
      const allowed = ['name','street','city','state','zipcode','google_place_id',
        'type','unit','floor','flights_of_stairs','deed','has_elevator'];
      let filtered = {};
      Object.keys(address).forEach((key) => {
        if(allowed.indexOf(key) == -1) return false;
        filtered[key] = address[key];
      });
      return filtered;
    }

  };

  return API;

});
