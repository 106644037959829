angular.module('core').factory('Modal',
function($uibModal,$rootScope,$templateCache,$timeout) {
  'ngInject';

  var modalStack = [];

  $templateCache.put('/templates/core/modal.html',
    `<div class="modal-close" ng-click="$parent.$dismiss('cancel')" ng-hide="$parent.required"></div>
    <div class="modal-dialog" uib-modal-transclude></div>`);

  let API = {
    open: (opts) => {
      var defaults = {
        backdrop: false,
        keyboard: opts.data ? !opts.data.required : true,
        windowClass: 'zoom',
        windowTemplateUrl: '/templates/core/modal.html',
        openedClass: 'no-scroll model-open',
        resolve: { data: () => opts.data || {} }
      };
      let options = angular.merge({},defaults,opts);
      options.windowClass += ' ' + opts.templateUrl.split('/').pop().replace('.html','');
      if(modalStack.length && !options.overlay) API.closeAll(true);
      let modal = $uibModal.open(options);
      modalStack.push({
        modal: modal,
        options: options
      });
      modal.opened.then((response) => {
        $timeout(() => $rootScope.$broadcast('customContentLoaded','.modal-content'),0);
      });
      modal.result.then(result => {
        let options = modalStack.find(item => item.modal == modal).options;
        if(options.next) $timeout(() => options.next(result),0);
      }, result => {
        let options = modalStack.find(item => item.modal == modal).options;
        if(result != 'force' && options.next) $timeout(() => options.next(result),0);
      })
      .finally(() => {
        let index = modalStack.findIndex(item => item.modal == modal);
        if(index !== -1) modalStack.splice(index,1);
      });
      return modal;
    },

    closeAll: (force) => {
      if(modalStack.length) modalStack.forEach(item => {
        if(force) item.modal.dismiss('force');
        else item.modal.dismiss('cancel');
      });
    },


    dialog: (params, options) => {
      return API.open(angular.merge({
        controller: 'dialogModalController',
        templateUrl: '/templates/core/dialog-modal.html',
        windowClass: 'bounce',
        overlay: true,
        resolve: {
          params: () => params
        }
      }, options));
    }

  };

  $rootScope.$on('$stateChangeStart', () => API.closeAll(true));

  return API;

});
