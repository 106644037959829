angular.module('tasks').component('screenCustom', {
  templateUrl: '/templates/tasks/screen-custom.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&',
    onClose: '&'
  },
  controller: function($q,$timeout,User,Task,Move,Field,S3) {
    'ngInject';

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      this.task.data = this.task.data || {};
      this.screen.title = this.screen.title || this.task.task.label;
      this.screen.fields.forEach((field) => {
        Field.addDefaults(field,this.task.data);
        field.value = Field.getValue(field,this.task);
        field.onChange = () => {
          if(this.screen.onChange) this.screen.onChange(buildData());
        };
      });
    };

    this.next = (skip) => {
      if(this.loading) return;
      this.loading = true;
      if(skip) return this.onClose();
      if(!valid(this.form)) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      return uploadImages().then(() => {
        let data = buildData(progressData ? {task:progressData} : {});
        return Task.update(this.task.id,data.task);
      });
    };

    const uploadImages = () => {
      let s3Uploads = [];
      this.screen.fields.filter(f => f.type == 'photos').forEach(field => {
        if(field.unsaved && field.unsaved.length) {
          field.value = field.value || [];
          s3Uploads.push(S3.uploadImages(field.unsaved,field.category).then((images) => {
            field.unsaved = null;
            images.forEach((image) => field.value.push(image));
            return field;
          }));
        }
      });
      if(s3Uploads.length) return $q.all(s3Uploads);
      else return $q.when(true);
    };

    const buildData = (data) => {
      data = data || {};
      this.screen.fields.filter(f => !f.hidden).forEach((field) =>
        Field.buildSaveData(field,data));
      return data;
    };

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

  }
});
