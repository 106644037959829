angular.module('core').component('animatedCheckmark', {
  template: `
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"
      ng-class="{'checked':$ctrl.isChecked}">
      <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>`,
  bindings: {
    isChecked: '<'
  },
  controller: function() {
    this.$onChanges = (changes) => {
      if(changes.isChecked) this.isChecked = changes.isChecked.currentValue;
    };
  }
});
