angular.module('move').factory('QuoteRequest',
function($http, Data, Modal) {
  'ngInject';

  return {
    get: (id) => {
      return $http.get(`${Data.apiUrl}/v2/quote_requests/${id}`)
        .then((result) => result.data);
    },
    find: (data) => {
      return $http.get(`${Data.apiUrl}/v2/quote_requests`,{params:data})
        .then((result) => result.data);
    },
    create: (data) => {
      return $http.post(`${Data.apiUrl}/v2/quote_requests`,data)
        .then((result) => result.data);
    },
    update: (id,data) => {
      return $http.patch(`${Data.apiUrl}/v2/quote_requests/${id}`,data)
        .then((result) => result.data);
    },
    remove: (id) => {
      return $http.delete(`${Data.apiUrl}/v2/quote_requests/${id}`)
        .then((result) => result.data);
    },
    modals: {
      summary: (quote, /* move, */ vendor, options) => {
        return Modal.open(angular.merge({
          controller: 'quoteRequestSummaryModalController',
          templateUrl: '/templates/move/quote-request-summary-modal.html',
          windowClass: 'bounce',
          resolve: {
            params: () => ({ quote, vendor })
          }
        },options));
      },
      priceBreakdown: (request, options) => {
        return Modal.open(angular.merge({
          controller: 'priceBreakdownModalController',
          templateUrl: '/templates/move/price-breakdown-modal.html',
          backdrop: true,
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            params: () => ({ request })
          }
        }, options));
      },
    }
  };

});
