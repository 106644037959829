angular.module('user').controller('signModalController',
function($uibModalInstance, $scope, Data, data) {
  'ngInject';

  $scope.webUrl = Data.webUrl;

  $scope.required = data.required;
  $scope.limit = data.limit;
  $scope.view = data.view || 'login';
  $scope.options = {
    onComplete: $uibModalInstance.close
  };

  $scope.toggleForm = () => {
    $scope.view = ['register','login'].find(state => $scope.view !== state);
  };

});
