angular.module('payment').factory('Vendor',
function($http, Modal, VendorDetails, Data) {
  'ngInject';

  return {
    lookup: function(term,filter) {
      return $http.get(`${Data.apiUrl}/v2/vendors`, angular.merge({
        params:{
          where:{name:{$istartswith:term}},
          per_page:1
        }
      },filter)).then((result) => {
        return result.data[0];
      });
    },
    get: function(id) {
      return $http.get(`${Data.apiUrl}/v2/vendors/${id}`).then((result) => {
        return result.data;
      });
    },
    getAll: (data) => {
      return $http.get(`${Data.apiUrl}/v2/vendors`,{params:data}).then((result) => {
        return {
          data: result.data,
          total: angular.isDefined(result.headers('X-Total-Count')) ? result.headers('X-Total-Count') : 0
        };
      });
    },
    getIDs: (data) => {
      return $http.get(`${Data.apiUrl}/v2/vendors/ids`,{params:data}).then((result) => {
        return result.data;
      });
    },
    create: function(data) {
      return $http.post(`${Data.apiUrl}/v2/vendors`, data).then((result) => {
        return result.data;
      });
    },
    tetherStripe: function(id,data) {
      return $http.post(`${Data.apiUrl}/v2/vendors/${id}/onboard`,data).then((result) => {
        return result.data;
      });
    },
    update: (id, data) => {
      return $http.patch(`${Data.apiUrl}/v2/vendors/${id}`, data).then((result) => {
        return result.data;
      });
    },
    details: VendorDetails,
    modals: {
      create: (options) => {
        options = options || {
          windowClass: 'bounce wide'
        };
        return Modal.open(angular.merge({
          controller: 'vendorModalController',
          templateUrl: '/templates/payment/vendor-modal.html',
          resolve: {
            params: () => ({})
          }
        }, options));
      },
      edit: (vendor, options) => {
        options = options || {
          windowClass: 'bounce wide'
        };
        return Modal.open(angular.merge({
          controller: 'vendorModalController',
          templateUrl: '/templates/payment/vendor-modal.html',
          resolve: {
            params: () => ({
              vendor: vendor
            })
          }
        }, options));
      }
    }
  };

});
