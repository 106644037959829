angular.module('cx').controller('moveSourceModalController',
function($scope,$uibModalInstance,Move,Alerts,params) {
  'ngInject';


  $scope.source = params.move.source || {};

  var valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

  $scope.submit = () => {
    if($scope.loading) return false;
    if(!valid($scope.sourceForm)) return error();
    $scope.loading = true;
    Move.update(params.move.id,{source:$scope.source}).then((result) => {
      $scope.loading = false;
      $uibModalInstance.close();
    }, error);
  };


  function error() {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    Alerts.error();
  }

});

