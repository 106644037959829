angular.module('cx')
.component('transactionDisplay', {
  template: `
    <div class="transaction-holder">
      <div class="info">
        <p class="item-title">
          <span class="item-sub">Job ID: </span>
          <span ng-bind="$ctrl.transaction.id"></span>
        </p>
        <div class="vendor-details">
          <svg-sprite class="offer-status-icon" ng-if="$ctrl.transaction.vendor_is_confirmed"
            uib-tooltip="Confirmed: {{$ctrl.transaction.vendor_offer_responded_at | amUtc | amLocal | amDateFormat: 'M/D/YY h:mma'}}"
            tooltip-placement="right"
            alt="Confirmed" source="material-icons" icon="check_circle"></svg-sprite>
          <svg-sprite class="offer-status-icon pending" ng-if="$ctrl.transaction.vendor_offer_status === 'pending'"
            uib-tooltip="Expires: {{$ctrl.transaction.vendor_offer_expires_at | amUtc | amLocal | amDateFormat: 'M/D/YY h:mma'}}"
            tooltip-placement="right"
            alt="Confirmed" source="material-icons" icon="hourglass_empty"></svg-sprite>
          <span class="item-title" ng-bind="$ctrl.transaction.vendor.name"></span>
          <span class="stripe-partner" ng-if="$ctrl.transaction.vendor.has_stripe &&
            !$ctrl.transaction.affirm_payment_details.length">
            <svg-sprite alt="stripe" source="partners" icon="stripe"/>
          </span>
          <span class="stripe-partner" ng-if="$ctrl.transaction.affirm_payment_details.length">
            <svg-sprite alt="Affirm" source="partners" icon="affirm"/>
          </span>
        </div>
        <p class="item-sub" ng-if="$ctrl.transaction.arrival_window">
          <span>Arrival Window: </span>
          <span class="black" style="font-size:12px;">{{$ctrl.transaction.arrival_window.from}} - {{$ctrl.transaction.arrival_window.to}}</span>
        </p>
        <p class="item-sub" ng-bind="$ctrl.transaction.transaction.label"></p>
        <p class="item-sub">Booked at {{$ctrl.transaction.created_at | amUtc | amLocal | amDateFormat: 'ddd M/D/YY h:mma'}}</p>
        <div class="note" ng-bind="$ctrl.transaction.internal_note"></div>
      </div>
      <div class="data">
        <div class="amounts">
          <div class="line-item" ng-if="$ctrl.transaction.status == 'final' || !$ctrl.transaction.is_hourly">
            <span class="label">Total</span>
            <p class="value item-title" ng-bind="$ctrl.transaction.total/100 | currency"></p>
          </div>
          <div class="line-item" ng-if="$ctrl.transaction.is_hourly">
            <span class="label">Rate</span>
            <p class="value item-title">{{$ctrl.transaction.rate/100 | currency}}/hour</p>
          </div>
          <div class="line-item" ng-if="!$ctrl.transaction.is_hourly && $ctrl.transaction.data && $ctrl.transaction.data.price_breakdown">
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.volume_price">
              <span class="label">Volume</span>
              <p class="value" ng-bind="$ctrl.transaction.data.price_breakdown.volume_price/100 | currency"></p>
            </div>
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.travel_price">
              <span class="label">Travel</span>
              <p class="value" ng-bind="$ctrl.transaction.data.price_breakdown.travel_price/100 | currency"></p>
            </div>
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.tolls_price">
              <span class="label">Tolls</span>
              <p class="value" ng-bind="$ctrl.transaction.data.price_breakdown.tolls_price/100 | currency"></p>
            </div>
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.stairs_price">
              <span class="label">Stairs</span>
              <p class="value" ng-bind="$ctrl.transaction.data.price_breakdown.stairs_price/100 | currency"></p>
            </div>
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.inventory_price">
              <span class="label">Special Items</span>
              <p class="value" ng-bind="$ctrl.transaction.data.price_breakdown.inventory_price/100 | currency"></p>
            </div>
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.storage_price">
              <span class="label">Storage</span>
              <p class="value" ng-bind="$ctrl.transaction.data.price_breakdown.storage_price/100 | currency"></p>
            </div>
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.packing_price &&
              $ctrl.transaction.data.packing_option == 'packing'">
              <span class="label">Packing</span>
              <p class="value" ng-bind="$ctrl.transaction.data.price_breakdown.packing_price/100 | currency"></p>
            </div>
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.packing_unpacking_price &&
              $ctrl.transaction.data.packing_option == 'packing_unpacking'">
              <span class="label">Packing & Unpacking</span>
              <p class="value" ng-bind="$ctrl.transaction.data.price_breakdown.packing_unpacking_price/100 | currency"></p>
            </div>
          </div>
          <div class="line-item" ng-if="$ctrl.transaction.is_hourly && $ctrl.transaction.data && $ctrl.transaction.data.price_breakdown">
            <div class="line-item">
              <span class="label">Total Hours</span>
              <p class="value">{{ $ctrl.transaction.total_hours }} hours</p>
            </div>
            <div class="line-item">
              <span class="label">Times</span>
              <p class="value">{{ $ctrl.transaction.data.price_breakdown.start_time }}-{{ $ctrl.transaction.data.price_breakdown.end_time }}</p>
            </div>
            <div class="line-item" ng-if="$ctrl.transaction.data.price_breakdown.additional_fees" ng-repeat="fee in $ctrl.transaction.data.price_breakdown.additional_fees">
              <span class="label">Fee: {{fee.description}}</span>
              <p class="value" ng-bind="fee.amount/100 | currency"></p>
            </div>
          </div>
          <div class="line-item">
            <span class="label">Revenue</span>
            <p class="value" ng-if="$ctrl.transaction.status == 'final' || !$ctrl.transaction.is_hourly"
              ng-bind="$ctrl.transaction.revenue/100 | currency"></p>
            <p class="value" ng-if="$ctrl.transaction.status != 'final' && $ctrl.transaction.is_hourly">TBD</p>
          </div>
        </div>
        <div class="status">
          <span class="item-edit" ng-click="$ctrl.toggleEditStatus()" ng-if="$ctrl.hasStatusControl()">
            <svg-sprite alt="Edit" source="material-icons" icon="mode_edit"></svg-sprite>
          </span>
          <span class="value" ng-class="$ctrl.transaction.status" ng-bind="$ctrl.statusLabel()"></span>
        </div>
      </div>
      <div class="actions vcenter-parent">
        <div class="vcenter-block">
          <span class="item-edit" ng-click="$ctrl.editTransaction()">
            <svg-sprite alt="Edit" source="material-icons" icon="mode_edit"></svg-sprite>
          </span>
          <span class="item-edit" ng-click="$ctrl.refundTransaction()" ng-if="$ctrl.isRefundable()">
            <svg-sprite alt="Refund" source="material-icons" icon="rotate_left"></svg-sprite>
          </span>
          <span class="item-edit" ng-click="$ctrl.deleteTransaction()"
            ng-if="$ctrl.transaction.status == 'pending'"
            ng-class="{'warning':$ctrl.deleteWarning}">
            <span class="message-warning" ng-if="$ctrl.deleteWarning">Are you sure?</span>
            <svg-sprite alt="Edit" source="material-icons" icon="close"></svg-sprite>
          </span>
          <span class="item-edit" ng-click="$ctrl.openStripeCheckout()" ng-if="$ctrl.canAddCard()">
            <svg-sprite alt="Payment" source="material-icons" icon="payment"></svg-sprite>
          </span>
          <span class="item-edit" ng-click="$ctrl.resendConfirmationEmail()" ng-if="$ctrl.canResendEmail()"
            ng-class="{'loading':$ctrl.isSendingEmail}">
            <svg-sprite alt="Resend Email" source="material-icons" icon="email"></svg-sprite>
          </span>
        </div>
      </div>
    </div>
    <div class="editor" slide-toggle="$ctrl.editStatus">
      <field-select field="$ctrl.statusField"></field-select>
    </div>`,
  bindings: {
    transaction: '=',
    move: '<',
    onDelete: '&'
  },
  controller: function($filter, $timeout, $q, Transaction, Alerts, User, Move, Payment) {
    'ngInject';

    this.$onInit = () => {
      this.transaction.move = this.move;
      this.statusField = {
        name:'status',
        type:'select',
        options: Transaction.getStatusList(),
        value: this.transaction.status,
        onChange: () => {
          if(this.statusField.value == this.transaction.status) return this.editStatus = false;
          else if(this.statusField.value == 'customer_cancelled')
            Transaction.modals.cancellationFee(this.transaction).result.then(response => {
              // update the transaction object with the local change
              this.transaction.status = this.statusField.value;
              this.toggleEditStatus();
            },() => this.statusField.value = this.transaction.status);
          else if(this.statusField.value == 'final')
            Transaction.modals.finalize(this.transaction).result.then(response => {
              // update the transaction object with the local change
              this.transaction.status = this.statusField.value;
              this.toggleEditStatus();
            }, () => this.statusField.value = this.transaction.status);
          else {
            Transaction.update(this.transaction.id,{status:this.statusField.value})
              .then((result) => {
                // update the transaction object with the local change
                this.transaction.status = this.statusField.value;
                this.toggleEditStatus();
              }, (error) => {
                // revert the change in browser and throw error
                this.statusField.value = this.transaction.status;
                Alerts.error()
              });
          }
        }
      };

      this.user = Move.findCustomer(this.move).user;

      Payment.getStripeCheckout().then(response => {
        this.stripeCheckout = response;
      });

      // trigger stripe checkout UI function
      this.openStripeCheckout = (transaction) => {
        // trigger stripe payment overlay
        this.stripeCheckout.open({
          name: this.transaction.vendor.name,
          description: 'Book Mover',
          email: this.user.email,
          amount: 0,
          panelLabel: 'Authorize'
        }).then(
          (result) => addCard(result[0].id, this.transaction),
          (result) => error()
        );
      };

      // success handler
      const success = (transaction) => {
        this.loading = false;
      };

      // error handler
      const error = (result) => {
        this.loading = false;
        this.error = true;
        $timeout(() => { this.error = false; }, 300);
        if(!result) return;
        if(result.data && result.data.error && result.data.error.message)
          return Alerts.error({msg:result.data.error.message});
        return Alerts.error();
      };

      const addCard = (source, transaction) => {
        Payment.addCardExternal(this.user.id, {
          move_transaction: transaction.id,
          source: source
        }).then(() => success(transaction),error);
      };
    };

    this.canResendEmail = () => {
      return (['authorized', 'approved', 'final'].includes(this.transaction.status) &&
        (this.transaction.transaction.name == 'move'));
    };

    // Option to resend booking confirmation email
    this.resendConfirmationEmail = () => {
      if(this.isSendingEmail) return;
      this.isSendingEmail = true;
      $q.all([
        Transaction.resendConfirmationMail(this.transaction.id),
        $timeout(() => {},1000)
      ]).then((result) => {
        this.isSendingEmail = false;
        Alerts.success({msg:'Email sent', delay:3});
      });
    };

    this.canAddCard = () => {
      return this.transaction.status == 'pending' &&
        this.transaction.vendor.has_stripe;
    };

    this.isRefundable = () => {
      return this.transaction.status == 'final' &&
        !this.transaction.refund && this.transaction.vendor.has_stripe;
    };

    this.hasStatusControl = () => {
      return !(this.transaction.vendor.has_stripe &&
        this.transaction.transaction.name == 'move' &&
        this.transaction.status == 'final');
    };

    this.toggleEditStatus = () => {
      this.editStatus = !this.editStatus;
    };

    this.statusLabel = () => {
      if(!this.statusField) return '';
      let status = this.statusField.options.find(s => s.value == this.transaction.status);
      return !this.transaction.refund ?
        (status ? status.label : this.transaction.status) :
        `Refunded ${$filter('currency')(this.transaction.refund/100)}`;
    }

    // function to edit existing move transaction
    this.editTransaction = () => {
      let moveTask = Move.findMoveTask(this.transaction.move_task.id,this.move);
      Transaction.modals.edit(this.move, moveTask, this.transaction).result.then((result) => {
        if(this.transaction.status != 'final') Alerts.success({msg:'Transaction Updated', delay:3});
      }).catch(angular.noop);
    };

    // function to refund move transaction
    this.refundTransaction = () => {
      Transaction.modals.refund(this.transaction).result.then((result) => {
        if(this.transaction.status != 'final') Alerts.success({msg:'Transaction Updated', delay:3});
      }).catch(angular.noop);
    };

    // function to delete a move transaction
    this.deleteTransaction = () => {
      if(this.deleteDisabled) return;
      if(!this.deleteWarning) {
        this.deleteWarning = true;
        this.deleteDisabled = true;
        $timeout(() => this.deleteDisabled = false, 300);
        $timeout(() => this.deleteWarning = false, 5000);
        return;
      }
      Transaction.delete(this.transaction.id).then((result) => {
        if(this.onDelete) this.onDelete({transaction:this.transaction});
      }, Alerts.error);
    }

  }
});
