angular.module('core')
  .directive('spinner', function() {
    return {
      restrict: 'E',
      template: `
        <div class="spinner">
          <svg role="img" alt="loading">
            <use xlink:href="images/sprites/movr.svg#logo-small"></use>
          </svg>
        </div>`,
      replace: true
    };
  });
