angular.module('payment')
.component('vendorAutocomplete', {
  template: `
    <span class="vendor"
      name="{{$ctrl.name}}"
      id="{{$ctrl.name}}"
      ng-required="$ctrl.options.required"
      maxlength="50"
      data-suggest=""
      autocomplete="$ctrl.findVendor(term)"
      on-select="$ctrl.selectVendor()"
      on-deselect="$ctrl.deselectVendor()"
      ng-class="{'suggested':$ctrl.isSuggested}"
      type="text"
      contenteditable="{{$ctrl.options.editable}}"
      ng-model="$ctrl.vendor"
      ng-model-options="{updateOn:'default blur',debounce:{'default':200,'blur':0}}"></span>
    <label class="placeholder" for="{{$ctrl.name}}">Vendor</label>
    <label class="error" ng-messages="$ctrl.form[$ctrl.name].$error"
      ng-if="$ctrl.form[$ctrl.name].$invalid && $ctrl.form.$submitted">
      <span ng-message="required">Required.</span>
      <span ng-message="suggested">Must be an existing vendor.</span>
    </label>
    <svg-sprite class="stripe-partner" alt="stripe" source="partners" icon="stripe"
      ng-if="$ctrl.isSuggested && $ctrl.suggestedVendor.has_stripe"></svg-sprite>`,
  bindings: {
    options: '<',
    form: '<?',
    name: '<?',
    onUpdate: '&'
  },
  controller: function($q,Vendor) {
    'ngInject';

    this.uuid = new Date();

    this.$onInit = () => {
      this.options = angular.merge({},{
        required:true,
        editable:true
      }, this.options);
      if(this.options.initial) this.vendor = this.options.initial;
      this.name = (this.name || 'vendor') + '-' + this.uuid;
    };

    this.$postLink = () => {
      if(!this.options.required) return;
      if(this.options.initial) {
        this.isSuggested = true;
        if(this.form && this.form[this.name])
          this.form[this.name].$setValidity('suggested',true);
      }
      else if(this.form && this.form[this.name])
        this.form[this.name].$setValidity('suggested',false);
    }

    // function to handle type-ahead suggestion
    this.findVendor = (term) => {
      var defer = $q.defer();
      if (!term) {
        this.suggestedVendor = false;
        defer.resolve(null);
      } else {
        Vendor.lookup(term,this.options.filter).then((result) => {
          if(result) {
            this.suggestedVendor = result;
            defer.resolve(result.name.trim());
          } else {
            this.suggestedVendor = false;
            defer.reject('No Matches Found');
          }
        });
      }
      return defer.promise;
    };

    this.selectVendor = () => {
      this.isSuggested = true;
      if(this.options.required && this.form && this.form[this.name])
        this.form[this.name].$setValidity('suggested',true);
      if(this.onUpdate) this.onUpdate({vendor:this.suggestedVendor});
    };

    this.deselectVendor = () => {
      this.isSuggested = false;
      if(this.options.required && this.form && this.form[this.name])
        this.form[this.name].$setValidity('suggested',false);
      if(this.onUpdate) this.onUpdate({vendor:false});
    };

  }
});
