angular.module('vendor').component('screenAutoQuoteComplete', {
  templateUrl: '/templates/vendor/screen-complete.html',
  bindings: {
    screen: '<',
    form: '<',
    vendor: '<',
    onClose: '&'
  },
  controller: function($timeout, Vendor) {
    'ngInject';

    this.$onInit = () => {
      $timeout(() => this.showCheck = true, 0);
    };

    this.complete = () => {
      if(this.loading) return false;
      this.loading = true;
      let data = { is_complete: true };
      Vendor.details.update(this.vendor.details.id, data).then(this.onClose,error);
    }

    const error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

  }

});
