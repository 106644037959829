angular.module('field')
.component('fieldChecklist', {
  template: `
    <div class="checklist-row" ng-repeat="item in $ctrl.field.list">
      <input type="checkbox" class="checkbox" name="item-{{$ctrl.uuid}}-{{$index}}"
        id="item-{{$ctrl.uuid}}-{{$index}}"
        checklist-model="$ctrl.field.value"
        checklist-value="item.value"
        checklist-change="$ctrl.change()" />
      <label for="item-{{$ctrl.uuid}}-{{$index}}">
        <span class="label" ng-bind="item.label"></span>
        <span class="checkmark">
          <svg-sprite source="material-icons" icon="check" alt="check"></svg-sprite>
        </span>
      </label>
    </div>`,
  bindings: {
    field: '=',
    form: '<',
  },
  controller: function() {
    'ngInject';

    this.uuid = Date.now();

    this.$onInit = () => {
      this.field.value = this.field.value || [];
    }

    this.change = () => {
      if(this.field.onChange) this.field.onChange();
    }

  }
});
