angular.module('tasks').constant('PackingMaterialsFlow', {

  'packing-materials': {
    flows: {
      default: [
        { name: 'supplyList', markComplete: true},
      ]
    },
    screens: {

      supplyList: {
        title: 'Order packing materials',
        editTitle: 'Edit materials you need to order.',
        type: 'checkout',
        model: 'supply_lists',
        name: 'supplyList',
        label: 'Packing Materials',
        transaction: 'packing',
        buttonLabel: 'Checkout',
        editable: false,
      },

      // Review
      review: {
        title: 'Your Order Summary',
        type: 'review',
        name: 'review',
        back: false,
        sections: [{
          label: 'Purchased Items',
          fields: [{
            name: 'supplyList',
            type: 'grouped-item-list',
            model: 'supply_lists'
          }]
        },{
          fields: [{
            name: 'total',
            label: 'Total',
            type: 'currency',
            model: 'move_transaction'
          }]
        }]
      },

      // Complete
      complete: {
        title: 'That\'s a wrap!',
        subtitle: `Your order is complete.
          Packing materials usually arrive within 3-5 business days.`,
        type: 'complete',
        name: 'complete',
        button: 'Done',
        back: 'review',
        fields: [{
          name: 'id',
          label: 'Order Number',
          type: 'text',
          model: 'move_transaction',
        },{
          name: 'total',
          label: 'Total',
          type: 'currency',
          model: 'move_transaction',
        },{
          name: 'created_at',
          label: 'Date Ordered',
          type: 'date',
          model: 'move_transaction',
        }],
      },
    }
  }

});
