angular.module('core').factory('Slack',
function($http,Data) {
  'ngInject';

  var webhookUrl = 'https://hooks.slack.com/services/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';

  return {
    message: (message,attachments) => {
      if (Data.env != 'prod') return;
      var data = { text: message };
      if (attachments) data.attachments = attachments;
      var config = {
        headers: {
          'content-type': () => null,
          'authorization': () => null,
          'app': () => null,
          'x-uuid': () => null
        }
      };
      return $http.post(webhookUrl,data,config);
    }
  };

});
