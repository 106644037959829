angular.module('vendor').component('quotesPanel', {
  templateUrl: '/templates/vendor/quotes-panel.html',
  bindings: {
    vendor: '<',
    initial: '='
  },
  controller: function($rootScope, $timeout, $filter, $q, $state, moment,
    Vendor, Field, Task, QuoteRequest, AddressUtil, Alerts) {
    'ngInject';

    // Default rows per page
    const per_page = 50;

    this.$onInit = () => {
      loadRequests();
    };

    const getFieldValue = (fieldName,request) => {
      let field = Field.get(fieldName,'book-movers');
      return Field.getValue(field,request.move_task);
    };

    this.filters = [{
      name: 'active',
      label: 'Active',
      value: 'pending',
      default: true
    },{
      name: 'history',
      label: 'History',
      value: { $in: ['submitted','approved'] }
    }];

    this.fields = [{
      name: 'move_date',
      label: 'Move Date',
      value: request => {
        return $filter('date')(getFieldValue('move_date',request), 'shortDate') || '&mdash;';},
      direction: false,
      sortable: true,
      default: true
    },{
      name: 'move_size',
      label: 'Move Size',
      value: request => getFieldValue('move_size',request) || '&mdash;',
      sortable: true
    },{
      name: 'from_address',
      label: 'Origin',
      value: request => AddressUtil.getFormatted(getFieldValue('from_address',request),{
        street:false, unit:false, zipcode:false
      }),
      class: () => 'origin',
      sortable: false
    },{
      name: 'to_address',
      label: 'Destination',
      value: request => AddressUtil.getFormatted(getFieldValue('to_address',request),{
        street:false, unit:false, zipcode:false
      }),
      sortable: false
    },{
      name: 'price',
      label: 'Quote',
      value: request => request.price ? $filter('currency')(request.price/100) : '&mdash;',
      class: () => 'price',
      sortable: true
    },{
      name: 'status',
      label: 'Status',
      value: request => mapStatusLabel(request),
      class: request => {
        let classes = ['request-status']
        if(request.status == 'pending' && request.price) classes.push('review');
        else classes.push(request.status);
        return classes;
      },
      sortable: true
    },{
      name: 'blank_space', // pads the table for active hover space
      label: '',
      value: request => ' ',
      sortable: false
    }];

    const mapStatusLabel = (request) => {
      switch(request.status) {
        case 'pending':
          if(request.price) return 'Review Auto-Quote'
          else return 'Needs Quote';
          break;
        case 'submitted':
        case 'approved':
          return 'Quote Sent';
      }
    }

    /* PAGINATION */
    this.pageOptions = { pageCount: 0 };

    // function for pagination
    this.updatePage = (page) => {
      if(this.loading) return false;
      this.loading = true;
      this.active.page = page;
      return loadActiveSet();
      /* Can't incremental load until sorting and filtering by relationship fields
      return loadRequests();
      */
    };

    this.updateFilter = (filter) => {
      if(this.active.filter.name == filter.name || this.loading) return;
      this.loading = true;
      this.active.filter = filter;
      this.active.page = 1;
      if(this.active.filter && this.active.filter.value)
        this.params.where.status = this.active.filter.value;
      return loadRequests();
    }

    /* SORTING */
    // function to update sort field
    const updateSort = (field) => {
      if(this.loading) return false;
      this.loading = true;
      this.active.page = 1;
      if(this.active.field && field.name == this.active.field.name)
        this.active.field.direction = !this.active.field.direction;
      else {
        this.active.field = field;
        this.active.field.direction = field.invertDirection || false;
        // this.params.sort[field.name] = this.active.field.direction;
      }
      return loadActiveSet();
      /* Can't incremental load until sorting and filtering by relationship fields
      this.params.sort = {};
      this.params.sort[this.active.field.name] = this.active.field.direction;
      return loadTransactions();
      */
    };

    // currently selected view options driving UI display
    this.active = {
      total: 0,
      page: 1, // temporary
      field: this.fields.find(f => f.default === true),
      filter: this.filters.find(f => f.default === true),
      data: []
    };

    // set default quote_request options for API
    this.params = {
      per_page: 9999, // per_page
      page: 1,
      sort: {},
      where: { status: this.active.filter.value }
    };

    const loadRequests = () => {
      this.loadSingleActiveQuote = false;
      this.requests = [];
      QuoteRequest.find(this.params).then((result) => {
        this.requests = result;
        loadActiveSet();
        if(this.initial) {
          let exists = this.requests.find(request => request.id == this.initial);
          if(exists) this.active.quote = angular.copy(exists);
          else Alerts.info({msg:`Oops, looks like that request for quote is no longer open.`});
          $state.go('.',{active:null});
        }
      },error);
    };

    const quoteRequestScreen = (request) => {
      if(request.status != 'pending') return false;
      this.active.quote = angular.copy(request);
    };

    // create set of actions to pass into sort-table component
    this.actions = {
      sort: updateSort,
      rowClick: (quote) => quote.status == 'pending' ? quoteRequestScreen(quote) : QuoteRequest.modals.summary(quote, this.vendor),
      rowOverlay: {
        label: () => 'Review',
        show: (quote) => quote.status == 'pending',
        click: quoteRequestScreen
      },
      rowClass: (quote) => {
        if(quote.status == 'pending' && quote.price) return 'review';
        return quote.status;
      }
    };

    // Temporary function since we can't do this serverside with relationship fields
    const loadActiveSet = () => {
      let data = angular.copy(this.requests);
      // limit active requests to the future, no past moves
      if(this.active.filter.name == 'active') data = data.filter((request) => {
        let move_date = getFieldValue('move_date',request);
        return move_date ? moment(move_date).isSame(new Date(),'day') ||
          moment(move_date).isAfter(new Date(),'day') : false;
      });
      // create sorted filtered set
      data.sort((a,b) => {
        switch(this.active.field.name) {
          case 'move_date':
            return new Date(getFieldValue('move_date',b)) - new Date(getFieldValue('move_date',a));
          case 'move_size':
            let sortOrder = Field.get('move_size','book-movers').options.map(option => option.value);
            return sortOrder.indexOf(getFieldValue('move_size',a)) - sortOrder.indexOf(getFieldValue('move_size',b));
          case 'price':
            return b.price - a.price;
          case 'status':
            if(b.status == a.status) return new Date(getFieldValue('move_date',b)) - new Date(getFieldValue('move_date',a));
            return b.status - a.status;
        }
      });
      if(this.active.field.direction) data.reverse();
      // limit to paginated set and load
      this.active.data = data.slice((this.active.page - 1) * per_page, this.active.page * per_page);
      this.pageOptions.pageCount = Math.ceil(data.length/per_page);
      complete();
    };

    const complete = () => {
      this.ready = true;
      $timeout(() => this.loading = false, 0);
    };

    // Configuration of fields on mobile moves list
    this.cardLayout = {
      title: this.fields.find(f => f.name == 'move_size'),
      status: this.fields.find(f => f.name == 'status'),
      date: this.fields.find(f => f.name == 'move_date'),
      total: this.fields.find(f => f.name == 'from_address'),
      revenue: this.fields.find(f => f.name == 'to_address'),
      button: {
        show: (request) => request.status == 'pending',
        click: (request) => quoteRequestScreen(request),
        label: () => 'Review'
      }
    };

    // listen for move task data broadcast
    // (doesn't usually work because vendor isn't subscribed until on transaction together)
    $rootScope.$on('move.task', (event, id, message) => updateTaskData(id, message));

    const updateTaskData = (move_id, data) => {
      if(!this.ready || !this.requests.length) return;
      let matches = this.requests.filter(request => request.move.id == move_id);
      matches.forEach((request) => {
        if(request.move_task.id == data.id) angular.merge(request.move_task, data);
      });
      loadActiveSet();
    };

    // listen for vendor.quote_request pubnub update
    $rootScope.$on('vendor.quote_request', (event, id, message) => updateRequestData(id, message));

    const updateRequestData = (vendor_id, data) => {
      if(!this.ready || !this.requests.length) return;
      let exists = this.requests.find(request => request.id == data.id);
      if(exists) angular.merge(exists, data);
      else if(data.status == 'pending') this.requests.push(data);
      else return;
      loadActiveSet();
    };

    this.closeQuoteRequest = () => this.active.quote = false;

    const error = (alert) => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
      if(alert) Alerts.error();
    };

  }
});
