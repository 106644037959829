angular.module('field').component('fieldRadio', {
  template: `
    <input type="radio" class="checkbox"
      name="{{$ctrl.field.name}}"
      id="{{$ctrl.field.name}}-{{$ctrl.option}}"
      ng-model="$ctrl.field.value"
      ng-value="$ctrl.field.options[$ctrl.option].value"
      ng-change="$ctrl.change()"
      ng-disabled="$ctrl.field.isDisabled()"/>
    <label for="{{$ctrl.field.name}}-{{$ctrl.option}}">
      <span class="checkmark"></span>
      <span ng-bind="$ctrl.field.options[$ctrl.option].label"></span>
    </label>`,
  bindings: {
    field: '=',
    form: '<',
    option: '@'
  },
  controller: function() {
    'ngInject';

    this.$onInit = () => {
      this.field.value = this.field.value || [];
    }

    this.change = () => {
      if(this.field.onChange) this.field.onChange();
    }

  }
});
