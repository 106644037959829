angular.module('customer').controller('dashboardController',
function ($scope, $timeout, $state, $stateParams, $q, $cookies, $window,
  User, Access, Move, Task, Field, MoveQuote, Transaction, Partner, Building,
  Alerts, Data, moment) {
  'ngInject';

  if(Access.limit('user', () => $state.reload())) return;

  $scope.user = User.get();
  $scope.$on('userUpdated',() => $scope.user = User.get());

  $scope.move = false;

  let taskList = false;

  Move.get().then((move) => {

    let promises = {};
    // get tasks
    promises.tasks = Task.getList().then((list) => {
      return taskList = list;
    });
    // get move_tasks
    if(!move.move_tasks) promises.move_tasks = Task.get();
    // get quotes
    if(!move.move_quotes) promises.quotes = MoveQuote.get();
    // get transactions
    if(!move.move_transactions) promises.transactions = Transaction.getAll();
    // get partner
    if(!move.partner && Move.getSource(move))
      promises.partner = Partner.get(Move.getSource(move)).catch(angular.noop);
    $q.all(promises).then((results) => {
      // don't load move until all move data is also fetched
      $scope.move = move;
      // append the moveTasks
      if(results.move_tasks) $scope.move.move_tasks = results.move_tasks;
      // append the task and flow to each moveTask
      $scope.move.move_tasks.forEach(formatMoveTask);
      // append the moveQuotes
      if(results.quotes) $scope.move.move_quotes = results.quotes;
      // append the moveTransactions
      if(results.transactions) $scope.move.move_transactions = results.transactions.data;
      // append the partner data
      if(results.partner) $scope.move.partner = results.partner;
      updateProgress();
      sortAll();

      // trigger action for newly registered users
      if(
        $stateParams.triggerWelcome || // just came from registration screen
        $cookies.get('triggerWelcome') || // just came from external reservation
        (!$stateParams.panel && // avoid re-opening this modal every time mobile user switches panels
          $scope.move.move_tasks.length == 1 && // only has one task
          $scope.move.move_tasks[0].progress == 0 // that task has no progress
        )
      ) {
        // if the user is an api created user from a partner, go directly to the first task
        // else display welcome modal
        if($scope.move.partner) {
          const task = $scope.move.move_tasks[0];
          if(task) $scope.selectTask(task);
        } else User.modals.welcome($scope.move);
      }
      $scope.ready = true;
    });

  });

  $scope.getTaskLength = (moveTask) => moveTask.flow ? moveTask.flow.filter(screen => !screen.hidden).length : 0;

  let formatMoveTask = (moveTask) => {
    let task = taskList.find(t => t.id == moveTask.task.id);
    moveTask.task = angular.merge(moveTask.task, task);
    moveTask.flow = Task.getFlow(moveTask, $scope.move);
    return moveTask;
  };

  function updateProgress() {
    let totalSteps = 0;
    let totalProgress = 0;
    $scope.move.move_tasks.forEach((moveTask) => {
      let taskLength = $scope.getTaskLength(moveTask)
      totalSteps += taskLength;
      totalProgress += moveTask.is_complete ? taskLength : Math.min(moveTask.progress,taskLength);
    });
    $scope.moveProgress = totalSteps ? parseInt(totalProgress/totalSteps*100) : 0;
  }

  $scope.$on('move.data',(event,id,message) => updateMoveData(message));

  function updateMoveData(moveData) {
    if(!$scope.move || moveData.id !== $scope.move.id) return;
    // remove children that shouldn't be extended
    stripArrays(moveData);
    angular.merge($scope.move,moveData);
    sortAll();
  }

  function stripArrays(data) {
    if(data instanceof Object) Object.keys(data).forEach((key) => {
      if(data[key] instanceof Array) delete data[key];
      else stripArrays(data[key]);
    });
  }

  function sortAll() {
    sortTasks();
    sortQuotes();
  }

  /*** TASKS ***/

  $scope.$on('move.task',(event,id,message) => updateMoveTask(message));

  function updateMoveTask(moveTask) {
    if(!$scope.ready || !taskList) return; // fix history loading bug
    let task = taskList.find(t => t.id == moveTask.task.id);
    moveTask.task = angular.merge(moveTask.task, task);
    let exists = Move.findMoveTask(moveTask.id,$scope.move);
    if(!exists) {
      $scope.move.move_tasks.push(formatMoveTask(moveTask));
      Alerts.info({msg:`${moveTask.task.label} task added!`});
    } else angular.extend(exists,moveTask);
    updateProgress();
    sortTasks();
    return moveTask;
  }

  $scope.$on('trigger:task',(event,selector) => {
    let task = Move.findMoveTask(selector,$scope.move) || Move.findMoveTasks(selector,$scope.move)[0];
    if(task) $scope.selectTask(task);
  });

  $scope.selectTask = (task) => {
    $timeout(() => $scope.activeFlow = (task ? angular.copy(task) : false), 0);
  };

  $scope.closeFlow = () => $scope.selectTask(false);

  $scope.addTask = () => $scope.selectTask({task: { name: 'add-task' }});

  function sortTasks() {
    if(!$scope.move || !$scope.move.move_tasks) return;
    $scope.move.move_tasks.sort((a,b) => {
      if(a.is_complete != b.is_complete) return a.is_complete ? 1 : -1;
      if(a.task.order != b.task.order) return a.task.order - b.task.order;
      return new Date(b.created_at) - new Date(a.created_at);
    });
  }

  $scope.taskDescription = (moveTask) => {
    switch(moveTask.task.name) {
      case 'book-movers':
        let moveDate = Field.getValue({name: 'move_date',type:'date'}, moveTask);
        let origin = Field.getValue({name: 'from_address',type:'address'}, moveTask);
        let destination = Field.getValue({name: 'to_address',type:'address'}, moveTask);
        if(moveDate && origin && destination) return `${moment(moveDate).format('MM/DD/YYYY')} - ${origin.city} to ${destination.city}`;
        break;
      case 'storage':
        let storageStartDate = Field.getValue({name: 'storage_start_date',type:'date'}, moveTask)
        let duration = Field.getValue({name: 'storage_duration'}, moveTask)
        if(storageStartDate && duration) return `${moment(storageStartDate).format('MM/DD')} for ${duration}`;
        break;
      case 'packing-materials':
        let itemCount = Field.getValue({name: 'supplyList', type: 'calculate'}, moveTask);
        if(itemCount) return `${itemCount} Items ${moveTask.is_complete ? 'Ordered' : 'Needed'}`;
        break;
      case 'update-address':
        let startDate = Field.getValue({name: 'address_start_date',type:'date'}, moveTask);
        if(startDate) return `Mail forwarding on ${moment(startDate).format('MM/DD')}`;
        break;
      case 'donate':
      case 'dispose':
      case 'sell':
        let title = Field.getValue({name: 'title'}, moveTask);
        if(title) return title;
        break;
      case 'clean':
        let roomCount = Field.getValue({name: 'rooms'}, moveTask)
        let bathCount = Field.getValue({name: 'bathrooms'}, moveTask)
        let date = Field.getValue({name: 'date'}, moveTask);
        if(roomCount && bathCount && date) return `${roomCount} Bedrooms and ${bathCount} Bathrooms on ${moment(date).format('MM/DD')}`;
        break;
      case 'custom':
        let description = Field.getValue({name: 'description'}, moveTask);
        if(description) return description;
        break;
      case 'finalize':
        if(moveTask.data && moveTask.data.book_movers_task_id) {
          let bookingTask = Move.findMoveTask(moveTask.data.book_movers_task_id,$scope.move);
          let moveDate = Field.getValue({name: 'move_date',type:'date'},bookingTask);
          if(moveDate) return `Requirements provided for your move on ${moment(moveDate).format('MM/DD/YYYY')}`;
        }
        break;
    }
    return moveTask.task.description;
  };

  $scope.canRepeatMove = () => {
    if(!$scope.move || !$scope.move.move_tasks) return false;
    let bookMoverTasks = Move.findMoveTasks('book-movers',$scope.move);
    return bookMoverTasks.length && !bookMoverTasks.find(task => !task.is_complete);
  };

  $scope.repeatMoveModal = () => {
    Move.modals.repeatMove($scope.user);
  };


  /** QUOTES **/

  // listen for move.quote broadcast
  $scope.$on('move.quote',(event,id,message) => newQuote(message));

  // updates quotes from broadcast
  function newQuote(newQuote) {
    if(!$scope.move) return; // fix history loading bug
    let exists = $scope.move.move_quotes.find(quote => quote.id == newQuote.id);
    if(exists) angular.merge(exists,newQuote);
    else $scope.move.move_quotes.push(newQuote);
  }

  function sortQuotes() {
    if(!$scope.move || !$scope.move.move_quotes) return;
    $scope.move.move_quotes.sort((a,b) => a.updated_at - b.updated_at);
  }

  $scope.mobilePanel = $stateParams.panel || 'taskList';
  $scope.updateMobilePanel = (active) => {
    if($scope.mobilePanel == active) return false;
    $scope.mobilePanel = active;
    if(angular.element('#scroll-messages').length && angular.element('.message.last').length)
      angular.element('#scroll-messages').scrollTop(angular.element('.message.last')[0].offsetTop);
  };

  $scope.getCXName = () => {
    if(!$scope.move) return;
    let cx = Move.findCXM($scope.move) ? Move.findCXM($scope.move).user : {};
    if(cx.id !== 1) return cx.firstname;
    else return $scope.move.assigned_concierge;
  }

  $scope.findCXM = () => Move.findCXM($scope.move);

  /* CX Status Indicator */
  $scope.CXStatus = () => {
    if(!$scope.move) return;
    let cx = Move.findCXM($scope.move) ? Move.findCXM($scope.move).user : {};
    if(!cx.data || !cx.data.away) return false;
    let startAway = moment.utc(cx.data.away.start, 'HH:mmZ');
    let endAway = moment.utc(cx.data.away.end, 'HH:mmZ');
    let isBefore = startAway.isBefore(endAway);
    let isBetween = isBefore ? moment().utc().isBetween(startAway,endAway) : moment().utc().isBetween(endAway,startAway);
    return (isBefore === isBetween) ? 'offline' : 'online';
  };

  $scope.CXReturnTime = () => {
    let cx = Move.findCXM($scope.move).user;
    return cx.data.away.end;
  };

  $scope.CXAwayTitle = () => {
    let cx = Move.findCXM($scope.move).user;
    return cx.data.away.title || 'Away';
  };

  $scope.CXAwayMessage = () => {
    let cx = Move.findCXM($scope.move).user;
    return cx.data.away.message || false;
  };

  $scope.hideAwayMessage = () => $scope.awayHidden = true;

  // PubNub updates for CXMs
  $scope.$on('move.user',(event,id,message,remove) => updateMoveUser(id,message,remove));
  const updateMoveUser = (move_id,moveUser,remove) => {
    if(!$scope.move) return;
    let index = $scope.move.move_users.findIndex(user => user.id == moveUser.id);
    if(remove) return (index != -1) ? $scope.move.move_users.splice(index,1) : false;
    if(index == -1) $scope.move.move_users.push(moveUser);
    else angular.merge($scope.move.move_users[index],moveUser);
  };


});
