angular.module('user').factory('Google',
function($q, $timeout, $window, Data) {
  'ngInject';

  const API = {
    login: (promise,attempt) => {
      promise = promise || $q.defer();
      if(attempt >= 50) return promise.reject('gapi error');
      if(!$window.gapi || !$window.gapi.auth2)
        $timeout(() => API.login(promise, attempt ? attempt+1 : 1),100);
      else $window.gapi.auth2.authorize({
        client_id: Data.googleClientId,
        scope: 'email profile openid',
        response_type: 'id_token token'
      }, (response) => {
        if(response.error) return promise.reject(response.error);
        promise.resolve(response);
      });
      return promise.promise;
    }
  };

  return API;

});
