angular.module('field')
.component('fieldItemList', {
  template: `
    <div class="clearfix" ng-click="$ctrl.field.onClick()">
      <label ng-bind-html="$ctrl.field.label"></label>
      <div class="form-arrow">
        <svg role="img" alt="details">
          <use xlink:href="images/sprites/icons.svg#arrow-right"></use>
        </svg>
      </div>
    </div>`,
  bindings: {
    field: '=',
    onChange: '&',
    onClick: '&'
  }
});
