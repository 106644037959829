angular.module('tasks').component('taskChain', {
  templateUrl: '/templates/tasks/task-chain.html',
  bindings: {
    initialTask: '<',
    move: '<',
    onClose: '&'
  },
  controller: function($rootScope, $timeout, User, Task) {
    'ngInject';

    this.$onInit = () => {
      this.taskList = [this.initialTask];
      if(this.initialTask.is_complete) this.activeTask = 'complete';
      else this.activeTask = 0;
    }

    this.next = () => {
      // logic for progressing to next task or calling onComplete
      if(this.activeTask == 'complete') this.close();
      else if(this.activeTask === this.taskList.length - 1)
        this.activeTask = 'complete';
      else this.activeTask ++;
    };

    this.add = (moveTasks) => {
      Task.getList().then((tasks) => {
        moveTasks.forEach((moveTask) => {
          if(moveTask.task.id) {
            moveTask.task = tasks.find((t) => t.id == moveTask.task.id);
            moveTask.flow = Task.getFlow(moveTask, this.move);
          }
          this.taskList.push(moveTask);
        });
      });
    };

    this.close = () => {
      // handle any housekeeping and call the parent close function
      this.onClose();
    };

    this.taskClasses = (moveTask) => [moveTask.task.name];

    $rootScope.$on('move.task',(event,id,message) => updateMoveTask(message));

    const updateMoveTask = (moveTask) => {
      if(!this.move || moveTask.move.id !== this.move.id) return;
      let exists = this.taskList.find((task) => task.id == moveTask.id);
      if(exists) angular.merge(exists,moveTask);
    };

  }
});
