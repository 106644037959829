angular.module('field').component('fieldZipcode', {
  template: `
    <input type="text"
      ng-model="$ctrl.displayValue"
      ng-model-options="{allowInvalid:true}"
      name="{{$ctrl.field.name}}" id="zip-{{$ctrl.uuid}}"
      ng-change="$ctrl.updateValue()"
      ng-required="$ctrl.field.isRequired()"
      ng-disabled="$ctrl.field.isDisabled()"
      ui-validate="{validZip: '$ctrl.isValid($value)'}"/>
    <label class="placeholder" for="zip-{{$ctrl.uuid}}"
      ng-bind-html="$ctrl.field.placeholder"></label>
    <label class="error" ng-messages="$ctrl.form[$ctrl.field.name].$error"
      ng-if="$ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      <span ng-message="required">Required. </span>
      <span ng-message="validZip">Must be a 5 digit US zipcode. </span>
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function() {
    'ngInject';

    this.uuid = Date.now();

    this.$onInit = () => {
      this.displayValue = angular.copy(this.field.value);
    };

    this.updateValue = () => {
      let value = String(this.displayValue);
      value = value.replace(/[^0-9]+/g, '');
      if(value != this.field.value) {
        this.field.value = value;
        if(angular.isDefined(this.field.onChange)) this.field.onChange(value);
      }
      this.displayValue = value;
    };

    this.isValid = (value) => {
      return value ? value.length == 5 : true;
    };

  }
});
