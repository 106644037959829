angular.module('tasks').component('screenMoveDate', {
  templateUrl: '/templates/tasks/screen-move-date.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,$q,User,Task,Move,Field,Transaction,moment,_) {
    'ngInject';

    this.ready = false;

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      this.view = User.get().role;
      this.screen.data = {};

      // find any active primary transactions for this task
      this.transaction = _.get(this.move, 'move_transactions', []).filter(mt => {
        return ['pending','authorized','approved','charged','final','declined'].includes(mt.status);
      }).find(mt => {
        return mt.move_task_id == this.task.id && mt.transaction.id == Transaction.getDefault(this.task.task.name);
      });

      Move.getSoldOutDates(this.move.id).then(dates => {
        this.soldOutDates = dates.filter(date => date.type === 'sold-out').map(date => date.date);
        this.limitedAvailableDates = dates.filter(date => date.type === 'all-day-arrivals').map(date => date.date);
        this.screen.fields.forEach(initializeField);
        this.ready = true;
      });
    };

    const initializeField = (field) => {
      field.task = this.task;
      Field.addDefaults(field, this.screen.data, this.move);
      if(field.name === 'move_date' && this.view === 'mover') {
        // disable the move_date field if already booked
        if(this.transaction) field.readonly = true;
        // custom semi-hack to support disabled dates from API
        field.filterDates = this.soldOutDates ? [...this.soldOutDates] : [];
        // no longer allow same day selection or next day if after 2pm
        field.filterDates.push(moment().format('YYYY-MM-DD'));
        if(moment().isAfter(moment().startOf('day').set({h:14})))
          field.filterDates.push(moment().add(1,'d').format('YYYY-MM-DD'));
      }
      if(field.custom && field.custom.label) field.customLabel = () =>
        Field.customLabel(field, this.screen.fields.find(f =>
          f.name == field.custom.label.field).value);
      field.value = angular.isDefined(Field.getValue(field,this.task,this.move)) ?
        Field.getValue(field,this.task,this.move) : field.default;
      this.screen.data[field.name] = field.value;
      field.onChange = () => {
        this.screen.data[field.name] = field.value;
        if(this.screen.onChange) this.screen.onChange(buildData());
      };

    };

    this.isDateLimited = () => {
      return this.screen.data.move_date && this.limitedAvailableDates.includes(moment(this.screen.data.move_date).format('YYYY-MM-DD'));
    };

    this.isNextDay = () => {
      return this.screen.data.move_date && moment().add(1,'day').isSame(moment(this.screen.data.move_date),'day');
    };

    this.fieldFilter = (field) => !field.hidden;

    this.next = (skip) => {
      if(this.loading) return;
      if(skip) {
        if(this.screen.skip.fields) {
          this.screen.fields = angular.copy(this.screen.skip.fields);
          this.screen.fields.forEach(initializeField);
          this.screen.title = angular.copy(this.screen.skip.title);
          this.screen.subtitle = angular.copy(this.screen.skip.subtitle);
          return this.screen.skip = false;
        } else return this.onNext();
      }
      this.loading = true;
      if(!valid(this.form)) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      data = data || {};
      this.screen.fields.filter(this.fieldFilter).forEach((field) =>
        Field.buildSaveData(field,data));
      return data;
    };

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }

});
