angular.module('tasks').component('taskFlow', {
  templateUrl: '/templates/tasks/task-flow.html',
  bindings: {
    task: '<',
    name: '<',
    move: '<',
    onAdd: '&',
    onClose: '&',
    onComplete: '&'
  },
  controller: function($rootScope, $timeout, Task, Alerts, Modal, Move) {
    'ngInject';

    this.$onChanges = (changes) => {
      if(changes.task) {
        if(!this.flow) return initFlow();
        if(changes.task.currentValue.id != changes.task.previousValue.id) {
          this.flow = false;
          $timeout(initFlow,0);
        }
      }
    };

    this.$postLink = () => $timeout(() => this.ready = true, 0);

    const initFlow = () => {
      let defaultFlow = this.task.flow || Task.getFlow(this.task,this.move);
      if(this.name == 'complete' || this.task.progress >= defaultFlow.length)
        this.flow = [Task.getScreen(this.task.task.name,'complete')]
      else this.flow = defaultFlow;
      this.review = angular.merge({},Task.getScreen(this.task.task.name,'review'),{back:false});
      // Hide specific screens for certain use cases (external / source flows)
      if(Move.findCustomer(this.move).user.external) this.flow = this.flow.filter((screen) =>
        !(screen.hideExternalSource && screen.hideExternalSource.includes(Move.getSource(this.move))));
      // Don't allow book-movers tasks to go beyond booking screen until booked
      if(!Move.findCustomer(this.move).user.external &&
        this.task.task.name == 'book-movers' &&
        this.task.progress > findScreenIndex('booking') &&
        !isBooked()) this.activeScreen = findScreenIndex('booking');
      else this.activeScreen = this.task.progress ? Math.min(this.flow.length - 1, this.task.progress) : 0;
    };

    this.screenClasses = (screen, $index) => {
      let classes = [screen.name];
      if($index === 0) classes.push('first');
      if($index == this.flow.length - 1) classes.push('last');
      if($index == this.activeScreen) classes.push('active');
      if($index < this.activeScreen) classes.push('prev');
      if($index > this.activeScreen) classes.push('next');
      if($index == this.flow.length) classes.push('complete');
      return classes;
    };

    this.isClickable = ($index) => {
      if(this.task.review || $index == this.activeScreen) return false;
      for(let i=$index+1; i<=this.activeScreen; i++) {
        if(this.flow[i].disableBack) return false;
      }
      return $index <= this.task.progress;
    }

    const getSaveData = (next) => {
      if(next === true || angular.isUndefined(next)) next = this.activeScreen + 1;
      let data =  {
        progress: next,
        completed_screen: this.findActiveScreen().name,
        next_screen: this.activeScreen < this.flow.length - 1 ?
          (!this.flow[next].hidden ? this.flow[next].name : null)
          : null
      };
      if(this.findActiveScreen().markComplete) data.is_complete = true;
      return data;
    };

    this.nextScreen = (save,name) => {
      let next = true;
      if(name) next = findScreenIndex(name);
      else if(this.activeScreen == -1) next = returnScreen || this.flow.length - 1;
      if(save) return save(getSaveData(next)).then((task) => {
        if(['add-task'].includes(this.task.task.name))
          this.onAdd({tasks:task});
        else if(task) $rootScope.$broadcast('move.task',task.id,task);
        $timeout(() => transition(next),0);
      });
      else transition(next);
    };

    this.back = () => {
      let screenIndex;
      if(this.findActiveScreen().back) screenIndex = findScreenIndex(this.findActiveScreen().back);
      transition(screenIndex);
    };

    let returnScreen;
    const transition = (index) => {
      scrollTop(true);
      this.form.$setPristine();
      if(index === true) {
        if(this.activeScreen < this.flow.length - 1) this.activeScreen++;
        else this.onComplete();
      }
      else if(angular.isDefined(index)) {
        if(index == -1) returnScreen = this.activeScreen;
        this.activeScreen = index;
      } else {
        if(this.activeScreen > 0) this.activeScreen--;
        else this.onClose();
      }
    };

    const scrollTop = (animate) => {
      let activeTask = angular.element('.task-flow').first();
      if(animate) activeTask.animate({scrollTop: 0},300);
      else $timeout(() => activeTask.scrollTop(0), 0);
    };

    this.findActiveScreen = () => this.activeScreen == -1 ? this.review : this.flow[this.activeScreen];

    const findScreenIndex = (name) => this.flow.findIndex(screen => screen.name == name);

    const isBooked = () => this.move.move_quotes
      .filter(q => q.move_task.id == this.task.id && q.booked).length ||
      this.move.move_transactions.filter(mt => mt.transaction.name == 'move' &&
        mt.move_task.id == this.task.id).length;
  }
});
