angular.module('cx')
.component('cxmAutocomplete', {
  template: `
    <span id="{{$ctrl.options.name}}-{{$ctrl.uuid}}"
      name="{{$ctrl.options.name}}"
      ng-required="$ctrl.options.required"
      maxlength="50"
      data-suggest=""
      autocomplete="$ctrl.find(term)"
      on-select="$ctrl.select()"
      on-deselect="$ctrl.deselect()"
      ng-class="{'suggested':$ctrl.isSuggested}"
      type="text"
      contenteditable="{{$ctrl.options.editable}}"
      ng-model="$ctrl.value"
      data-placeholder="{{$ctrl.options.label}}"
      ng-model-options="{updateOn:'default blur',debounce:{'default':200,'blur':0}}"></span>`,
  bindings: {
    onLookup: '&',
    onUpdate: '&?',
    options: '<?',
    form: '<?',
    name: '<?'
  },
  controller: function($q) {
    'ngInject';

    this.uuid = Date.now();
    this.$onInit = () => {
      this.options = angular.merge({},{
        required:true,
        editable:true,
        name: 'cxm',
        label: 'Add another'
      }, this.options);
      if(this.options.initial) this.value = this.options.initial;
    };

    this.$postLink = () => {
      if(!this.options.required) return;
      if(this.options.initial) {
        this.isSuggested = true;
        if(this.form && this.form[this.options.name])
          this.form[this.options.name].$setValidity('suggested',true);
      }
      else if(this.form && this.form[this.options.name])
        this.form[this.options.name].$setValidity('suggested',false);
    }

    // function to handle type-ahead suggestion
    this.find = (term) => {
      var defer = $q.defer();
      if (!term) {
        this.suggested = false;
        defer.resolve(null);
      } else {
        this.onLookup({term:term}).then((result) => {
          if(result) {
            this.suggested = result;
            defer.resolve(result.fullname.trim());
          } else {
            this.suggested = false;
            defer.reject('No Matches Found');
          }
        });
      }
      return defer.promise;
    };

    this.select = () => {
      this.isSuggested = true;
      if(this.options.required && this.form && this.form[this.options.name])
        this.form[this.options.name].$setValidity('suggested',true);
      let promise = false;
      if(this.onUpdate) promise = this.onUpdate({cxm:this.suggested});
      if(promise) promise.then(result => {
        if(this.options.clearAfterUpdate) this.value = '';
      });
    };

    this.deselect = () => {
      this.isSuggested = false;
      if(this.options.required && this.form && this.form[this.options.name])
        this.form[this.options.name].$setValidity('suggested',false);
      if(this.onUpdate) this.onUpdate({cxm:false});
    };

  }
});
