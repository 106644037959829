angular.module('tasks').component('screenReview', {
  templateUrl: '/templates/tasks/screen-review.html',
  bindings: {
    screen: '<',
    task: '<',
    move: '<',
    flow: '<',
    onNext: '&',
    onClose: '&'
  },
  controller: function($timeout, $q, moment, Task, Field) {
    'ngInject';

    let moveDateField = false;

    this.$onInit = () => {

      this.screen.sections.forEach((section) => {
        section.fields.forEach((field) => {
          angular.merge(field,Field.get(field.name,this.task.task.name),angular.copy(field));
          field.display = field.display || 'summary';
          if(field.name == 'move_date') moveDateField = field;
        });
      });
      // manual override to hide the inventory section from 'storage' flow
      if(this.task.flow_variant == 'storage') {
        let inventoryIndex = this.screen.sections.findIndex(section => section.label == 'Inventory');
        this.screen.sections.splice(inventoryIndex, 1);
      }
    };

    this.allowEdit = () => {
      if(this.task.task.name == 'book-movers') {
        if(this.screen.name == 'verify') return false;
        return !moment(Field.getValue(moveDateField,this.task)).isBefore(moment(),'day');
      } else return !this.task.is_complete;
    }
    this.edit = () => Task.modals.editReview(this.task,this.move);

    this.next = () => {
      if(this.loading) return;
      this.loading = true;
      if(this.flow.includes(this.screen)) return this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
      else return this.onNext();
    };

    this.save = (progressData) => {
      return Task.update(this.task.id,progressData);
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

  }

});
