// declare module routes
angular.module('core').config(function($stateProvider, $urlRouterProvider, Data) {
  'ngInject';
  $urlRouterProvider
    .when('','/')
    .otherwise(($injector, $location) => {
       var $state = $injector.get('$state');
       $state.go('four04');
       return $location.path();
    });

  $stateProvider
    .state('home', {
      url: `${Data.webUrl}`,
      external: true
    })
    .state('app', {
      abstract: true,
      resolve: {
        userInfo: function (User) { return User.init(); }
      }
    })
    .state('layout-default', {
      abstract: true,
      parent: 'app',
      views: {
        'layout@': {
          templateUrl: '/templates/core/layout-default.html'
        },
        'header@layout-default': {
          templateUrl: '/templates/core/header.html',
          controller: 'headerController'
        },
        'footer@layout-default': {
          templateUrl: '/templates/core/footer.html',
          controller: 'footerController'
        }
      }
    })
    .state('layout-panels', {
      abstract: true,
      parent: 'app',
      views: {
        'layout@': {
          templateUrl: '/templates/core/layout-panels.html'
        },
        'header@layout-panels': {
          templateUrl: '/templates/core/header.html',
          controller: 'headerController'
        }
      }
    })
    .state('layout-full', {
      abstract: true,
      parent: 'app',
      views: {
        'layout@': {
          templateUrl: '/templates/core/layout-full.html'
        }
      }
    })
    .state('four04', {
      parent:'layout-default',
      views: {
        'main': {
          templateUrl: '/templates/core/404.html',
          controller: '404Controller'
        },
        'footer': {
          template: ''
        }
      },
      resolve: {
        // needed to allow the outer template to render before the 404 controller
        delay: ['$timeout', function ($timeout) { return $timeout(() => true, 100); }]
      },
      data: {
        name: '404',
        title: 'Page not found, here\'s some pong.'
      }
    })
    .state('dashboard', {
      parent: 'layout-default',
      url: '/?search&panel&active&triggerWelcome',
      views: {
        'main': {
          controller: 'dashboardRouterController'
        },
        'footer': {
          template: ''
        }
      },
      params: {},
      data: {
        name: 'Dashboard'
      }
    });
});
