angular.module('payment').controller('cancellationFeeModalController',
function ($scope, $uibModalInstance, Access, Alerts, Transaction, transaction) {
  'ngInject';

  if(Access.limit('concierge', function() { $uibModalInstance.dismiss('unauthorized'); })) return;

  $scope.fields = {
    toggle: {
      name: 'charge_fee',
      label: 'Charge Fee',
      type: 'toggle',
      value: true,
      isDisabled: () => $scope.loading
    },
    amount: {
      name: 'cancellation_fee',
      placeholder: 'Fee Amount',
      allowDecimals: true,
      isRequired: () => $scope.fields.toggle.value,
      positiveValue: true
    }
  }
  Transaction.checkCancellationFee(transaction.id).then(response => {
    $scope.fields.amount.value = response.cancellation_fee;
    if(response.cancellation_fee === 0) $scope.fields.toggle.value = false;
    $scope.ready = true;
  },() => {
    Alerts.error();
    $uibModalInstance.dismiss();
  });

  const valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

  const error = () => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    return false;
  };

  $scope.submit = (form) => {
    if($scope.loading) return;
    if(!valid(form)) return error();
    $scope.loading = true;
    Transaction.cancel(transaction.id,{
      charge_fee: $scope.fields.toggle.value,
      amount: $scope.fields.toggle.value ? $scope.fields.amount.value : 0
    }).then(result => {
      Alerts.success({msg:'Transaction Cancelled'});
      $uibModalInstance.close(result);
    });
  }
});
