angular.module('tasks').constant('StorageFlow', {

  'storage': {
    flows: {
      default: [
        { name: 'storage_location' },
        { name: 'storage_start' },
        { name: 'storage_duration' },
      ],
    },
    screens: {

      // location
      storage_location: {
        title: 'Where should the storage unit be?',
        type: 'task-data',
        name: 'storage_location',
        label: 'Storage Location',
        editable: true,
        fields: [{
          name: 'storage_location',
          placeholder: 'Zip Code',
          type: 'zipcode',
          minlength: 5,
          maxlength: 5,
          required: true
        }]
      },

      // start date
      storage_start: {
        title: 'When do you need the storage unit?',
        type: 'task-data',
        name: 'storage_start',
        label: 'Starting Date',
        editable: true,
        fields: [{
          name: 'storage_start_date',
          label: 'Starting Date',
          type: 'date',
          required:true,
          custom: { limit: 'future' }
        }]
      },

      // duration
      storage_duration: {
        title: 'How long do you need storage for?',
        type: 'task-data',
        name: 'storage_duration',
        label: 'Duration',
        editable: true,
        fields: [{
          name: 'storage_duration',
          label: 'Storage Duration',
          type: 'select',
          options: [{
            value: 'Less than 1 month',
            label: 'Less than 1 month'
          },{
            value: '1 month',
            label: '1 month'
          },{
            value: '2 months',
            label: '2 months'
          },{
            value: '3 months',
            label: '3 months'
          },{
            value: '4 months',
            label: '4 months'
          },{
            value: '5 months',
            label: '5 months'
          },{
            value: '6 months',
            label: '6 months'
          },{
            value: '7 months',
            label: '7 months'
          },{
            value: '8 months',
            label: '8 months'
          },{
            value: '9 months',
            label: '9 months'
          },{
            value: '10 months',
            label: '10 months'
          },{
            value: '11 months',
            label: '11 months'
          },{
            value: '12 months',
            label: '12 months'
          },{
            value: 'More than 12 months',
            label: 'More than 12 months'
          }],
          required:true
        }]
      },

      // Review
      review: {
        title: 'Summary',
        type: 'review',
        name: 'review',
        back: false,
        sections: [{
          fields: [{
            name: 'storage_location'
          },{
            name: 'storage_start_date'
          },{
            name: 'storage_duration'
          }]
        }]
      },

      // Complete
      complete: {
        type: 'complete',
        name: 'complete',
        title: 'Finished!',
        subtitle: 'Your assistant is on it and will send you an update shortly.',
        button: 'Done',
        back: 'review',
        fields: [{
          name: 'name',
          label: 'Company',
          type: 'text',
          model: 'move_transaction.vendor'
        },{
          name: 'total',
          label: 'Estimate',
          type: 'currency',
          model: 'move_transaction'
        }]
      }

    }
  }

});
