angular.module('core').component('cardList', {
  templateUrl: '/templates/core/card-list.html',
  bindings: {
    data: '<', // array of data objects
    layout: '<cardLayout' // object that defines what fields and classes apply to which region
  },
  controller: function() {
    'ngInject';
  }
});
