angular.module('field')
.component('fieldSwitch', {
  template: `
    <h5 class="label" ng-if="$ctrl.field.label" ng-bind="$ctrl.field.label"></h5>
    <div class="switch-holder" ng-class="'count-'+$ctrl.field.values.length">
      <div class="switch-toggle" ng-repeat="option in $ctrl.field.values"
        ng-click="$ctrl.updateValue(option)"
        ng-class="$ctrl.classes(option,$index)">
        <svg-sprite ng-if="option.icon" source="material-icons" icon="{{option.icon}}"></svg-sprite>
        <span ng-bind="option.label"></span>
      </div>
    </div>
    <input type="checkbox" name="{{$ctrl.field.name}}" ng-model="$ctrl.has_value" ng-required="$ctrl.field.isRequired()"/>
    <label class="error" ng-if="$ctrl.field.isRequired() &&
      $ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      Required.
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function() {
    'ngInject';
    this.uuid = Date.now();
    this.$onInit = () => {
      if(this.field.value) this.has_value = true;
    };
    this.updateValue = (option) => {
      this.field.value = option.value;
      this.has_value = true;
      if(this.field.onChange) this.field.onChange();
    };

    this.classes = (option,index) => {
      let classes = [option.value];
      if(index === 0) classes.push('first');
      if(index == this.field.values.length - 1) classes.push('last');
      if(option.value === this.field.value) classes.push('selected');
      return classes;
    }
  }
});
