angular.module('move').controller('moveQuotesModalController',
($scope, $timeout, $uibModalInstance, Alerts, User, Move, move) => {
  'ngInject';

  $scope.move = move;
  const excludeTasks = ['book-movers'];

  $scope.onBook = (quote) => {
    Alerts.success({ msg: `High five! You're one step closer!
        Your assistant will follow up with you shortly.` });
    $uibModalInstance.close('force');
  }

  // updates quotes list
  const updateQuotes = () => {
    $scope.quotes = move.move_quotes.filter((quote) => {
      if(['invalid','expired'].includes(quote.status)) return false;
      let moveTask = Move.findMoveTask(quote.move_task.id, move);
      return User.get().role == 'mover' ? excludeTasks.indexOf(moveTask.task.name) == -1 : true;
    }).sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at));
  };
  updateQuotes();

  // listen for move.quote broadcast
  $scope.$on('move.quote',() => $timeout(updateQuotes,100));

});
