angular.module('move')
.component('progressBar', {
  template: '',
  bindings: {
    onComplete:'&',
    start:'<',
    progress:'<',
    delay: '<'
  },
  controller: function($scope, $element, $timeout, ngProgressFactory) {
    'ngInject';


    const ngProgress = ngProgressFactory.createInstance();
    ngProgress.setParent($element[0]);

    let timer;

    this.$onInit = () => {
      this.delay = this.delay || 3000;
    };

    this.$onChanges = (changes) => {
      if(changes.start && this.start) {
        if(angular.isDefined(this.progress)) ngProgress.set(this.progress);
        else {
          $timeout(() => {
            ngProgress.set(0);
            ngProgress.start();
            timer = $timeout(() => {
              ngProgress.complete();
              $timeout(this.onComplete, 1000);
            }, this.delay-500);
          }, 0);
        }
      }
      if(changes.progress) {
        ngProgress.set(this.progress);
      }
    };

    this.$onDestroy = () => {
      if(timer) $timeout.cancel(timer);
    };

  }
});
