angular.module('payment')
.component('transactionEditor', {
  templateUrl: '/templates/payment/transaction-editor.html',
  bindings: {
    move: '<',
    moveTask: '<',
    existing: '<',
    form: '<',
    onSave: '&'
  },
  controller: function($timeout,$q,User,Move,Transaction,Vendor,Messages) {
    'ngInject';

    this.sendPaymentLink = false;

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      this.moveTask = this.moveTask ||
        Move.findMoveTasks('book-movers',this.move)[0] ||
        this.move.move_tasks[0];
      if(this.existing) {
        this.existing = angular.copy(this.existing); // break connection
        // make sure coupon toggle is on if existing coupon value
        if(this.existing.coupon && (!this.existing.data || !this.existing.data.coupon))
          this.existing.data = angular.merge({},this.existing.data,{coupon:true});
        this.transaction = angular.copy(this.existing);
        this.vendor = this.existing.vendor;
        this.final = this.existing.status == 'final';
      } else {
        this.transaction = {};
      }
      this.vendorOptions = {
        initial: this.existing ? this.existing.vendor.name : null,
        editable: !this.final
      };
      this.transaction.data = this.transaction.data || {};
      // preload the transaction types
      Transaction.getTypes().then((result) => {
        this.types = result;
        this.ready = true;
        let defaultTransactionId = this.existing ? this.existing.transaction.id :
          Transaction.getDefault(this.moveTask.task.name);
        this.transaction.transaction_type = result.find((t) => t.id === defaultTransactionId);
        updateCalculatedFields();
      });
      this.fields = {};
      let fieldlist = [{
        name: 'total',
        placeholder: 'Total',
        isDisabled: () => this.final && this.vendor.has_stripe
      },{
        name: 'rate',
        placeholder: 'Hourly Rate',
        isDisabled: () => this.final && this.vendor.has_stripe
      },{
        name: 'discount',
        placeholder: 'Discount',
        required: false,
        isDisabled: () => this.final && this.vendor.has_stripe
      },{
        name: 'coupon',
        placeholder: 'Coupon',
        isDisabled: () => this.final && this.vendor.has_stripe
      },{
        name: 'minimum',
        placeholder: 'Minimum',
        required: false,
        isDisabled: () => this.final && this.vendor.has_stripe
      },{
        name: 'revenue',
        placeholder: 'Revenue',
        required: false,
        isDisabled: () => this.final && this.vendor.has_stripe
      },{
        name: 'stripe_auth_amount',
        placeholder: 'Authorization Amount',
        required: false,
        isDisabled: () => this.final && this.vendor.has_stripe
      }];

      fieldlist.forEach(field => {
        this.fields[field.name] = angular.merge({},{
          allowDecimals: true,
          required: true,
          debounce: 0,
          value: this.transaction[field.name] || 0,
          onChange: () => updateCalculatedFields()
        },field);
      });
      // manually declaring the crew_size object to avoid from setting a default value of 0
      this.fields.crew_size = {
        name: 'crew_size',
        placeholder: 'Crew Size',
        required: false,
        debounce: 0,
        value: this.transaction.crew_size,
        min: 2,
        max: 8,
        minMessage: (num) => `Moves must have at least ${num} movers.`,
        maxMessage: (num) => `Moves can only have up to ${num} movers.`,
        isDisabled: () => this.final && this.vendor.has_stripe,
        onChange: () => updateCalculatedFields()
      }
    };

    function valid(form) {
      form.$setSubmitted();
      return !form.$invalid;
    }

    this.updateFields = () => updateCalculatedFields();
    const updateCalculatedFields = () => {
      this.stripeVendor = (this.transaction.transaction_type.name == 'move' &&
        this.vendor && this.vendor.has_stripe);
      if(this.transaction.is_hourly) {
        if(!this.final) this.transaction.total = 0;
        this.transaction.rate = this.fields.rate.value || 0;
        this.transaction.minimum = this.fields.minimum.value || 0;
        this.transaction.stripe_auth_amount = this.fields.stripe_auth_amount.value || 0;
        this.transaction.crew_size = this.fields.crew_size.value || null;
      } else {
        this.transaction.total = this.fields.total.value || 0;
        this.transaction.rate = 0;
        this.transaction.minimum = 0;
        this.transaction.crew_size = null;
      }
      if(this.stripeVendor) {
        if(this.transaction.data.coupon) {
          this.transaction.discount = 0;
          this.transaction.coupon = this.fields.coupon.value || 0;
          this.transaction.take = Math.max(this.transaction.total,this.transaction.coupon) * this.vendor.commission;
          this.transaction.charge = Math.max(this.transaction.total - this.transaction.coupon, 0);
          this.transaction.vendorRevenue = Math.max(this.transaction.total,this.transaction.coupon) * (1-this.vendor.commission);
          this.transaction.revenue = this.transaction.take;
        }
        else {
          this.transaction.coupon = 0;
          this.transaction.discount = this.fields.discount.value || 0;
          this.transaction.take = (this.transaction.total || 0) * this.vendor.commission;
          this.transaction.charge = (this.transaction.total || 0) - (this.transaction.discount || 0);
          this.transaction.vendorRevenue = (this.transaction.total || 0) - this.transaction.take;
          this.transaction.revenue = this.transaction.take - (this.transaction.discount || 0);
        }
      } else {
        this.transaction.data.coupon = false;
        this.transaction.coupon = 0;
        this.transaction.discount = 0;
        this.transaction.revenue = this.fields.revenue.value;
      }
    };

    this.canUseCoupon = () => {
      // TODO: find new way to do this without hardcoded list
      let source = Move.getSource(this.move);
      if(source) return ['mms','access','exr','lifestorage','safeguard','local-locker','citiwide'].includes(source.toLowerCase());
      return false;
    };

    this.resetData = () => {
      this.transaction.data = {};
      updateCalculatedFields();
    };

    this.save = () => {
      if(this.loading) return;
      if(!valid(this.form)) return error();
      this.loading = true;
      // format the data
      let transaction = {
        move: this.move.id,
        move_task_id: this.moveTask.id,
        transaction: this.transaction.transaction_type.id,
        vendor_id: this.vendor.id,
        is_hourly: this.transaction.is_hourly || false,
        revenue: parseInt(this.transaction.revenue),
        note: this.transaction.note,
        internal_note: this.transaction.internal_note,
        data: this.transaction.data,
        coupon: parseInt(this.transaction.coupon),
        discount: parseInt(this.transaction.discount),
        send_payment_link: this.showSendPaymentLink() && this.sendPaymentLink,
        crew_size: null
      };
      if(this.transaction.is_hourly) {
        if(!this.final) transaction.revenue = 0; // don't save revenue value for hourly unless it's final
        transaction.rate = parseInt(this.transaction.rate);
        transaction.minimum = parseInt(this.transaction.minimum);
        transaction.stripe_auth_amount = parseInt(this.transaction.stripe_auth_amount);
        transaction.crew_size = this.transaction.crew_size;
      }
      else transaction.total = parseInt(this.transaction.total);
      if(this.transaction.arrival_window && (this.transaction.arrival_window.to ||
        this.transaction.arrival_window.from)) transaction.arrival_window = this.transaction.arrival_window;
      return saveTransaction(transaction);
    };

    const saveTransaction = (transaction) => {
      // update existing transaction
      if(this.existing) {
        if(hasChanged(transaction) || this.sendPaymentLink) removeExistingPaymentMessages()
          .then(() => Transaction.update(this.existing.id, transaction))
          .then(success, error);
        else Transaction.update(this.existing.id, transaction).then(success, error);
      }
      else Transaction.create(transaction).then(success, error);
    };

    const hasChanged = (data) => {
      if(this.existing && (
        this.existing.vendor.id != data.vendor_id ||
        this.existing.revenue != data.revenue ||
        this.existing.is_hourly != data.is_hourly ||
        (data.is_hourly && this.existing.rate != data.rate) ||
        (!data.is_hourly && this.existing.total != data.total) ||
        (this.existing.data && this.existing.data.coupon != data.data.coupon) ||
        (data.data.coupon && this.existing.coupon != data.coupon) ||
        (!data.data.coupon && this.existing.discount != data.discount) ||
        (data.is_hourly && this.existing.minimum != data.minimum)))
          return true;
      return false;
    };

    const removeExistingPaymentMessages = (transaction) => {
      return Messages.get({where:{move:this.move.id,type:4}}).then((results) => {
        if(!results.messages || !results.messages.length) return;
        let promises = [];
        results.messages.filter(message => {
          return message.data && message.data.move_transaction.id === this.existing.id
        }).forEach((message) => promises.push(Messages.delete(message.id)));
        return $q.all(promises);
      });
    };

    const success = (transaction) => {
      this.loading = false;
      if(this.onSave) this.onSave({transaction:transaction});
    };

    const error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

    this.showSendPaymentLink = () => {
      if(this.transaction.status && this.transaction.status != 'pending') return false;
      if(this.user.external) return this.stripeVendor;
      return true;
    };

    this.updateVendor = (vendor) => {
      this.vendor = vendor;
      updateCalculatedFields();
    };

  }
});
