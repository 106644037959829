angular.module('messages').component('messageImage', {
  template: `
    <div class="message-bubble image-upload" ng-if="::$ctrl.message.type === 2">
      <div class="image-container" ng-click="$ctrl.photosModal()"
        ng-style="{'background-image':'url({{::$ctrl.message.data.url}})'}"></div>
    </div>
    <div class="message-timestamp">
      <span ng-bind="::$ctrl.message.sender.firstname" ng-if="::$ctrl.isCXMessage"></span>
      <span ng-bind="::$ctrl.message.created_at | amUtc | amLocal | amDateFormat: 'M/D/YYYY h:mma'"></span>
    </div>`,
  bindings: {
    message: '<'
  },
  controller: function(Image, User) {
    'ngInject';
    this.$onInit = () => {
      this.isCXMessage = this.message.sender.role == 'concierge' && this.view == 'concierge';
    };
    // open a modal to view a set of photos
    this.photosModal = () => Image.modals.view([this.message.data.url]);
  }
});
