angular.module('tasks').component('screenGroupedItemList', {
  templateUrl: '/templates/tasks/screen-grouped-item-list.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,User,Move,Payment,Task,Field,Data) {
    'ngInject';


    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      Task.getItemList(this.screen.model).then((sections) => {
        this.screen.sections = angular.copy(sections);
        this.screen.sections.sort((a,b) => a.order - b.order);
        this.screen.sections.forEach((section) => {
          section.items.sort((a,b) => a.order - b.order);
          section.items.forEach((field) => {
            field.type = 'integer';
            field.parent = this.screen.name;
            field.value = Field.getValue(field,this.task);
            field.onChange = () => {
              if(this.screen.onChange) this.screen.onChange(buildData());
            };
          });
        });
        if(this.screen.fields) this.screen.fields.forEach(field => {
          field.value = Field.getValue(field, this.task);
          field.onChange = () => {
            if(this.screen.onChange) this.screen.onChange(buildData());
          };
        });
        this.ready = true;
      }).catch(error);
    };

    this.toggleSection = (index) => {
      this.showSection = false;
      this.activeSection = this.activeSection !== index ? index : false;
      $timeout(() => this.showSection = true, 0);
    };

    this.sectionItemCount = (section) => {
      return section.items
        .map(item => item.value)
        .reduce((count,itemCount) => count += (itemCount || 0),0);
    };

    this.totalItemCount = () => {
      if(!this.screen.sections) return 0;
      return this.screen.sections
        .reduce((count,section) => count += this.sectionItemCount(section),0);
    }

    this.next = () => {
      if(this.loading) return;
      this.loading = true;
      if(!valid(this.form)) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };


    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      data = data || {};
      this.screen.sections.forEach((section) => {
        section.items.forEach((field) => {
          Field.buildSaveData({
            name: field.name,
            value: field.value,
            type: this.screen.name
          },data);
        });
      });
      if(this.screen.fields)
        this.screen.fields.filter(f => !f.hidden).forEach((field) =>
          Field.buildSaveData(field,data));
      return data;
    };

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }
});
