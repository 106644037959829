angular.module('core').factory('Script',
function($q, $document) {
  'ngInject';

  const promises = [];
  const doc = $document[0];

  return {
    load: (url,opts) => {
      if(promises[url]) return promises[url].promise;

      opts = angular.extend({type: 'text/javascript'},opts);

      promises[url] = $q.defer();

      let script = doc.querySelector("script[src*='"+url+"']");
      if(script) {
        promises[url].resolve('preloaded');
        return promises[url].promise;
      }
      script = doc.createElement('script');
      script.setAttribute('src', url);
      script.setAttribute('type', opts.type);
      if (opts.charset) script.setAttribute('charset', opts.charset);
      script.onload = () => promises[url].resolve('loaded');
      script.onreadystatechange = function () {
        let rs = this.readyState;
        if(rs === 'loaded' || rs === 'complete') promises[url].resolve('onready');
      };
      script.onerror = () => promises[url].reject(new Error(`Unable to load script: ${url}`));
      doc.getElementsByTagName("head")[0].appendChild(script);
      return promises[url].promise;
    }
  };

});
