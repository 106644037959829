angular.module('tasks').component('screenInterstitial', {
  templateUrl: '/templates/tasks/screen-interstitial.html',
  bindings: {
    screen: '<',
    move: '<',
    nextTask: '<',
    onNext: '&',
    onClose: '&'
  },
  controller: function($timeout,User,Task,Move,Field) {
    'ngInject';

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      // set defaults
      this.screen = this.screen || {
        title: `Thanks ${this.user.firstname}!`,
        subtitle: 'What would you like to do next?',
        icon: 'default',
        actions: [{
          label: this.nextTask.task.label,
          value: true,
          class: 'button-secondary'
        },{
          label: 'I\'ll do that later',
          value: false,
          class: 'skip'
        }]
      };
    };

    this.next = (value) => {
      if(this.loading) return;
      this.loading = true;
      if(this.screen.name) {
        this.screen.value = value;
        this.onNext({callback:this.save})
          .then((result) => this.loading = false, error);
      } else {
        if(value) this.onNext();
        else this.onClose();
      }
    };

    this.save = (progressData) => {
      let data = progressData ? {task:progressData} : {};
      Field.buildSaveData({
        name: this.screen.name,
        value: this.screen.value
      },data);
      return Task.update(this.task.id,data.task);
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }
});
