angular.module('payment').controller('introModalController',
function ($scope, $uibModalInstance, Access, Data, User, data) {
  'ngInject';

  if(Access.limit('user', function() { $uibModalInstance.dismiss('unauthorized'); })) return;

  $scope.direct = data.direct;
  $scope.move_transaction = data.move_transaction;
  $scope.vendor = data.move_transaction.vendor;
  $scope.amount = data.amount;
  $scope.is_hourly = data.is_hourly;
  $scope.minimum = data.minimum;
  $scope.transaction = data.type;
  $scope.hasStripe = User.get().has_stripe;
  
  const terms = data.move_transaction.vendor.terms;
  $scope.terms_url = terms ? (`${Data.s3AssetUrl}/vendors/terms/${terms}`) : false;

  $scope.cancellationPolicy = `For local moves, cancel anytime up to 48 hours before your move with no charge!
    Moving long distance? Be sure to provide at least 5 days notice.
    Cancelling without proper notice will result in a $150 cancellation fee for local moves,
    or a $300 cancellation fee for long distance moves.`;

});
