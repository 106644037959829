// directive to convert task field into a compiled component
angular.module('field').directive('fieldGenerator',
function($compile,$parse) {
  'ngInject';

  return {
    restrict: 'A',
    link: (scope, element, attr) => {
      const fieldType = $parse(attr.fieldGenerator)(scope).type.replace('_','-');
      const markup = `
        <field-${fieldType} field="${attr.fieldGenerator}" form="$ctrl.form" move="$ctrl.move"
          ng-class="${attr.fieldGenerator}.class"></field-${fieldType}>
        <label ng-if="${attr.fieldGenerator}.tooltip" class="field-tooltip">
          <svg-sprite alt="info" source="material-icons" icon="info_outline"
            class="tooltip-icon" tooltip-append-to-body="true"
            uib-tooltip="{{${attr.fieldGenerator}.tooltip}}"></svg-sprite>
        </label>`;
      element.html(markup);
      $compile(element.contents())(scope);
    }
  }
});
