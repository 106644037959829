angular.module('core').directive("contenteditable",
(Alerts,$sce,$q,$timeout,$filter,$window) => {
  'ngInject';

  return {
    restrict: "A",
    require: "ngModel",
    scope: {
      onBlur: '&',
      onChange: '&',
      onEnter: '&',
      autocomplete: '&',
      onSelect: '&',
      onDeselect: '&'
    },
    link: (scope, element, attrs, ngModel) => {

      ngModel.$render = () => {
        element.html($sce.getTrustedHtml(ngModel.$viewValue || ""));
        cleanup();
      };

      function read() {
        ngModel.$setViewValue((element.html() === "" ? null : element.html()));
      }

      function getClean(options) {
        options = angular.extend({},{multiline: attrs.type=="textarea"}, options);
        return $filter('cleanHTML')(element.html(),options);
      }

      function cleanup(options) {
        options = options || {};
        var selection;
        if(options.keepSelection) selection = saveSelection(element[0]);
        element.html(getClean(options));
        read();
        if(selection) restoreSelection(element[0], selection);
      }

      function bindings() {
        // unbind if not editable
        if(attrs.contenteditable!=='true') {
          element.unbind('.editable');
          return false;
        }

        // update model value binding
        element.on("blur.editable keyup.editable change.editable", () => {
          scope.$evalAsync(read);
        });

        // blur function binding
        if(attrs.onBlur && scope.onBlur) {
          element.on("blur.editable", () => {
            cleanup();
            if(typeof scope.onBlur === 'function')
              scope.onBlur();
          });
        }

        // change function binding
        element.on("input.editable change.editable", (event) => {
          cleanup({trimWhiteSpace:false, keepSelection:true});

          // max length bindings
          if(attrs.maxlength && attrs.maxlength > 0) {
            var current = getClean();
            if(current && current.length > attrs.maxlength) {
              Alerts.warn({
                msg: `Exceeded maximum character count (${attrs.maxlength}).`
              });
            }
          }
          if(attrs.onChange && scope.onChange) {
            if(typeof scope.onChange === 'function')
              scope.onChange();
          }

        });

        // enter key bindings
        if(attrs.onEnter && scope.onEnter) {
          element.on("keydown.editable", (event) => {
            if(event.which == 13) {
              event.preventDefault();
              cleanup();
              if(typeof scope.onEnter === 'function')
                scope.onEnter();
            }
          });
        } else if(attrs.type!='textarea') {
          element.on("keydown.editable", (event) => {
            if(event.which == 13) {
              event.preventDefault();
            }
          });
        }

        // paste cleanup bindings
        element.on("paste.editable", (e) => {
          delayPaste().then(() => cleanup({keepSelection:true}));
        });

        // auto suggest bindings
        if(attrs.autocomplete && scope.autocomplete) {
          scope.$watch(() => ngModel.$modelValue, (newValue) => {
            if (!newValue) {
              attrs.$set('suggest', '');
              return scope.isSuggested = false;
            }
            var term = decodeEntities(newValue).trim();
            if(attrs.suggest.toLowerCase().indexOf(term.toLowerCase())!==0)
              attrs.$set('suggest', '');
            scope.autocomplete({term:term}).then((response) => {
              var liveText = decodeEntities(element.text()).trim();
              if(term != liveText) { // out of sync due to debounce
                return scope.isSuggested = false;
              }
              if(response && term.toLowerCase() == response.toLowerCase()) {
                attrs.$set('suggest', response);
                selectSuggestion();
              } else {
                attrs.$set('suggest',
                  (response ? userStringReplace(response,term) : ''));
                scope.isSuggested = false;
              }
            }, () => {
              attrs.$set('suggest', '');
              scope.isSuggested = false;
            });
          });
          element.on("input.editable", (event) => {
            var term = element[0].textContent.trim();
            if(attrs.suggest.indexOf(term)!==0) attrs.$set('suggest','');
          });
          var selectKeys = [
            9,  // tab
            13, // enter
            39, // right arrow
            40  // down arrow
          ];
          element.on("keydown.editable", (event) => {
            if($.inArray(event.which,selectKeys) != -1 &&
              attrs.suggest && !scope.isSuggested) {
              event.preventDefault();
              selectSuggestion();
            }
          });
          scope.$watch('isSuggested', (newVal,oldVal) => {
            if(newVal == oldVal) return;
            if(newVal && scope.onSelect) scope.onSelect();
            if(!newVal && scope.onDeselect) scope.onDeselect();
          });

        }

      }

      scope.$watch(attrs.contenteditable,() => {
        bindings();
      });

      function delayPaste(deferred) {
        deferred = deferred || $q.defer();
        if(element[0].childNodes && element[0].childNodes.length)
          deferred.resolve(true);
        else $timeout(() => { delayPaste(deferred); },1);
        return deferred.promise;
      }

      function userStringReplace(match,term) {
        return term + match.substring(term.length);
      }

      function selectSuggestion() {
        // write the suggestion to the element
        element[0].innerHTML = attrs.suggest;
        scope.isSuggested = true;
        read();
        cursorToEnd();
      }

      function cursorToEnd() {
         // move the cursor to the end of the element
        var range,selection;
        range = document.createRange();
        range.selectNodeContents(element[0]);
        range.collapse(false);
        selection = $window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }

      // Gets the offset of a node within another node. Text nodes are counted a n where n is the length. Entering (or passing) an element is one offset. Exiting is 0.
      var getNodeOffset = (start, dest) => {
        var offset = 0;

        var node = start;
        var stack = [];

        while (true) {
          if (node === dest) {
            return offset;
          }

          // Go into children
          if (node.firstChild) {
            offset += 1;
            stack.push(node);
            node = node.firstChild;
          }
          // If can go to next sibling
          else if (stack.length > 0 && node.nextSibling) {
            // If text, count length (plus 1)
            if (node.nodeType === 3)
              offset += node.nodeValue.length + 1;
            else
              offset += 1;

            node = node.nextSibling;
          }
          else {
            // If text, count length
            if (node.nodeType === 3)
              offset += node.nodeValue.length + 1;
            else
              offset += 1;

            // No children or siblings, move up stack
            while (true) {
              if (stack.length <= 1)
                return offset;

              var next = stack.pop();

              // Go to sibling
              if (next.nextSibling) {
                node = next.nextSibling;
                break;
              }
            }
          }
        }
      }

      var getNodeAndOffsetAt = (start, offset) => {
        var node = start;
        var stack = [];

        while (true) {
          // If arrived
          if (offset <= 0)
            return { node: node, offset: 0 };

          // If will be within current text node
          if (node.nodeType == 3 && (offset <= node.nodeValue.length))
            return { node: node, offset: Math.min(offset, node.nodeValue.length) };

          // Go into children
          if (node.firstChild) {
            offset -= 1;
            stack.push(node);
            node = node.firstChild;
          }
          // If can go to next sibling
          else if (stack.length > 0 && node.nextSibling) {
            // If text, count length
            if (node.nodeType === 3)
              offset -= node.nodeValue.length + 1;
            else
              offset -= 1;

            node = node.nextSibling;
          }
          else {
            // No children or siblings, move up stack
            while (true) {
              if (stack.length <= 1) {
                // No more options, use current node
                if (node.nodeType == 3)
                  return { node: node, offset: Math.min(offset, node.nodeValue.length) };
                else
                  return { node: node, offset: 0 };
              }

              var next = stack.pop();

              // Go to sibling
              if (next.nextSibling) {
                // If text, count length
                if (node.nodeType === 3)
                  offset -= node.nodeValue.length + 1;
                else
                  offset -= 1;

                node = next.nextSibling;
                break;
              }
            }
          }
        }
      }

      var saveSelection = (containerEl) => {
        // Get range
        var range = window.getSelection().getRangeAt(0);
        return { start: getNodeOffset(containerEl, range.startContainer) + range.startOffset, end: getNodeOffset(containerEl, range.endContainer) + range.endOffset };
      }

      var restoreSelection = (containerEl, savedSel) => {
        var range = document.createRange();

        var startNodeOffset, endNodeOffset;
        startNodeOffset = getNodeAndOffsetAt(containerEl, savedSel.start);
        endNodeOffset = getNodeAndOffsetAt(containerEl, savedSel.end);

        range.setStart(startNodeOffset.node, startNodeOffset.offset);
        range.setEnd(endNodeOffset.node, endNodeOffset.offset);

        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }

    }
  };
});
