// declare module routes
angular.module('vendor').config(function($stateProvider,Data) {
  'ngInject';

  $stateProvider
    .state('dashboard.vendor', {
      parent: 'layout-panels',
      views: {
        'banner': {
          template: `<stripe-banner vendor="vendor"></stripe-banner>`,
          controller: function($scope,vendor) { $scope.vendor = vendor; }
        },
        'nav': {
          template: '<vendor-nav vendor="vendor"></vendor-nav>',
          controller: function($scope,vendor) { $scope.vendor = vendor; }
        },
        'main': {
          template: `<section class="vendor-dashboard-page" ui-view="panel"></section>`,
          controller: 'vendorDashboardController'
        }
      },
      params: {
        active: null
      },
      resolve: {
        vendor: function (Vendor, userInfo) { return Vendor.get(userInfo.vendor.id); },
        vendorDetails: function (Vendor, userInfo) {
          return Vendor.details.get(false,{where:{vendor:userInfo.vendor.id}});
        }
      }
    })
    .state('dashboard.vendor.jobs', {
      url: `${Data.vendorUrl}/jobs`,
      external: true
    })
    .state('dashboard.vendor.calendar', {
      url: `${Data.vendorUrl}/calendar`,
      external: true
    })
    .state('dashboard.vendor.quotes', {
      url: `/quotes?active`,
      views: {
        'panel': {
          template: '<quotes-panel vendor="vendor" initial="active"></quotes-panel>'
        }
      },
      params: {
        active: null
      },
      reloadOnSearch: false
    })
    .state('dashboard.vendor.pricing', {
      url: `/pricing`,
      views: {
        'panel': {
          template: `
            <pricing-summary vendor="vendor" ng-if="vendor.details.is_complete"
              id="pricing-summary" on-close="selectPanel('moves')"></pricing-summary>
            <auto-quote-flow vendor="vendor" ng-if="!vendor.details.is_complete"
              on-complete="selectPanel('moves')" on-close="selectPanel('moves')"></auto-quote-flow>`
        }
      }
    });
});
