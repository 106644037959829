angular.module('cx').component('activeMoveDetails', {
  templateUrl: '/templates/cx/move-details.html',
  bindings: {
    move: '<',
  },
  controller: function($q, $timeout, $rootScope, $state, $window,
    User, Move, Task, Transaction, QuoteRequest, EmailList,
    Partner, MoveTag, PubNub, Alerts, Data) {
    'ngInject';

    // static lists needed for formatting an active move
    let taskList = false,
        itemLists = {
          inventories: false,
          boxes: false,
          supply_lists: false
        },
        listeners = [];

    this.$onInit = () => {
      // Preload the static data from the db
      let promises = {};
      promises.tasks = Task.getList();
      for(let list in itemLists) {
        promises[list] = Task.getItemList(list);
      }
      $q.all(promises).then((results) => {
        if(results.tasks) taskList = results.tasks;
        for(let list in itemLists) {
          if(results[list]) {
            itemLists[list] = angular.copy(results[list]).sort((a,b) => a.order - b.order);
            itemLists[list].forEach((item) => {
              if(item.items) item.items.sort((a,b) => a.order - b.order);
            });
          }
        }
        this.ready = true;
      });
    };

    // cleanup
    this.$onDestroy = () => listeners.forEach(deregister => deregister());

    // handle updates to the active move
    this.$onChanges = (changes) => {
      if(changes.move && this.move) {
        this.loading = true;
        this.customer = Move.findCustomer(this.move).user;
        PubNub.setActiveMove(this.move.id);
        loadMoveDetails();
      }
    }

    // load all of the additional move objects (transactions, concierge_logs)
    const loadMoveDetails = () => {
      // create async promise list
      let moveFilter = {where:{move:this.move.id},per_page:999,sort:{created_at:true}};
      let promises = {};
      promises.transactions = Transaction.getAll(moveFilter);
      promises.quoteRequests = QuoteRequest.find(moveFilter);
      promises.emailList = EmailList.get({where:{user:this.customer.id}});

      // callback after all requests
      $q.all(promises).then((results) => {
        this.move.move_transactions = results.transactions ? results.transactions.data.sort((a,b) =>
          new Date(b.created_at) - new Date(a.created_at)) : [];
        this.move.quoteRequests = results.quoteRequests || [];
        this.move.emailList = results.emailList || [];
        if(Move.getSource(this.move)) {
          Partner.get(Move.getSource(this.move))
            .then(partner => {
              this.move.partner = partner;
              if(this.onLoad) this.onLoad();
              this.loading = false;
            })
            .catch(angular.noop);
        }
        else {
          if(this.onLoad) this.onLoad();
          this.loading = false;
        }
      },(error) => {
        Alerts.error();
        if(this.onLoad) this.onLoad(error);
        this.loading = false;
      });
    };

    /* STATUS SWITCHER */
    // create a list of status's with labels
    this.statusList = angular.copy(Move.statusList);
    // change a move's status on click
    this.updateStatus = (status) => {
      Move.update(this.move.id,{status:status}).then(
        result => this.move.status = result.status,
        () => Alerts.error()
      );
    };

    /* TAG MANAGER */
    // function to handle type-ahead suggestion
    this.tagCount = 0;
    this.findTag = (term) => {
      var defer = $q.defer();
      if (!term) {
        defer.resolve(null);
        this.tagCount = 0;
      } else {
        term = term.toLowerCase().trim();
        let data = {
          page:1,
          per_page:1,
          sort:{name:false},
          where:{name:{$startswith:term}}
        };
        MoveTag.find(data).then((result) => {
          if(result && result.length) {
            defer.resolve(result[0].name);
            this.tagCount = result[0].count;
          }
          else {
            defer.reject('No Matches Found');
            this.tagCount = 0;
          }
        });
      }
      return defer.promise;
    };
    this.tagEditor = {
      value:'',
      suggested: false,
      visible: false,
      close: () => {
        this.tagEditor.value = '';
        this.tagEditor.visible = false;
      },
      select: () => this.tagEditor.suggested = true,
      deselect: () => this.tagEditor.suggested = false,
      add: () => {
        this.tagEditor.value = this.tagEditor.value ? this.tagEditor.value.toLowerCase().trim() : '';
        if(!this.tagEditor.value) return;
        if(this.move.tag_names.find(t=>t==this.tagEditor.value))
          return this.tagEditor.value = '';
        this.tagEditor.loading = true;
        MoveTag.add(this.move.id,{name:this.tagEditor.value}).then((response) => {
          this.tagEditor.loading = false;
          if(response) this.tagEditor.value = '';
        });
      },
      remove: (tag) => MoveTag.remove(this.move.id,{name:tag})
    };

    /* Customer Information */
    this.directLinkCopied = false;
    this.directLink = () => `${Data.adminUrl}/cx/moves/${this.move.id}?direct=${encodeURIComponent(this.customer.email)}`;
    this.clipboardSuccess = () => {
      this.directLinkCopied = true;
      $timeout(() => this.directLinkCopied = false, 2000);
    };

    this.proxyAsUser = (user) => {
      Alerts.success({msg:'With great power comes great responsibility.'});
      User.proxy(user.id).then((user) => $window.top.location = Data.appUrl);
    };

    this.editMoveSource = () => Move.modals.editSource(this.move);

    /* Notes Field */
    this.saveNotes = () => {
      if(this.userForm && this.userForm.notes && this.userForm.notes.$dirty && !this.loading) {
        Move.update(this.move.id,{notes: this.move.notes}).then((result) => {
          this.noteSaved = $timeout(() => this.noteSaved = false, 2000);
        });
      }
    }

    // hide/show move detail sections
    this.toggleVisibility = (section) => {
      this.move.visible = this.move.visible || {};
      this.move.visible[section] = !this.move.visible[section];
    };

    /* Transactions List */
    this.deleteTransaction = (transaction) => {
      let index = this.move.move_transactions.findIndex((t) => t.id == transaction.id);
      if(index === -1) return;
      this.move.move_transactions.splice(index,1);
      Alerts.success({msg: 'Transaction Deleted', delay: 3});
    };

    /* Email List */
    this.emailListRemove = (email,index) => {
      if(email.loading) return;
      email.loading = true;
      EmailList.remove(email.id).then((result) => {
        Alerts.success({msg:'Removed from email list!', delay:3});
        email.deleted = true;
        $timeout(() => this.move.emailList.splice(index,1), 500);
      });
    };

    /* CP Movers List */
    this.findCustomer = Move.findCustomer;

    // add new task to move
    this.addTask = () => Task.modals.editScreen({task:{name:'add-task'}},'add-task',this.move);

    // listen for move.data broadcast
    listeners.push($rootScope.$on('move.data',(event,id,data) => {
      if(!this.loading && id === this.move.id) angular.merge(this.move,data);
    }));

    // Handle pubnub broadcast updates to this moves tags (add and remove)
    listeners.push($rootScope.$on('move.tag_names', (event,id,tags) => {
      if(!this.loading && id == this.move.id) this.move.tag_names = tags.tag_names;
    }));

    // PubNub updates for CXMs
    listeners.push($rootScope.$on('move.user',(event,id,message,remove) => {
      if(!this.loading && id === this.move.id) updateMoveUser(message,remove);
    }));
    const updateMoveUser = (moveUser,remove) => {
      let index = this.move.move_users.findIndex(user => user.id == moveUser.id);
      if(remove) return (index != -1) ? this.move.move_users.splice(index,1) : false;
      if(index === -1) this.move.move_users.push(moveUser);
      else angular.merge(this.move.move_users[index],moveUser);
      if(moveUser.id === this.customer.id) angular.merge(this.customer,moveUser);
    };

    // listen for move_task broadcast
    listeners.push($rootScope.$on('move.task',(event,id,task) => {
      if(!this.loading && id === this.move.id) addMoveTask(task);
    }));
    const addMoveTask = (task) => {
      // add move task if it doesn't exist
      if(this.move.move_tasks.find((t) => task.id === t.id)) return;
      this.move.move_tasks.push(task);
    };

    // listen for move_transaction broadcast
    listeners.push($rootScope.$on('move.transaction',(event,id,message) => {
      if(!this.loading && id === this.move.id) updateMoveTransaction(message);
    }));
    const updateMoveTransaction = (transaction) => {
      let exists = this.move.move_transactions.find((t) => transaction.id === t.id);
      if(exists) angular.merge(exists,transaction);
      else this.move.move_transactions.unshift(transaction);
    };

    // listen for move.quote broadcast
    listeners.push($rootScope.$on('move.quote',(event,id,message) => {
      if(!this.loading && id === this.move.id) updateMoveQuote(message);
    }));
    const updateMoveQuote = (moveQuote) => {
      let exists = this.move.move_quotes.find(quote => quote.id == moveQuote.id);
      if(exists) angular.merge(exists,moveQuote);
      else this.move.move_quotes.push(moveQuote);
    };

    // listen for move.quote_request broadcast
    listeners.push($rootScope.$on('move.quote_request', (event, id, message) => {
      if(!this.loading && id === this.move.id) updateQuoteRequest(message);
    }));
    const updateQuoteRequest = (quoteRequest) => {
      let exists = this.move.quoteRequests.find(request => request.id == quoteRequest.id);
      if(exists) angular.merge(exists, quoteRequest);
      else this.move.quoteRequests.push(quoteRequest);
    };

  }
});
