angular.module('tasks').component('screenQuotes', {
  templateUrl: '/templates/tasks/screen-quotes.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&',
    onClose: '&'
  },
  controller: function($rootScope, $timeout, $interval, moment,
    MoveQuote, Move, Task, User, Payment, Alerts, _) {
    'ngInject';

    this.quotes = [];
    this.delay = 30 * 1000; // 30s for instant quotes

    const setDeadline = () => {
      if(this.deadline) return;
      const move_date = moment(_.get(_.get(this.task, 'move_task_dates', []).find(date => date.name === 'move_date'),'date'));
      this.deadline = move_date.clone().subtract(1,'d').set({ h: 14 });
    };

    const isPastDeadlineToBook = () => {
      setDeadline();
      return this.deadline.isBefore();
    };

    this.$onInit = () => {
      this.user = User.get();
      updateQuotes(true);
      setSubtext();
      $timeout(() => this.ready = true, 0);
    };

    const setSubtext = () => {
      let time = moment().isBefore(moment(this.task.updated_at).add(4,'h')) ?
        moment().tz("America/New_York").hour() : false;
      this.subtext = 'We’ll email you ' +
        (User.get().allow_sms_notification ? '(and send you a text) ' : '') +
        'when your quotes are ready';
      switch(true) {
        case(time && time < 7):
          return this.subtext += ', which should be by <strong>mid-morning</strong>.';
        case(time && time < 17):
          return this.subtext += ', which will be <strong>in the next 2-3 hours</strong>, if not sooner.';
        case(time && time <= 24):
          return this.subtext += ', which should be by <strong>tomorrow mid-morning</strong>.';
        default:
          return this.subtext += '.';
      }
    };

    let timeSync = $interval(setSubtext, 1000*60*5);
    this.$onDestroy = () => {
      if(angular.isDefined(timeSync)) $interval.cancel(timeSync);
    };

    this.startBook = () => this.booking = true;
    this.cancelBook = () => this.booking = false;
    this.onBook = (quote,moveTransaction) => {
      this.booking = false;
      quote.booked = true;
      let exists = this.move.move_transactions.find(mt => mt.id == moveTransaction.id);
      if(exists) angular.merge(exists,moveTransaction);
      else this.move.move_transactions.push(moveTransaction);
      updateActiveView();
    };

    this.next = () => {
      if(this.loading) return;
      this.loading = true;
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      return Task.update(this.task.id,progressData);
    };

    this.goBack = () => {
      return this.onNext({next:'review'});
    };

    const error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

    Payment.getStripeCheckout().then(response => {
      this.stripeCheckout = response;
    });

    this.openStripeCheckout = () => {
      const quote = this.quotes.find(quote => quote.booked);
      this.loading = true;
      this.stripeCheckout.open({
        name: quote.vendor_name,
        description: 'Book Mover',
        email: this.user.email,
        amount: 0,
        panelLabel: 'Authorize'
      }).then(
        (result) => addCard(result[0].id),
        (result) => error()
      );
    };

    const addCard = (source) => {
      Payment.addCard({
        source: source
      }).then(() => {
        User.refresh();
        this.needsStripe = false;
        this.loading = false;
        this.activeView = getActiveView();
      },error);
    };

    // listen for move.quote broadcast
    $rootScope.$on('move.quote',() => $timeout(() => updateQuotes(),100));

    // updates quotes from broadcast
    const updateQuotes = (init) => {
      this.quotes = this.move.move_quotes
        .filter(q => q.move_task.id == this.task.id && !['invalid','expired'].includes(q.status))
        .sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at));
      if(init) this.instantLoading = this.task.data.instant_quotes && !this.quotes.length;
      updateActiveView(init);
    };

    this.instantTimeout = () => {
      this.instantLoading = false;
      Alerts.info({msg:`Uh Oh. It's taking longer than usual to generate your quotes.`});
      updateActiveView();
    };

    const updateActiveView = (init) => {
      let bookedQuote = this.quotes.find(quote => quote.booked);
      let bookedTransaction = bookedQuote ? this.move.move_transactions.find(mt =>
        mt.move_task.id === this.task.id && mt.move_quote_id === bookedQuote.id) : false;
      this.booked = bookedQuote && bookedTransaction;
      if(this.booked) needsStripeTest(bookedTransaction);
      if(this.quotes.length && this.instantLoading) {
        this.progress = 100;
        $timeout(() => {
          this.instantLoading = false;
        }, 1500);
      }
      this.activeView = getActiveView();
      if(this.activeView == 'complete' && !init)
        this.screen.edit ? this.onClose() : this.next();
    };

    const getActiveView = () => {
      if(!this.quotes.length) {
        if(this.task.data.noncore) return 'noncore';
        if(this.instantLoading) return 'instant-loading';
        else return 'pending';
      }
      else {
        if(!this.booked || this.booking) {
          if(isPastDeadlineToBook()) return 'last-minute';
          if(this.task.flow_variant == 'storage') return 'single-quote';
          return 'quotes-list';
        }
        if(this.needsStripe) return 'stripe';
        else return 'complete';
      }
    };

    const needsStripeTest = (moveTransaction) => {
      if(moveTransaction.affirm_payment_details.length &&
        !User.get().has_stripe) this.needsStripe = true;
      else this.needsStripe = false;
    };

  }

});
