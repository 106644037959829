angular.module('cx').controller('quoteCreateModalController',
function ($scope, $rootScope, $timeout, $uibModalInstance, $q,
  Messages, User, Access, Move, MoveQuote, Transaction, move, moveTask) {
  'ngInject';

  if(Access.limit('concierge', () => $uibModalInstance.dismiss('unauthorized'))) return;

  function quote() {
    this.transaction_id = Transaction.getDefault(moveTask.task.name);
    this.vendor = null;
    this.description = null;
    this.cost = null;
    this.is_hourly = false;
    this.link = null;
  }

  $scope.moveTask = moveTask;
  $scope.sendMessage = true;

  Transaction.getTypes().then(transactions => {
    $scope.transactions = transactions;
    $scope.quotes = [new quote()];
    $scope.moveTransaction = $scope.transactions.find(t => t.name == 'move');
  });

  $scope.isAffirmEligible = (quote) => {
    return !quote.is_hourly && (quote.vendor && quote.vendor.has_stripe) &&
      $scope.moveTransaction && quote.transaction_id == $scope.moveTransaction.id
  }

  $scope.addQuote = (event) => {
    $scope.quotes.push(new quote());
  };

  $scope.removeQuote = (index) => {
    $scope.quotes.splice(index, 1);
  };

  function valid() {
    $scope.quoteWidget.$setSubmitted();
    return !$scope.quoteWidget.$invalid;
  }

  // scope function to post a new message
  $scope.send = () => {
    if($scope.loading) return;
    $scope.loading = true;
    if(!valid()) return error();
    let quotes = [];
    angular.forEach($scope.quotes, (quote) => {
      let data = {
        move_task: moveTask.id,
        transaction: quote.transaction_id,
        company: quote.vendor.name, // backwards compatibility
        vendor: quote.vendor.id,
        description: quote.description,
        is_hourly: quote.is_hourly || false,
        price: 0,
        rate: 0,
        affirm_enabled: $scope.isAffirmEligible(quote) ? quote.affirm_enabled : false
      };
      if(quote.is_hourly) {
        data.rate = parseInt(parseFloat(quote.cost) * 100);
        data.minimum = parseInt(parseFloat(quote.minimum) * 100);
        data.stripe_auth_amount = (quote.stripe_auth_amount) ? parseInt(parseFloat(quote.stripe_auth_amount) * 100) : 0;
      }
      else data.price = parseInt(parseFloat(quote.cost) * 100);
      if(quote.link) data.link = quote.link;
      quotes.push(data);
    });
    MoveQuote.create(moveTask.move.id,{move_quotes:quotes}).then((result) => {
      if(!$scope.sendMessage) return $uibModalInstance.close();
      let data = { move: moveTask.move.id };
      if(moveTask.task.name == 'book-movers') {
        let firstMessage = angular.extend({},data,{
          body: `Hey ${Move.findCustomer(move).user.firstname}! Your quotes are` +
            ` available. Click below to check them out.`,
          auto_generated: true
        });
        return Messages.send(firstMessage).then((message) => {
          let secondMessage = angular.extend({}, data, {
            type: 5,
            data: { move_task: moveTask.id },
            auto_generated: true
          });
          return Messages.send(secondMessage).then((message) => {
            $scope.loading = false;
            $uibModalInstance.close(message);
          });
        });
      } else {
        angular.extend(data, {
          type: 3,
          data: {
            quotes: result.map(quote => ({ quote_id: quote.id }))
          },
          auto_generated: true
        });
        return Messages.send(data).then((message) => {
          $scope.loading = false;
          $uibModalInstance.close(message);
        });
      }
    }, error);
  };

  $scope.setVendor = (vendor, index) => {
    $scope.quotes[index].vendor = vendor;
    $scope.quotes[index].description = $scope.quotes[index].description || vendor.default_description;
    $scope.quotes[index].link = $scope.quotes[index].link || vendor.review_link;
  }

  let error = () => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
  };

});
