angular.module('tasks').factory('Task',
function($http, $q, $timeout, Data, Modal, Move, User, Transaction, TaskFlow,
  BookMoversFlow, UpdateAddressFlow, PackingMaterialsFlow, StorageFlow,
  CleaningFlow, FinalizeBookingFlow) {
  'ngInject';

  let TaskFlows = angular.merge({},
    TaskFlow, // deprecated
    BookMoversFlow,
    FinalizeBookingFlow,
    UpdateAddressFlow,
    PackingMaterialsFlow,
    StorageFlow,
    CleaningFlow
  );

  let promises = {};

  var API = {
    getList: () => {
      if(!promises.tasks) promises.tasks = $http.get(`${Data.apiUrl}/v2/tasks`);
      return promises.tasks.then(r => r.data);
    },
    getAll: (data) => {
      let queue = {
        taskList: promises.tasks ? promises.tasks.then((r) => r.data) : API.getList(),
        moveTasks: $http.get(`${Data.apiUrl}/v2/move_tasks`,
          {params:data}).then(r => r.data)
      };
      return $q.all(queue).then((results) => {
        results.moveTasks.forEach((moveTask) => {
          moveTask.task = results.taskList.find((t) => t.id == moveTask.task.id);
        });
        return results.moveTasks;
      });
    },
    get: (id) => {
      return $http.get(`${Data.apiUrl}/v2/move_tasks`+(id ? '/'+id : '')).then(r => r.data)
    },
    add: (id,tasks) => {
      return $http.post(`${Data.apiUrl}/v2/moves/${id}/add_move_tasks`, {tasks:tasks}).then(r => r.data)
    },
    update: (id,data) => {
      return $http.patch(`${Data.apiUrl}/v2/move_tasks/${id}`, data).then(r => r.data)
    },
    read: (id) => {
      return $http.post(`${Data.apiUrl}/v2/move_tasks/${id}/mark_read`).then(r => r.data);
    },
    sendQuoteRequests: (id,data) => {
      return $http.post(`${Data.apiUrl}/v2/move_tasks/${id}/request_quotes`,data).then(r => r.data);
    },
    sendQuoteReadyMail: (id) => {
      return $http.post(`${Data.apiUrl}/v2/move_tasks/${id}/quotes_ready`).then(r => r.data);
    },
    getEligibleVendors: (id) => {
      return $http.get(`${Data.apiUrl}/v2/move_tasks/${id}/get_eligible_vendors`).then(r => r.data);
    },
    generateAutoQuotes: (id) => {
      return $http.get(`${Data.apiUrl}/v2/move_tasks/${id}/generate_quote_estimates`).then(r => r.data);
    },
    regenerateAutoQuotes: (id) => {
      return $http.post(`${Data.apiUrl}/v2/move_tasks/${id}/regenerate_auto_quotes`).then(r => r.data);
    },
    getItemList: (model) => {
      if(!promises[model]) promises[model] = $http.get(`${Data.apiUrl}/v2/${model}?per_page=999`);
      return promises[model].then(r => r.data);
    },
    findItems: (model,data) => {
      return $http.get(`${Data.apiUrl}/v2/${model}`,{params:data}).then(r => r.data);
    },
    getFlow: (task, move, flow) => {
      flow = flow || API.getDefaultFlow(task,move);
      if(!TaskFlows[task.task.name]) return [];
      let taskFlow = TaskFlows[task.task.name].flows[flow].map(screen =>
        angular.merge({},TaskFlows[task.task.name].screens[screen.name],screen));
      return taskFlow;
    },
    getDefaultFlow: (task,move) => {
      if(task.task.name == 'book-movers') {
        if(Move.findCustomer(move).user.external) return 'external';
        if(task.flow_variant) return task.flow_variant;
      }
      return 'default'
    },
    getScreen: (task, name) => {
      return angular.copy(TaskFlows[task].screens[name]);
    },
    getAllScreens: (task) => TaskFlows[task].screens,
    address: {
      delete: (id) => $http.delete(`${Data.apiUrl}/v2/move_task_addresses/${id}/delete_address`).then(r => r.data)
    },
    getPrimaryTransaction: (task, transactions) => {
      transactions = transactions || [];
      return transactions.filter(t => {
        return ['pending','authorized','approved','charged','final','declined'].includes(t.status);
      }).find(t => {
        return t.move_task_id == task.id && t.transaction.id == Transaction.getDefault(task.task.name);
      });
    },
    modals: {
      editScreen: (task, screen, move, options) => {
        return Modal.open(angular.merge({
          controller: 'editScreenModalController',
          templateUrl: '/templates/tasks/edit-screen-modal.html',
          windowClass: 'bounce wide',
          overlay: true,
          resolve: {
            params: () => ({ task, screen, move })
          }
        }, options));
      },
      editReview: (task, move, options) => {
        return Modal.open(angular.merge({
          controller: 'editReviewModalController',
          templateUrl: '/templates/tasks/edit-review-modal.html',
          resolve: {
            params: () => ({ task, move })
          }
        }, options));
      },
      taskFlow: (task, move, options) => {
        return Modal.open(angular.merge({
          controller: 'taskFlowModalController',
          templateUrl: '/templates/tasks/task-flow-modal.html',
          resolve: {
            params: () => ({ task, move })
          }
        }, options));
      },
      requestQuote: (task, options) => {
        return Modal.open(angular.merge({
          controller: 'requestQuoteModalController',
          templateUrl: '/templates/tasks/request-quote-modal.html',
          backdrop: true,
          windowClass: 'bounce',
          resolve: {
            params: () => ({ task })
          }
        }, options));
      },
      viewQuotes: (move, task, options) => {
        return Modal.open(angular.merge({
          controller: 'taskQuotesModalController',
          templateUrl: '/templates/tasks/task-quotes-modal.html',
          resolve: { move: () => move, task: () => task }
        },options));
      },
      confirm: (screen, options) => {
        return Modal.open(angular.merge({
          controller: 'confirmChangeModalController',
          templateUrl: '/templates/tasks/confirm-change-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            params: () => ({ screen })
          }
        }, options));
      },
      address: (field, options, opts) => {
        return Modal.open(angular.merge({
          controller: 'addressModalController',
          templateUrl: '/templates/tasks/address-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            params: () => ({ field, options })
          }
        }, opts));
      },
      boxesEstimates: (task, boxes, options) => {
        return Modal.open(angular.merge({
          controller: 'boxesEstimatesModalController',
          templateUrl: '/templates/tasks/boxes-estimates-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            params: () => ({ task, boxes })
          }
        }, options));
      }
    }
  };

  return API;

});
