angular.module('tasks').controller('taskQuotesModalController',
($scope, $timeout, $uibModalInstance, Alerts, User, Task, MoveQuote, Modal, move, task) => {
  'ngInject';

  $scope.move = move;
  $scope.task = task;
  $scope.loading = false;

  $scope.onBook = (quote) => {
    Alerts.success({ msg: `High five! You're one step closer!
        Your assistant will follow up with you shortly.` });
    $uibModalInstance.close('force');
  }

  // TODO: refactor and organize all quotes flow components, services, modals into new `quotes` module
  $scope.sendQuoteRequest = () => Task.modals.requestQuote($scope.task,{overlay:true});
  $scope.createQuote = () => MoveQuote.modals.create($scope.task,$scope.move,{overlay:true});

  // listen for move.quote broadcast
  $scope.$on('move.quote',(event,move_id,message) => {
    if(move_id == $scope.move.id) $timeout(updateQuotes,100);
  });
  // updates quotes list
  const updateQuotes = () => {
    $scope.quotes = move.move_quotes
      .filter((quote) => task.id == quote.move_task.id)
      .sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at));
  };

  // listen for move.request broadcast
  $scope.$on('move.quote_request',(event,move_id,message) => {
    if(move_id == $scope.move.id) $timeout(updateRequests,100);
  });
  const updateRequests = () => {
    $scope.requests = {
      created: move.quoteRequests
        .filter((request) => task.id == request.move_task.id && request.status == 'created')
        .sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at)),
      pending: move.quoteRequests
        .filter((request) => task.id == request.move_task.id && request.status == 'pending')
        .sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at)),
      submitted: move.quoteRequests
        .filter((request) => task.id == request.move_task.id && request.status == 'submitted')
        .sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at))
    }
  };

  updateQuotes();
  updateRequests();

  //trigger resending of quote ready email
  $scope.triggerQuoteReadyMail = () => {
    if($scope.loading || !$scope.quotes.length) return false;
    $scope.loading = true;
    Task.sendQuoteReadyMail($scope.task.id).then((result) => {
      Alerts.success({msg:'Email sent', delay:3});
      $scope.loading = false;
    },() => {
      $scope.loading = false;
      Alerts.error();
    });
  };

  $scope.triggerRegenerateAutoQuotes = () => {
    Modal.dialog({
      title: 'Are you sure?',
      body: `We’ll update auto-quote pricing for any quote requests in process,
        and if applicable, expire quotes for any vendors that are no longer
        available based on this customer’s updated move details.`,
      accept: 'Continue',
      decline: 'Cancel'
    }).result.then(
      () => Task.regenerateAutoQuotes($scope.task.id).then(result => {
        Alerts.success({msg: 'Regeneration in process...'})}),
      angular.noop
    );
  };

});
