angular.module('messages')
.component('messagePayment', {
  template: `
    <div class="message-bubble payment" ng-class="{'complete':$ctrl.complete,'loading':$ctrl.loading,'updated':$ctrl.message.data.updated}">
      <div class="message-container" ng-click="$ctrl.paymentFlow()">
        <svg-sprite class="notification" source="material-icons" ng-if="!$ctrl.complete"
          icon="notifications" alt="pay"></svg-sprite>
        <svg-sprite class="notification" source="material-icons" ng-if="$ctrl.complete"
          icon="check_circle" alt="paid"></svg-sprite>
        <div class="title" ng-if="::$ctrl.message.data.direct">
          {{ ::$ctrl.message.data.type }} - {{ ::$ctrl.message.data.amount/100 | currency }}<span ng-if="::$ctrl.message.data.is_hourly">/hour</span>
        </div>
        <div class="title" ng-if="::!$ctrl.message.data.direct">
          {{ ::$ctrl.message.data.move_transaction.vendor.name }} - {{ ::$ctrl.message.data.amount/100 | currency }}<span ng-if="::$ctrl.message.data.is_hourly">/hour</span>
        </div>
        <div class="description" ng-bind-html="$ctrl.description()"></div>
        <svg-sprite class="arrow" source="material-icons" ng-if="!$ctrl.complete"
          icon="chevron_right" alt="view"></svg-sprite>
      </div>
      <div class="message-delete" ng-click="$ctrl.messageDelete()"
        ng-if="::$ctrl.isCXMessage">
        <span class="message-warning" ng-if="$ctrl.warning">Are you sure?</span>
        <svg-sprite source="material-icons" icon="close" alt="remove"></svg-sprite>
      </div>
    </div>
    <div class="message-timestamp">
      <span ng-bind="::$ctrl.message.sender.firstname" ng-if="::$ctrl.isCXMessage"></span>
      <span ng-bind="::$ctrl.message.created_at | amUtc | amLocal | amDateFormat: 'M/D/YYYY h:mma'"></span>
    </div>`,
  bindings: {
    message: '<',
    move: '<',
    view: '<'
  },
  controller: function($timeout, User, Move, Messages, Payment, Transaction, Data, Alerts) {
    'ngInject';

    let isComplete = () => {
      this.complete = this.message.data.authorized || this.message.data.paid;
      if(this.complete) {
        this.paymentFlow = angular.noop;
        this.disabled = true;
        return this.complete = true;
      }
    };

    this.description = () => {
      if(isComplete()) return this.message.data.replace ? 'Payment Submitted' : 'Payment Accepted!';
      else return this.message.data.replace ? 'Update your card' : 'Click here to pay';
    }

    this.messageDelete = () => {
      if(this.deleteDisabled) return;
      if(!this.warning) {
        this.warning = true;
        this.deleteDisabled = true;
        $timeout(() => this.deleteDisabled = false, 300);
        $timeout(() => this.warning = false, 5000);
        return;
      }
      Messages.delete(this.message.id).then((result) => {}, Alerts.error);
    };

    this.$onInit = () => {
      this.isCXMessage = this.message.sender.role == 'concierge' && this.view == 'concierge';
    };

    // watch to complete after update via pubnub
    this.$doCheck = (changes) => {
      this.complete = isComplete();
    };

    // disable functionality for non-mover users
    if(User.get().role != 'mover') {
      this.disabled = true;
    } else {
      this.checkoutUI = false;
      this.loading = true;
      Payment.getStripeCheckout().then((stripe) => {
        this.checkoutUI = stripe;
        this.loading = false;
      });
    }

    // payment flow handler
    this.paymentFlow = () => {
      if(this.loading || this.disabled || this.complete) return;
      this.loading = true;

      // if direct without existing payment, go straight to stripe UI
      if(this.message.data.direct && !User.get().has_stripe)
        stripeCheckout((result) => charge(result[0].id));

      // if replacing declined payment, go straight to stripe UI
      else if(this.message.data.replace) stripeCheckout((result) => {
        let move_transaction = this.move.move_transactions.find(mt =>
          mt.id == this.message.data.move_transaction.id);
        if(move_transaction.stripe_payment_details && move_transaction.stripe_payment_details.length)
          Payment.finalize(move_transaction.id,{
            message:this.message.id,
            source:result[0].id
          })
          .then(success, () => error({data:{error:{message:`Uh Oh. There was a problem processing this card.
            Please try again, or use a different payment method.`}}}));
        else Payment.addCard({
            message: this.message.id,
            source:result[0].id
          })
          .then(success, () => error({data:{error:{message:`Uh Oh. There was a problem processing this card.
            Please try again, or use a different payment method.`}}}));
      });

      // show intro modal in all other situations
      else Payment.modals.intro(this.message.data).result.then(result => {
        // if direct with existing payment, route to charge or replace card and charge
        if(this.message.data.direct && User.get().has_stripe) {
          if(result == 'stripe') stripeCheckout((result) => charge(result[0].id));
          else if(result == 'continue') charge();
        }
        // if indirect, replace card or authorize with existing method
        if(!this.message.data.direct) {
          if(result == 'stripe') stripeCheckout((result) => addCard(result[0].id));
          else if(result == 'continue')
            Payment.authorize({message:this.message.id}).then(success,error);
        }
      },() => this.loading = false);
    };

    // add card function
    let addCard = (source) => {
      return Payment.addCard({
        message: this.message.id,
        source: source
      }).then(success,error);
    };

    // charge card function
    let charge = (source) => {
      let data = {
        message: this.message.id,
      };
      if(source) data.source = source;
      Payment.charge(data).then(success,error);
    }

    // trigger stripe checkout UI function
    let stripeCheckout = (callback) => {
      // trigger stripe payment overlay
      this.checkoutUI.open({
        name: (this.message.data.direct ? this.message.data.type : this.message.data.move_transaction.vendor.name),
        description:(this.message.data.direct ? 'via Moved' : this.message.data.type),
        email: User.get().email,
        amount: this.message.data.direct ? this.message.data.amount : 0,
        panelLabel: this.message.data.direct ? 'Pay' : 'Authorize'
      }).then(callback, (result) => {
        if(!result) return this.loading = false;
        error(false);
      });
    };

    // success handler
    let success = () => {
      this.loading = false;
      Alerts.success({ msg: this.message.data.direct ?
        'Payment Submitted!' : 'Card Accepted!' });
      if(!User.get().has_stripe) User.refresh();
    };

    // error handler
    let error = (result) => {
      this.loading = false;
      if(!result) return;
      if(result.data && result.data.error && result.data.error.message)
        return Alerts.error({msg:result.data.error.message});
      return Alerts.error();
    };

  }
});
