angular.module('field').controller('editFieldModalController',
function ($scope, $uibModalInstance, $q, $timeout, Field, Move, Task, User, params) {
  'ngInject';

  $scope.field = angular.copy(params.field);
  Field.addDefaults($scope.field);

  $scope.save = (form) => {
    if($scope.loading) return;
    if(!valid(form)) return error();
    $scope.loading = true;
    let data = buildData();
    let promises = {};
    if(data.task) promises.task = Task.update($scope.field.task_id,data.task);
    if(data.user) promises.user = User.update(Move.findCustomer(params.move).user.id,data.user);
    $q.all(promises).then((results) => {
      $scope.loading = false;
      if(results.user) Move.findCustomer(params.move).user = results.user;
      $uibModalInstance.close('success')
    },error);
  };

  const valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

  const buildData = () => {
    let data = {};
    Field.buildSaveData($scope.field,data);
    return data;
  };

  const error = () => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => $scope.error = false, 300);
    return false;
  };

});
