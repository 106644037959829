angular.module('tasks').component('screenAddresses', {
  templateUrl: '/templates/tasks/screen-addresses.html',
  bindings: {
    move: '<',
    task: '<',
    form: '<',
    screen: '<',
    onNext: '&'
  },
  controller: function($timeout,$q,User,Task,Move,Field,AddressUtil,GoogleAPI) {
    'ngInject';

    this.$onInit = () => {
      // map field array to object so they can be referenced in the template directly
      this.fields = {
        origin: angular.copy(this.screen.fields.find(field => field.name == 'from_address')),
        stops: angular.copy(this.screen.fields.find(field => field.name == 'stops')),
        destination: angular.copy(this.screen.fields.find(field => field.name == 'to_address'))
      }
      this.originalStops = [];
      // load the existing static field values from the move task data
      angular.forEach(this.fields, field => {
        angular.merge(field,{
          editable: true,
          display: 'list',
          value: Field.getValue(field, this.task, this.move),
          onChange: () => this.screen.onChange(buildData())
        });
        Field.addDefaults(field, this.task.data, this.move);
        if(field.type == 'address-list') field.list.forEach(item => {
          this.stops = this.stops || [];
          let exists = this.task.move_task_addresses.find(address => address.name === item.name);
          if(exists) {
            this.addStop(angular.copy(exists));
            this.originalStops.push(exists);
          }
        });
        // Temporary lookup google_place_id if field has value but not google_place_id (partner api hack)
        // TODO: fix this on the partner api
        if(field.type == 'complete-address' && field.value && !this.hasValue(field))
          GoogleAPI.places.getPredictions({input: AddressUtil.getFormatted(field.value)},(places) => {
            if(places && places.length) field.value.google_place_id = places[0].place_id;
          });
      });
      if(!(this.fields.origin.value || this.fields.destination.value)) addOnboardingLocations();
      setSubtitle();
    };

    const addOnboardingLocations = () => {
      if(this.task.data.origin_place_id && this.task.data.destination_place_id) {
        this.onboarding = {};
        ['origin','destination'].forEach(loc =>
          GoogleAPI.places.getDetails(this.task.data[`${loc}_place_id`],(place) => {
            if(!place) return;
            let match = {};
            AddressUtil.updateComponents(place,match);
            this.onboarding[loc] = match.value;
            setSubtitle();
          }));
      }
    };

    const setSubtitle = () => {
      if(this.onboarding && this.onboarding.origin && this.onboarding.destination) {
        this.screen.subtitle = `Please provide the pickup and dropoff locations for your move
          from ${this.onboarding.origin.city}${this.onboarding.origin.state != this.onboarding.destination.state ? ', '+this.onboarding.origin.state : ''}
          to ${this.onboarding.destination.city}${this.onboarding.origin.state != this.onboarding.destination.state ? ', '+this.onboarding.destination.state : ''}`;
      }
    }


    this.showStops = () => {
      let show = true;
      angular.forEach(this.fields, field => {
        if(field.name !== 'stops' && !this.hasValue(field)) show = false;
      });
      return show;
    };

    // function to format and add a new 'stop' field
    this.addStop = (value) => {
      if(this.stops.length >= this.fields.stops.list.length) return;
      let field = {
        name: this.fields.stops.list[this.stops.length].name,
        type: 'complete-address',
        required: true,
        label: `Address`,
        saveOnEdit: false,
        include: this.fields.stops.include
      };
      if(this.hasValue({value: value})) {
        field.value = value;
        field.formatted = AddressUtil.getFormatted(field.value);
        Field.addDefaults(field);
        this.stops.push(field);
      } else {
        Task.modals.address(field).result.then((address) => {
          if(address && address.google_place_id) {
            field.value = address;
            field.formatted = AddressUtil.getFormatted(field.value, {unit:false});
            Field.addDefaults(field);
            this.stops.push(field);
            if(this.screen.onChange) this.screen.onChange(buildData());
          }
        }).catch(angular.noop);
      }
    };

    this.removeStop = (index) => {
      this.stops.splice(index,1);
      this.stops.forEach((stop,i) => stop.name = this.fields.stops.list[i].name);
      if(this.screen.onChange) this.screen.onChange(buildData());
    };

    // not sure yet address
    this.notSureYet = () => {
      let destination = angular.copy(this.fields.destination);
      if(!destination.value && this.onboarding && this.onboarding.destination) {
        destination.value = this.onboarding.destination;
        destination.formatted = AddressUtil.getFormatted(destination.value, {unit:false});
      }
      destination.label = 'Enter City, State, or Zip Code';
      destination.include = {};
      let options = {
        subtitle:'Enter as specific of an area as you know at this point.',
        title:'Where are you moving to?'
      };
      Task.modals.address(destination,options).result.then((address) => {
        if(address && address.google_place_id) this.fields.destination.value = address;
        if(this.screen.onChange) this.screen.onChange(buildData());
      }).catch(angular.noop);
    };

    // convenience function to check if an address field has a valid value
    this.hasValue = (field) => {
      return field.value && field.value.google_place_id;
    };

    // convenience function to return the formatted value for display purposes
    this.getFormatted = (field) => {
      return this.hasValue(field) ? AddressUtil.getFormatted(field.value) : field.placeholder;
    };

    // expose the function to trigger a complete address modal and update the return value
    this.editAddress = (field) => {
      Task.modals.address(field).result.then((address) => {
        if(address) field.value = address;
        if(this.screen.onChange) this.screen.onChange(buildData());
      }).catch(angular.noop);
    };

    this.next = () => {
      if(this.loading) return;
      this.loading = true;
      if(!valid(this.form)) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      // handle deleting of removed stops
      if(this.originalStops.length > this.stops.length) {
        return $q.all(this.originalStops.filter(stop => {
            return parseInt(stop.name.replace('stop','')) > this.stops.length;
          }).map(stop => Task.address.delete(stop.id)))
        .then(() => Task.update(this.task.id,data.task));
      }
      // nothing to delete
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      data = data || {};
      angular.forEach(this.fields, field => {
        if(field.name !== 'stops') Field.buildSaveData(field,data);
      });
      if(this.stops.length) this.stops.forEach((field) => Field.buildSaveData(field,data));
      return data;
    };

    let valid = (form) => {
      form.$setSubmitted();
      let invalid = false;
      // custom validation for display only fields
      angular.forEach(this.fields, field => {
        if(field.required && !this.hasValue(field)) invalid = true;
      });
      return !invalid && !form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }

});
