angular.module('vendor').component('screenAutoQuoteLocations', {
  templateUrl: '/templates/vendor/screen-location-data.html',
  bindings: {
    vendor: '<',
    screen: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,$q,AddressUtil,Vendor,Field) {
    'ngInject';

    this.fields = {
      headquarters: {
        label: 'Headquarters',
        name: 'headquarters',
        type: 'address',
        required: true
      },
      pick_up_options: {
        type: 'radio',
        name: 'pick_up_options',
        options: {
          number_of_miles: {
            label: 'Number of Miles',
            value: 'number_of_miles'
          },
          entire_usa: {
            label: 'Entire Continental U.S.',
            value: 'entire_usa'
          }
        },
        default: 'entire_usa'
      },
      pick_up_miles: {
        label: 'Miles',
        name: 'pick_up_miles',
        required: true,
        custom: {
          hidden: {
            compare: { field:'pick_up_options' },
            boolean: 'not',
            value: 'number_of_miles'
          }
        }
      },
      delivery_options: {
        type: 'radio',
        name: 'delivery_options',
        options: {
          number_of_miles: {
            label: 'Number of Miles',
            value: 'number_of_miles'
          },
          entire_usa: {
            label: 'Entire Continental U.S.',
            value: 'entire_usa'
          }
        },
        default: 'entire_usa'
      },
      delivery_miles: {
        label: 'Miles',
        name: 'delivery_miles',
        required: true,
        custom: {
          hidden: {
            compare: { field:'delivery_options' },
            boolean: 'not',
            value: 'number_of_miles'
          }
        }
      }
    };

    this.$onInit = () => {
      this.screen.data = {};
      angular.forEach(this.fields, (field) => {
        if(field.type === 'address') {
          field.value = (this.vendor.vendor_addresses) ?
            this.vendor.vendor_addresses.find(a => a.name === field.name) : {};
          field.formatted = AddressUtil.getFormatted(field.value);
        } else if(field.name.includes('_options')) {
          if((Vendor.details.getValue(this.fields[field.name.replace('_options','_miles')],this.screen,this.vendor) || field.default) === 'entire_usa')
            field.value = 'entire_usa';
          else field.value = 'number_of_miles';
        } else {
          field.value = Vendor.details.getValue(field,this.screen,this.vendor);
          if(angular.isUndefined(field.value)) field.value = field.default;
        }
        Field.addDefaults(field,this.screen.data);
        // keep local copy for comparisons
        this.screen.data[field.name] = field.value;
        field.onChange = () => {
          this.screen.data[field.name] = field.value;
          if(this.screen.onChange) this.screen.onChange(buildData());
        };
      });
    };

    this.next = (skip) => {
      if(this.loading) return;
      if(skip) return this.onNext();
      this.loading = true;
      if(!valid()) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progress) => {
      let data = buildData();
      data.progress = progress;
      let addressData = { vendor_addresses: data.vendor_addresses };
      delete data.vendor_addresses;
      return $q.all({
        vendor: Vendor.update(this.vendor.id, addressData),
        vendor_details: Vendor.details.update(this.vendor.details.id, data)
      });
    };

    const buildData = () => {
      let data = {};
      ['pick_up','delivery'].forEach(location => {
        data[`${location}_miles`] = (this.fields[`${location}_options`].value === 'entire_usa' ?
          'entire_usa' : (this.fields[`${location}_miles`].value ?
          this.fields[`${location}_miles`].value.toString() : null))
      });
      if(this.fields.headquarters.value) data.vendor_addresses = [AddressUtil.filterFields(angular.merge(this.fields.headquarters.value,{name:this.fields.headquarters.name}))];
      return data;
    };

    let valid = () => {
      this.form.$setSubmitted();
      return !this.form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }

});
