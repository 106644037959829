angular.module('payment').controller('stripeRedirectController',
function ($scope, $state, $stateParams, User, Vendor, Access, Alerts) {
  'ngInject';

  if(Access.limit('vendor', function() { $uibModalInstance.dismiss('unauthorized'); })) return;

  if($stateParams.code && $stateParams.scope=='read_write' && !$stateParams.error) {
    Vendor.tetherStripe(User.get().vendor.id,{
      stripe_code:$stateParams.code
    }).then((result) => {
      Alerts.success({msg:'Congrats, you are now connected to recieve payments automatically through Stripe!'})
      $state.go('dashboard');
    }, error);
  } else error();

  function error() {
    Alerts.error({msg:'There was an error connecting your stripe account. Please try again.'});
    $state.go('dashboard');
  }

});
