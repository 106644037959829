angular.module('messages').factory('Typing',
function($rootScope,$timeout,Pubnub,debounce,User) {
  'ngInject';

  let isTyping = false;
  let userList = {};

  let setTyping = debounce((moveID) => {
    // already typing, renew timeout;
    if(isTyping) {
      $timeout.cancel(isTyping);
      isTyping = $timeout(() => API.setState(moveID,false),10000,false);
    }
    // not previously typing, set indicator and start timeout
    else {
      isTyping = $timeout(() => API.setState(moveID,false),10000,false);
      Pubnub.setState({
        channels: [`move.${moveID}.messages`],
        state: { user: User.get().id, isTyping: true }
      });
    }
  },200,true);
  let unsetTyping = (moveID) => {
    if(isTyping) $timeout.cancel(isTyping);
    isTyping = false;
    Pubnub.setState({
      channels: [`move.${moveID}.messages`],
      state: { user: User.get().id, isTyping: false }
    });
  };

  const API = {

    getUsers: (moveID) => userList[moveID],

    setState: (moveID, typing) => {
      if(typing) setTyping(moveID);
      else unsetTyping(moveID);
    },

    getState: (moveID,callback) => {
      return Pubnub.getState({
        channels: [`move.${moveID}.messages`]
      }, callback);
    },

    updateUserList: (moveID,event) => {
      // We don't want to receive our own presence events
      if (event.uuid === localStorage.uuid) return;

      userList[moveID] = userList[moveID] || [];

      // Add people typing
      if (event.action === 'state-change' && event.state.isTyping) {
        // Check if not already in the array
        if (!userList[moveID].find(user => user.id == event.state.user))
          userList[moveID].push({id:event.state.user,uuid:event.uuid});
      }
      // Remove people typing
      else if ((event.action === 'state-change' && event.state.isTyping === false) ||
        event.action === 'timeout' ||
        event.action === 'leave') {
        let index = userList[moveID].findIndex(user => user.uuid === event.uuid);
        if(angular.isDefined(index)) userList[moveID].splice(index,1);
        if(!userList[moveID].length) delete userList[moveID];
      }
      $rootScope.$apply($rootScope.$broadcast('move.typing',moveID,userList[moveID]));
    }

  };

  return API;

});
