angular.module('core')
  .filter('safeClass', function () {
    return (item) => item.replace(/([^A-Za-z0-9]+)/g,'-').toLowerCase();
  })
  .filter('phonenumber', function() {
    /*
    Format phonenumber as: c (xxx) xxx-xxxx
        or as close as possible if phonenumber length is not 10
        if c is not '1' (country code not USA), does not use country code
    */

    return function (number) {
      /*
      @param {Number | String} number - Number that will be formatted as telephone number
      Returns formatted number: (###) ###-####
          if number.length < 4: ###
          else if number.length < 7: (###) ###

      Does not handle country codes that are not '1' (USA)
      */
      if (!number) { return ''; }

      number = String(number);

      // Will return formattedNumber.
      // If phonenumber isn't longer than an area code, just show number
      var formattedNumber = number;

      // if the first character is '1', strip it out and add it back
      var c = (number[0] == '1') ? '1 ' : '';
      number = number[0] == '1' ? number.slice(1) : number;

      // # (###) ###-#### as c (area) front-end
      var area = number.substring(0,3);
      var front = number.substring(3, 6);
      var end = number.substring(6);
      if (front) formattedNumber = (c + '(' + area + ') ' + front);
      if (end) formattedNumber += ('-' + end);

      return formattedNumber;
    };
  })
  .filter('uriEncode', function() {
    /* Format a string for URI */
    return (input) => input ? window.encodeURIComponent(input) : '';
  })
  .filter('ordinal', function() {
    /* Format a number with an ordinal suffix (e.g. 1st, 2nd, etc) */
    const s=["th","st","nd","rd"];
    return (n) => {
      const v=n%100;
      return n+(s[(v-20)%10]||s[v]||s[0]);
    };
  })
  .filter('userAbbreviatedName', function() {
    /* filter to format customer/user name to a specific pattern i.e O. Phemmy */
    return (user) => `${user.firstname.substring(0,1)}. ${user.lastname}`;
  })
  .filter('formatResponse', function() {
    const versionCompatibility = (data) => {
      if(data instanceof Array) data.forEach((item) => versionCompatibility(item));
      if(data instanceof Object) Object.keys(data).forEach((key) => {
        // v2 compatibility
        if(key == '$uri' || key == '$ref')
          data.id = parseInt(data[key].split('/').pop());
        // v1 compatibility
        else if(key.indexOf('_id') != -1 && data[key])
          data[key.replace('_id','')] = { id: data[key] };
        // v2 timestamps add explicit UTC
        else if(typeof data[key] === "string" && (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d*$/.test(data[key])))
          data[key] += 'Z';
        else versionCompatibility(data[key]);
      });
      return data;
    };
    return versionCompatibility;
  });
