angular.module('field')
  .component('fieldOptionslist', {
    template: `
    <div class="list-holder">
      <div class="option-row" ng-repeat="option in $ctrl.options">
        <div class="type-item" ng-click="$ctrl.updateValue(option)"
          ng-bind="option.label" ng-class="$ctrl.classes(option)"></div>
      </div>
    </div>
    <div class="error-row" ng-if="$ctrl.field.isRequired()">
      <input type="checkbox" name="{{$ctrl.field.name}}" ng-model="$ctrl.has_value" required/>
      <label class="error" ng-if="$ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
        Please select a value from the list.
      </label>
    </div>`,
    bindings: {
      field: '=',
      form: '<',
      move: '<'
    },
    controller: function() {
      'ngInject';
      this.$onInit = () => {
        if(this.field.value) this.has_value = true;
        this.options = this.field.options.filter(option =>
          !option.flowSpecific || !this.field.task || option.flowSpecific.includes(this.field.task.flow_variant));
      };
      this.updateValue = (option) => {
        this.field.value = option.value;
        this.has_value = true;
        if(this.field.onChange) this.field.onChange();
      };
      this.classes = (option) => {
        let classes = [];
        if(option.value == this.field.value) classes.push('selected');
        return classes;
      }
    }
  });
