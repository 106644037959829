angular.module('partner').controller('channelPartnerDashboardController',
function ($scope, $timeout, $state, $stateParams, $q, $filter,
  Messages, Alerts, User, Access, Move, Task, Field, Token, Image) {
  'ngInject';

  if(Access.limit('channel-partner', () => $state.reload())) return;

  $scope.ready = false; // set to true after first page move request
  $scope.user = User.get();
  $scope.activePanel = 'moves';
  $scope.selectPanel = (panel) => $scope.activePanel = panel;
  $scope.moves = [];

  /*** MOVES ***/

  let taskList = false;
  let promises = {};
  promises.tasks = Task.getList();
  promises.moves = Move.getAll();

  $q.all(promises).then(results => {
    if(results.tasks) taskList = results.tasks;
    if(results.moves) formatMoves(results.moves.data);
  }, () => Alerts.error());

  function formatMoves(moves) {
    moves.forEach(move => {
      if(Move.findCustomer(move).user.id == User.get().id) $scope.move = move;
      else {
        move.progressList = angular.copy($scope.moveProgress);
        angular.forEach(move.progressList, (item) => {
          item.name = move.source.link + item.name;
        });
        updateUnit(move);
        move.move_tasks.forEach(moveTask => {
          moveTask.task = taskList.find((task) => task.id == moveTask.task.id)
        });
        $scope.moves.push(move);
      }
    });

    $scope.ready = true;
  }

  function updateUnit(move) {
    move.linked_unit = (move.source.link == 'origin') ?
      (move.from_address ? move.from_address.unit : '') :
      (move.to_address ? move.to_address.unit : '');
  }

  // progress options
  $scope.moveProgress = [{
      label: 'COI',
      name: 'Coi'
    },{
      label: 'Elevator',
      name: 'Elevator'
  }];

  $scope.inviteMover = () => {
    Token.modals.inviteMover();
  };

  // expose utility functions
  $scope.findCustomer = Move.findCustomer;
  $scope.findSelf = Move.findSelf;
  // TODO: [multiple moves] add logic for selecting WHICH one if more than one
  $scope.findTask = (name, move) => Move.findMoveTasks(name,move)[0];
  $scope.getFieldValue = (name, move) => {
    let task = $scope.findTask('book-movers',move);
    return Field.getValue(Field.get(name,'book-movers'),task);
  };

  /*** MOVE LIST PANEL ***/

  // listen for move.data broadcast
  $scope.$on('move.data',(event,id,message) => updateData(message));

  function updateData(moveData) {
    if(!$scope.moves) return;
    let move = $scope.moves.find((move) => move.id === moveData.id);
    if(!move) return;
    angular.extend(move,moveData);
    updateUnit(move);
  }

  /*** MESSAGES ***/

  // listen for newMessage broadcast
  $scope.$on('move.message',(event,id,message) => newMessage(message));

  function newMessage(message) {
    if($scope.move.id !== message.move.id) return;
    if(message.sender.id !== User.get().id && $scope.activePanel != 'messages') {
      if($scope.move.messages) {
        $scope.move.messages.push(message);
        $scope.move.messageCount++;
      }
      Move.findSelf($scope.move).unread++;
      Alerts.info({msg:'You have a new message!'});
    }
  }

  // open a modal to view a set of photos
  $scope.photosModal = Image.modals.view;

});
