angular.module('cx').controller('adminNavController',
function ($scope, $state, $stateParams, User, Access) {
  'ngInject';

  $scope.menu = [{
    label:'Moves',
    state: 'reactDash',
    icon: {
      alt: 'moves',
      source: 'material-icons',
      icon: 'grade',
    },
    order: 0
  },{
    label:'Vendors',
    state: 'admin.vendors',
    icon: {
      alt: 'vendors',
      source: 'material-icons',
      icon: 'local_shipping',
    },
    order: 20,
  },{
    label:'Admin Abode',
    state: 'adminAbode',
    icon: {
      alt: 'To the moon!',
      source: 'material-icons',
      icon: 'domain',
    },
    order: 30
  }];

  $scope.user = User.get();

  $scope.home = () => {
    $state.go('dashboard.cx',{},{inherit:false, reload:true});
  };


});
