angular.module('field')
.component('fieldSelect', {
  template: `
  <ui-select ng-model="$ctrl.field.value" name="{{$ctrl.field.name}}"
    search-enabled="$ctrl.field.searchable || false"
    append-to-body="$ctrl.field.appendToBody !== false"
    ng-required="$ctrl.field.isRequired()"
    ng-disabled="$ctrl.field.isDisabled() || $ctrl.field.isReadonly()"
    on-select="$ctrl.field.onChange()">
    <ui-select-match placeholder="{{$ctrl.field.label}}">{{$select.selected.label}}</ui-select-match>
    <ui-select-choices repeat="option.value as option in $ctrl.field.options">
      {{option.label}}
    </ui-select-choices>
  </ui-select>
  <label class="error" ng-if="$ctrl.field.isRequired() &&
    $ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
    Required.
  </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
});
