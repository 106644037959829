angular.module('core').factory('Pixel',
function($rootScope, $window, User, Data) {
  'ngInject';

  // set defaults
  $window.fbq.disablePushState = true;

  let init = false;

  $rootScope.$on('userUpdated', () => {
    if(init) return;
    let data = User.get().email ? { em: User.get().email.toLowerCase() } : null;
    $window.fbq('init', '1331312456908965', data);
    init = true;
  });

  return {
    FB: {
      track: (event,data) => {
        if(Data.env != 'prod') return;
        $window.fbq('track',event,data);
      }
    }
  }

});
