angular.module('field')
.component('fieldNumber', {
  template: `
    <input type="number" name="{{$ctrl.field.name}}" id="number-{{$ctrl.uuid}}"
      ng-model="$ctrl.field.value" ng-model-options="{debounce:200}"
      ng-min="$ctrl.field.min"
      ng-max="$ctrl.field.max"
      ng-minlength="$ctrl.field.minlength"
      ng-maxlength="$ctrl.field.maxlength"
      ng-required="$ctrl.field.isRequired()"
      ng-disabled="$ctrl.field.isDisabled()"
      ng-readonly="{{$ctrl.field.readOnly}}"
      ng-change="$ctrl.field.onChange()" />
    <label class="placeholder" for="number-{{$ctrl.uuid}}"
      ng-bind-html="$ctrl.field.label || $ctrl.field.placeholder"></label>
    <label class="error" ng-messages="$ctrl.form[$ctrl.field.name].$error"
      ng-if="$ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      <span ng-message="required">Required. </span>
      <span ng-message="minlength">Must be at least {{$ctrl.field.minlength}} digits. </span>
      <span ng-message="maxlength">Must be no longer than {{$ctrl.field.maxlength}} digits. </span>
      <span ng-message="min">{{ $ctrl.field.minMessage ? $ctrl.field.minMessage($ctrl.field.min) : 'Must be greater than ' + $ctrl.field.min }}</span>
      <span ng-message="max">{{ $ctrl.field.maxMessage ? $ctrl.field.maxMessage($ctrl.field.max) : 'Must be less than ' + $ctrl.field.max' }}</span>
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function() {
    'ngInject';
    this.uuid = Date.now();
    this.$onInit = () => {
      if(this.field.value) this.field.value = parseInt(this.field.value);
    };
  }
});
