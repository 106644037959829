angular.module('move').factory('Building',
function($http, Data, Modal, User) {
  'ngInject';

  const API = {
    get: (id) => {
      return $http.get(`${Data.apiUrl}/buildings/${id}`).then(r => r.data.data);
    },
    modals: {}
  };

  return API;

});
