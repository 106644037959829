angular.module('vendor').component('pricingSummary', {
  templateUrl: '/templates/vendor/pricing-summary.html',
  bindings: {
    vendor: '<',
    onClose: '&'
  },
  controller: function($timeout,$q,$location,$anchorScroll,User,Vendor,Field,Modal) {
    'ngInject';

    this.updatedData = false;

    this.$onInit = () => {
      this.flow = Vendor.details.getFlow();
      this.flow.forEach(screen => {
        screen.summary = true;
        screen.onChange = (data) => {
          this.updatedData = this.updatedData ? angular.merge(this.updatedData,data) : data;
        };
      });
      this.ready = true;
    };

    this.update = () => {
      if(this.loading) return;
      if(!valid()) return error();
      if(!this.updatedData) return this.onClose();
      this.loading = true;
      let promises = [];
      if(this.updatedData.vendor_addresses) {
        promises.push(Vendor.update(this.vendor.id, {vendor_addresses: this.updatedData.vendor_addresses}));
        delete this.updatedData.vendor_addresses;
      }
      promises.push(Vendor.details.update(this.vendor.details.id, this.updatedData));
      $q.all(promises).then(this.onClose,error);
    };

    let valid = () => {
      this.form.$setSubmitted();
      return !this.form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

    this.close = () => {
      this.loading = false;
      if(this.updatedData)
        return Modal.dialog({
          title: '<img src="/images/think-2x.png"/> Hang on!',
          body: 'Leave without saving changes?',
          accept: 'Save Changes',
          decline: 'Leave',
          class: 'unsaved-changes'
        }).result.then(
          () => this.update(), // save
          () => this.onClose() // leave
        );
      if(this.onClose) this.onClose();
    }

  }

});
