angular.module('core').controller('coreController',
function ($rootScope, $timeout, $state, $filter, $cookies, $window, Data, Menu, User) {
  'ngInject';

  let stateName = 'loading';
  let stateClasses = [];

  // track the active state infro as a class on 'body'
  $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState) => {
    if(toState.external) {
      event.preventDefault();
      // append search params based on toParams of the external state
      const search = Object.keys(toParams)
        .filter(key => toParams[key])
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(toParams[key])}`)
        .join('&');
      return $window.open(`${toState.url}${search?'?'+search:''}`, '_self');
    }
    if(stateName != fromState.name || toState.name == fromState.name) return;
    if(!stateClasses.includes('loading')) stateClasses.push('loading');
    stateName = toState.name;
    event.preventDefault();
    $timeout(() => $state.go(toState.name,toParams),100);
  });

  // update page data when state changes
  $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
    // update stateName and stateClasses for body classes
    stateName = '';
    stateClasses = [];
    toState.name.split('.').forEach((segment,i) => {
      stateName += (i == 0 ? '' : '.') + segment;
      stateClasses.push($filter('safeClass')(stateName));
    });
    for (let key in toParams) {
      if(toParams[key]) stateClasses.push($filter('safeClass')(`${key}-${toParams[key]}`));
    }
    if($cookies.getObject('darkMode') === true) stateClasses.push('dark-mode');

    // update the SEO tags
    let data = toState.data || {};
    $rootScope.meta = {
      name: data.name || '',
      title:  data.title || `Moved: Your Free Personal Moving Assistant`,
      description: data.description || `Moving made easy with a personal
        assistant to coordinate the entire process. Compare moving company
        quotes, book movers, find storage, order supplies, update your address,
        sell items and much more.`
    };

  });

  this.classes = () => {
    // include user role
    let classes = User.get().role ? [User.get().role] : [];
    // include state based info
    stateClasses.forEach((className) => classes.push(className));

    // include winter snow easter egg
    if(Snow) classes.push('snowing');

    return classes;
  };

  this.isProxy = () => User.isProxy();
  this.exitProxy = () => User.exitProxy().then((email) => {
    $state.go('reactDash',{direct:email});
  });

  // track main menu open status
  this.showMobileNav = () => Menu.showMobileNav('main');

  let Snow = false;
  this.snowToggle = false;
  $rootScope.$on('startSnow', () => {
    Snow = !$cookies.getObject('disableSnow');
    this.snowToggle = {
      label: 'Let it snow!',
      value: Snow,
      onChange: () => {
        Snow = !Snow;
        $rootScope.$broadcast('toggleSnow');
      }
    };
  });

});
