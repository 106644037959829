angular.module('payment').controller('stripePaymentController',
function ($rootScope, $scope, $stateParams, $state, $timeout, $q, $filter,
  Alerts, Analytics, Payment, Move, Task, Field, Transaction, Auth, User, Data) {
  'ngInject';

  $scope.ready = false;
  $scope.user = {};
  $scope.move_transaction = {};
  $scope.bookingDetailsModal = () => {
    Payment.modals.bookingTerms($scope.move_transaction,$scope.move);
  }
  $scope.webUrl = Data.webUrl;

  // validate token
  if(!$stateParams.token) {
    error(true);
    $scope.ready = true;
    return $timeout(() => $state.go('dashboard'),2000);
  }

  // load payment token
  $q.all({
    token: Payment.validateToken({token:$stateParams.token}),
    stripe: Payment.getStripeCheckout(),
    transactions: Transaction.getTypes()
  }).then((results) => {
    $scope.checkoutUI = results.stripe;
    $scope.user = results.token.user;
    $scope.move_transaction = results.token.move_transaction;
    $scope.move_transaction.transaction = results.transactions.find(t => t.id == $scope.move_transaction.transaction.id);
    $scope.move = results.token.move;
    $scope.move_task = Move.findMoveTask($scope.move_transaction.move_task_id, $scope.move);
    $scope.amount = $scope.move_transaction.is_hourly ? $scope.move_transaction.rate :
      $scope.move_transaction.total - ($scope.move_transaction.discount || 0);
    $scope.access_token = results.token.access_token;
    setFields();
    return $timeout(() => $scope.ready = true, 500);
  }, () => error(true));

  // expose form using $ctrl for consistency with field-generator
  $scope.$ctrl = {};

  const setFields = () => {
    $scope.fields = [{
      name: 'email',
      placeholder: 'Email',
      value: $scope.user.email,
      type: 'email',
      required: true,
      onChange: (v) => $scope.user.email = v,
      isDisabled: () => false
    },{
      name: 'phone',
      placeholder: 'Phone',
      value: Field.getValue(Field.get('contact_phone','book-movers'),$scope.move_task),
      type: 'phone_number',
      required: true,
      onChange: (v) => $scope.user.phone = v,
      isDisabled: () => false
    },{
      name: 'move_date',
      placeholder: 'Move date',
      value: $filter('date')(Field.getValue(Field.get('move_date','book-movers'),$scope.move_task),'MMMM d, yyyy'),
      type: 'text',
      isDisabled: () => true,
      classes: 'dashed'
    },{
      name: 'vendor',
      placeholder: 'Moving company',
      value: $scope.move_transaction.vendor.name,
      type: 'text',
      isDisabled: () => true,
      classes: 'dashed'
    },{
      name: 'arrival_window',
      placeholder: 'Arrival Window',
      value: $scope.move_transaction.arrival_window ?
        $filter('uppercase')(`${$scope.move_transaction.arrival_window.from} - ${$scope.move_transaction.arrival_window.to}`) :
        null,
      type: 'text',
      isDisabled: () => true,
      isHidden: () => !angular.isObject($scope.move_transaction.arrival_window) ||
        angular.equals($scope.move_transaction.arrival_window, {}),
      classes: 'dashed'
    }];
  };

  const valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

  $scope.book = () => {
    if($scope.loading || !valid($scope.$ctrl.form)) return;
    $scope.loading = true;
    User.update($scope.user.id,{
      firstname:$scope.user.firstname,
      lastname: $scope.user.lastname,
      email: $scope.user.email,
      phone: $scope.user.phone
    },{headers:{'Authorization':`Bearer ${$scope.access_token}`}}).then(stripeCheckout, (result) => {
      this.loading = false;
      error(false);
      if(result.status == 409) Alerts.error({msg: `That email address is already registered,
        please try a different one.`})
      else Alerts.error();
    });
  };

  // trigger stripe checkout UI function
  const stripeCheckout = () => {
    return $scope.checkoutUI.open({
      name: $scope.move_transaction.vendor.name,
      description: $scope.move_transaction.transaction.label,
      email: $scope.user.email,
      amount: 0,
      panelLabel: 'Authorize'
    }).then(addCard, (result) => {
      if(!result) return $scope.loading = false;
      error(false);
    });
  }

  const addCard = (result) => {
    return Payment.addCard({
      source:result[0].id,
      move_transaction:$scope.move_transaction.id
    },{headers:{'Authorization':`Bearer ${$scope.access_token}`}}).then(success,() => {
      error(false);
      Alerts.error({msg:`Uh Oh. There was a problem processing this card.
        Please try again, or use a different payment method.`});
    });
  }

/* Needed to support non-stripe vendors eventually
  // charge card function
  const charge = (source) => {
    let data = {};
    if(source) data.source = source;
    Payment.charge(data).then(success,error);
  }
*/

  function success() {
    $scope.loading = false;
    $scope.successMessage = true;
  }

  function error(invalid) {
    $scope.ready = true;
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    $scope.errorMessage = invalid;
  }

  $scope.password = '';
  $scope.setPassword = () => {
    if($scope.loading) return;
    if(!valid($scope.passwordForm)) return passwordError();
    $scope.loading = true;
    User.setPasswordLegacy($scope.access_token,$scope.password,$scope.user.id).then(() => {
      $scope.loading = false;
      Alerts.success({ msg: 'Your password has been successfully created.'});
      $state.go('dashboard');
    }, (data) => {
      $scope.loading = passwordError();
      return Alerts.error();
    });
  };

  function passwordError() {
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    return false;
  }


});
