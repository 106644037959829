angular.module('field')
.component('fieldTextarea', {
  template: `
    <textarea name="{{$ctrl.field.name}}" id="{{$ctrl.field.name}}"
      placeholder="{{$ctrl.field.placeholder}}" ng-required="$ctrl.field.isRequired()"
      ng-change="$ctrl.field.onChange()" ng-disabled="$ctrl.field.isDisabled()"
      ng-model="$ctrl.field.value" ng-model-options="{debounce:200}"></textarea>
    <label class="error" ng-if="$ctrl.field.isRequired() &&
      $ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      Required.
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  }
});
