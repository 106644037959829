angular.module('payment').controller('transactionsViewModalController',
function ($scope, $uibModalInstance, $timeout, Alerts, Access, Transaction, move, moveTask, onDelete) {
  'ngInject';

  if(Access.limit('concierge', () => $uibModalInstance.dismiss('unauthorized'))) return;

  $scope.taskFilter = (transaction) => {
    return transaction.move_task_id == moveTask.id;
  }

  $scope.move = move;

  $scope.onDelete = onDelete;

});
