angular.module('user').controller('createMoverModalController',
function($scope,$uibModalInstance,$timeout,$state,$q,
  User,Move,Task,MoveTag,Alerts) {
  'ngInject';

  $scope.account = {
    firstname: null,
    lastname: null,
    email: null,
    new_message_notification: false,
    external: true,
    source: {
      channel: 'MMS'
    }
  };

  $scope.sources = [{
    label: 'MMS',
    value: 'MMS'
  },{
    label: 'Access Self Storage',
    value: 'access'
  },{
    label: 'Extra Space Storage',
    value: 'EXR'
  },{
    label: 'Citiwide',
    value: 'citiwide'
  },{
    label: 'Life Storage',
    value: 'lifestorage'
  },{
    label: 'Safeguard Self Storage',
    value: 'safeguard'
  },{
    label: 'Local Locker',
    value: 'local-locker'
  },{
    label: 'Yelp',
    value: 'Yelp'
  },{
    label: 'Kandela',
    value: 'Kandela'
  },{
    label: 'Thumbtack',
    value: 'Thumbtack'
  },{
    label: 'Email',
    value: 'Email'
  },{
    label: 'Other',
    value: 'Other'
  }];

  var valid = () => {
    $scope.createForm.$setSubmitted();
    return !$scope.createForm.$invalid;
  };

  $scope.submit = () => {
    if($scope.loading) return false;
    if(!valid()) return error();
    $scope.loading = true;
    $q.all({
      user:User.create($scope.account),
      tasks:Task.getList()
    }).then(postRegister, (result) => {
      if(result.status == 409)
        return error({msg:'This email is already in use by another user.'});
      error({alert:{}});
    });
  };

  function postRegister(data) {
    $scope.taskList = data.tasks;
    User.getMoves(data.user.id)
      .then(ids => Move.get(ids[0]))
      .then(move => {
        // notify the user of success
        success(`User created for ${data.user.fullname}!`);
        $timeout(() => {
          triggerTaskFlow(move);
          $uibModalInstance.close(move.id);
        },500);
      }, () => error({alert:{}}));

  }
  const triggerTaskFlow = (move,attempt) => {
    // attempt limit hack for slow async serverside response
    attempt = attempt || 0;
    if(attempt > 10) return error({alert:{}});
    Task.getAll({where:{move:move.id}}).then(moveTasks => {
      let task = moveTasks.find(moveTask => moveTask.task.name == 'book-movers');
      if(!task) return $timeout(() => triggerTaskFlow(move,attempt+1),500);
      task.task = $scope.taskList.find(t => t.name == 'book-movers');
      // save default values for external user by source
      switch(move.source.channel) {
        case 'MMS':
        case 'access':
        case 'EXR':
          let defaultData = {move_size: 'Storage', professional_packing: 'none'};
          Task.update(task.id, {data: defaultData });
          break;
      }
      Task.modals.taskFlow(task, move);
    });
  };

  function success(msg) {
    $scope.loading = false;
    if(msg) Alerts.success({msg: msg});
  }

  function error(params) {
    params = params || {};
    $scope.loading = false;
    $scope.errorMessage = params.msg || null;
    if(params.alert) Alerts.error(params.alert);
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    return false;
  }

});
