angular.module('messages')
.component('typingIndicator', {
  template: `
  <span>
    {{ $ctrl.userNames() }} {{ $ctrl.users.length == 1 ? 'is' : 'are'}} typing
  </span>
  <bouncing-dots></bouncing-dots>`,
  bindings: {
    users: '<'
  },
  controller: function() {
    'ngInject';
    this.userNames = () => {
      return this.users.map(user => user.firstname).join(', ');
    }
  }
});
