angular.module('user').factory('Access',
function($rootScope, $state, $window, Modal, User, Alerts, Data) {
  'ngInject';

  var access = {
    limit: (level,callback,options) => {
      let allowed = [];
      switch(level) {
        case 'user':
          allowed.push('mover');
          /* falls through */
        case 'channel-partner':
          allowed.push('channel-partner','partner');
          /* falls through */
        case 'vendor':
          allowed.push('vendor');
          /* falls through */
        case 'concierge':
          allowed.push('concierge');
          /* falls through */
        case 'admin':
          allowed.push('admin');
      }
      if(!allowed.length) return true;
      else if(User.get().role == 'guest') {
        return $window.top.location = `${Data.consumerUrl}/signin`;
      } else if(allowed.indexOf(User.get().role) == -1) {
        Alerts.warn({ msg: "Caution: Here there be dragons." });
        $state.go('dashboard');
        return true;
      }
      return false;
    },
    forceLogin: (callback,options) => {
      options = angular.merge({
        data: { required:true },
        next: callback
      },options);
      var modal = User.modals.sign(options);
      modal.result.catch((reason) => {
        if(reason != 'force') access.forceLogin(callback,options);
      });

      if(options.message) Alerts.warn({
        msg: options.message
      });
    }
  };

  return access;

});
