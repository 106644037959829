angular.module('cx').factory('MoveTag',
function($http, Data) {
  'ngInject';

  return {
    find: function(data) {
      return $http.get(`${Data.apiUrl}/v2/tags`,{params:data}).then((result) => {
        return result.data;
      });
    },
    add: function(move_id,data) {
      return $http.post(`${Data.apiUrl}/v2/moves/${move_id}/add_tag`, data).then((result) => {
        return result.data;
      });
    },
    remove: function(move_id,data) {
      return $http.post(`${Data.apiUrl}/v2/moves/${move_id}/remove_tag`, data).then((result) => {
        return result.data;
      });
    }
  };

});
