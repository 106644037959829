angular.module('field')
.component('fieldPhoneNumber', {
  template: `
    <input type="tel"
      ng-model="$ctrl.displayValue"
      ng-model-options="{allowInvalid:true}"
      name="{{$ctrl.field.name}}" id="phone-{{$ctrl.uuid}}"
      ng-change="$ctrl.updateValue()"
      ng-required="$ctrl.field.isRequired()"
      ng-disabled="$ctrl.field.isDisabled()"
      ui-validate="{validPhone: '$ctrl.isValid($value)'}"
      class="phonenumber" />
    <label class="placeholder" for="phone-{{$ctrl.uuid}}"
      ng-bind-html="$ctrl.field.placeholder"></label>
    <label class="error" ng-messages="$ctrl.form[$ctrl.field.name].$error"
      ng-if="$ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      <span ng-message="required">Required. </span>
      <span ng-message="validPhone">Must be a valid US phone number. </span>
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function($filter) {
    'ngInject';

    this.uuid = Date.now();

    this.$onInit = () => {
      this.displayValue = angular.copy(this.field.value);
      this.updateValue();
    };
    this.updateValue = () => {
      let value = String(this.displayValue);
      value = value.replace(/[^0-9]+/g, '');
      if(value != this.field.value) {
        this.field.value = value;
        if(angular.isDefined(this.field.onChange)) this.field.onChange(value);
      }
      this.displayValue = $filter('phonenumber')(value);
    };

    this.isValid = (value) => {
      let number = String(value);
      number = number.replace(/[^0-9]+/g, '');
      return number ? number.length == ((number[0] == '1') ? 11 : 10) : true;
    };

  }
});
