// declare module routes
angular.module('user').config(function($stateProvider, Data) {
  'ngInject';
  $stateProvider
    .state('login', {
      url: '/login',
      parent:'app',
      views: {
        'special@': {
          controller: 'signController',
          template: ''
        }
      },
      params: { view: 'signin'},
    })
    .state('register', {
      url: '/register',
      parent:'app',
      views: {
        'special@': {
          controller: 'signController',
          template: ''
        }
      },
      params: { view: 'signup' },
    })
    .state('logout', {
      url: '/logout',
      parent:'app',
      views: {
        'special@': {
          controller: 'logoutController',
          template: ''
        }
      },
      data: { name: 'Logout' }
    })
    .state('registerSpecial', {
      url: '/register/{token}',
      parent:'app',
      views: {
        'special@': {
          controller: 'registerSpecialController',
          template: ''
        }
      },
      params: { token: null },
      data: { name: 'Register' }
    })
    .state('passwordSet', {
      url: '/password-set/{token:[A-Za-z0-9_\.\-]+}',
      parent:'app',
      views: {
        'special@': {
          controller: 'passwordSetController',
          template: ''
        }
      },
      data: {
        name: 'Set Password'
      }
    })
    .state('account', {
      url: '/account',
      parent:'layout-default',
      views: {
        'main': {
          controller: 'accountController',
          templateUrl: '/templates/user/account.html'
        },
        'footer': {}
      },
      data: {
        name: 'Account'
      }
    });
});
