angular.module('payment').controller('transactionRefundModalController',
function ($scope, $uibModalInstance, $timeout, Alerts, Access, Transaction, move_transaction) {
  'ngInject';

  if(Access.limit('concierge', () => $uibModalInstance.dismiss('unauthorized'))) return;

  $scope.fields = {
    refund: {
      name: 'refund',
      placeholder: 'Refund Amount',
      allowDecimals: true,
      required: true,
      positiveValue: true
    },
    reason: {
      name: 'reason',
      placeholder: 'Reason',
      required: true
    }
  };

  const valid = () => {
    $scope.refundTransactionForm.$setSubmitted();
    return !$scope.refundTransactionForm.$invalid;
  };

  const error = () => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    return false;
  };

  $scope.submit = () => {
    if($scope.loading) return;
    if(!valid()) return error();
    $scope.loading = true;
    if(move_transaction.vendor.has_stripe)
      Transaction.refund(move_transaction.id, {
        original_amount: (move_transaction.total - (move_transaction.discount || 0)),
        refund_amount: $scope.fields.refund.value,
        refund_reason: $scope.fields.reason.value
      }).then(
        () => {
          Alerts.success({msg: 'Refund Complete'});
          $timeout(() => { $uibModalInstance.close(true); }, 300)
        },
        (resp) => {
          let message = 'Oops, something went wrong, please try again';
          if(resp && resp.data && resp.data.message) {
            if(resp.data.message == 'Cannot find the specified charge.') {
              message = 'Oh no! This transaction is too old to refund in the App :(';
            }
          }
          Alerts.error({ msg: message, delay: 3 });
          error();
        }
      );
    // not fully implemented yet
    else Transaction.update(move_transaction.id,{
      refund: $scope.fields.refund.value,
      refund_reason: $scope.fields.reason.value
    }).then(() => $timeout(() => $uibModalInstance.close(true), 300),error);

  };

});
