angular.module('move').factory('MoveQuote',
function($rootScope, $http, moment, Data, Move, Task, Field, AddressUtil, Modal) {
  'ngInject';

  const quoteRequestData = [{
        name: 'from_address',
        label: 'Moving From'
      },{
        newline:true
      },{
        name: 'to_address',
        label: 'Moving To'
      },{
        newline:true
      },{
        name: 'stops',
      },{
        newline:true
      },{
        name: 'move_date'
      },{
        name: 'is_date_flexible',
        label: 'Flexible Date'
      },{
        name: 'time_preference'
      },{
        newline:true
      },{
        name: 'professional_packing'
      },{
        newline:true
      },{
        name: 'move_size'
      },{
        type: 'label',
        label: 'Inventory List'
      },{
        name: 'boxes',
        type: 'item-list'
      },{
        name: 'inventory',
        type: 'item-list'
      },{
        name: 'inventory_list',
        label: false
      }];

  return {
    get: (id) => {
      return $http.get(`${Data.apiUrl}/v2/move_quotes`+(id ? '/' + id : '')).then((result) => {
        return result.data;
      });
    },
    create: (id,data) => {
      return $http.post(`${Data.apiUrl}/v2/moves/${id}/add_move_quotes`,data)
        .then((result) => result.data);
    },
    update: (id,data) => {
      return $http.patch(`${Data.apiUrl}/v2/move_quotes/${id}`,data).then((result) => {
        return result.data;
      });
    },
    book: (id,data) => {
      return $http.post(`${Data.apiUrl}/v2/move_quotes/${id}/book`,data).then(r => r.data);
    },
    remove: (id) => {
      return $http.delete(`${Data.apiUrl}/v2/move_quotes/${id}`).then((result) => {
        return result.data;
      });
    },
    isValid: (quote, task) => {
      const moveDate = Field.getValue({name:'move_date',type:'date'}, task);
      return !['expired','invalid'].includes(quote.status) && !moment(moveDate).isBefore(undefined,'day');
    },
    getFormatted: (move, task, inventory, boxes) => {
      let moveDate, shortTo, shortFrom;
      let mover = Move.findCustomer(move).user;
      let data = angular.copy(quoteRequestData);
      let display = '';
      data.forEach((field) => {
        if(field.newline) return display += '\n';
        field = angular.merge(Field.get(field.name,'book-movers'),field);
        if(field.name == 'move_date') moveDate = moment(Field.getValue(field, task));
        if(field.name == 'from_address') shortFrom = AddressUtil.getFormatted(Field.getValue(field, task),{street:false,unit:false,zipcode:false});
        if(field.name == 'to_address') shortTo = AddressUtil.getFormatted(Field.getValue(field, task),{street:false,unit:false,zipcode:false});
        if(field.name == 'inventory') field.groups = inventory;
        if(field.name == 'boxes') field.items = boxes;
        display += Field.getFormatted(field,task);
      });
      return `${mover.firstname.charAt(0)}. ${mover.lastname} ${moveDate.format('M/D')} ` +
       `Move Quote: ${shortFrom} to ${shortTo}\n\n`+
       `Hello - Could you kindly provide me with a quote for the move below? `+
       `Please let me know if you have any questions. Thanks!\n\n`+
       `${display}`;
    },
    priceBreakdown: (id) => {
      return $http.get(`${Data.apiUrl}/v2/move_quotes/${id}/price_breakdown`).then((result) => {
        return result.data;
      });
    },
    modals: {
      create: (moveTask, move, options) => {
        return Modal.open(angular.merge({
          controller: 'quoteCreateModalController',
          templateUrl: '/templates/cx/quote-create-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: { moveTask: () => moveTask, move: () => move }
        },options));
      },
      viewAll: (move, options) => {
        return Modal.open(angular.merge({
          controller: 'moveQuotesModalController',
          templateUrl: '/templates/move/move-quotes-modal.html',
          resolve: { move: () => move }
        },options));
      },
      edit: (quote, move,options) => {
        return Modal.open(angular.merge({
          controller: 'quoteEditModalController',
          templateUrl: '/templates/cx/quote-edit-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: { quote: () => quote, move: () => move }
        },options));
      },
      priceBreakdown: (quote, options) => {
        return Modal.open(angular.merge({
          controller: 'priceBreakdownModalController',
          templateUrl: '/templates/move/price-breakdown-modal.html',
          backdrop: true,
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            params: () => ({ quote })
          }
        }, options));
      },
    }
  };

});
