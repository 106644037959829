angular.module('cx').factory('EmailList',
function($http,Data) {
  'ngInject';

  return {
    get: (data) => {
      return $http.get(`${Data.apiUrl}/v2/email_list`,{params:data}).then((result) => {
        return result.data;
      });
    },
    remove: (id) => {
      return $http.delete(`${Data.apiUrl}/v2/email_list/${id}`).then((result) => {
        return result.data;
      });
    }
  };

});
