angular.module('field')
.component('fieldEmail', {
  template: `
    <input type="email" name="{{$ctrl.field.name}}" id="email-{{$ctrl.uuid}}"
      ng-model="$ctrl.field.value" ng-model-options="{debounce:200}"
      ng-required="$ctrl.field.isRequired()"
      ng-disabled="$ctrl.field.isDisabled()"
      ng-change="$ctrl.field.onChange($ctrl.field.value)"
      ng-class="$ctrl.field.classes" />
    <label class="placeholder" for="text-{{$ctrl.uuid}}"
      ng-bind-html="$ctrl.field.placeholder"></label>
    <label class="error" ng-if="$ctrl.field.isRequired() &&
      $ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      Please enter a valid email address.
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function() {
    'ngInject';
    this.uuid = Date.now();
  }
});
