angular.module('core')
  .directive('fixHeight', function($timeout) {
    'ngInject';

    let css_time_to_milliseconds = (time_string) => {
      var num = parseFloat(time_string, 10),
          unit = time_string.match(/m?s/);
      if (unit) unit = unit[0];
      switch (unit) {
        case "s": return num * 1000;
        case "ms": return num;
        default: return 0;
      }
    };

    return {
      priority: 1,
      restrict: 'A',
      multiElement:true,
      link: function(scope, element, attr) {
        scope.$watch(attr.fixHeight, function(value,old) {
          let height = element[0].getBoundingClientRect().height; //unrounded
          if(value) element.css({
            height:Math.floor(height),
            minHeight:Math.floor(height),
            maxHeight:Math.floor(height)
          });
          else $timeout(() => element.css({
            height:'',
            minHeight:'',
            maxHeight:''
          }), attr.delay ? css_time_to_milliseconds(element.css('transition-duration')) : 0);
        });
      }
    };
  });
