angular.module('tasks').controller('editReviewModalController',
function ($scope, $uibModalInstance, $q, $timeout, _, User, Field, Move, Task, Transaction,
  Messages, Alerts, Modal, params) {
  'ngInject';

  $scope.updatedData = false;
  $scope.flow = angular.copy(params.task.flow.filter(screen => !screen.hideSummary));
  $scope.flow.forEach(screen => {
    screen.summary = true;
    screen.onChange = (data) => updateData(data);
  });

  $scope.screenClasses = () => {
    let classes = [params.screen];
    return classes;
  };

  $scope.$ctrl = {
    move: params.move,
    task: params.task,
  };

  $scope.update = () => {
    if($scope.loading) return;
    if(!valid($scope.$ctrl.form)) return error();
    if(!$scope.updatedData) return $uibModalInstance.dismiss();
    if(needsConfirmation()) Modal.dialog({
        title: '<img src="/images/think-2x.png" alt="Thinking Emoji"/> Hang on!',
        body: `Changing your ${needsConfirmation()} could impact your quotes.<br>How would you like to proceed?`,
        accept: 'Update quotes',
        decline: `Don't change`,
        class: 'unsaved-changes'
      }).result.then(
        () => save($scope.updatedData, true),
        angular.noop
      );
    else save($scope.updatedData);
  };

  const needsConfirmation = () => {
    let data = $scope.updatedData;
    if(!data.task && !data.delete) return false;
    let fields = [];
    if(data.task && data.task.dates && data.task.dates.find(date => date.name == 'move_date')) fields.push('move date');
    if((data.task && data.task.addresses) || data.delete) fields.push('addresses');
    if(data.task && data.task.inventories) fields.push('inventory');
    if(data.task && data.task.data && data.task.data.boxes) fields.push('boxes');
    if(data.task && data.task.data && data.task.data.professional_packing) fields.push('professional packing');
    return fields.length != 0 ? fields.join(', ') : false;
  };

  const originalTask = _.merge({},{data:{},move_task_addresses:[],move_task_dates:[],move_task_inventories:[]},params.task);
  const updateData = (data) => {
    $scope.updatedData = $scope.updatedData ? angular.merge($scope.updatedData,data) : data;
    if($scope.updatedData.task) {
      if($scope.updatedData.task.data) {
        angular.forEach($scope.updatedData.task.data, (value,key) => {
          if(angular.equals(originalTask.data[key],value)) delete $scope.updatedData.task.data[key];
        })
        if(angular.equals($scope.updatedData.task.data, {})) delete $scope.updatedData.task.data;
      }
      ['addresses','dates','inventories'].forEach(list => {
        if($scope.updatedData.task[list]) {
          if(list == 'addresses') {
            $scope.updatedData.delete = [];
            let newStops = getStops($scope.updatedData.task.addresses);
            let originalStops = getStops(originalTask.move_task_addresses);
            if(originalStops.length > newStops.length) {
              originalStops.forEach(address => {
                if(parseInt(address.name.replace('stop','')) > newStops.length)
                  $scope.updatedData.delete.push(address);
              });
            }
            if($scope.updatedData.delete.length === 0) delete $scope.updatedData.delete;
          }
          $scope.updatedData.task[list] = $scope.updatedData.task[list].filter(updated => {
            let original = originalTask[`move_task_${list}`].find(item => item.name == updated.name);
            if(list == 'addresses' && original && angular.equals(original,angular.merge({},original,updated))) return false;
            if(list == 'dates' && original && original.date == updated.date) return false;
            if(list == 'inventories' && (original && original.count == updated.count) || (!original && updated.count == 0)) return false;
            return true;
          });
          if($scope.updatedData.task[list].length === 0) delete $scope.updatedData.task[list];
        }
      });
      if(angular.equals($scope.updatedData.task, {})) delete $scope.updatedData.task;
    }
    if($scope.updatedData.user) {
      angular.forEach($scope.updatedData.user, (value,key) => {
        if(User.get()[key] == value) delete $scope.updatedData.user[key];
      });
      if(angular.equals($scope.updatedData.user, {})) delete $scope.updatedData.user;
    }
    if(angular.equals($scope.updatedData,{})) $scope.updatedData = false;
  };

  const getStops = (addressList) => {
    if(!addressList || !addressList.length) return [];
    return addressList.filter(address => address.name.includes('stop'));
  };

  const save = (data, updated) => {
    $scope.loading = true;
    let promises = [];
    if(data.user) promises.push(User.update(User.get().id,data.user));
    if(data.task || data.delete) promises.push(Task.update(params.task.id,data.task || {}));
    if(data.delete) data.delete.forEach(address => promises.push(Task.address.delete(address.id)));
    if(updated) promises.push(Messages.send({
      move: params.move.id,
      body: `I've updated my ${needsConfirmation()} and need updated quotes.`,
      auto_generated: true
    }));
    $q.all(promises).then($uibModalInstance.close,error);
  };

  const valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

  const error = () => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
  };

  $scope.close = () => {
    if($scope.loading) return;
    if($scope.updatedData)
      return Modal.dialog({
        title: '<img src="/images/think-2x.png" alt="Thinking Emoji"/> Hang on!',
        body: 'Leave without saving changes?',
        accept: 'Save Changes',
        decline: 'Leave',
        class: 'unsaved-changes'
      }).result.then(
        () => $scope.update(), // save
        () => $uibModalInstance.dismiss() // leave
      );
    else $uibModalInstance.dismiss();
  }

});
