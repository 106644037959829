angular.module('core').controller('dashboardRouterController',
function ($scope, $state, $stateParams, $window, Alerts, User, Access, Data) {
  'ngInject';

  if(Access.limit('user', () => $state.reload('dashboard'))) return;

  const onError = () => {
    Alerts.error({'msg':'Dashboard view is unavailable'});
    $state.go('account');
  }

  if(['admin','concierge'].indexOf(User.get().role) != -1) $state.go('.cx',$stateParams);
  else if(User.get().role == 'channel-partner') $state.go('.channel-partner',$stateParams);
  else if(User.get().role == 'vendor') $state.go('.vendor',$stateParams);
  else if(User.get().role == 'mover')
    User.getActiveMove().then(move => {
      if(!move) return onError();
      switch(move.app) {
        case 'storage':
        case 'resident':
        case 'consumer':
          return $window.top.location = `${Data.consumerUrl}/moves/${move.move_id}`;
        case 'direct':
        /* falls through */
        default:
          $state.go('.customer',$stateParams);
      }
    });
  else onError();

});
