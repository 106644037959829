angular.module('payment').controller('transactionFinalizeModalController',
function ($scope, $uibModalInstance, $timeout, moment, _,
Alerts, Access, User, Transaction, Vendor, Payment, move_transaction) {
  'ngInject';

  if(Access.limit('vendor', function() { $uibModalInstance.dismiss('unauthorized'); })) return;

  $scope.user = User.get();
  $scope.transaction = angular.copy(move_transaction);
  $scope.transactionFee = {};

  // Lookup any auto-quote price breakdown data that may exist for pre-population
  const getPriceBreakdown = (name) => {
    return _.get($scope.transaction,`data.price_breakdown.${name}`, 0) || 0;
  };

  const incrementTime = (time) => {
    return time ? moment(time,'h:mma').add(15,'m').format('h:mma') : false;
  };

  if($scope.transaction.is_hourly) {
    $scope.fields = [{
      name: 'start_time',
      label: 'Start Time',
      type: 'time-picker',
      appendToBody: false,
      custom: {
        interval: '15min',
        min: '5:00am',
        max: '10:00pm'
      },
      onChange: () => {
        $scope.fields.find(field => field.name == 'end_time').custom.min =
          incrementTime($scope.fields.find(field => field.name == 'start_time').value) || '6:00am';
        $scope.updateTotal();
      },
      isRequired: () => true
    },{
      name:'end_time',
      label: 'End Time',
      type: 'time-picker',
      appendToBody: false,
      custom: {
        interval: '15min',
        min: '5:15am',
        max: '11:00pm'
      },
      onChange: () => $scope.updateTotal(),
      isRequired: () => true
    },{
      name: 'total_hours',
      label: 'Total Hours',
      type: 'number',
      value: 0,
      readOnly: true
    },{
      name: 'hourly_rate',
      placeholder: 'Hourly Rate',
      type: 'currency',
      readOnly: true,
      value: $scope.transaction.rate
    },{
      name: 'additional_fees',
      label: 'Additional Fees',
      type: 'list',
      value: []
    },{
      name: 'final_price',
      placeholder: 'Final Price',
      type: 'currency',
      readOnly: true,
      value: $scope.transaction.total,
      isRequired: () => true
    }];
  } else {
    $scope.fields = [{
      name: 'volume_price',
      placeholder: 'Volume',
      type: 'currency',
      value: getPriceBreakdown('volume_price')
    },{
      name: 'travel_price',
      placeholder: 'Travel',
      type: 'currency',
      value: getPriceBreakdown('travel_price')
    },{
      name: 'tolls_price',
      placeholder: 'Tolls',
      type: 'currency',
      value: getPriceBreakdown('tolls_price')
    },{
      name: 'stairs_price',
      placeholder: 'Stairs',
      type: 'currency',
      value: getPriceBreakdown('stairs_price')
    },{
      name: 'inventory_price',
      placeholder: 'Special Items',
      type: 'currency',
      value: getPriceBreakdown('inventory_price')
    },{
      name: 'storage_price',
      placeholder: 'Storage',
      type: 'currency',
      value: getPriceBreakdown('storage_price')
    },{
      name: 'packing_price',
      placeholder: 'Packing',
      type: 'currency',
      value: $scope.transaction.data ? getPriceBreakdown(`${$scope.transaction.data.packing_option}_price`) : null,
      hidden: !$scope.transaction.data || !(['packing','none'].includes($scope.transaction.data.packing_option)),
      exclude: !$scope.transaction.data || !(['packing','none'].includes($scope.transaction.data.packing_option)),
    },{
      name: 'packing_unpacking_price',
      placeholder: 'Packing & Unpacking',
      type: 'currency',
      value: getPriceBreakdown('packing_unpacking_price'),
      hidden: !$scope.transaction.data || $scope.transaction.data.packing_option != 'packing_unpacking',
      exclude: !$scope.transaction.data || $scope.transaction.data.packing_option != 'packing_unpacking'
    },{
      name: 'final_price',
      placeholder: 'Final Price',
      type: 'currency',
      readOnly: true,
      exclude: true,
      value: $scope.transaction.total
    }];
  }

  if($scope.user.role === "concierge" && !$scope.transaction.vendor.has_stripe)
    $scope.fields.splice(($scope.fields.length - 1), 0, {
      name: 'revenue',
      placeholder: 'Revenue',
      type: 'currency',
      readOnly: false,
      exclude: true,
      value: $scope.transaction.revenue
    });

  $scope.fields.forEach(field => {
    if(field.type == 'currency' || field.type == 'percentage') {
      field.allowDecimals = true;
      field.positiveValue = true;
      field.onChange = () => $scope.updateTotal();
    }
  });
  $scope.ready = true;

  $scope.updateTotal = () => {
    let totalField = $scope.fields.find(f => f.name == 'final_price');
    if($scope.transaction.is_hourly) {
      let start_time = $scope.fields.find(f => f.name == 'start_time').value;
      let end_time = $scope.fields.find(f => f.name == 'end_time').value;
      let total_hours;
      if(start_time && end_time) total_hours = moment.duration(moment(end_time,'h:mma').diff(moment(start_time,'h:mma'))).as('hours');
      else return totalField.value = null;
      $scope.fields.find(f => f.name == 'total_hours').value = total_hours;
      let total = total_hours * $scope.fields.find(f => f.name == 'hourly_rate').value +
        $scope.fields.find(field => field.name == 'additional_fees').value.reduce((subTotal,fee) =>
          subTotal + (fee.amount.value || 0),0);
      totalField.value = Math.round(total);
    } else {
      totalField.value = $scope.fields.reduce((acc,field) => {
        if(!field.exclude) return acc + (field.value || 0);
        else return acc;
      },0);
    }
  };


  $scope.submit = () => {
    if($scope.loading) return;
    if(!valid()) return error();
    $scope.loading = true;
    if($scope.transaction.vendor.has_stripe) {
      let data = {
        total: $scope.fields.find(f => f.name == 'final_price').value,
        charge_card: $scope.transaction.charge_card || User.get().role == 'vendor'
      };
      if($scope.transaction.is_hourly) data.total_hours =
        parseFloat($scope.fields.find(f => f.name == 'total_hours').value);
      Transaction.finalize($scope.transaction.id, data)
        .then(() => {
          let data = $scope.transaction.data || {};
          data.price_breakdown = {};
          $scope.fields.forEach(field => {
            if(field.exclude) return;
            else if(field.type == 'list' && field.value.length) data.price_breakdown[field.name] = field.value.map(item =>
              ({description: item.description, amount: item.amount.value}));
            else data.price_breakdown[field.name] = field.value || 0;
            if(field.name == 'packing_price' && field.value &&
              data.packing_option != 'packing') data.packing_option = 'packing';
          });
          return Transaction.update($scope.transaction.id, {data: data});
        })
        .then(() => $timeout(() => { $uibModalInstance.close(true); }, 300),
          (resp) => {
            if(resp && resp.data && resp.data.status == 400 && resp.data.message &&
              resp.data.message.error_code == 'card_declined')
              return Payment.modals.cardDeclinedVendor();
            let message = 'Oops, something went wrong, please try again';
            if(resp && resp.data && resp.data.message) {
              if(resp.data.message == 'Vendor does not have a connected stripe account') {
                message = 'You need to set up your Stripe account first';
              }
              // This shouldn't happen in practice, since moves with
              // moveTransaction.status 'pending' are filtered out
              else if(resp.data.message == 'Mover does not have a connected stripe account') {
                message = 'This customer hasn&rsquo;t entered their payment info yet';
              }
            }
            Alerts.error({ msg: message, delay: 3 });
            error();
          });
    }
    else {
      Transaction.update($scope.transaction.id,{
        status: 'final',
        total: $scope.fields.find(f => f.name == 'final_price').value,
        revenue: $scope.fields.find(f => f.name == 'revenue').value
    }).then(() => $timeout(() => $uibModalInstance.close(true), 300),error);}

  };

  const valid = () => {
    $scope.$ctrl.form.$setSubmitted();
    return (!$scope.$ctrl.form.$invalid && $scope.fields.find(f => f.name == 'final_price').value != 0);
  };

  const error = () => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    return false;
  };

  $scope.addAdditionalFee = () => {
    let field = $scope.fields.find(field => field.name == 'additional_fees');
    field.value.push({
      description:'',
      amount:{
        name: 'amount',
        placeholder: 'Amount',
        allowDecimals: true,
        isRequired: () => true,
        onChange: $scope.updateTotal
      }
    });
  };

  $scope.removeFee = (index) => {
    $scope.fields.find(field => field.name == 'additional_fees').value.splice(index,1);
    $scope.updateTotal();
  };

});
