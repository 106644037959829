angular.module('core')
  .directive('autoResize', function($timeout) {
    'ngInject';

    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attr, ngModel) {
        // Make textarea's auto-resize
        element[0].setAttribute('style', 'overflow-y:hidden;');
        scope.$watch(attr.ngModel,update);
        element.on("focus blur keyup change paste",update);
        $timeout(update,300); // after ng-hide is removed

        function update() {
          element[0].style.height = 'auto';
          element[0].style.height = (element[0].scrollHeight) + 'px';
        }

      }
    };
  });
