angular.module('messages')
.component('messageText', {
  template: `
    <div class="message-bubble" ng-class="{'warning':$ctrl.warning}">
      <span class="message-content" ng-bind-html="::$ctrl.message.body | cleanHTML | parseUrl | emojify"></span>
      <div class="message-delete" ng-click="$ctrl.messageDelete()"
        ng-if="::$ctrl.isCXMessage">
        <span class="message-warning" ng-if="$ctrl.warning">Are you sure?</span>
        <svg-sprite source="material-icons" icon="close" alt="remove"></svg-sprite>
      </div>
    </div>
    <div class="message-timestamp">
      <span ng-bind="::$ctrl.message.sender.firstname" ng-if="::$ctrl.isCXMessage"></span>
      <span ng-bind="::$ctrl.message.created_at | amUtc | amLocal | amDateFormat: 'M/D/YYYY h:mma'"></span>
    </div>`,
  bindings: {
    message: '<',
    move: '<',
    view: '<'
  },
  controller: function($timeout,Messages,User,Alerts) {
    'ngInject';

    this.$onInit = () => {
      this.isCXMessage = this.message.sender.role == 'concierge' && this.view == 'concierge';
    };

    this.messageDelete = () => {
      if(this.disabled) return;
      if(!this.warning) {
        this.warning = true;
        this.disabled = true;
        $timeout(() => this.disabled = false, 300);
        $timeout(() => this.warning = false, 5000);
        return;
      }
      Messages.delete(this.message.id).then((result) => {}, Alerts.error);
    };


  }
});
