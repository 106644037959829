angular.module('core').component('pagination', {
  template: `
    <a class="page turn-page" ng-class="{ disabled: $ctrl.page === 1 }"
      ng-click="$ctrl.updatePage('back')">
      <svg-sprite alt="Previous" class="right-arrow"
        source="material-icons" icon="navigate_before"/>
    </a>

    <span ng-repeat="page in $ctrl.pages track by $index">
      <span class="page" ng-if="page > 1 && $ctrl.pages[$index-1] !== page-1">...</span>
      <a class="page"
        ng-class="{ active: $ctrl.page == page }"
        ng-click="$ctrl.updatePage(page)"
        ng-if="$ctrl.opts.pageCount > 1"
        ng-bind="page"></a>
    </span>

    <a class="page turn-page" ng-class="{ disabled: $ctrl.page == $ctrl.opts.pageCount }"
      ng-click="$ctrl.updatePage('next')">
      <svg-sprite alt="Next" class="right-arrow"
        source="material-icons" icon="navigate_next"/>
    </a>`,
  bindings: {
    opts: '<?options', // object of pagination options
    onPageChange: '&', // parent function to call with a page change (accepts `page`)
    page: '<?'
  },
  controller: function() {
    'ngInject';

    this.updatePage = (page) => {
      if(page == this.page) return;
      if(page === 'back') {
        if(this.page == 1) return;
        page = this.page-1;
      }
      if(page === 'next') {
        if(this.page == this.opts.pageCount) return;
        page = this.page+1;
      }
      if(this.onPageChange) {
        let promise = this.onPageChange({page:page});
        if(promise) promise.then(() => this.page = page);
      }
    };

    // initialize the component
    this.$onInit = () => {
      // default options
      this.opts = this.opts || {};
      this.opts.pageCount = this.opts.pageCount || 0;
      this.page = this.page || this.opts.initialPage || 1;
      this.buildNav();
      if(this.opts.onInit) this.opts.onInit();
    };

    // watch for changes
    this.$onChanges = (changes) => {
      if(changes.page) {
        this.page = changes.page.currentValue;
        this.buildNav();
      }
      if(this.opts.onUpdate) this.opts.onUpdate();
    };

    this.buildNav = () => {
      this.pages = [];
      if(this.opts.pageCount <= 10) Array.from({length: this.opts.pageCount},
        (v, i) => i).forEach((i) => this.pages.push(i+1));
      else {
        this.pages.push(1);
        [-3,-2,-1,0,1,2,3].forEach(i => {
          let page = this.page + i;
          if(page > 1 && page < this.opts.pageCount) this.pages.push(page);
        });
        this.pages.push(this.opts.pageCount);
      }
    }

  }
});
