angular.module('cx').controller('cxDashboardController',
function ($scope, $rootScope, $timeout, $state, $stateParams, $q, $sce,
  Alerts, User, Access, Move, MoveTag, Messages, Data, _, moment) {
  'ngInject';

  if(Access.limit('concierge', () => $state.reload())) return;

  // Moves List Controls
  const per_page = 30;
  $scope.activeMove = false;
  $scope.loadingActive = false; // set to true when selecting a move

  $scope.search = {
    loading: false,
    total: null,
    params: {},
  };

  $scope.newSearch = ({params}) => {
    if($scope.search.loading) return;
    $scope.search.params = params.active ? {
      move_id: params.active,
    } : {
      app: params.app,
      customer_status: params.status,
      tags: params.tags,
      keywords: params.search,
      sort_by: params.sort,
      sort_order: params.sortOrder,
    };
    $scope.refreshSearch();
  };

  $scope.refreshSearch = () => {
    if($scope.search.loading) return;
    $scope.moves = [];
    $scope.search.total = null;
    $scope.loadMoves();
  };

  $scope.loadMoves = () => {
    if($scope.search.loading || _.isEmpty($scope.search.params)) return;
    $scope.search.loading = true;

    if($scope.search.params.move_id) {
      return Move.searchById($scope.search.params.move_id).then(move => {
        $scope.moves = [move];
        $scope.search.total = 1;
        $scope.selectMove(move,true);
        $timeout(() => $scope.search.loading = false, 0);
      },(data, code) => {
        $scope.moves = [];
        $scope.search.total = 0;
        $scope.search.loading = false;
        Alerts.error();
      });
    }

    let searchParams = _.merge({},{
      limit: per_page,
      page: $scope.moves ? Math.floor($scope.moves.length / per_page) + 1 : 1,
    }, $scope.search.params);

    Move.search(searchParams).then(results => {
      results.data.forEach(addMove);
      $scope.search.total = results.pagination.total_items;
      // select correct activeMove
      if($scope.moves.length && // there are moves
        (!$scope.activeMove || // there isnt an existing active move
        !$scope.moves.find(move => move.id === $scope.activeMove.id)) // old active move is no longer in the results
      ) $scope.selectMove($scope.moves[0],true);
      else if (!$scope.moves.length) $scope.selectMove(false);
      $timeout(() => $scope.search.loading = false, 0);
    },(data, code) => {
      $scope.moves = [];
      $scope.search.total = 0;
      $scope.search.loading = false;
      Alerts.error();
    });

  };

  // scope function to navigate to a selected move
  $scope.selectMove = (move,immediate) => {
    if(($scope.activeMove && move && move.id === $scope.activeMove.id) || $scope.loadingActive)
      return false;
    $scope.loadingActive = true;
    if(move) $scope.hideMobilePanels(); // hide mobile panels
    $timeout(() => {
      $scope.activeMove = move;
      $scope.activeCustomer = $scope.activeMove ? Move.findCustomer($scope.activeMove).user : {};

      // Update meta data for browser tab title
      $rootScope.meta.name = $scope.activeCustomer.fullname || 'None Selected';
      $rootScope.meta.title = $scope.activeMove.move_date ? moment($scope.activeMove.move_date).format('MM-DD') : 'TBD';

      // don't start fadein in until next thread
      $timeout(() => $scope.loadingActive = false, 0);
    }, immediate ? 0 : 300); // allow 300ms for fade out
  };

  // expose utility functions
  $scope.isDirectApp = () => Move.isDirectApp($scope.activeMove);
  $scope.reactDetailsUrl = () => $sce.trustAsResourceUrl(`${Data.adminUrl}/embed/moves/${$scope.activeMove.id}`);

  // handle de-duping if sort has changed since last search
  const addMove = (move) => {
    let exists = $scope.moves.find(m => m.id === move.id);
    if(exists) exists = _.merge(exists,move);
    else $scope.moves.push(move);
  };

  /*** MOVE LIST PANEL ***/
  // classes for moves in the list

  $scope.selectedClasses = () => {
    var classes = [];
    if($scope.loadingActive) return 'loading';
    if($scope.activeMove.last_message &&
      $scope.activeCustomer.id === $scope.activeMove.last_message.sender.id)
      classes.push('unanswered');
    return classes;
  };

  $scope.addUser = () => User.modals.create().result.then((moveId) => {
    if(moveId) {
      $timeout(()=> $scope.newSearch({params:{active:moveId}}),500);
    }
  }).catch(angular.noop);

  /*** MOVE LIST ***/

  const updateMoveData = (event, id) => {
    let exists = $scope.moves.find(move => move.id === id);
    if(exists) Move.searchById(id).then(move => _.merge(exists,move));
  }
  $scope.$on('cx.move_list',updateMoveData);

  // mobile panels
  $scope.showMobileThreads = () => {
    $scope.mobileDetails = false;
    $scope.mobileThreads = true;
  };
  $scope.showMobileDetails = () => {
    $scope.mobileDetails = true;
    $scope.mobileThreads = false;
  };
  $scope.hideMobilePanels = () => {
    $scope.mobileDetails = false;
    $scope.mobileThreads = false;
  };

});
