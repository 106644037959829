angular.module('tasks').component('screenSpecialItems', {
  templateUrl: '/templates/tasks/screen-data.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,Move,Task,Field) {
    'ngInject';

    let itemsField = {
      type: 'checklist',
      list: [],
      value: []
    };

    this.$onInit = () => {
      this.screen.fields = [itemsField];
      itemsField.onChange = () => {
        if(this.screen.onChange) this.screen.onChange(buildData());
      };
      Task.getItemList(this.screen.model).then((sections) => {
        sections.sort((a,b) => a.order - b.order);
        sections.forEach((section) => {
          section.items.sort((a,b) => a.order - b.order);
          section.items.filter(item => item.has_vendor_support).forEach((item) => {
            itemsField.list.push(angular.merge({},item,{value:item.name}));
            if(Field.getValue(angular.merge({parent:'inventory',type:'integer'},item),this.task))
              itemsField.value.push(item.name);
          });
        });
        this.ready = true;
      }).catch(error);
    };

    this.next = () => {
      if(this.loading) return;
      this.loading = true;
      if(!valid(this.form)) return error();
      this.onNext({callback:this.save}).then(() => this.loading = false, error);
    };

    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      data = data || {};
      itemsField.list.forEach(item => {
        Field.buildSaveData({
          name: item.name,
          value: itemsField.value.includes(item.value) ? 1 : 0,
          type: 'inventory'
        },data);
      });
      return data;
    };

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }
});
