angular.module('move').constant('TaskFlow', {

  /* INTERNAL TASKS */
  'add-task': {
    flows: {
      default: [
        { name: 'add-task' },
      ]
    },
    screens: {
      'add-task': {
        title: 'What do you need help with?',
        editTitle: 'What do you need help with?',
        type: 'add-task',
        name: 'add-task'
      }
    }
  },

  /* DEPRECATED TASKS */
  // TODO: in order to use any of these they need 'review', 'complete' screens

  donate: {
    flows: {
      default: [
        { name: 'donate_title' },
        { name: 'donate_description' },
        { name: 'donate_pickup' },
        { name: 'donate_photos' },
      ]
    },
    screens: {
      donate_title: {
        title: 'What are you looking to donate?',
        editTitle: 'What are you looking to donate?',
        subtitle: 'Note: Free donation pickups are often in high demand and should be scheduled at least 3-4 weeks ahead of time.',
        type: 'custom',
        name: 'donate_title',
        label: 'Items',
        editable: true,
        fields: [{
          name: 'title',
          type: 'text',
          label: 'Items',
          placeholder: 'List items',
          required: true
        }]
      },

      donate_description: {
        title: 'Great. Provide a short description for the donation.',
        editTitle: 'Provide a short description for the donation.',
        type: 'custom',
        name: 'donate_description',
        label: 'Description',
        editable: true,
        fields: [{
          name: 'description',
          type: 'text',
          label: 'Description',
          placeholder: 'Items included, condition, etc.',
          required: true
        }]
      },

      donate_pickup: {
        title: 'And when would you like to schedule a pickup?',
        editTitle: 'When would you like to schedule a pickup?',
        type: 'custom',
        name: 'donate_pickup',
        label: 'Pickup Date',
        editable: true,
        fields: [{
          name: 'pickup_date',
          type: 'date',
          label: 'Pickup Date',
          required: true,
          custom: { limit: 'future' }
        }]
      },

      donate_photos: {
        title: 'Thanks. Finally, add any photos you\'d like to include for the donation',
        editTitle: 'Add any photos you\'d like to include for the donation',
        type: 'custom',
        name: 'donate_photos',
        label: 'Photos',
        editable: true,
        skip: {
          button: 'Add Later'
        },
        fields: [{
          name: 'photos',
          type: 'photos',
          category: 'donation',
          label: 'Add Photos',
          required: true
        }]
      }
    }
  },

  dispose: {
    flows: {
      default: [
        { name: 'dispose_title' },
        { name: 'dispose_description' },
        { name: 'dispose_pickup' },
        { name: 'dispose_photos' },
      ]
    },
    screens: {

      dispose_title: {
        title: 'What are you looking to have removed?',
        editTitle: 'What are you looking to have removed?',
        type: 'custom',
        name: 'dispose_title',
        label: 'Items',
        editable: true,
        fields: [{
          name: 'title',
          type: 'text',
          label: 'Item',
          placeholder: 'Item name',
          required: true
        }]
      },

      dispose_description:{
        title: 'Great. Provide a short description for the item.',
        editTitle: 'Provide a short description for the item.',
        type: 'custom',
        name: 'dispose_description',
        label: 'Description',
        editable: true,
        fields: [{
          name: 'description',
          type: 'text',
          label: 'Description',
          placeholder: 'Size, dimensions, disassembly required, etc.',
          required: true
        }]
      },

      dispose_pickup: {
        title: 'And when would you like to have it removed?',
        editTitle: 'When would you like to have it removed?',
        type: 'custom',
        name: 'dispose_pickup',
        label: 'Pickup Date',
        editable: true,
        fields: [{
          name: 'pickup_date',
          type: 'date',
          label: 'Pickup Date',
          required: true,
          custom: { limit: 'future' }
        }]
      },

      dispose_photos: {
        title: 'Thanks. Finally, add any photos you\'d like to include for the removal',
        editTitle: 'Add any photos you\'d like to include for the removal',
        type: 'custom',
        name: 'dispose_photos',
        label: 'Photos',
        editable: true,
        skip: {
          button: 'Add Later'
        },
        fields: [{
          name: 'photos',
          type: 'photos',
          category: 'dispose',
          label: 'Add Photos',
          required: true
        }]
      }
    }
  },

  sell: {
    flows: {
      default: [
        { name: 'sell_title' },
        { name: 'sell_description' },
        { name: 'sell_start_price' },
        { name: 'sell_lowest_price' },
        { name: 'sell_photos' },
      ]
    },
    screens: {

      sell_title: {
        type: 'custom',
        title: 'What are you looking to sell?',
        editTitle: 'What are you looking to sell?',
        subtitle: `For any item you sell through Moved, there is a<br>
          10% service fee taken directly from the sale.`,
        name: 'sell_title',
        label: 'Title',
        editable: true,
        fields: [{
          name: 'title',
          type: 'text',
          label: 'Item',
          placeholder: 'Item name',
          required: true
        }]
      },

      sell_description: {
        title: 'Great. Provide a short description for the item.',
        editTitle: 'Provide a short description for the item.',
        type: 'custom',
        name: 'sell_description',
        label: 'Description',
        editable: true,
        fields: [{
          name: 'description',
          type: 'text',
          label: 'Description',
          placeholder: 'Condition, dimensions, make, model, age, etc.',
          required: true
        }]
      },

      sell_start_price: {
        title: 'Got it! What price should this start at?',
        editTitle: 'What price should this start at?',
        type: 'custom',
        name: 'sell_start_price',
        label: 'Start Price',
        editable: true,
        fields: [{
          name: 'starting_price',
          type: 'currency',
          label: 'Starting Price',
          placeholder: 'Starting Price',
          required: true
        }]
      },

      sell_lowest_price: {
        title: 'And what\'s the lowest price you\'ll take?',
        editTitle: 'What\'s the lowest price you\'ll take?',
        type: 'custom',
        name: 'sell_lowest_price',
        label: 'Lowest Price',
        editable: true,
        fields: [{
          name: 'lowest_price',
          type: 'currency',
          label: 'Lowest Price',
          placeholder: 'Lowest Price',
          required: true
        }]
      },

      sell_photos: {
        title: 'Thanks. Finally, add any photos for potential buyers to see.',
        editTitle: 'Add any photos for potential buyers to see.',
        type: 'custom',
        name: 'sell_photos',
        label: 'Photos',
        editable: true,
        skip: {
          button: 'Add Later'
        },
        fields: [{
          name: 'photos',
          type: 'photos',
          category: 'sell',
          label: 'Add Photos',
          required: true
        }]
      }
    }
  },

  paint: {
    flows: {
      default: [
        { name: 'paint_description' },
      ]
    },
    screens: {
      paint_description: {
        type: 'custom',
        name: 'paint_description',
        label: 'Description',
        editable: true,
        fields: [{
          name: 'description',
          type: 'text',
          label: 'Description',
          placeholder: 'Describe what you need',
          required: true
        }]
      }
    }
  },

  furnishings: {
    flows: {
      default: [
        { name: 'furnishings_description' },
      ]
    },
    screens: {
      furnishings_description: {
        type: 'custom',
        name: 'furnishings_description',
        label: 'Description',
        editable: true,
        fields: [{
          name: 'description',
          type: 'text',
          label: 'Description',
          placeholder: 'Describe what you need',
          required: true
        }]
      }
    }
  },

  custom: {
    flows: {
      default: [
        { name: 'custom' },
      ]
    },
    screens: {
      'custom': {
        type: 'custom',
        name: 'custom',
        label: 'Custom',
        editable: true,
        fields: [{
          name: 'label',
          type: 'text',
          label: 'Title',
          placeholder: 'Title',
          required: true
        },{
          name: 'description',
          type: 'text',
          label: 'Description',
          placeholder: 'Describe what you need',
          required: true
        }]
      }
    }
  },

});
