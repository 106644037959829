angular.module('cx').controller('activeMoveController',
function ($scope, $state, $stateParams, Alerts, Access, Move) {
  'ngInject';

  if(Access.limit('concierge', () => $state.reload())) return;

  $scope.activeMove = false;
  $scope.loadingActive = true;
  $scope.isDirectApp = true;
  $scope.embed = true;

  this.$onInit = () => {
    Move.get($stateParams.id).then(move => {
      $scope.activeMove = move;
      $scope.activeCustomer = $scope.activeMove ? Move.findCustomer($scope.activeMove).user : {};
      $scope.isDirectApp = $scope.activeMove && Move.isDirectApp($scope.activeMove);
      $scope.loadingActive = false;
    },(data, code) => {
      $scope.loadingActive = false;
      Alerts.error();
    });
  };

});
