angular.module('user').factory('Facebook',
function($q, $cookies, $timeout, $window) {
  'ngInject';

  function facebookLogin(promise,retry) {
    var scopes = {
      scope:'email',
      return_scopes:true
    };
    if(retry||$cookies.get('facebook_rerequest'))
      angular.extend(scopes,{auth_type:'rerequest'});
    $window.FB.login((response) => {
      if(response.authResponse) {
        if(response.authResponse.grantedScopes.indexOf('email')>=0){
          $cookies.remove('facebook_rerequest');
          promise.resolve(response);
        } else {
          $cookies.put('facebook_rerequest',true);
          promise.reject('email');
        }
      } else {
        promise.reject('declined');
      }
    },scopes);
  }

  function emailPermission(permissions) {
    return $.map(permissions, (item) => {
      return item.permission == 'email' ?
        (item.status == 'granted' ? true : false) : null;
    })[0];
  }

  function emailGranted() {
    var promise = $q.defer();
    $window.FB.api('/me/permissions', (response) => {
      if (!response || response.error) {
        promise.reject('Error occured');
      } else {
        promise.resolve(emailPermission(response.data));
      }
    });
    return promise.promise;
  }

  const API = {
    getInfo: (promise, attempt) => {
      promise = promise || $q.defer();
      if(attempt >= 50) return promise.reject('fb error');
      if(!$window.FB) $timeout(() => API.getInfo(promise, attempt ? attempt+1 : 1),100);
      else $window.FB.getLoginStatus((response) => {
        if(response.status==='connected'){
          $window.FB.api('/me', {}, (response) => {
            if (!response || response.error) {
              promise.reject('Error occured');
            } else {
              promise.resolve(response);
            }
          });
        }
      });
      return promise.promise;
    },
    login: (promise,attempt) => {
      promise = promise || $q.defer();
      if(attempt >= 50) return promise.reject('fb error');
      if(!$window.FB) $timeout(() => API.login(promise, attempt ? attempt+1 : 1),100);
      else $window.FB.getLoginStatus((response) => {
        if(response.status==='connected' && !$cookies.get('facebook_rerequest')) {
          emailGranted().then((granted) => {
            if(granted){
              $cookies.remove('facebook_rerequest');
              promise.resolve(response);
            } else {
              $cookies.put('facebook_rerequest',true);
              facebookLogin(promise);
            }
          });
        } else {
          facebookLogin(promise);
        }
      });
      return promise.promise;
    }
  };

  return API;

});
