angular.module('tasks').component('screenAddTask', {
  templateUrl: '/templates/tasks/screen-add-task.html',
  bindings: {
    move: '<',
    task: '<',
    form: '<',
    screen: '<',
    onNext: '&'
  },
  controller: function($timeout,User,Task,Move) {
    'ngInject';

    let allowCXAddition = ['book-movers','storage','packing-materials','update-address','clean'];

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      Task.getList().then((taskList) => {
        this.tasks = taskList.filter((task) => {
          if(allowCXAddition.includes(task.name) && User.get().role == 'concierge') return true;
          if(task.is_hidden) return false;
          return !task.is_unique || !Move.findMoveTasks(task.name,this.move).length;
        });
      }).catch(error);
    };

    this.selected = [];
    this.selectTask = (task) => {
      if(this.task.task.multiple) {
        let exists = this.selected.find(id => id == task.id);
        if(exists) this.selected = this.selected.filter(id => id !== task.id);
        else this.selected.push(task.id);
      }
      else this.selected = [task.id];
    };

    this.taskClasses = (task) => {
      let classes = [];
      if(this.selected.indexOf(task.id) != -1) classes.push('selected');
      return classes;
    };

    this.next = () => {
      if(this.loading) return;
      this.loading = true;
      if(!this.selected.length) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = () => {
      return Task.add(this.move.id,this.selected);
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => this.error = false, 300);
    };

  }

});
