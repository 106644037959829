angular.module('user').factory('Token',
  function($http, $q, Data, Modal) {
    'ngInject';

    return {

      create: (data) => {
        return $http.post(`${Data.apiV1Url}/v1/email_tokens`,data).then((result) => {
          return result.data.data[0];
        });
      },

      verify: (token,data) => {
        let params = data ? '?' + jQuery.param(data) : '';
        return $http.get(`${Data.apiV1Url}/v1/email_tokens/${token}${params}`).then((result) => {
          return result.data.data[0];
        });
      },

      inviteVendor: (id,data) => {
        return $http.post(`${Data.apiUrl}/v2/vendors/${id}/invite`,data).then((result) => {
          return result.data;
        });
      },

      decode: (token) => JSON.parse(atob(token.split('.')[0])),

      modals: {

        inviteMover: (options) => {
          options = options || {};
          return Modal.open(angular.merge({
            controller: 'inviteMoverModalController',
            templateUrl: '/templates/partner/invite-mover-modal.html'
          },options));
        }

      }

    };

  });
