angular.module('messages', [
  'ui.router',
  'ui.bootstrap',
  'ngSanitize',
  'ngAnimate',
  'angularMoment',
  'pubnub.angular.service',
  'ngFileUpload',
  'user'
]);
