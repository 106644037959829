angular.module('payment').factory('Payment',
function($http, $q, $window, Modal, Data, StripeCheckout, Script) {
  'ngInject';

  // stripe library load
  let stripePromise = false;
  const loadStripe = () => {
    return Script.load('https://checkout.stripe.com/checkout.js').then(() => {
      return StripeCheckout.configure({
        key: Data.stripePubKey,
        image: `${Data.webUrl}/images/icons/apple-touch-icon.png`,
        locale: 'auto',
        zipCode: true,
        allowRememberMe: false
      });
    });
  };

  // affirm library load
  let affirmPromise = false;
  const loadAffirm = () => {
    $window._affirm_config = {
      public_api_key: Data.affirmAPIKey
    };
    return Script.load(Data.affirmJSSDK).then(() => $window.affirm);
  }

  return {
    /* STRIPE FUNCTIONS */
    addCard: (data, config) => {
      return $http.post(`${Data.apiUrl}/v2/users/me/add_card`, data, config)
        .then((result) => result.data);
    },
    addCardExternal: (id, data, config) => {
      return $http.post(`${Data.apiUrl}/v2/users/${id}/add_card`, data, config)
        .then((result) => result.data);
    },
    charge: (data) => {
      return $http.post(`${Data.apiUrl}/v2/users/me/charge`, data)
        .then((result) => result.data);
    },
    authorize: (data) => {
      return $http.post(`${Data.apiUrl}/v2/users/me/book`, data)
        .then((result) => result.data);
    },
    finalize: (id, data) => {
      return $http.post(`${Data.apiUrl}/v2/move_transactions/${id}/finalize`, data)
        .then((result) => result.data);
    },
    validateToken: (data) => {
      return $http.post(`${Data.apiUrl}/v2/users/validate_payment_token`, data)
        .then((result) => result.data);
    },
    taskOrder: (id,data) => {
      return $http.post(`${Data.apiUrl}/v2/move_tasks/${id}/order`, data)
        .then((result) => result.data);
    },
    getStripeCheckout: () => {
      if(stripePromise) return stripePromise.promise;
      stripePromise = $q.defer();
      loadStripe().then((stripeCheckout) => {
        stripePromise.resolve(stripeCheckout);
      });
      return stripePromise.promise;
    },
    /* AFFIRM FUNCTIONS */
    affirmAuthorize: (data) => {
      return $http.post(`${Data.apiUrl}/v2/affirm_payment_details/checkout_token`, data)
        .then((result) => result.data);
    },
    getAffirmCheckout: () => {
      if(affirmPromise) return affirmPromise.promise;
      affirmPromise = $q.defer();
      loadAffirm().then((affirm) => affirmPromise.resolve(affirm.checkout));
      return affirmPromise.promise;
    },
    modals: {
      link: (move_id, options) => {
        return Modal.open(angular.merge({
          controller: 'paymentLinkModalController',
          templateUrl: '/templates/payment/payment-link-modal.html',
          resolve: { moveId: () => move_id }
        },options));
      },
      stripeConnect: (vendor, markAsDelivered, options) => {
        markAsDelivered = markAsDelivered || false;
        return Modal.open(angular.merge({
          controller: 'stripeConnectModalController',
          templateUrl: '/templates/payment/stripe-connect-modal.html',
          windowClass: 'bounce',
          overlay:true,
          resolve: {
            vendor: () => vendor,
            markAsDelivered: () => markAsDelivered
          }
        },options));
      },
      intro: (data, options) => {
        return Modal.open(angular.merge({
          controller: 'introModalController',
          templateUrl: '/templates/payment/intro-modal.html',
          windowClass: 'bounce',
          overlay:true,
          resolve: { data: () => data }
        },options));
      },
      cardDeclinedVendor: (data, options) => {
        return Modal.open(angular.merge({
          templateUrl: '/templates/payment/card-declined-vendor-modal.html',
          windowClass: 'bounce',
          overlay:true,
          resolve: { data: () => data }
        },options));

      },
      bookingTerms: (paymentObject /* transaction or quote */, move, options) => {
        return Modal.open(angular.merge({
          controller: 'bookingTermsModalController',
          templateUrl: '/templates/payment/booking-terms-modal.html',
          windowClass: 'bounce',
          overlay: true,
          resolve: {
            data: () => ({ paymentObject, move })
          }
        }, options));
      }
    }
  };

});
