angular.module('tasks').component('screenTitle', {
  template: `
  <div class="title">
    <div ng-if="$ctrl.screen.title" ng-bind-html="$ctrl.screen.title" compile></div>
    <div class="subtitle" ng-if="$ctrl.screen.subtitle">
      <span ng-bind-html="$ctrl.screen.subtitle" compile></span>
      <svg-sprite alt="info" source="material-icons" icon="info_outline"
        class="tooltip-icon" ng-if="$ctrl.screen.subtitleTooltip"
        uib-tooltip="{{$ctrl.screen.subtitleTooltip}}"></svg-sprite>
    </div>
  </div>
  `,
  bindings: {
    screen: '<',
  },
  controller: function() {}
});
