angular.module('cx').controller('quoteEditModalController',
function ($scope, $rootScope, $timeout, $uibModalInstance,
  Messages, User, Access, Move, MoveQuote, Task, Transaction, quote, move) {
  'ngInject';

  if(Access.limit('concierge', function() { $uibModalInstance.dismiss('unauthorized'); })) return;

  $scope.quote = angular.copy(quote);
  if($scope.quote.price) $scope.quote.cost = $scope.quote.price / 100;
  if($scope.quote.rate) $scope.quote.cost = $scope.quote.rate / 100;
  if($scope.quote.minimum) $scope.quote.minimum = $scope.quote.minimum / 100;
  $scope.vendorOptions = {initial:$scope.quote.vendor_name || $scope.quote.company};

  $scope.moveTask = Move.findMoveTask($scope.quote.move_task.id,move);

  $scope.sendMessage = false;

  Transaction.getTypes().then(transactions => {
    $scope.transactions = transactions;
    $scope.moveTransaction = transactions.find(t => t.name == 'move');
  });

  $scope.isAffirmEligible = () => {
    return !$scope.quote.is_hourly && ($scope.quote.vendor && $scope.quote.vendor.has_stripe) &&
      $scope.moveTransaction && $scope.quote.transaction.id == $scope.moveTransaction.id;
  }

  function valid() {
    $scope.quoteWidget.$setSubmitted();
    return !$scope.quoteWidget.$invalid;
  }

  // scope function to submit the update
  $scope.update = function() {
    if($scope.loading) return;
    $scope.loading = true;
    if(!valid()) return error();
    let data = {
      transaction: $scope.quote.transaction.id,
      company: $scope.quote.vendor.name,
      vendor: $scope.quote.vendor.id,
      description: $scope.quote.description,
      is_hourly: $scope.quote.is_hourly || false,
      price: 0,
      rate: 0,
      affirm_enabled: $scope.isAffirmEligible($scope.quote) ?
        $scope.quote.affirm_enabled : false
    };
    if($scope.quote.is_hourly) {
      data.rate = parseInt(parseFloat($scope.quote.cost) * 100);
      data.minimum = parseInt(parseFloat($scope.quote.minimum) * 100) || 0;
    }
    else data.price = parseInt(parseFloat($scope.quote.cost) * 100);
    if($scope.quote.link) data.link = $scope.quote.link;
    MoveQuote.update($scope.quote.id, data).then((result) => {
      if($scope.sendMessage) {
        let data = { move: $scope.quote.move.id };

        if($scope.moveTask.task.name == 'book-movers' && !$scope.sendClassicMessage) {
          let firstMessage = angular.extend({},data,{
            body: `Hey ${Move.findCustomer(move).user.firstname}!` +
              ` Your quotes are available. Click below to check them out.`,
            auto_generated: true
          });
          return Messages.send(firstMessage).then((message) => {
            let secondMessage = angular.extend({}, data, {
              type: 5,
              data: { move_task: $scope.moveTask.id },
              auto_generated: true
            });
            return Messages.send(secondMessage).then((message) => {
              $scope.loading = false;
              $uibModalInstance.close(message);
            });
          });
        } else {
          angular.extend(data, {
            type: 3,
            data: {
              quotes: { quote_id: $scope.quote.id }
            },
            auto_generated: true
          });
          return Messages.send(data).then((message) => {
            $scope.loading = false;
            $uibModalInstance.close(message);
          });
        }
      }
      else {
        $scope.loading = false;
        $uibModalInstance.close($scope.quote);
      }
    }, error);
  };

  $scope.setVendor = (vendor) => {
    $scope.quote.vendor = vendor;
    $scope.quote.description = $scope.quote.description || vendor.default_description;
    $scope.quote.link = $scope.quote.link || vendor.review_link;
  }

  let error = () => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
  };

});
