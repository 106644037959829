// declare module routes
angular.module('cx').config(function($stateProvider,Data) {
  'ngInject';

  $stateProvider
    .state('dashboard.cx', {
      views: {
        'layout@': {
          templateUrl: '/templates/core/layout-panels.html'
        },
        'nav@dashboard.cx': {
          templateUrl: '/templates/cx/nav.html',
          controller: 'adminNavController'
        },
        'main@dashboard.cx': {
          templateUrl: '/templates/cx/dashboard.html',
          controller: 'cxDashboardController'
        }
      }
    })
    .state('admin', {
      parent: 'layout-panels',
      abstract: true,
      views: {
        'nav': {
          templateUrl: '/templates/cx/nav.html',
          controller: 'adminNavController'
        }
      },
      data: {
        name: 'Admin'
      }
    })
    .state('admin.vendors', {
      url: '/admin/vendors',
      views: {
        'main@layout-panels': {
          templateUrl: '/templates/cx/admin-vendors.html',
          controller: 'adminVendorsController'
        }
      },
      data: {
        name: 'Vendors List'
      }
    })
    .state('adminAbode', {
      url: `${Data.adminUrl}/admin/properties`,
      external: true
    })
    .state('reactDash', {
      url: `${Data.adminUrl}/cx/moves`,
      external: true,
      params: {
        direct: null,
      },
    })
    .state('activeMove', {
      parent: 'layout-full',
      url: '/admin/moves/{id:[0-9]+}',
      views: {
        'main': {
          templateUrl: '/templates/cx/active-move.html',
          controller: 'activeMoveController'
        }
      }
    });

});
