angular.module('tasks').component('screenCards', {
  templateUrl: '/templates/tasks/screen-cards.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&',
    onClose: '&'
  },
  controller: function($timeout,User,Task,Move,Field) {
    'ngInject';

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      if(this.screen.fieldName) this.screen.value = Field.getValue(Field.get(this.screen.fieldName,this.task.task.name),this.task) || this.screen.default;
    };

    this.cardClasses = (card) => {
      let classList = [card.class];
      if(this.screen.value == card.value) classList.push('selected');
      return classList;
    }

    this.onClick = (value) => {
      this.screen.value = value;
      if(this.screen.onChange) this.screen.onChange(buildData());
      if(!this.screen.summary) this.next(value);
    }

    this.next = (value) => {
      if(this.loading) return;
      this.loading = true;
      this.onNext({callback:this.save}).then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      data = data || {};
      Field.buildSaveData({ name: this.screen.fieldName, value: this.screen.value }, data);
      return data;
    };

    const error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

  }
});
