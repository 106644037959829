angular.module('move').component('quotesList', {
  templateUrl: '/templates/move/quotes-list.html',
  bindings: {
    quotes: '<',
    move: '<',
    task: '<',
    deadline: '<',
    onBook: '&',
    startBook: '&',
    cancelBook: '&'
  },
  controller: function($rootScope, $timeout, $q, $window, _,
    Data, User, MoveQuote, Payment, Transaction, Alerts, Field, AddressUtil, Events, Move) {
    'ngInject';

    this.affirmLogo = `${Data.s3AssetUrl}/affirm-logo-2x.svg`;
    this.user = User.get();
    $rootScope.$on('userUpdated', () => this.user = User.get());

    this.$onInit = () => {
      // Create event that quotes have been viewed
      if(User.get().role == 'mover') Events.create({
        name: 'quotes-viewed',
        move_id: this.move.id,
        time_stamp: new Date(),
        data: { move_quotes: this.quotes.map(q => q.id) }
      });
    };

    this.toggleQuote = (quote) => {
      if(['invalid','expired'].includes(quote.status)) return;
      if(!quote.active) this.quotes.filter(q => q.active).forEach(q => q.active = false);
      quote.active = !quote.active;
    };

    this.quoteClasses = (quote) => {
      let classes = [];
      if(quote.booked) classes.push('booked');
      else {
        if(new Date(quote.created_at) > Date.now() - 24*60*60*1000) classes.push('new');
        else if(new Date(quote.updated_at) > Date.now() - 24*60*60*1000) classes.push('updated');
        if(User.get().role == 'concierge') classes.push('editable');
        if(!MoveQuote.isValid(quote,this.task)) classes.push('invalid'); // handles expired because past move_date
        if(quote.status) classes.push(quote.status);
      }
      if(quote.active) classes.push('active');
      return classes;
    };

    if(User.get().role == 'concierge') {
      this.edit = (index, $event) => {
        $event.stopPropagation();
        MoveQuote.modals.edit(this.quotes[index],this.move);
      };

      this.delete = (index, $event) => {
        $event.stopPropagation();
        MoveQuote.remove(this.quotes[index].id).then((result) => {
          this.quotes.splice(index,1);
        },error);
      };

      this.book = (quote, $event) => {
        $event.stopPropagation();
        return false;
      };

      this.showPriceBreakdown = (quote) => (quote.data && quote.data.price_breakdown);

      this.ready = true;
    }

    if(User.get().role == 'mover') {
      this.book = (quote, $event) => {
        $event.stopPropagation();
        if(this.loading || !MoveQuote.isValid(quote,this.task) || this.deadline.isBefore()) return;
        this.loading = true;
        if(this.startBook) this.startBook();

        // manual booking (no payment method)
        if(!quote.vendor_has_stripe) return Transaction.modals.confirm(quote)
          .result.then(() => bookQuote(quote), () => error());

        // affirm booking selected
        if(this.selectedPayment == 'affirm') return affirmCheckout(quote).then(
          (affirmData) => bookQuote(quote, affirmData),
          () => {
            Alerts.error({msg:`There was an issue authorizing your Affirm loan.
              Please check out again or use a different payment method.`});
            error();
          }
        );

        // stripe booking
        let data = {
          type: 'Book Mover',
          amount: quote.is_hourly ? quote.rate : quote.price,
          is_hourly: quote.is_hourly,
          minimum: quote.minimum,
          move_transaction: {
            vendor: {
              id: quote.vendor_id,
              name: quote.vendor_name,
              has_stripe: quote.vendor_has_stripe,
              terms: quote.vendor_terms_url
            }
          }
        };
        Payment.modals.intro(data).result.then(result => {
          if(result == 'stripe') stripeCheckout(quote).then(
            (result) => {
              // if replacing card
              if(User.get().has_stripe) Payment.addCard({ source: result[0].id })
                .then(() => {
                  User.refresh();
                  bookQuote(quote);
                },error);
              // if adding first card
              else bookQuote(quote,{source:result[0].id});
            },
            (result) => error()
          );
          // if booking with existing card
          else if(result == 'continue') bookQuote(quote);
        }, error);
      };

      this.loading = true;
      const providers = {
        stripe: Payment.getStripeCheckout(),
        affirm: Payment.getAffirmCheckout()
      };
      $q.all(providers).then(checkouts => {
        this.stripeCheckout = checkouts.stripe;
        this.affirmCheckout = checkouts.affirm;
        this.loading = false;
        this.ready = true;
        updateAffirmUI();
        $window.affirm.ui.ready(() => $window.affirm.ui.error.on("close", () => {
          Alerts.error({msg:`There was an issue authorizing your Affirm loan.
                Please check out again or use a different payment method.`});
          error();
        }));
      });

      const updateAffirmUI = () => {
        this.lowestPrice = false;
        this.quotes.forEach(quote => {
          if(!quote.is_hourly && quote.affirm_enabled) this.lowestPrice = this.lowestPrice ?
            Math.min(this.lowestPrice, quote.price) : quote.price;
        });
        $timeout($window.affirm.ui.refresh,0);
      };
      // listen for move.quote broadcast
      $rootScope.$on('move.quote',() => $timeout(updateAffirmUI,100));

      const affirmCheckout = (quote) => {
        // build the checkout data
        let address = Field.getValue(Field.get('to_address','book-movers'),
          Move.findMoveTask(quote.move_task.id,this.move));
        let options = {
          total: quote.price, // cents integer
          merchant: {
            user_confirmation_url: `${Data.appUrl}/affirm/confirm`, // not used but req'd
            user_cancel_url: `${Data.appUrl}/affirm/cancel` // not used but req'd
          },
          shipping:{
            name:{
              first: User.get().firstname,
              last: User.get().lastname
            },
            address:{
              line1: address.street,
              line2: address.unit,
              city: address.city,
              state: address.state,
              zipcode: address.zipcode
            },
            phone_number: User.get().phone,
            email: User.get().email
          },
          items: [{
            display_name: 'Book a Mover',
            sku: `book-${quote.vendor.id}`,
            unit_price: quote.price,
            qty: 1
          }],
          order_id: `${quote.move.id}-${quote.move_task.id}`,
          shipping_amount: 0,
          tax_amount: 0,
          metadata: {
            mode: 'modal'
          }
        };
        let deferred = $q.defer();
        // trigger affirm checkout flow
        this.affirmCheckout(options).open({
          onSuccess: (response) => deferred.resolve({
            affirm: true,
            checkout_token: response.checkout_token,
            order_id: options.order_id
          }),
          onFail: deferred.reject
        });
        return deferred.promise;
      };

      // trigger stripe checkout UI function
      const stripeCheckout = (quote) => {
        // trigger stripe payment overlay
        return this.stripeCheckout.open({
          name: quote.vendor_name,
          description: 'Book Mover',
          email: User.get().email,
          amount: 0,
          panelLabel: 'Authorize'
        });
      };

      // generic book quote request for all payment methods
      const bookQuote = (quote, data) =>
        MoveQuote.book(quote.id, data).then((moveTransaction) => {
          this.loading = false;
          if(this.onBook) this.onBook({
            moveTransaction:moveTransaction,
            quote:quote
          });
        }, error);

      this.showPriceBreakdown = (quote) => false;

    }

    // error handler
    const error = (result) => {
      if(this.cancelBook) this.cancelBook();
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);

      if(!result) return;

      if(result.data && result.data.error && result.data.error.message)
        return Alerts.error({msg:result.data.error.message});

      else if(result.data && result.data.message && result.data.message.error_code == 'card_declined')
        return Alerts.error({msg:`Uh Oh. There was a problem processing this card.
            Please try again, or use a different payment method.`});

      else if(result.data && result.data.message)
        return Alerts.error({
          msg: result.data.message
        });

      return Alerts.error();
    }

    this.hasValidQuote = () => {
      return this.quotes.filter(quote => MoveQuote.isValid(quote,this.task)).length;
    };
    this.quoteIsValid = (quote) => MoveQuote.isValid(quote,this.task);

    this.stopPropagation = ($event) => $event.stopPropagation();

    this.isMoveDateSoon = () => {
      const move_date = moment(_.get(_.get(this.task, 'move_task_dates', []).find(date => date.name === 'move_date'),'date'));
      const soonCutoff = move_date.clone().subtract(3,'d');
      return soonCutoff.isBefore();
    };

    // brings up the modal for full price breakdown display
    this.priceBreakdownModal = (quote,$event) => {
      $event.stopPropagation();
      MoveQuote.modals.priceBreakdown(quote,{overlay:true});
    };
  }
});
