angular.module('field').component('fieldDate', {
  template: `
    <md-datepicker ng-disabled="$ctrl.field.isDisabled() || $ctrl.field.isReadonly()"
      name="{{$ctrl.field.name}}" id="date-{{$ctrl.field.name}}"
      md-placeholder="{{$ctrl.field.placeholder}}" ng-model="$ctrl.field.value"
      md-open-on-focus="true" md-min-date="$ctrl.minDate" md-max-date="$ctrl.maxDate"
      ng-change="$ctrl.field.onChange()" ng-model-options="$ctrl.options"
      ng-required="$ctrl.field.isRequired()"
      md-hide-icons="triangle"
      md-date-filter="$ctrl.dateFilter"></md-datepicker>
    <label class="error" ng-messages="$ctrl.form[$ctrl.field.name].$error"
      ng-if="$ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      <span ng-message="required">Required.</span>
      <span ng-message="mindate">Must be a date in the future.</span>
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function() {

    this.options = {
      debounce: 200,
    };

    this.$onInit = () => {
      // handle conversion from string to javascript date
      if(this.field.value && typeof this.field.value == 'string')
        this.field.value = new Date(this.field.value +
          (this.field.value.indexOf('T') == -1 ? 'T00:00:00' : ''));

      this.field.placeholder = this.field.placeholder || 'Select date';

      if(this.field.custom && this.field.custom.limit) {
        if(this.field.custom.limit == 'future') this.minDate = new Date();
        if(this.field.custom.limit == 'past') this.maxDate = new Date();
      }
    };

    this.dateFilter = (date) => {
      if(!this.field.filterDates || this.field.filterDates.length === 0) return true;
      return !this.field.filterDates.includes(moment(date).format('YYYY-MM-DD'));
    };

  }

});
