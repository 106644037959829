angular.module('move').component('quoteRequestList', {
  templateUrl: '/templates/move/quote-request-list.html',
  bindings: {
    requests: '<',
    task: '<',
    move: '<'
  },
  controller: function($rootScope, $timeout, $q, $filter,
    User, Move, Messages, QuoteRequest, MoveQuote, Alerts) {
    'ngInject';

    this.user = User.get();
    $rootScope.$on('userUpdated', () => this.user = User.get());

    this.toggle = (request) => {
      if(request.status == 'pending' && !request.active)
        this.requests.filter(r => r.active).forEach(r => r.active = false);
      if(request.status == 'submitted' && !request.active) {
        request.description = request.description || request.vendor.default_description;
        request.link = request.link || request.vendor.review_link;
        if(angular.isUndefined(request.affirm_enabled)) request.affirm_enabled = true;
      }
      request.active = !request.active;
    };

    this.requestClasses = (request) => {
      let classes = [];
      if(new Date(request.created_at) > Date.now() - 24*60*60*1000) classes.push('new');
      else if(new Date(request.updated_at) > Date.now() - 24*60*60*1000) classes.push('updated');
      if(request.active) classes.push('active');
      return classes;
    };

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    if(User.get().role == 'concierge') {
      this.sendRequest = (request,form) => {
        if(this.loading) return;
        if(!valid(form)) return error();
        this.loading = true;
        QuoteRequest.update(request.id,{
          status:'pending',
          note:$filter('cleanHTML')(request.note,{multiline:true})
        }).then((result) => {
          request.active = false;
          this.loading = false;
        }, error);
      };

      this.delete = (index, $event) => {
        $event.stopPropagation();
        QuoteRequest.remove(this.requests[index].id).then((result) => {
          this.requests.splice(index,1);
        },error);
      };

      this.sendMessage = true;
      this.hasApproved = () => this.requests.filter(r => r.active && r.status == 'submitted').length;

      this.approve = (form) => {
        if(this.loading) return;
        if(!valid(form)) return error();
        this.loading = true;
        // build the data
        let data = [];
        this.requests.filter(r => r.active).forEach((request) => {
          let quote = {
            move_task: request.move_task.id,
            transaction: request.transaction.id,
            company: request.vendor.name, // backwards compatibility
            vendor: request.vendor.id,
            quote_request: request.id,
            description: $filter('cleanHTML')(request.description,{multiline:true}),
            is_hourly: false,
            price: request.price,
            affirm_enabled: request.affirm_enabled || false,
            arrival_window: request.arrival_window,
            rate: 0,
            data: { price_breakdown: request.adjusted_price }
          };
          if(request.link) quote.link = request.link;
          data.push(quote);
        });
        // send request to create the quotes
        MoveQuote.create(this.move.id,{move_quotes:data})
          // run all QuoteRequest updates in parallel
          .then(() => {
            // build the data to update each QuoteRequest status to 'approved'
            let promises = [];
            this.requests.filter(r => r.active).forEach((request) => {
              promises.push(QuoteRequest.update(request.id,{status:'approved'}));
            });
            return $q.all(promises);
          })
          // Send notification messages
          .then(() => {
            if(!this.sendMessage) return success();
            let message = {
              move: this.move.id,
              body: `Hey ${Move.findCustomer(this.move).user.firstname}! Your quotes are` +
                ` available. Click below to check them out.`,
              auto_generated: true
            };
            Messages.send(message).then(() => {
              let message = {
                move: this.move.id,
                type: 5,
                data: { move_task: this.task.id },
                auto_generated: true
              };
              return Messages.send(message);
            }).then(success,error);
          },error);
      };

    }

    this.stopPropagation = ($event) => $event.stopPropagation();

    // brings up the modal for full price breakdown display
    this.priceBreakdown = (quote_request, $event) => {
      $event.stopPropagation();
      QuoteRequest.modals.priceBreakdown(quote_request,{overlay:true});
    };

    // success handler
    const success = () => {
      Alerts.success({msg:'Quotes sent'});
      this.loading = false;
    };

    // error handler
    const error = (alert) => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
      if(alert) return Alerts.error();
    };

  }

});
