angular.module('core').factory('_',
function($window) {
  'ngInject';

  let _ = $window._; // get local reference
  delete $window._; // delete the global reference for good hygiene

  return ( _ );

});
