// create module & set dependencies
angular.module('partner', [
  'ngCookies',
  'ngAnimate',
  'ngSanitize',
  'ui.router',
  'ui.calendar',
  'stripe.checkout',
  'infinite-scroll',
  'user',
  'move',
  'messages'
]);
