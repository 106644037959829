angular.module('payment').controller('vendorHqModalController',
  function($scope,$uibModalInstance,$timeout,Field,AddressUtil,Vendor,params) {
    'ngInject';

    $scope.vendor = angular.copy(params.vendor);

    $scope.fields = {
      headquarters: {
        type: 'address',
        label: 'Headquarters',
        name: 'headquarters',
        required: true
      }
    };

    angular.forEach($scope.fields, (field, key) => {
      if(field.type == 'address') {
        field.value = ($scope.vendor && $scope.vendor.vendor_addresses) ?
            $scope.vendor.vendor_addresses.find(a => a.name == field.name) : {};
        field.formatted = AddressUtil.getFormatted(field.value);
      } else {
        field.value = this.vendor[field.name];
      }
      Field.addDefaults(field);
    });

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    $scope.submit = (form) => {
      if($scope.loading) return false;
      if(!valid(form)) return error();
      $scope.loading = true;
      let data = {};
      angular.forEach($scope.fields, (field) => {
        if(field.type == 'address') {
         data.vendor_addresses = data.vendor_addresses || [];
         field.value.name = field.name;
          data.vendor_addresses.push(AddressUtil.filterFields(field.value));
        } else {
          data[field.name] = field.value;
        }
      });
      Vendor.update($scope.vendor.id, data).then(() => {
        $scope.loading = false;
        $uibModalInstance.close('Updated');
      },error);
    };

    function error() {
      $scope.loading = false;
      $scope.error = true;
      $timeout(() => { $scope.error = false; }, 300);
      return false;
    }

});
