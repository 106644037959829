angular.module('user').factory('PubNub',
function($rootScope, $state, $window, $timeout, $http, $filter, Data, Pubnub, User, Move, Typing) {
  'ngInject';

  let subscribed = [];

  const initializePubNub = () => {
    Pubnub.init({
      subscribeKey: Data.pubNubSubKey,
      uuid: localStorage.uuid,
      ssl: true,
      restore: true,
      heartbeatInterval: 0,
      presenceTimeout: 0,
      suppressLeaveEvents: true,
    });
    addListeners();
  };

  const addListeners = () => {

    Pubnub.addListener({
      message: (m) => {
        let segments = m.channel.split('.');
        if(m.message.data) $filter('formatResponse')(m.message.data);
        if(segments[0] === 'user' && localStorage.uuid !== m.message.uuid && m.message.data)
          $timeout(() => $rootScope.$broadcast(`${segments[0]}Data`,m.message.data),300);
        if(segments[0] === 'move' || segments[0] === 'vendor')
          broadcast(`${segments[0]}.${segments[2]}`,parseInt(segments[1]),m.message.data,m.message.delete);
        if(segments[0] === 'cx')
          broadcast(`${segments[0]}.${segments[1]}`,parseInt(segments[2]),m.message.data);
      },

      presence: (p) => Typing.updateUserList(parseInt(p.channel.split('.')[1]),p),

      status: (s) => {
        // handle updated subscribe list
        if(s.operation === 'PNSubscribeOperation' && s.subscribedChannels)
          subscribed = s.subscribedChannels;
        else if (s.operation === 'PNUnsubscribeOperation' && s.affectedChannels)
          subscribed = subscribed.filter(channel => !s.affectedChannels.includes(channel));
        // reconnect handler (could be improved, just reloads the state)
        if(s.category === 'PNReconnectedCategory') $state.reload();
      }

    });
  };

  const defaultChannels = (user) => {
    let channels = [`user.${user.id}`];
    if(['admin','concierge'].includes(user.role)) {
      channels.push('cx.move_list.*','vendor.*','move.*');
      return API.subscribe(channels,false);
    }
    if(user.role == 'vendor') {
      // TODO: fix vendor broadcast to include all move_task and move_transaction data associated with this vendor
      channels.push(`vendor.${user.vendor.id}.*`);
      return API.subscribe(channels,false);
    }
    else Move.getIDs().then((moves) => {
      angular.forEach(moves, (move_id) => channels.push(`move.${move_id}.*`));
      return API.subscribe(channels,true);
    });
  };

  let broadcast = (channel,id,data,remove) => {
    if(['admin','concierge'].includes(User.get().role))
      $rootScope.$applyAsync($rootScope.$broadcast(channel,id,data,remove));
    else $timeout(() => $rootScope.$broadcast(channel,id,data,remove), 300);
  }

  const updateUser = (user) => {
    if(Pubnub.unsubscribeAll) Pubnub.unsubscribeAll();
    if(user.role == 'guest') {
      if(Pubnub.stop) Pubnub.stop();
      delete localStorage.uuid;
      delete $http.defaults.headers.common['x-uuid'];
    } else {
      // update uuid in localStorage, http headers, and pubnub service
      if(!localStorage.uuid) localStorage.uuid = $window.PubNub.generateUUID();
      $http.defaults.headers.common['x-uuid'] = localStorage.uuid;
      defaultChannels(user);
    }
  };

  $rootScope.$on('userUpdated',(e,dataOnly) => {
    if(!dataOnly) updateUser(User.get());
  });

  let API = {
    init: () => initializePubNub(),
    subscribe: (channels, presence) => Pubnub.subscribe({channels: channels, withPresence: presence}),
    subscribeMove: (id) => Pubnub.subscribe({channels: [`move.${id}.*`], withPresence: true}),
    unsubscribeMove: (id) => Pubnub.unsubscribe({channels:[`move.${id}.*`]}),
    setActiveMove: (id) => {
      let previous = subscribed.filter(channel => channel.includes('move.'));
      if(previous) Pubnub.unsubscribe({channels:previous});
      API.subscribeMove(id);
    }
  };

  return API;

});
