angular.module('field')
.component('fieldToggle', {
  template: `
    <label>
      <span ng-bind-html="$ctrl.customLabel()"></span>
      <svg-sprite alt="info" source="material-icons" icon="info_outline"
        class="tooltip-icon" ng-if="$ctrl.field.description"
        uib-tooltip="{{$ctrl.field.description}}"></svg-sprite>
    </label>
    <span class="toggle-holder">
      <input type="checkbox" class="toggle" id="toggle-{{$ctrl.uuid}}"
        ng-model="$ctrl.field.value" ng-disabled="$ctrl.field.isDisabled()"
        ng-change="$ctrl.field.onChange()" ng-model-options="{debounce:$ctrl.field.debounce || 200}" />
      <label for="toggle-{{$ctrl.uuid}}"></label>
    </span>`,
  bindings: {
    field: '=',
  },
  controller: function() {
    'ngInject';
    this.uuid = Date.now();
    this.$onInit = () => {
      if(this.field.value !== true) this.field.value = false;
    };
    this.customLabel = () => {
      if(this.field.customLabel) return this.field.customLabel();
      else return this.field.label;
    };
  }
});
