angular.module('vendor').component('screenAutoQuoteStorage', {
  templateUrl: '/templates/vendor/screen-storage-data.html',
  bindings: {
    vendor: '<',
    screen: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,$q,User,Vendor,Field) {
    'ngInject';

    this.screen = angular.copy(this.screen);

    this.$onInit = () => {
      this.screen.fields.forEach((field) => {
        field.value = Vendor.details.getValue(field,this.screen,this.vendor);
        if(angular.isUndefined(field.value)) field.value = field.default;
        if(this.screen.onChange) field.onChange = () => this.screen.onChange(buildData());
        Field.addDefaults(field);
      });
      this.storageField = this.screen.fields.shift();
      if(this.screen.summary) this.storageField.label = 'Do you offer storage?';
    };

    this.fieldFilter = (field) => !field.hidden;

    this.next = (skip) => {
      if(this.loading) return;
      if(skip) return this.onNext();
      this.loading = true;
      if(this.storageField.value && !valid()) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progress) => {
      let data = buildData();
      data.progress = progress;
      return Vendor.details.update(this.vendor.details.id, data);
    };

    const buildData = () => {
      let data = {};
      data[`${this.screen.name}_data`] = {};
      data[`${this.screen.name}_data`][this.storageField.name] = this.storageField.value;
      if(!this.storageField.value) return data;
      this.screen.fields.forEach((field) => {
        data[`${this.screen.name}_data`][field.name] = formatData(field);
      });
      return data;
    };

    const formatData = (field) => {
      if(field.type == 'date') return moment(field.value).format('YYYY-MM-DD');
      return field.value;
    };

    let valid = () => {
      this.form.$setSubmitted();
      return !this.form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }

});
