angular.module('cx').component('moveSearchList', {
  templateUrl: '/templates/cx/move-search-list.html',
  bindings: {
    moves: '<',
    activeMove: '<',
    search: '<',
    onSelect: '&',
    loadMoves: '&',
  },
  controller: function($state, Move) {
    'ngInject';

    this.moveClasses = (move) => {
      var classes = [];
      classes.push(`status-${move.status}`);
      if(this.activeMove && move.id === this.activeMove.id) classes.push('active');
      if(move.last_message && Move.findCustomer(move).user.id === move.last_message.sender.id) classes.push('unanswered');
      return classes;
    };

  }
});
