angular.module('core').factory('FullStory',
function($window, $rootScope, $cookies, User, Data) {
  'ngInject';

  // FullStory Initialization
  if(!Data.fullstoryId) return false;

  $window._fs_debug = false;
  $window._fs_host = 'fullstory.com';
  $window._fs_script = 'edge.fullstory.com/s/fs.js';
  $window._fs_org = Data.fullstoryId;
  $window._fs_namespace = 'FS';
  (function(m,n,e,t,l,o,g,y){
    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
    g.consent=function(a){g("consent",!arguments.length||a)};
    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
    g.clearUserCookie=function(){};
    o=n.createElement(t);o.async=1;o.src='https://'+_fs_script;y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
  })($window,document,$window._fs_namespace,'script','user');

  $rootScope.$on('userUpdated',(e,dataOnly) => {
    let user = angular.copy(User.get());
    if(user.role == 'concierge') return FS.shutdown();
    if(!dataOnly) FS.restart();
    let proxy = $cookies.get(`adminToken.${Data.env}`) ? true : false;
    if(user.role == 'guest') {
      if(!proxy) return $window.FS.identify(false);
      user.id = `guest`;
      user.fullname = 'Guest User';
      user.email = '';
    }
    $window.FS.identify(proxy ? `admin::${user.id}` : user.id, {
      displayName: proxy ? `Admin as ${user.fullname}` : user.fullname,
      email: user.email
    });
  })

  return $window.FS;

});
