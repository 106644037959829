angular.module('messages')
.component('messageTask', {
  template: `
    <div class="message-bubble task-widget" ng-click="$ctrl.launchModal()">
      <div class="message-container">
        <div class="title" ng-bind="::$ctrl.label"></div>
        <div class="description">Click here to fill out the details.</div>
        <svg-sprite class="arrow" source="material-icons"
          icon="chevron_right" alt="view"></svg-sprite>
      </div>
      <div class="message-delete" ng-click="$ctrl.messageDelete()"
        ng-if="::$ctrl.isCXMessage">
        <span class="message-warning" ng-if="$ctrl.warning">Are you sure?</span>
        <svg-sprite source="material-icons" icon="close" alt="remove"></svg-sprite>
      </div>
    </div>
    <div class="message-timestamp">
      <span ng-bind="::$ctrl.message.sender.firstname" ng-if="::$ctrl.isCXMessage"></span>
      <span ng-bind="::$ctrl.message.created_at | amUtc | amLocal | amDateFormat: 'M/D/YYYY h:mma'"></span>
    </div>`,
  bindings: {
    message: '<',
    move: '<',
    view: '<'
  },
  controller: function($timeout, $q, User, Task, Messages, Move, Alerts) {
    'ngInject';

    this.$onInit = () => {
      let promises = {
        taskList: Task.getList(),
      };
      if(!this.move.move_tasks) promises.task = Task.get(this.message.data.move_task);
      $q.all(promises).then(results => {
        this.task = results.task || Move.findMoveTask(this.message.data.move_task, this.move);
        this.task.task = results.taskList.find((task) => task.id == this.task.task.id) || {};
        if(this.task.task.label) this.label = this.task.task.label;
        else this.label = 'Task Not Found';
      });
      this.isCXMessage = this.message.sender.role == 'concierge' && this.view == 'concierge';
    };

    this.launchModal = User.get().role == 'mover' ? () => {
      Task.modals.taskFlow(this.task, this.move);
    } : angular.noop;

    this.messageDelete = () => {
      if(this.disabled) return;
      if(!this.warning) {
        this.warning = true;
        this.disabled = true;
        $timeout(() => this.disabled = false, 300);
        $timeout(() => this.warning = false, 5000);
        return;
      }
      Messages.delete(this.message.id).then((result) => {}, Alerts.error);
    };

  }
});
