angular.module('user').factory('Auth',
function($rootScope, $http, $timeout, $cookies, moment, _, Data) {
  'ngInject';

  let token,
      tokenExpiration,
      isProxy;

  const setToken = ({ access_token, access_token_expiration, access_token_is_proxy } = {}) => {
    token = access_token; // store locally
    tokenExpiration = access_token_expiration; // store locally
    isProxy = access_token_is_proxy; // store locally
    updateLegacyToken(token && !tokenExpiration ? token : false) // support legacy
    return setAuthHeaders();
  };

  const setAuthHeaders = () => {
    if(token) {
      $http.defaults.headers.common.Authorization = `Bearer ${token}`;
      return true; // there is a logged in user
    }
    else {
      delete $http.defaults.headers.common.Authorization;
      return false; // there is not a logged in user
    }
  };

  // LEGACY TOKEN FUNCTIONS
  const getLegacyToken = () => $cookies.get(`accessToken.${Data.env}`);
  const updateLegacyToken = (accessToken) => accessToken ? $cookies.put(`accessToken.${Data.env}`,accessToken) : $cookies.remove(`accessToken.${Data.env}`);

  const API = {

    create: (data, skipLogin) => {
      // create user and then login
      return $http.post(`${Data.apiUrl}/v2/users`,data).then((result) => {
        if(skipLogin || !data.password) return result.data;
        return API.login({
          email: data.email.toLowerCase(),
          password: data.password
        }).then((response) => {
          return response;
        });
      });
    },

    // TODO: migrate to v2
    oauthLogin: (data) => $http.post(`${Data.apiV1Url}/v1/oauth_users`,_.merge(data,{app:'web'}))
      .then((result) => {
        setToken({access_token:result.data.data[0].access_token});
        return result.data.data[0];
      }),

    // TO BE DEPRECATED (needed for legacy set password)
    setToken: (token) => setToken({access_token:token}),

    /* UPDATED FOR NEW JWT + REFRESH AUTHENTICATION */
    refreshToken: () => $http.post(`${Data.apiUrl}/users/token/refresh`,undefined,{ withCredentials: true, headers: { Authorization: () => null }})
      .then(r => _.get(r,'data.data'))
      .then((tokenData) => setToken(tokenData || { access_token: getLegacyToken() })),
    login: (data) => $http.post(`${Data.apiUrl}/users/login`,data,{ withCredentials: true })
      .then(r => _.get(r,'data.data'))
      .then(({ user, ...tokenData}) => setToken(tokenData)),
    logout: () => $http.post(`${Data.apiUrl}/users/logout`,undefined,{ withCredentials: true })
      .then(() => setToken()),
    proxyStart: (userId) => $http.post(`${Data.apiUrl}/users/${userId}/proxy_as`,undefined,{ withCredentials: true })
      .then(r => _.get(r,'data.data'))
      .then(({ user, ...tokenData}) => setToken(tokenData)),
    proxyEnd: () => $http.post(`${Data.apiUrl}/users/end_proxy`,undefined,{ withCredentials: true })
      .then(r => _.get(r,'data.data'))
      .then(({ user, ...tokenData}) => setToken(tokenData)),

    setPasswordViaToken: (data) => $http.post(`${Data.apiUrl}/users/password`, data, { withCredentials:true })
      .then(r => _.get(r,'data.data'))
      .then((tokenData) => setToken(tokenData)),

    isProxy: () => isProxy,
    hasExpiredToken: () => tokenExpiration && moment.utc(tokenExpiration).isBefore(),

  };

  return API;

});
