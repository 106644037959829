// create module & set dependencies
angular.module('field', [
  'ngAnimate',
  'ngSanitize',
  'angularMoment',
  'googlePlacesAutocomplete',
  'checklist-model',
  'ui.bootstrap',
  'ui.select',
  'user'
]);
