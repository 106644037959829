angular.module('tasks').component('screenItemList', {
  templateUrl: '/templates/tasks/screen-item-list.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,User,Move,Task,Field,Modal) {
    'ngInject';

    this.$onInit = () => {
      if(this.screen.fields) this.screen.fields.forEach(field => {
        Field.addDefaults(field, this.task.data, this.move);
        field.value = angular.isDefined(Field.getValue(field,this.task,this.move)) ?
          Field.getValue(field,this.task,this.move) : field.default;
        field.onChange = () => {
          if(this.screen.onChange) this.screen.onChange(buildData());
        };
      });
      Task.getItemList(this.screen.model).then((fields) => {
        this.fields = angular.copy(fields);
        this.fields.sort((a,b) => a.order - b.order);
        this.fields.forEach((field) => {
          Field.addDefaults(field);
          field.type = 'integer';
          field.parent = this.screen.name;
          field.value = Field.getValue(field,this.task);
          field.onChange = () => {
            if(this.screen.onChange) this.screen.onChange(buildData());
          };
        });
        this.ready = true;
      }).catch(error);
    };

    this.totalItems = () => {
      let count = 0;
      if(!this.fields) return count;
      this.fields.forEach((item) => count += item.value);
      return count;
    }

    this.next = (confirmed) => {
      if(this.loading) return;
      if(!valid(this.form)) return error();
      if(!this.totalItems() && !confirmed) return confirmDialog();
      this.loading = true;
      this.onNext({
        callback:this.save,
        name:this.totalItems() ? false : 'time_preference'
      }).then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      if(!this.totalItems()) data.task.data.professional_packing = 'none';
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      data = data || {};
      [...this.fields, ...this.screen.fields].forEach((field) => Field.buildSaveData(field,data));
      return data;
    };

    this.generateEstimates = () => Task.modals.boxesEstimates(this.task, this.fields).result
      .then((estimates) => {
        this.fields.forEach((field) => {
          if(estimates.find(box => box.name == field.name)) field.value = estimates.find(box => box.name == field.name).count;
          else field.value = 0;
        });
        if(this.screen.onChange) this.screen.onChange(buildData());
      },angular.noop);

    const confirmDialog = () => Modal.dialog({
      title: 'No Boxes?',
      body: `Please verify that you will not be moving any boxes. The quotes we
        provide are only as good as the info you provide, so even rough estimates are
        better than nothing.`,
      accept: 'Nope! No boxes',
      decline: `Oops, I'll add some`
    }).result.then(
      result => this.next(true),
      () => this.generateEstimates()
    );

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }
});
