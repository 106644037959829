angular.module('tasks').constant('UpdateAddressFlow', {

  'update-address': {
    flows: {
      default: [
        { name: 'verify_from_address' },
        { name: 'verify_to_address' },
        { name: 'contact_info' },
        { name: 'forwarding_timing' },
        { name: 'review', markComplete: true },
        { name: 'update_address_loader' },
      ],
    },
    screens: {

      // verify from address
      verify_from_address: {
        title: 'Confirm your current address.',
        type: 'task-data',
        name: 'verify_from_address',
        label: 'From Address',
        editable: true,
        fields: [{
          name: 'from_address',
          type: 'complete-address',
          label: 'From Address',
          required: true,
          include: {
            unit: true
          },
          custom: {
            default: {
              source: 'book-movers',
              field: 'from_address'
            }
          }
        }]
      },

      // verify to address
      verify_to_address: {
        title: 'Confirm your new address.',
        type: 'task-data',
        name: 'verify_to_address',
        label: 'To Address',
        editable: true,
        fields: [{
          name: 'to_address',
          type: 'complete-address',
          label: 'To Address',
          required: true,
          include: {
            unit: true
          },
          custom: {
            default: {
              source: 'book-movers',
              field: 'to_address'
            }
          }
        }]
      },

      // contact_info
      contact_info: {
        title: 'Fill in your contact info below.',
        type: 'task-data',
        name: 'contact_info',
        label: 'Contact Info',
        editable: true,
        fields: [{
          name: 'address_name',
          type: 'text',
          label: 'Contact',
          placeholder: 'Contact name',
          required: true,
          custom: {
            default: {
              source: 'user',
              field: 'fullname'
            }
          }
        },{
          name: 'address_phone',
          type: 'phone_number',
          label: 'Phone number',
          placeholder: 'Contact phone number',
          required: true,
          custom: {
            default: {
              source: 'book-movers',
              field: 'contact_phone'
            }
          }
        }]
      },

      // forwarding_timing
      forwarding_timing: {
        title: 'When would you like your mail to start forwarding?',
        type: 'task-data',
        name: 'forwarding_timing',
        label: 'Timing',
        editable: true,
        fields: [{
          name: 'address_start_date',
          type: 'date',
          label: 'Start Forwarding Date',
          required: true,
          custom: {
            limit: 'future',
            default: {
              source: 'book-movers',
              field: 'move_date'
            }
          }
        },{
          name: 'address_temporary',
          type: 'toggle',
          label: 'Is move temporary?',
          description: `If you plan to move back to your old address within 12
            months, click the toggle to the right so it turns green.`
        }]
      },

      // summary
      review: {
        title: 'Summary',
        type: 'review',
        name: 'review',
        button: 'Looks good - update it!',
        hideSummary: true,
        sections: [{
          fields: [{
            name: 'address_name'
          },{
            name: 'address_phone'
          }, {
            name: 'address_start_date'
          }]
        },{
          label: 'Address',
          fields: [{
            name: 'from_address',
            label: 'Current address',
            display: 'summary'
          },{
            name: 'to_address',
            label: 'New address',
            display: 'summary'
          }]
        }]
      },

      // loader
      update_address_loader: {
        title: 'Magic is happening...',
        type: 'timed-loader',
        name: 'update_address_loader',
        back: false,
        hideSummary: true,
        delay: 3000,
        hidden: true
      },

      // Complete
      complete: {
        title: 'All done!',
        subtitle: `You’ll get an email shortly confirming that this has
          been processed. Ask your assistant if you have any questions.`,
        type: 'complete',
        name: 'complete',
        button: 'Done',
        back: 'review'
      }

    }
  }

});
