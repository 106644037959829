angular.module('core').factory('Image',
function(Modal) {
  'ngInject';

  const Image = {
    modals: {
      view: (images,options) => {
        options = options || {};
        return Modal.open(angular.merge({
          controller: 'imageViewerModalController',
          templateUrl: '/templates/core/image-viewer-modal.html',
          resolve: {
            images: () => images,
            initial: () => options.initial || 0
          }
        },options));
      },
      upload: (bucket,options) => {
        options = options || {};
        return Modal.open(angular.merge({
          controller: 'imageUploadModalController',
          templateUrl: '/templates/core/image-upload-modal.html',
          resolve: { bucket: () => bucket }
        },options));
      }
    }
  };

  return Image;


});

