angular.module('messages').component('messageInput', {
  templateUrl: '/templates/messages/message-input.html',
  bindings: {
    move: '<'
  },
  controller: function ($rootScope, $window, $filter, $timeout, debounce,
    User, Alerts, Messages, Move, Typing, MoveQuote, Image, Payment) {
    'ngInject';

    this.view = (User.get().role == 'admin') ? 'concierge' : User.get().role;
    if(this.view == 'guest') return;

    this.debounce = 100;
    this.$onInit = () => {
      Typing.getState(this.move.id,(status,response) => {
        if(response && response.channels &&
          response.channels[`move.${this.move.id}.messages`] &&
          response.channels[`move.${this.move.id}.messages`].isTyping) {
          Typing.setState(this.move.id,false);
        }
      });
    }

    // function to post a new message
    this.message = '';
    this.sendMessage = (message) => {
      if(!message) return false;
      this.message = '';
      message = $filter('cleanHTML')(message);
      if(message && message.length) {
        Messages.send({
          move: this.move.id,
          body: message
        }).catch(() => {
          this.message = message;
          Alerts.error();
        });
        Typing.setState(this.move.id,false);
      }
    };

    // set typing indicator when typing
    this.onChange = () => {
      if(this.message) $timeout(() => Typing.setState(this.move.id,true),0);
      else $timeout(() => Typing.setState(this.move.id,false),0);
    };

    this.onKeyPress = ($event) => {
      if($event && $event.keyCode === 13) {
        $event.preventDefault();
        return $timeout(() => this.sendMessage(this.message), this.debounce);
      }
    };

    /* ACTION MENU BUTTONS */
    // upload image
    this.uploadImage = () => {
      Image.modals.upload('message-uploads').result.then((response) => {
        Messages.send({
          move: this.move.id,
          type: 2,
          data: response
        });
      }).catch(angular.noop);
    };

    this.paymentLinkModal = () => {
      Payment.modals.link(this.move.id).result.then((response) => {
        Messages.send({
          move: this.move.id,
          body: response
        });
      }).catch(angular.noop);
    };

    /* CONCIERGE MENU */
    this.showMenu = false;
    var openMenu = () => {
      this.showMenu = true;
      this.emojiPicker = false;
    };
    var closeMenu = () => {
      this.showMenu = false;
    };
    this.toggleMenu = (event) => {
      event.stopPropagation();
      return this.showMenu ? closeMenu() : openMenu();
    };
    $(document).on('click.conversations', () => {
      if(this.showMenu) $rootScope.$apply(closeMenu);
    });
    this.$onDestroy = () => {
      $(document).unbind('.conversations');
      Typing.setState(this.move.id,false);
    };

  }

});
