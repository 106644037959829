angular.module('user').controller('passwordForgotModalController',
  function ($scope, $timeout, User, $uibModalInstance, Alerts, data) {
    'ngInject';

    $scope.loggedin = angular.isDefined(User.get().id);
    $scope.forgot = {
      email: ($scope.loggedin ? User.get().email : '')
    };

    var valid = () => {
      $scope.$broadcast('$validate');
      $scope.forgotForm.$setSubmitted();
      return !$scope.forgotForm.$invalid;
    };

    $scope.submit = () => {
      if($scope.loading) return;
      if(!valid()) return error();
      $scope.loading = true;
      User.forgotPassword($scope.forgot.email.toLowerCase())
        .then((data) => {
          $scope.loading = false;
          Alerts.success({msg: `Request approved! You can expect instructions
            in your inbox shortly.` });
          $uibModalInstance.close();
        }, (result) => {
          $scope.loading = error();
          switch (result.data.message) {
            case 'User not found.':
              Alerts.error({ msg: `Sorry, that email does not exist in our
                system. Double check your spelling and
                <a href="mailto:crew@moved.com" target="_blank">email us</a>
                if you continue to have problems.`, delay: 8 });
              break;
            default:
              Alerts.error();
              break;
          }
        });
    };

    function error() {
      $scope.error = true;
      $timeout(() => { $scope.error = false; }, 300);
      return false;
    }
});
