angular.module('payment').controller('paymentLinkModalController',
function ($scope, $uibModalInstance, Access, Data, moveId) {
  'ngInject';

  if(Access.limit('concierge', function() { $uibModalInstance.dismiss('unauthorized'); })) return;

  function validate() {
    $scope.$broadcast('$validate');
    $scope.paymentForm.$setSubmitted();
    var valid = !$scope.paymentForm.$invalid;
    return valid;
  }

  // scope function to post a new message
  $scope.sendLink = function() {
    if(!validate()) return;
    $scope.loading = true;

    let link = Data.appUrl + '/payment/'+moveId+'?amount='+$scope.amount;
    $uibModalInstance.close(link);

  };

});
