angular.module('core').component('uiCalendar', {
  template: `
    <div id="calendar-{{$ctrl.name}}"
      ui-calendar="$ctrl.options"
      calendar="{{$ctrl.name}}"></div>`,
  bindings: {
    name: '@',
    events: '<',
    config: '<',
    refresh: '<?'
  },
  controller: function($window,uiCalendarConfig) {
    'ngInject';

    this.calendar = false;
    let sourceCopy = false;

    this.$doCheck = () => {
      if(this.calendar) {
        if(angular.equals(sourceCopy, this.events)) return;
        this.calendar.refetchEvents();
        return sourceCopy = angular.copy(this.events);
      } else if(uiCalendarConfig.calendars[this.name]) {
        this.calendar = uiCalendarConfig.calendars[this.name].fullCalendar('getCalendar');
      }
    };

    this.gotoDate = (date) => {
      if(this.calendar) this.calendar.gotoDate(date);
    };

    this.$onInit = () => {
      this.options = angular.merge({},{
        editable: false,
        header: {
          left: 'prev,today,next title',
          right: ''
        },
        events: (start,end,timezone,callback) => callback(this.events)
      }, this.config);
    };

    this.$onChanges = (changes) => {
      if(changes.refresh && this.refresh) $window.dispatchEvent(new Event('resize'));
    };

  }
});
