// directive to convert message.type into a compiled component
angular.module('messages').directive('messageGenerator',
function ($compile, Messages, User) {
  'ngInject';
  return {
    restrict: 'A',
    link: (scope, element, attr) => {
      scope.senderName = () => {
        return scope.message.sender.id == User.get().id ? 'Me' : 'Moved';
      };

      let markup = `
        <message-${Messages.getType(scope[attr.messageGenerator])}
          message="message" view="'${User.get().role}'" move="$ctrl.move"></message-${Messages.getType(scope[attr.messageGenerator])}>`;

      if(User.get().role == 'concierge') {
        markup += `<div class="last-read" ng-if="message.moverLastRead">
          Last Read
          <span class="bold" am-time-ago="message.moverLastRead | amUtc | amLocal"></span>
        </div>`;
      }

      else markup = `
        <div class="sender-info">
          <span class="sender" ng-bind-html="::senderName()"></span>
          <span class="message-timestamp"
            ng-bind="::message.created_at | amUtc | amLocal | amDateFormat: 'M/D/YYYY h:mma'"></span>
        </div>` + markup;

      element.html(markup);
      $compile(element.contents())(scope);
    }
  };
});
