angular.module('move').controller('repeatMoveModalController',
  function ($scope, $window, $timeout, Move, Data, params) {
    'ngInject';

    $scope.submit = () => {
      if($scope.loading) return;
      $scope.loading = true;
      Move.createConsumerMove(params.user.id)
        .then(move => {
          $window.top.location = `${Data.consumerUrl}/moves/${move.id}`;
        })
        .catch(() => {
          $scope.loading = false;
          $scope.error = true;
          $timeout(() => { $scope.error = false; }, 300);
        });
    };

  });
