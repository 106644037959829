angular.module('messages').factory('Messages',
function($http,Data,S3,Upload) {
  'ngInject';

  const messageTypeMap = [
    {name:'text', type:'text'},
    {name:'status', type:'system'},
    {name:'image', type:'image', preview:'Special: Image Upload'},
    {name:'quote', type:'quotes', preview:'Special: Quotes'},
    {name:'payment', type:'payment', preview:'Special: Payment'},
    {name:'task_link', type:'task', preview:'Special: Task Link'},
    {name:'resident_contact_message', type:'text', preview:'Special: Contact Form Message'},
  ];

  return {
    send: (data) => {
      return $http.post(`${Data.apiUrl}/v2/messages`, data).then((result) => {
        return result.data;
      });
    },
    get: (data) => {
      return $http.get(`${Data.apiUrl}/v2/messages`,{ params: data }).then((result) => {
        return {
          messages: result.data,
          total: angular.isDefined(result.headers('X-Total-Count')) ? result.headers('X-Total-Count') : 0
        };
      });
    },
    getLatest: (move) => {
      return $http.get(`${Data.apiUrl}/v2/messages`,{ params: {
        where: { move: move.id }, per_page: 1, page: 1, sort: {created_at:true}
      }}).then((result) => result.data[0]);
    },
    getPreview: (message) => {
      if(!message) return 'No messages';
      let type = messageTypeMap.find(m => m.name === message.type);
      if(type && type.preview) return type.preview;
      if(type) return message.body;
      return 'Invalid Message';
    },
    update: (id, data) => {
      return $http.patch(`${Data.apiUrl}/v2/messages/${id}`, data).then((result) => {
        return result.data;
      });
    },
    delete: (id) => {
      return $http.delete(`${Data.apiUrl}/v2/messages/${id}`).then((result) => {
        return result.data;
      });
    },
    markRead: (id) => {
      return $http.post(`${Data.apiUrl}/v2/moves/${id}/mark_read`).then((result) => {
        return result.data;
      });
    },
    getType: (message) => messageTypeMap[message.type] ? messageTypeMap[message.type].type : false,

  };

});
