// create module & set dependencies
angular.module('customer', [
  'ngCookies',
  'ngAnimate',
  'ngSanitize',
  'ui.router',
  'ui.calendar',
  'stripe.checkout',
  'infinite-scroll',
  'angular-svg-round-progressbar',
  'user',
  'move',
  'messages',
  'payment'
]);
