angular.module('field')
.component('fieldInteger', {
  template: `
    <label>
      <span ng-bind-html="$ctrl.field.label"></span>
      <svg-sprite alt="info" source="material-icons" icon="info_outline"
        class="tooltip-icon" ng-if="$ctrl.field.description"
        uib-tooltip="{{$ctrl.field.description}}"></svg-sprite>
    </label>
    <stepper value="$ctrl.field.value" min="$ctrl.field.min" max="$ctrl.field.max"
      on-change="$ctrl.field.onChange()"></stepper>`,
  bindings: {
    field: '=',
  }
});
