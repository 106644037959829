angular.module('vendor').component('stripeBanner', {
  template: `
    <div class="stripe-banner" ng-if="$ctrl.vendor && !$ctrl.vendor.has_stripe">
      <div class="banner-content">
        <span class="hide-on-mobile">First, you&rsquo;ll need to set up a Stripe account.</span>
        <a class="button-primary" ng-href="{{$ctrl.stripeLink}}">
          Set Up Stripe
        </a>
      </div>
    </div>`,
  bindings: {
    vendor: '<'
  },
  controller: function(Data) {
    'ngInject';
    this.stripeLink = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write` +
       `&client_id=${Data.stripeClientId}&redirect_uri=${Data.appUrl}/stripe_connect`;
  }
});
