angular.module('vendor').component('autoQuoteFlow', {
  templateUrl: '/templates/vendor/auto-quote-flow.html',
  bindings: {
    vendor: '<',
    onClose: '&',
    onComplete: '&'
  },
  controller: function($rootScope, $timeout, Vendor, Alerts) {
    'ngInject';

    this.$onInit = () => {
      this.flow = Vendor.details.getFlow();
      this.activeScreen = this.vendor.details ? this.vendor.details.progress : false;
      this.ready = true;
    };

    this.createDetails = () => {
      if(this.vendor.details) return transition(0);
      this.loading = true;
      Vendor.details.create({vendor:this.vendor.id}).then(result => {
        this.vendor.details = result;
        this.loading = false;
        transition(0);
      },error);
    };

    this.screenClasses = ($index) => {
      let classes = [];
      if($index === 0) classes.push('first');
      if($index == this.flow.length - 1) classes.push('last');
      if($index == this.activeScreen) classes.push('active');
      if($index < this.activeScreen) classes.push('prev');
      if($index > this.activeScreen) classes.push('next');
      if($index == this.flow.length) classes.push('complete');
      if($index < this.flow.length) classes.push(this.flow[$index].name);
      return classes;
    };

    this.nextScreen = (save, name) => {
      let nextIndex = name ? findScreenIndex(name) : this.activeScreen+1;
      if(save) return save(Math.max(nextIndex,this.vendor.details.progress))
        .then((result) => $timeout(() => transition(nextIndex),0));
      else if(name) transition(nextIndex);
      else transition(nextIndex);
    };

    this.back = () => transition();

    const transition = (index) => {
      scrollTop(true);
      this.form.$setPristine();
      if(angular.isDefined(index)) {
        if(index < this.flow.length) this.activeScreen = index;
        else this.onComplete();
      }
      else {
        if(this.activeScreen > 0) this.activeScreen--;
        else this.activeScreen = false;
      }
    };

    const scrollTop = (animate) => {
      let flow = angular.element('auto-quote-flow').first();
      if(animate) flow.animate({scrollTop: 0},300);
      else $timeout(() => flow.scrollTop(0), 0);
    };

    const findScreenIndex = (name) => {
      return this.flow.findIndex((screen) => screen.name == name);
    }

    const error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

  }
});
