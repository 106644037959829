angular.module('core')
  .directive('updateHeight', function($timeout,$interval) {
    'ngInject';

    return {
      restrict: 'A',
      multiElement:true,
      link: function(scope, element, attr) {

        let watcher = false,
            height = 0,
            init = true;

        scope.$watch(attr.updateHeight, function(value,old) {
          if(init) return $timeout(() => {
            let target = attr.target && angular.element(attr.target).length ?
              angular.element(attr.target) : element;
            height = target[0].getBoundingClientRect().height;
            element.css({height:Math.floor(height)});
            init = false;
          },100);
          if(watcher) $timeout.cancel(watcher);
          watcher = $timeout(() => {
            let target = attr.target && angular.element(attr.target).length ?
              angular.element(attr.target) : element;
            if(value) {
              height = target[0].getBoundingClientRect().height;
              element.css({height:Math.floor(height)});
            } else {
              element.css({height: ''});
            }
            watcher = false;
          }, (value ? 50 : 0));
        });
      }
    };
  });
