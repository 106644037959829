angular.module('payment').factory('VendorDetails',
function($http, $q, $timeout, Data, Modal, AutoQuoteFlow) {
  'ngInject';

  var API = {
    create: (data) => {
      return $http.post(`${Data.apiUrl}/v2/vendor_details`,data).then(r => r.data);
    },
    get: (id,params) => {
      return $http.get(`${Data.apiUrl}/v2/vendor_details`+(id ? '/'+id : ''),{params:params}).then(r => {
        return id ? r.data : r.data[0];
      });
    },
    update: (id,data) => {
      return $http.patch(`${Data.apiUrl}/v2/vendor_details/${id}`, data).then(r => r.data);
    },
    getFlow: () => angular.copy(AutoQuoteFlow.screens),
    getScreen: (name) => {
      let screens = API.getFlow(flow);
      let match = screens.find((screen) => screen.name == name) || false;
      return match;
    },
    getValue: (field,screen,vendor) => {
      if(angular.isDefined(field.value)) return field.value;
      if(!vendor || !vendor.details) return;
      let source = screen.saveDirect ? vendor.details : vendor.details[`${screen.name}_data`];
      if(source && field.name in source) return source[field.name];
    },
    modals: {
      edit: (vendor, options) => {
        return Modal.open(angular.merge({
          controller: 'vendorDetailsModalController',
          templateUrl: '/templates/payment/vendor-details-modal.html',
          resolve: {
            params: () => ({
              vendor: vendor
            })
          }
        }, options));
      }
    }
  };

  return API;

});
