angular.module('move').controller('cancelMoveModalController',
function($scope,$uibModalInstance,$timeout,Move,Messages,Alerts,params) {
  'ngInject';

  $scope.options = [
    'I need a different date/time',
    'I need a new vendor',
    'Other'
  ];
  $scope.customMessage = '';

  var valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

  $scope.submit = (form) => {
    if($scope.loading) return false;
    if(!valid(form)) return error();
    $scope.loading = true;
    let message = `Cancel Move Request: ${($scope.selected == 'Other' ?
      $scope.customMessage : $scope.selected)}`;
    let data = {
      move: params.task.move.id,
      body: message,
      auto_generated: true
    };
    return Messages.send(data).then(() => {
      $scope.loading = false;
      Alerts.success({msg: 'Cancellation request sent. Your assistant will respond shortly.'});
      $uibModalInstance.close($scope.selected);
    }, error);

  };

  function error() {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
  }

});
