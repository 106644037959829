angular.module('field')
.component('fieldInventoryAutocomplete', {
  template: `
    <md-autocomplete flex
      md-input-name="{{$ctrl.field.name}}"
      md-min-length="2"
      md-delay="300"
      md-selected-item="$ctrl.selectedItem"
      md-selected-item-change="$ctrl.selectItem(item)"
      md-clear-button="false"
      md-search-text="$ctrl.searchText"
      md-items="item in $ctrl.findItems($ctrl.searchText)"
      md-item-text="item.label"
      placeholder="{{$ctrl.field.placeholder}}">
      <md-item-template>
        <span md-highlight-text="$ctrl.searchText"
          md-highlight-flags="i">{{item.label}}</span>
      </md-item-template>
      <md-not-found>
        <span>No matches found.</span>
      </md-not-found>
      <div ng-messages="$ctrl.form[$ctrl.field.name].$error"
        ng-if="$ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
        <div ng-message="required">Please select an existing option.</div>
        <div ng-message="md-require-match">Please select an existing option.</div>
      </div>
    </md-autocomplete>
    <label class="placeholder" for="{{$ctrl.field.name}}">{{$ctrl.field.label}}</label>`,
  bindings: {
    field: '=',
    form: '<',
    inventory: '<?'
  },
  controller: function($q,Task) {
    'ngInject';

    this.uuid = new Date();

    this.$onInit = () => {
      if(!this.inventory) Task.getItemList('inventory_items').then((items) => {
        this.inventory = items.sort((a,b) => {
          if(a.label.toLowerCase() < b.label.toLowerCase()) return -1;
          if(a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          return 0;
        });
      },() => {/* silent error */});
    };

    // function to handle type-ahead suggestion
    this.findItems = (searchText) => {
      if(!searchText) return [];
      return this.inventory.filter((item) =>
        item.label.toLowerCase().includes(searchText.toLowerCase()));
    };

    this.selectItem = (item) => {
      if(this.field.onChange) this.field.onChange(item);
      if(this.field.custom && this.field.custom.clearOnSelect) this.searchText = '';
    };

  }
});
