angular.module('field').factory('Field',
function(moment, Modal, Move, Task, Transaction, AddressUtil, _) {
  'ngInject';

  const calculate = (field, task) => {
    let value = 0;
    switch (field.name) {
      case 'inventory':
        // v1.7+
        if(task.move_task_inventories && task.move_task_inventories.length)
          task.move_task_inventories.forEach((item) => value += item.count);
        // Optionally append " items" to the count
        if (field.labelValue) {
          value += ' item' + (value == 1 ? '' : 's');
        }
        break;
      case 'supplyList':
        // v1.7+
        if(task.data && task.data.supplyList)
          angular.forEach(task.data.supplyList, (count) => {
            value += count;
          });
        // Optionally append " items" to the count
        if (field.labelValue) {
          value += ' item' + (value == 1 ? '' : 's');
        }
        break;
      case 'boxes':
        // v1.7+
        if(task.data && task.data.boxes)
          angular.forEach(task.data.boxes, (count) => {
            value += count;
          });
        if(field.labelValue) {
          value += ' box' + (value == 1 ? '' : 'es');
        }
        break;
      case 'storage':
        // v1.7+
        value = !!task.data.storage_location ||
          !!task.data.storage_start_date ||
          !!task.data.storage_duration ||
          null;
        break;
      case 'volume':
        if(task.move_task_inventories && task.move_task_inventories.length)
          task.move_task_inventories.forEach((item) => {
            if(item.inventory_item) value += item.count*item.inventory_item.cubic_feet;
          });
        if(task.data && task.data.boxes)
          angular.forEach(task.data.boxes, (box,name) => {
            if(box.box) value += box.count*box.box.cubic_feet;
          });
        break;
      case 'flights_of_stairs':
        if(task.move_task_addresses) task.move_task_addresses.forEach(address => {
          value += address.flights_of_stairs || 0;
        });
        break;
    }
    return value;
  };

  const logicTest = (logic, data, move) => {
    data = data || {};
    let compareValue;
    if(move && move.move_tasks && logic.compare.task) {
      let compareField = API.get(logic.compare.field, logic.compare.task);
      let compareTask = Move.findMoveTask(logic.compare.task, move);
      if(compareField && compareTask)
        compareValue = API.getValue(compareField,compareTask);
    }
    else compareValue = data[logic.compare.field];
    switch(logic.boolean) {
      case 'eq':
        compareValue = angular.isDefined(compareValue) ? compareValue : false;
        return compareValue == logic.value;
      case 'lte':
        compareValue = angular.isDefined(compareValue) ? compareValue : 0;
        return compareValue <= logic.value;
      case 'gte':
        compareValue = angular.isDefined(compareValue) ? compareValue : 0;
        return compareValue >= logic.value;
      case 'lt':
        compareValue = angular.isDefined(compareValue) ? compareValue : 0;
        return compareValue < logic.value;
      case 'gt':
        compareValue = angular.isDefined(compareValue) ? compareValue : 0;
        return compareValue > logic.value;
      case 'not':
        compareValue = angular.isDefined(compareValue) ? compareValue : false;
        return compareValue != logic.value;
      case 'in':
        compareValue = angular.isDefined(compareValue) ? compareValue : false;
        return logic.value.includes(compareValue);
      case '!in':
        compareValue = angular.isDefined(compareValue) ? compareValue : false;
        return !logic.value.includes(compareValue);
      default:
        return false;
    }
  };

  const formatSaveValue = (field) => {
    switch(field.type) {
      case 'date':
        return moment(field.value).format('YYYY-MM-DD');
      case 'address':
      case 'complete-address':
        return AddressUtil.filterFields(angular.merge(field.value,{name:field.name}));
      case 'inventory':
      case 'checkout-item':
        return field.value || 0;
      default:
        return field.value;
    }
  };

  const API = {

    get: (field, task) => {
      let screens = Task.getAllScreens(task),
          match = false;
      if(screens) Object.keys(screens).some(screen => {
        match = screens[screen].fields ? screens[screen].fields.find((f) => f.name == field) : false;
        if(match) match = angular.merge({},match,{task:task,screen:screens[screen].name});
        return match;
      });
      return match || { task: task, screen: field };
    },

    getValue: (field, task, move, source) => {
      if(angular.isDefined(field.value)) return field.value;
      else if(field.type == 'calculate') return calculate(field, task);
      let model = field.model ? field.model.split('.') : [];
      switch(model.shift()) {
        case 'user':
          if(move) source = Move.findCustomer(move).user;
          break;
        case 'move_transaction':
          if(task && move && move.move_transactions) source = move.move_transactions.filter(mt => {
            return ['pending','authorized','approved','charged','final','declined'].includes(mt.status);
          }).find(mt => {
            return mt.move_task_id == task.id && mt.transaction.id == Transaction.getDefault(task.task.name);
          });
          if(model.length && source) source = _.get(source, model, false);
          break;
        default:
          switch(field.type) {
            case 'address-list':
              if(task) return field.list.map(item =>
                API.getValue(item,task)).filter(item => item);
              break;
            case 'address':
            case 'complete-address':
              if(task) source = task.move_task_addresses;
              if(source && source.length) {
                let match = source.find(item => item.name == field.name);
                if(match) return match;
              }
              break;
            case 'date':
              if(task) source = task.move_task_dates;
              if(source && source.length) {
                let match = source.find(item => item.name == field.name);
                if(match) return match.date;
              }
              break;
            case 'checkout-item':
            case 'integer':
              switch(field.parent) {
                case 'inventory':
                  if(task) source = task.move_task_inventories;
                  if(source && source.length) {
                    let match = source.find(item => item.name == field.name);
                    return match ? match.count : 0;
                  }
                  break;
                case 'supplyList':
                case 'boxes':
                  if(task) source = task.data;
                  if(source && source[field.parent] && field.name in source[field.parent]) {
                    let box = source[field.parent][field.name];
                    return angular.isObject(box) ? (box.count || 0) : box;
                  }
              }
            /* falls through */
            default:
              if(task) source = task.data;
              break;
          }
      }
      if(source && field.name in source) return source[field.name];
    },

    isHidden: (field,data,move) => {
      if(!field.custom || !field.custom.hidden) return false;
      return logicTest(field.custom.hidden,data,move);
    },

    isDisabled: (field,data,move) => {
      if(!field.custom || !field.custom.disabled) return false;
      var disable = logicTest(field.custom.disabled,data,move);
      if(disable && field.value) {
        field.value = null;
        if(field.onChange) field.onChange();
      }
      return disable;
    },

    isReadonly: (field,data,move) => {
      if(field.readonly) return field.readonly;
      if(!field.custom || !field.custom.readonly) return false;
      var readonly = logicTest(field.custom.readonly,data,move);
      return readonly;
    },

    getDefault: (field,data,move) => {
      if(field.default) return field.default;
      if(!field.custom || !field.custom.default || !field.custom.default.field) return;
      let options = field.custom.default;
      if(options.source == 'user' && move) {
        let user = Move.findCustomer(move).user;
        return user ? user[options.field] : null;
      } else if(options.source && move) {
        let defaultField = API.get(options.field,options.source);
        let defaultValue;
        if(defaultField) Move.findMoveTasks(options.source, move).some(task => {
          return defaultValue = API.getValue(defaultField, task);
        });
        return defaultValue;
      }
      else return data[options.field];
    },

    isRequired: (field,data) => {
      if(data && (API.isDisabled(field,data) || API.isHidden(field,data))) return false;
      if(!field.custom || !field.custom.required) return field.required;
      return logicTest(field.custom.required,data);
    },

    addDefaults: (field, data, move) => {
      if(!field.isRequired) field.isRequired = () => API.isRequired(field,data);
      if(!field.isHidden) field.isHidden = () => API.isHidden(field,data);
      if(!field.isDisabled) field.isDisabled = () => API.isDisabled(field,data);
      if(!field.isReadonly) field.isReadonly = () => API.isReadonly(field,data);
      field.default = field.default || API.getDefault(field, data, move);
    },

    customLabel: (field,data) => {
      if(!field.custom || !field.custom.label) return field.label;
      let compareValue = angular.isDefined(data[field.custom.label.compare]) ?
        data[field.custom.label.compare] : false;
      if(!compareValue || !field.custom.label.values[compareValue]) return field.label;
      return field.custom.label.values[compareValue];
    },

    buildSaveData: (field,data) => {
      switch(field.model) {
        case 'user':
          data.user = data.user || {};
          angular.merge(data.user,{[field.name]:formatSaveValue(field)});
          break;
        default:
          data.task = data.task || {};
          switch(field.type) {
            case 'date':
              data.task.dates = data.task.dates || [];
              data.task.dates.push({name: field.name, date:formatSaveValue(field)});
              break;
            case 'address':
            case 'complete-address':
              data.task.addresses = data.task.addresses || [];
              data.task.addresses.push(formatSaveValue(field));
              break;
            case 'inventory':
              data.task.inventories = data.task.inventories || [];
              data.task.inventories.push({name: field.name, count:formatSaveValue(field)});
              break;
            default:
              data.task.data = data.task.data || {};
              if(field.parent) angular.merge(data.task.data,{[field.parent]:{[field.name]:formatSaveValue(field)}});
              else angular.merge(data.task.data,{[field.name]:formatSaveValue(field)});
              break;
          }
          break;
      }
      return data;
    },

    getFormatted: (field,task) => {
      let value = API.getValue(field, task);
      let display = field.label ? field.label + ': ' : '';
      switch(field.type) {
        case 'address-list':
          let exists = field.list.filter(item => {
            return API.getValue(angular.merge({},field,item), task);
          });
          if(!exists.length) return '';
          exists.forEach(item => {
            display += '\n'+API.getFormatted(angular.merge({},field,item),task);
          });
          break;
        case 'address':
        case 'complete-address':
          if(!value) return '';
          display += '\n'+AddressUtil.getFormatted(value, {unit:false});
          if(value.unit)
            display += '\n'+'Unit: '+value.unit;
          if(value.floor)
            display += '\n'+'Floor: '+value.floor;
          if(value.floor > 1)
            display += '\n'+'Elevator: ' + (value.has_elevator ? 'Yes' : 'No');
          break;
        case 'date':
          display += moment(value).format('MM/DD/YYYY');
          break;
        case 'item-list':
          if(field.name == 'inventory') {
            field.groups.forEach((group) => {
              group.items.forEach((item) => {
                let count = API.getValue(item,task);
                display += count ? `${item.label}: ${count}\n` : '';
              });
            });
          } else if(field.name == 'boxes') {
            field.items.forEach((item) => {
              let count = API.getValue(item,task);
              display += count ? `${item.label}: ${count}\n` : '';
            });
          }
          return display;
        case 'toggle':
          display += value ? 'Yes' : 'No';
          break;
        case 'label':
          break;
        case 'select':
          if(!value) return '';
          let selected = field.options.find(option => option.value == value);
          display += (selected ? selected.label : value);
          break;
        default:
          if(!value) return '';
          display += value;
          break;
      }
      return display+'\n';
    },

    modals: {
      edit: (field, move, options) => {
        return Modal.open(angular.merge({
          controller: 'editFieldModalController',
          templateUrl: '/templates/field/edit-field-modal.html',
          resolve: {
            params: () => ({
              field: field,
              move: move
            })
          },
          windowClass: 'bounce',
          overlay: true
        }, options));
      }
    }

  };

  return API;

});
