angular.module('vendor').component('quoteRequestPanel', {
  templateUrl: '/templates/vendor/quote-request-panel.html',
  bindings: {
    vendor: '<',
    quote: '<',
    onClose: '&'
  },
  controller: function($timeout, $q, moment,
    Field, Task, QuoteRequest, AddressUtil, Alerts) {
    'ngInject';

    this.$onInit = () => {
      formatQuote();
      loadFieldValues();
      updateTotal();
    };

    this.fields = [{
      label: 'Moving date',
      name: 'move_date'
    },{
      name: 'time_preference'
    },{
      label: 'Cubic feet',
      name: 'volume',
      type: 'calculate'
    },{
      name: 'professional_packing'
    },{
      label: 'Flights of stairs',
      name: 'flights_of_stairs',
      type: 'calculate'
    }];

    this.addresses = {
      origin: Field.get('from_address','book-movers'),
      stops: Field.get('stops','book-movers'),
      destination: Field.get('to_address','book-movers')
    };

    const updateTotal = () => {
      this.total = this.priceBreakdownFields.reduce((acc,field) => {
        return acc + (field.value || 0);
      },0);
    };

    const loadFieldValues = () => {
      /* MOVE DATA DISPLAY */
      this.fields.forEach(field => {
        angular.merge(field,Field.get(field.name,'book-movers'),angular.copy(field));
      });
      angular.forEach(this.addresses, (address,name) => {
        address.display = 'condensed';
        if(name === 'stops') address.isHidden = () => {
          return !address.list.filter(item => {
            return Field.getValue(angular.merge({},address,item), this.quote.move_task);
          }).length;
        };
      });
      this.routeLink = AddressUtil.getRouteLink(
          Field.getValue(this.addresses.origin,this.quote.move_task),
          Field.getValue(this.addresses.destination,this.quote.move_task),
          Field.getValue(this.addresses.stops,this.quote.move_task)
        );

      /* QUOTE REQUEST INPUTS */
      [...this.priceBreakdownFields,...this.packingFields].forEach(item => {
        item.allowDecimals = true;
        item.positiveValue = true;
        if(this.quote.data) item.value = Math.round(this.quote.data[item.name]) || 0;
        item.onChange = updateTotal;
      });

      this.arrivalWindow = {
        start: {
          name:'arrivalStart',
          label: 'From',
          value: this.quote.arrival_window ? this.quote.arrival_window.from : null,
          appendToBody: false,
          custom: {
            interval: 'hour',
            min: '5:00am',
            max: '9:00pm'
          },
          onChange: () => {
            this.arrivalWindow.end.custom.min = incrementHour(this.arrivalWindow.start.value) || '6:00am';
          },
          isRequired: () => this.arrivalWindow.end.value
        },
        end: {
          name:'arrivalEnd',
          label: 'To',
          value: this.quote.arrival_window ? this.quote.arrival_window.to : null,
          appendToBody: false,
          custom: {
            interval: 'hour',
            min: this.quote.arrival_window && this.quote.arrival_window.from ?
              incrementHour(this.quote.arrival_window.from) : '6:00am',
            max: '10:00pm'
          },
          isRequired: () => this.arrivalWindow.start.value
        }
      };
      angular.forEach(this.arrivalWindow,field => Field.addDefaults(field));

    };

    // build auto quote data
    this.priceBreakdownFields = [{
      name: 'volume_price',
      placeholder: 'Volume price',
      type: 'currency',
      isRequired: () => true
    },{
      name: 'travel_price',
      placeholder: 'Travel price',
      type: 'currency'
    },{
      name: 'tolls_price',
      placeholder: 'Tolls',
      type: 'currency'
    },{
      name: 'stairs_price',
      placeholder: 'Stairs price',
      type: 'currency'
    },{
      name: 'inventory_price',
      placeholder: 'Special Items price',
      type: 'currency'
    },{
      name: 'storage_price',
      placeholder: 'Storage price',
      type: 'currency'
    }];

    this.packingFields = [{
      name: 'packing_price',
      placeholder: 'Packing Only',
      type: 'currency',
      isRequired: () => true,
      onChange: updateTotal
    },{
      name: 'packing_unpacking_price',
      placeholder: 'Packing & Unpacking',
      type: 'currency',
      isRequired: () => this.quote.move_task.data.professional_packing != 'none',
      onChange: updateTotal
    }];

    const formatQuote = () => {
      let promises = {
        boxes: Task.getItemList('boxes'),
        inventories: Task.getItemList('inventories')
      };
      $q.all(promises).then(results => {
        // append the inventory_item to each move_task_inventory (to expose cubic_feet)
        this.inventoryCount = 0;
        this.quote.move_task.move_task_inventories.forEach(inventory => {
          this.inventoryCount += inventory.count;
          results.inventories.some(group => {
            let match = group.items.find(item => item.name == inventory.name);
            if(match) inventory.inventory_item = match;
            return match;
          })
        });
        // create box objects list that includes the db boxes model (to expose cubic_feet)
        if(this.quote.move_task.data && this.quote.move_task.data.boxes) {
          let boxesMap = {};
          this.boxesCount = 0;
          angular.forEach(this.quote.move_task.data.boxes, (count,name) => {
            this.boxesCount += count;
            boxesMap[name] = {
              count: count,
              box: results.boxes.find(b => b.name == name)
            };
          });
          this.quote.move_task.data.boxes = boxesMap;
        }
        // build inventory field-display data
        this.inventoryDisplay = [];
        results.inventories.sort(orderSort).forEach((inventory) => {
          inventory.items.sort(orderSort).forEach((item) => {
            item.type = 'integer';
            item.parent = 'inventory';
            this.inventoryDisplay.push(angular.copy(item));
          });
        });

        // build boxes field-display data
        this.boxesDisplay = [];
        results.boxes.sort(orderSort).forEach((box) => {
          box.type = 'integer';
          box.parent = 'boxes';
          this.boxesDisplay.push(angular.copy(box));
        });

        this.ready = true;
      }).catch(error);
    };

    const orderSort = (a,b) => a.order - b.order;

    const incrementHour = (time) => {
      return time ? moment(time,'h:mma').add(1,'h').format('h:mma') : false;
    };

    const valid = (form) => {
      if(!this.total) return false;
      form.$setSubmitted();
      return !form.$invalid;
    };

    const error = (alert) => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
      if(alert) Alerts.error();
    };

    // add submit function to mark as delivered
    this.submit = (form) => {
      if(this.loading) return;
      if(!valid(form)) return error();
      this.loading = true;

      updateTotal();

      let adjustedPrice = {};
      [...this.priceBreakdownFields,...this.packingFields].forEach(field =>
        adjustedPrice[field.name] = field.value || 0);
      adjustedPrice.base_price = this.total || 0;
      let total_price = this.total;
      if(this.packingFields.find(field => field.name == `${this.quote.move_task.data.professional_packing}_price`))
        total_price += this.packingFields.find(field => field.name == `${this.quote.move_task.data.professional_packing}_price`).value;

      let data = {
        status: 'submitted',
        adjusted_price: adjustedPrice,
        price: total_price,
        note: this.vendor_notes || ''
      };

      if(this.arrivalWindow.start.value && this.arrivalWindow.end.value)
        data.arrival_window = {
          from: this.arrivalWindow.start.value,
          to: this.arrivalWindow.end.value
        };

      QuoteRequest.update(this.quote.id,data).then(response => {
        this.loading = false;
        this.onClose();
      }, error);
    };

  }
});
