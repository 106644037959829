angular.module('vendor').component('screenAutoQuotePacking', {
  templateUrl: '/templates/vendor/screen-packing-costs.html',
  bindings: {
    vendor: '<',
    screen: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,$q,User,Vendor,Task,Field) {
    'ngInject';

    this.user = User.get();

    this.$onInit = () => {
      Task.getItemList(this.screen.model).then((boxes) => {
        this.screen.rows = [];
        boxes.filter(box => box.included_for_packing).forEach((box) => {
          this.screen.rows.push({
            name: box.name,
            label: box.label,
            description: box.description,
            order: box.order,
            fields: [{
              name: `${box.name}_packing`,
              type: 'currency',
              allowDecimals: true,
              required: true,
              default: box.default_packing_cost
            },{
              name: `${box.name}_packing_unpacking`,
              type: 'currency',
              allowDecimals: true,
              required: true,
              default: box.default_packing_unpacking_cost
            }]
          });
        });
        this.screen.rows.sort((a,b) => a.order - b.order);
        this.screen.rows.forEach((row) => {
          row.fields.forEach((field) => {
            field.value = Vendor.details.getValue(field,this.screen,this.vendor);
            if(angular.isUndefined(field.value)) field.value = field.default;
            Field.addDefaults(field);
            if(this.screen.onChange) field.onChange = () => this.screen.onChange(buildData());
          });
        });
        this.ready = true;
      });
    };

    this.next = () => {
      if(this.loading) return;
      this.loading = true;
      if(!valid()) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progress) => {
      let data = buildData();
      data.progress = progress;
      return Vendor.details.update(this.vendor.details.id, data);
    };

    const buildData = () => {
      let data = {};
      data[`${this.screen.name}_data`] = {};
      this.screen.rows.forEach((row) => {
        row.fields.forEach((field) => {
          data[`${this.screen.name}_data`][field.name] = formatData(field);
        });
      });
      return data;
    };

    const formatData = (field) => {
      if(field.type == 'date') return moment(field.value).format('YYYY-MM-DD');
      return field.value;
    };

    let valid = () => {
      this.form.$setSubmitted();
      return !this.form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }
});
