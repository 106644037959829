angular.module('field')
.component('fieldTimePicker', {
  template: `
    <field-select field="$ctrl.field" form="$ctrl.form"></field-select>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function(moment) {
    let allTimes = [],
        min,
        max;

    let setTimeOptions = () => {
      min = this.field.custom ? this.field.custom.min : false;
      max = this.field.custom ? this.field.custom.max : false;
      let filteredTimes = allTimes.filter(time => {
        if((min && moment(time,'h:mma') < moment(min,'h:mma')) ||
         (max && moment(time,'h:mma') > moment(max,'h:mma'))) return false;
        return true;
      });
      this.field.options = filteredTimes.map(t => ({value:t,label:t}));
      if(!filteredTimes.includes(this.field.value)) this.field.value = null;
    };

    this.$onInit = () => {
      let interval = ['00','15','30','45'];
      if(this.field.custom && this.field.custom.interval) {
        switch (this.field.custom.interval) {
          case 'hour':
            interval = ['00'];
            break;
          case '30min':
            interval = ['00','30'];
            break;
          case '15min':
            interval = ['00','15','30','45'];
            break;
          case '10min':
            interval = ['00','10','20','30','40','50'];
            break;
        }
      }
      ['am','pm'].forEach(a => {
        Array.from({length: 12}, (v, i) => i).forEach(h => {
          interval.forEach(m => {
            allTimes.push(`${h||'12'}:${m}${a}`);
          });
        });
      });
      setTimeOptions();
    };

    this.$doCheck = () => {
      if(allTimes.length && this.field.custom && (
        ((this.field.custom.min || min) && this.field.custom.min != min) ||
        ((this.field.custom.max || max) && this.field.custom.max != max))) {
        setTimeOptions();
      }
    };

  }

});
