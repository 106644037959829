angular.module('vendor').component('vendorNav', {
  template: `
    <section class="panel-nav hide-on-mobile"
      ng-class="{'active-banner': $ctrl.vendor && !$ctrl.vendor.has_stripe}">
      <div class="main-logo">
        <svg-sprite alt="Moved" class="logo-image"
          source="moved" icon="icon" />
      </div>
      <button class="button-primary main-action" ng-if="$ctrl.vendor.activate_auto_quotes"
        ui-sref="dashboard.vendor.pricing" ui-sref-active="active">
        Auto Quote {{ $ctrl.vendor.details.is_complete ? 'Pricing' : '' }}
        <svg-sprite alt="Setup" class="icon-plus" ng-if="!$ctrl.vendor.details.is_complete"
          source="icons" icon="plus"></svg-sprite>
      </button>
      <div class="main-nav">
        <nav-item item="item" ng-repeat="item in $ctrl.menu | orderBy: 'order'"
          ng-if="item.show ? item.show() : true"></nav-item>
      </div>
      <div class="bottom-menu"
        ng-class="{'active-banner': $ctrl.vendor && !$ctrl.vendor.has_stripe}">
        <a ui-sref="account">
          <span class="profile">Profile</span>
          <span class="user-name" ng-bind-html="$ctrl.vendor.name"></span>
          <span class="arrow">
            <svg alt="Profile" role="img">
              <use xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="images/sprites/material-icons.svg#keyboard_arrow_down">
              </use>
            </svg>
          </span>
        </a>
      </div>
    </section>`,
  bindings: {
    vendor: '<'
  },
  controller: function ($state, User, _) {
    'ngInject';

    this.menu = [{
      label:'Booked Jobs',
      state: '.jobs',
      order: 0
    },{
      label:'Calendar',
      state: '.calendar',
      order: 10,
      show: () => _.get(this,'vendor.generate_auto_quotes'),
    },{
      label:'Win The Move',
      state: '.quotes',
      order: 20
    }];

    this.home = () => {
      $state.go('.moves',{},{inherit:false, reload:true});
    };

  }
});
