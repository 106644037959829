// create module & set dependencies
angular.module('cx', [
  'ngCookies',
  'ngAnimate',
  'ngSanitize',
  'ui.router',
  'ui.calendar',
  'infinite-scroll',
  'user',
  'move',
  'messages',
  'payment'
]);
angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);
