angular.module('core').factory('S3',
function($http, $q, Data, Upload) {
  'ngInject';

  const S3 = {
    access: (data) => {
      return $http.get(`${Data.apiUrl}/v2/users/me/s3`,{params:data})
        .then((result) => result.data);
    },

    uploadImage: (file, directory) => {
      directory = directory || 'message-uploads';
      return Upload.imageDimensions(file).then((dims) => {
        return S3.access({
          filename:file.name,
          filetype:file.type,
          directory:directory
        }).then((s3data) => {
          return Upload.http({
            url: s3data.signedRequest,
            method: 'PUT',
            headers:{
              Authorization:() => null,
              App:() => null,
              "Content-Type":() => file.type
            },
            data: file
          }).then((response) => {
            s3data.height = file.$ngfHeight;
            s3data.width = file.$ngfWidth;
            delete s3data.signedRequest;
            return s3data;
          });
        });
      });
    },

    uploadImages: (files, directory) => {
      let promises = [];
      files.forEach((file) => {
        promises.push(S3.uploadImage(file, directory));
      });
      return $q.all(promises);
    }

  };

  return S3;

});
