angular.module('user').component('signForm', {
  templateUrl: '/templates/user/sign-form.html',
  bindings: {
    view: '<',
    options: '<'
  },
  controller: function($timeout,$cookies,$state,$q,
    User,Company,Vendor,Facebook,Google,Alerts,Pixel,Analytics,Payment,Data) {
    'ngInject';

    this.webUrl = Data.webUrl;

    this.$onInit = () => {

      let account = {};
      if($cookies.get('commercial')) account.type = 2;
      if($cookies.get('onboarding')) account.onboarding_id = $cookies.getObject('onboarding').id;
      if($cookies.get('channel')) this.channel = true;
      this.account = angular.merge({
        firstname: null,
        lastname: null,
        email: '',
        password: '',
        type: 1
      }, account);

      this.signup = this.view == 'register';
      if($cookies.get('token')) loadTokenData();


    };

    this.$onChanges = (changes) => {
      if(changes.view) this.toggleForm();
    }

    const loadTokenData = () => {
      let token = $cookies.getObject('token');
      if(token.email) {
        this.readOnlyEmail = true;
        this.account.email = token.email;
      }
      switch(token.token_type) {
        case 'vendor':
          this.vendor = token.vendor;
          angular.merge(this.account,{
            level: 1,
            type: 4,
            invite_tok: token.invite_tok,
          });
          break;
        case 'channel-partner':
          angular.merge(this.account,{
            level: 2,
            type: 2,
          });
          break;
        case 'concierge':
          angular.merge(this.account,{
            level: 1,
            type: 3,
          });
          break;
      }
    }

    this.forgotPassword = () => User.modals.forgotPassword();

    this.toggleForm = () => {
      this.signup = !this.signup;
      restoreFixedEmail();
    };

    const restoreFixedEmail = () => {
      if(this.signup && this.readOnlyEmail)
        this.account.email = $cookies.getObject('token').email;
    };

    var valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    this.submit = (form) => {
      if(this.loading) return false;
      if(!valid(form)) return error();
      this.loading = true;
      if(!this.signup) login();
      else register();
    };

    const login = () => {
      $q.all({
        login: User.login({
          email: this.account.email.toLowerCase(),
          password: this.account.password
        }),
        min: $timeout(() => true, 500)
      }).then(postLogin, (result) => {
        if((result.status == 404) || (result.status == 401 &&
          result.data.message == 'Password incorrect'))
          return error(`Invalid user and password combination. Please double check and try again.`);
        error();
        Alerts.error();
      });
    }

    const postLogin = () => {
      cleanCookies();
      success('Welcome back '+User.get().firstname+'!');
      $state.go('dashboard');

      if(this.options && this.options.onLogin) this.options.onLogin();
      if(this.options && this.options.onComplete) this.options.onComplete();

    }

    const register = () => {
      // add source tracking data
      appendSource();
      // submit the data
      User.register(this.account).then(postRegister, (result) => {
        error();
        if(result.status == 409)
          return Alerts.error({msg:'This email address is already in use by another user.'});
        if(result.status == 403 && result.data && result.data.message &&
          result.data.message.indexOf('Invalid invite token') !== -1)
          return Alerts.error({msg:'Uh Oh! This link has expired. Please email us to request a new one.'});
        Alerts.error();
      });
    }

    const postRegister = () => {
      let user = User.get();
      // notify the user of success
      let msg = 'Welcome '+User.get().firstname+'!' +
        (User.get().role == 'mover' ? ' Let&apos;s get you Moved.' : '');
      success(msg);

      // track the conversion
      Pixel.FB.track('CompleteRegistration');
      Analytics.trackEvent('Users','Register',User.get().fullname);

      // clean the cookies
      cleanCookies();

      // launch stripe connect modal if vendor
      if(user.role == 'vendor') $timeout(() => Payment.modals.stripeConnect(this.vendor),500);

      // go to dashboard (pass parameter to trigger welcome modal if customer)
      $state.go('dashboard',{ triggerWelcome: user.role == 'mover' },{reload:true});

      if(this.options && this.options.onRegister) this.options.onRegister();
      if(this.options && this.options.onComplete) this.options.onComplete();

    }

    const cleanCookies = () => {
      if($cookies.get('referrer')) $cookies.remove('referrer');
      if($cookies.get('channel')) $cookies.remove('channel');
      if($cookies.get('coupon')) $cookies.remove('coupon');
      if($cookies.get('campaign')) $cookies.remove('campaign');
      if($cookies.get('token')) $cookies.remove('token');
      if($cookies.get('commercial')) $cookies.remove('commercial');
      if($cookies.get('lead_id')) $cookies.remove('lead_id');
      if($cookies.get('onboarding')) $cookies.remove('onboarding');
    }

    const appendSource = (v1) => {
      let source = {};
      if($cookies.get('referrer')) source.referrer = $cookies.get('referrer');
      if($cookies.get('channel')) source.channel = $cookies.get('channel').toLowerCase();
      if($cookies.get('coupon')) source.coupon = $cookies.get('coupon');
      if($cookies.get('campaign')) source.campaign = $cookies.get('campaign');
      if($cookies.get('token')) {
        let token = $cookies.getObject('token');
        if(v1) this.account.token = token.access_token;
        if(token.data) angular.extend(source,token.data);
        if(token.token_type == 'channel-mover') {
          source['channel-partner'] = token.user.id;
          source.referrer = token.user.email;
          source.channel = 'channel-partner';
        }
      }
      if(!angular.equals(source,{})) this.account.source = source;
      if($cookies.get('lead_id')) this.account.lead_id = parseInt($cookies.get('lead_id'));
    }

    const success = (msg) => {
      this.loading = false;
      Alerts.success({
        msg: msg
      });
    };

    const error = (msg) => {
      this.loading = false;
      this.errorMessage = msg || null;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
      return false;
    };


    /* SOCIAL */
    this.facebookLogin = () => {
      if(this.loading) return false;
      this.loading = true;
      Facebook.login().then((result) => {
        submitSocialLogin('facebook',result.authResponse.accessToken);
      }, (reason) => {
        let msg = 'Sorry, we were unable to complete your Facebook login.';
        if(reason == 'email') msg = `Sorry, we need you to share your email
          to complete Facebook login.`;
        error(msg);
      });
    };

    this.googleLogin = () => {
      if(this.loading) return false;
      this.loading = true;
      Google.login().then(result => {
        submitSocialLogin('google',result.id_token,result.access_token);
      }, err => {
        error('Sorry, we were unable to complete your Google login.');
      });
    };

    const submitSocialLogin = (service,oauth_token,access_token) => {
      appendSource(true);
      this.account.provider = service;
      this.account.oauth_token = oauth_token;
      if(access_token) this.account.access_token = access_token;
      delete this.account.password;
      User.socialLogin(this.account).then((data) => {
        if(data) postRegister();
        else postLogin();
      },(data,code) => {
        error('There was a problem with your login, please try again.');
      });
    };

  }
});
