// directive to convert task screen into a compiled component
angular.module('tasks').directive('screenGenerator',
function($compile, $parse) {
  'ngInject';

  return {
    restrict: 'A',
    link: (scope, element, attr) => {
      const type = $parse(attr.screenGenerator)(scope).type.replace('_','-');
      let markup = `
        <screen-${type} class="screen vcenter-block"
          screen="${attr.screenGenerator}"
          move="$ctrl.move"
          task="$ctrl.task"
          flow="$ctrl.flow"
          vendor="$ctrl.vendor"
          form="$ctrl.form"
          on-close="$ctrl.onClose()"
          on-next="$ctrl.nextScreen(callback,next)"></screen-${type}>`;
      element.html(markup);
      $compile(element.contents())(scope);
    }
  }
});
