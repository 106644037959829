angular.module('core').directive('positiveValue',
function() {
  return {
    restrict: 'A',
    require: '?ngModel',
    scope: {
      positiveValue: '='
    },
    link: function(scope, element, attrs, ctrl) {
      if(!ctrl) return;
      ctrl.$validators.positiveValue = (modelValue,viewValue) => {
        return !scope.positiveValue || ctrl.$isEmpty(viewValue) || parseFloat(viewValue) >= 0;
      };
      attrs.$observe('positiveValue',() => ctrl.$validate());
    }
  };
});
