angular.module('tasks').component('screenMoveTransaction', {
  template: '<transaction-editor move="$ctrl.move" move-task="$ctrl.task" on-save="$ctrl.next()" form="$ctrl.form"></transaction-editor>',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&'
  },
  controller: function(Task) {
    'ngInject';

    this.next = () => {
      if(this.loading) return;
      this.loading = true;
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      return Task.update(this.task.id,progressData);
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

  }
});
