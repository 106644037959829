angular.module('core').controller('headerController',
function ($scope,$state,$timeout,$interval,Menu,User,Alerts) {
  'ngInject';

  const name = 'main';

  $scope.user = User.get();

  $scope.toggleMobileNav = () => Menu.toggleMobileNav(name);

  $scope.showMobileNav = () => Menu.showMobileNav(name);

  $scope.home = () => $state.go('dashboard',{},{inherit:false});

});
