angular.module('tasks').controller('boxesEstimatesModalController',
  function ($scope, $uibModalInstance, Task, Field, params) {
    'ngInject';

    $scope.moveSize = Field.getValue(Field.get('move_size','book-movers'), params.task);
    Task.findItems('box_estimates',{where:{move_size:$scope.moveSize}}).then((estimates) => {
      $scope.estimates = estimates;
      $scope.estimates.forEach(box => {
        box.label = params.boxes.find(b => b.name == box.box_name).label;
      });
    });

    $scope.options = [{
      label: 'Light',
      value: 'light'
    },{
      label: 'Average',
      value: 'avg'
    },{
      label: 'More',
      value: 'more'
    }];

    $scope.select = (option) => {
      if(!valid($scope.form)) return error();
      let counts = $scope.estimates.map(box => ({name: box.box_name, count: box[`count_${option}`]}));
      $uibModalInstance.close(counts);
    };

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    let error = () => {
      $scope.error = true;
      $timeout(() => { $scope.error = false; }, 300);
    };

  });
