angular.module('field').component('fieldDisplay', {
  template: `<div ng-include="$ctrl.templateUrl"></div>`,
  bindings: {
    field: '<',
    task: '<',
    move: '<?'
  },
  controller: function(Move, Task, Field, AddressUtil, Image, Messages) {
    'ngInject';

    let hasTemplate = [
      'arrival_window',
      'label',
      'item-list',
      'grouped-item-list',
      'date',
      'toggle',
      'complete-address',
      'address-list',
      'photos',
      'currency',
      'phone_number',
      'checklist',
      'decide',
      'select'];

    this.$onInit = () => {
      this.template = hasTemplate.indexOf(this.field.type) == -1 ? 'default' : this.field.type;
      this.templateUrl = `/templates/field/display-field-${this.template}.html`;
      this.moverIsExternal = this.move ? Move.findCustomer(this.move).user.external : true;
      if(this.field.type === 'address-list') {
        this.fields = [];
        this.field.list.forEach(field => this.fields.push(angular.merge({},this.field,field)));
      }
      if(['item-list','grouped-item-list'].includes(this.field.type) && this.field.model)
        Task.getItemList(this.field.model).then(results => {
          this.field.itemList = angular.copy(results);
          if(this.field.itemList.find(group => group.items))
            this.field.itemList.sort(orderSort).forEach(group => createDisplayItemList(group.items));
          else createDisplayItemList(this.field.itemList);
          this.ready = true;
        });
    };

    const orderSort = (a,b) => a.order - b.order;
    const createDisplayItemList = (itemList) => {
      return itemList.sort(orderSort).forEach((item) => {
        item.type = 'integer';
        item.parent = this.field.name;
      });
    };

    this.getValue = (field) => Field.getValue(field || this.field, this.task, this.move);

    this.isDefinedValue = (field) => {
      field = field || this.field;
      let value = this.getValue(field);
      if(['address','complete-address'].includes(field.type))
        return value && value.google_place_id;
      if(this.field.type == 'address-list') return this.fields.find(field => this.isDefinedValue(field));
      return angular.isDefined(value) && value !== null;
    };

    this.getValueLabel = () => {
      let value = this.getValue();
      let option = this.field ? this.field.options.find(option => option.value == value) : false;
      return option ? option.label : value;
    };

    // open a modal to view a set of photos
    this.photosModal = (photos) => {
      let images = photos.map((photo) => photo.url);
      Image.modals.view(images);
    };

    this.editDataModal = () => {
      let field = angular.copy(this.field);
      field.value = this.getValue();
      Field.modals.edit(field,this.move);
    };

    this.editAddressModal = (field) => {
      field = angular.copy(field || this.field);
      let onChange = field.onChange || angular.noop;
      delete field.onChange;
      field.value = this.getValue(field);
      if(field.type === 'complete-address' && field.editable)
        Task.modals.address(field).result.then((address) => {
          if(address && !field.saveOnEdit) this.field.value = address;
          onChange();
        }).catch(angular.noop);
    };

    this.editScreenModal = () => {
      Task.modals.editScreen(this.task, this.field.screen, this.move);
    };

  }
});
