angular.module('tasks').controller('requestQuoteModalController',
  function($uibModalInstance, $scope, $filter, Move, Task, Field, QuoteRequest, Alerts, params) {
  'ngInject';

  $scope.task = params.task;

  $scope.vendorList = [{}];

  $scope.setVendor = (vendor,index) => {
    angular.merge($scope.vendorList[index],vendor);
  };

  $scope.vendorOptions = {filter:{params:{where:{user:{$ne:null}}}}};

  $scope.addVendor = () => $scope.vendorList.push({});
  $scope.removeVendor = (index) => $scope.vendorList.splice(index,1);

  // add submit function to mark as delivered
  $scope.submit = (form) => {
    if($scope.loading) return;
    if(!valid(form)) return false;
    $scope.loading = true;
    let data = {
      vendors: $scope.vendorList.map(vendor => vendor.id),
      note: $filter('cleanHTML')($scope.note,{multiline:true})
    }
    Task.sendQuoteRequests(params.task.id,data).then((response) => {
      $uibModalInstance.close();
    });
  };

  const valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

});
