angular.module('user').controller('registerSpecialController',
function($scope, $state, $stateParams, $cookies, $timeout, User, Alerts, Token, Access) {
  'ngInject';

  var user = User.get();
  if(user.id) {
    Alerts.info({ msg: 'Already signed in as ' + user.fullname + '!' });
    $state.go('dashboard');
    return;
  }

  if($stateParams.token.split('.').length > 1) {
    let token = Token.decode($stateParams.token);
    if(token) {
      token.token_type = 'vendor';
      token.invite_tok = $stateParams.token;
      $cookies.putObject('token', token);
      Access.forceLogin(() => true, {data: { view: 'register' }});
    } else invalid();
  }
  else Token.verify($stateParams.token).then((token) => {
    if(token && token.token_type) {
      $cookies.putObject('token',token);
      switch(token.token_type) {
        case 'channel-mover':
          $state.go('channelPartnerLanding');
          break;
        case 'channel-partner':
        case 'concierge':
          Access.forceLogin(() => true, {data: { view: 'register' }});
          break;
        default:
          invalid();
      }
    }
    else invalid();
  }, invalid);

  function invalid() {
    Alerts.info({ msg: 'Uh oh. This link is invalid.' });
    $timeout(() => $state.go('home'), 5000); // need to show the message
  }


});
