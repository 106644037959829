angular.module('move').factory('Partner',
function($http, Data, Modal, User) {
  'ngInject';

  const API = {
    get: (source) => {
      return $http.get(`${Data.apiUrl}/v2/partners/${source}`).then(r => r.data);
    },
    getLocationContacts: (id) => {
      return $http.get(`${Data.apiUrl}/v2/partner_locations/${id}/contacts`).then(r => r.data);
    },
    modals: {}
  };

  return API;

});
