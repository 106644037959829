angular.module('field')
.component('fieldCompleteAddress', {
  template: `
    <div class="field-row" ng-repeat="field in $ctrl.fields" field-generator="field" slide-toggle="!field.isHidden()"
      ng-class="[field.name,{'error':$ctrl.form[field.name].$invalid && $ctrl.form.$submitted}]">`,
  bindings: {
    field: '=',
    screen: '<',
    form: '<'
  },
  controller: function(Field,AddressUtil) {
    'ngInject';

    const setFields = () => {
      this.fields = [{
        label: 'Address',
        name: this.field.name,
        type: 'address',
        required: true
      },{
        name: 'type',
        type: 'switch',
        label: 'Property type',
        required: true,
        values: AddressUtil.types
      },{
        name: 'unit',
        type: 'text',
        label: 'Unit',
        placeholder: 'Unit',
        custom: {
          hidden: {
            compare: { field: 'type' },
            boolean: '!in',
            value: ['apartment','other']
          }
        }
      },{
        name: 'floor',
        type: 'select',
        label: 'Floor',
        options: AddressUtil.floors,
        required: true,
        custom: {
          hidden: {
            compare: { field: 'type' },
            boolean: '!in',
            value: ['apartment','other']
          }
        }
      },{
        name: 'flights_of_stairs',
        type: 'select',
        label: 'Stairs/Elevator',
        options: AddressUtil.stairs,
        required: true,
        custom: {
          hidden: {
            compare: { field: 'type' },
            boolean: '!in',
            value: ['apartment','other']
          },
          readonly: {
            compare: { field: 'floor'},
            boolean: 'in',
            value: AddressUtil.floors.filter(f => f.requireElevator).map(f => f.value)
          }
        }
      },{
        name: 'deed',
        type: 'switch',
        label: 'Ownership',
        required: true,
        values: AddressUtil.ownership,
        custom: {
          hidden: {
            compare: { field: 'type' },
            boolean: '!in',
            value: ['house','apartment','other']
          }
        }
      }].filter(f => f.type == 'address' || this.field.include[f.name]);
    };

    this.$onInit = () => {
      setFields();
      this.field.value = this.field.value || {};
      this.fields.forEach((field) => {
        Field.addDefaults(field,this.field.value);
        if (field.type == 'address') {
          field.value = field.value || {};
          transferAddressData(this.field.value,field.value);
          field.formatted = AddressUtil.getFormatted(field.value);
          field.onChange = () => {
            transferAddressData(field.value,this.field.value);
            if(this.field.onChange) this.field.onChange();
          }
        }
        else field.value = this.field.value[field.name];
        switch(field.name) {
          case 'floor':
            field.onChange = () => {
              // stairs value depends on floor
              let stairsField = this.fields.find(f => f.name == 'flights_of_stairs');
              if(stairsField) {
                let floor = AddressUtil.floors.find(f => f.value == field.value);
                if(floor && floor.requireElevator) {
                  stairsField.value = true;
                  stairsField.onChange();
                } else if (angular.isUndefined(stairsField.value)) {
                  switch(field.value) {
                    case 'basement':
                      stairsField.value = 1;
                      break;
                    case 'ground':
                      stairsField.value = 0;
                      break;
                    default:
                      stairsField.value = parseInt(field.value) - 1;
                  }
                  stairsField.onChange();
                }
              }
              this.field.value[field.name] = field.value;
              if(this.field.onChange) this.field.onChange();
            };
            break;
          case 'flights_of_stairs':
            if(this.field.value.has_elevator) field.value = true;
            field.onChange = () => {
              if(field.value === true) {
                this.field.value.has_elevator = true;
                this.field.value[field.name] = 0;
              }
              else {
                this.field.value.has_elevator = false;
                this.field.value[field.name] = field.value;
              }
              if(this.field.onChange) this.field.onChange();
            };
            break;
          case 'type':
            field.onChange = () => {
              this.field.value[field.name] = field.value;
              this.fields.forEach(f => {
                if(f.isHidden()) {
                  f.value = null;
                  f.onChange();
                }
              });
              if(this.field.onChange) this.field.onChange();
            };
            break;
          default:
            if(!field.onChange) field.onChange = () => {
              this.field.value[field.name] = field.value
              if(this.field.onChange) this.field.onChange();
            };
            break;
        }
      });
    };

    let transferAddressData = (fromData, toData) => {
      if(!fromData) return;
      toData.street = fromData.street;
      toData.city = fromData.city;
      toData.state = fromData.state;
      toData.zipcode = fromData.zipcode;
      toData.google_place_id = fromData.google_place_id;
    };

  }
});
