angular.module('tasks').component('screenAddressList', {
  templateUrl: '/templates/tasks/screen-address-list.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,User,Task,Move,Field,Alerts,AddressUtil) {
    'ngInject';

    this.$onInit = () => {
      this.addresses = [];
      this.user = Move.findCustomer(this.move).user;
      let initial = Field.getValue(Field.get('address','clean'),this.task) || {};
      // create list of all existing addresses from all book-movers tasks
      let bookMoversTasks = this.move.move_tasks ? this.move.move_tasks.filter(mt => mt.task.name == 'book-movers') : false;
      if(bookMoversTasks && bookMoversTasks.length) {
        bookMoversTasks.sort((a,b) => { return (new Date(b.created_at) - new Date(a.created_at))}).forEach(task => {
          ['from_address','to_address'].forEach(name => {
            let address = Field.getValue(Field.get(name,'book-movers'),task);
            if (address) this.addresses.push(address);
          });
        });
      }
      this.custom = {};

      this.addresses.forEach((address,index) => {
        if(matches(address,initial)) this.selected = `${index}`;
      });
      if(angular.isUndefined(this.selected) && !angular.equals({},initial)) {
        this.selected = 'custom';
        this.custom = angular.copy(initial);
      }

      this.screen.fields.forEach((field) => {
        Field.addDefaults(field,this.custom);
        if(field.type == 'complete-address') {
          field.value = field.value || {};
          transferAddressData(this.custom,field.value);
          field.formatted = AddressUtil.getFormatted(this.custom, {unit:false});
        }
        else field.value = this.custom[field.name];
        field.customLabel = () => Field.customLabel(field,this.custom);
        field.onChange = () => {
          if(field.type == 'complete-address') transferAddressData(field.value,this.custom);
          else this.custom[field.name] = field.value;
          if(this.screen.onChange) this.screen.onChange(buildData());
        };
      });

    };

    this.formatted = (address) => {
      return AddressUtil.getFormatted(address);
    };

    let transferAddressData = (fromData, toData) => {
      toData.street = fromData.street;
      toData.city = fromData.city;
      toData.state = fromData.state;
      toData.zipcode = fromData.zipcode;
      toData.google_place_id = fromData.google_place_id;
      toData.type = fromData.type;
      toData.unit = fromData.unit;
      toData.floor = fromData.floor;
    };

    this.next = (skip) => {
      if(this.loading) return;
      this.loading = true;
      if(skip) return this.onNext();
      if(!valid(this.form)) return error();
      this.onNext({callback:this.save}).then((result) => {
        this.loading = false;
      }, error);
    };

    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      data = data || {};
      let selectedAddress = this.selected == 'custom' ? this.custom : this.addresses[this.selected];
      Field.buildSaveData({
        name: this.screen.model,
        value: angular.merge({},selectedAddress,{name:this.screen.model}),
        type: 'address'
      },data);
      return data;
    };

    let valid = (form) => {
      form.$setSubmitted();
      return this.selected == 'custom' ? !form.$invalid :
        angular.isDefined(this.selected);
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };

    const matches = (a1,a2) => {
      if(!a1 || !a2) return false;
      if(a1.google_place_id != a2.google_place_id) return false;
      if(a1.unit != a2.unit) return false;
      if(a1.floor != a2.floor) return false;
      return true;
    }

  }
});
