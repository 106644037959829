angular.module('core')
  .directive('fixWidth', function($timeout) {
    'ngInject';

    let css_time_to_milliseconds = (time_string) => {
      var num = parseFloat(time_string, 10),
          unit = time_string.match(/m?s/);
      if (unit) unit = unit[0];
      switch (unit) {
        case "s": return num * 1000;
        case "ms": return num;
        default: return 0;
      }
    };

    return {
      priority: 1,
      restrict: 'A',
      multiElement:true,
      link: function(scope, element, attr) {
        scope.$watch(attr.fixWidth, function(value,old) {
          let width = element[0].getBoundingClientRect().width; //unrounded
          if(value) element.css({
            width:Math.floor(width),
            minWidth:Math.floor(width),
            maxWidth:Math.floor(width)
          });
          else $timeout(() => element.css({
            width:'',
            minWidth:'',
            maxWidth:''
          }), css_time_to_milliseconds(element.css('transition-duration')));
        });
      }
    };
  });
