angular.module('tasks').component('screenComplete', {
  templateUrl: '/templates/tasks/screen-complete.html',
  bindings: {
    screen: '<',
    task: '<',
    move: '<',
    onNext: '&',
    onClose: '&',
  },
  controller: function(Task,Field,Move,Partner,Transaction) {
    'ngInject';

    this.$onInit = () => {
      // find any active primary transactions for this task
      this.transaction = this.move.move_transactions.filter(mt => {
        return ['pending','authorized','approved','charged','final','declined'].includes(mt.status);
      }).find(mt => {
        return mt.move_task_id == this.task.id && mt.transaction.id == Transaction.getDefault(this.task.task.name);
      });

      // format the fields for this screen
      if(this.screen.fields) this.screen.fields.forEach(field => {
        angular.merge(field,Field.get(field.name,this.task.task.name),field);
        // handle custom hide/show for cost values based on the status of the transaction
        if(field.custom &&
          field.custom.statusFilter &&
          !field.custom.statusFilter.includes(this.transaction.status))
          field.isHidden = () => true;
        // custom override to hide the vendor name if the fallback vendor 'Moved' is used
        if(field.name == 'name' && field.model == 'move_transaction.vendor')
          field.isHidden = () => this.transaction && this.transaction.vendor && this.transaction.vendor.name == "Moved";
      });

      switch(this.task.task.name) {
        case 'book-movers':
          // allow cancel move if transaction is not 'final'
          if(this.transaction && this.transaction.status !== 'final') this.cancelMove = () => {
            Move.modals.initiateCancelMove(this.task).result.then(this.onClose);
          };
          // if an address is associated with a partner, show partner contact info on the screen
          let partnerAddress = this.task.move_task_addresses.find(address => address.partner_location);
          if(partnerAddress) {
            Partner.getLocationContacts(partnerAddress.partner_location.id).then(contacts => {
              if(contacts && contacts.length) this.partnerContact = contacts[0];
            });
          }
          break;
        case 'update-address':
        case 'packing-materials':
        case 'storage':
        case 'clean':
        case 'finalize':
          break;
        default:
          this.screen.title = 'Finished!';
          this.screen.subtitle = `Your assistant is on it and will send you an update
            shortly.`;
          this.screen.button = 'Done';
          break;
      }
    };

    this.complete = () => {
      this.onClose();
    };

    this.review = () => {
      this.onNext({name:'review'})
    }

  }

});
