angular.module('field')
.component('fieldAddress', {
  template: `
    <input type="text" name="{{$ctrl.field.name}}"
      id="address-{{$ctrl.uuid}}"
      ng-model="$ctrl.field.formatted"
      google-places-autocomplete options="$ctrl.options"
      initial-place="$ctrl.field.value.google_place_id"
      details="$ctrl.details"
      placeholder=""
      autocomplete="false"
      ng-readonly="$ctrl.field.isReadOnly()"
      ng-required="$ctrl.field.isRequired()"
      ng-disabled="$ctrl.field.isDisabled()"
      ng-blur="$ctrl.validate()" />
    <label class="placeholder" for="address-{{$ctrl.uuid}}"
      ng-bind-html="$ctrl.field.label"></label>
    <label class="error" ng-if="$ctrl.field.isRequired() &&
      $ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      Required.
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function($scope, AddressUtil) {
    'ngInject';

    let init = true;
    this.uuid = Date.now();

    this.options = {
      types: 'geocode',
      country: 'us',
      watchEnter: true
    }

    this.validate = () => {
      if(!this.details && (!this.field.value || !this.field.value.google_place_id))
        this.field.formatted = '';
      if(!this.field.formatted) this.details = false;
    };

    $scope.$watch(() => this.details, (newVal,oldVal) => {
      if(init) return init = false;
      if(this.details) AddressUtil.updateComponents(this.details, this.field);
      else delete this.field.value;
      if(this.field.onChange) this.field.onChange();
    }, true);

  }
});
