angular.module('cx').component('taskSummary', {
  templateUrl: '/templates/cx/task-summary.html',
  bindings: {
    move: '<',
    task: '<',
  },
  controller: function($rootScope, $q, $timeout, $filter, _, Data,
    Move, Task, Field, AddressUtil, MoveQuote, Transaction, Alerts, Messages) {
    'ngInject';

    // static lists needed for formatting an active move
    let taskList = false,
        itemLists = {
          inventories: false,
          boxes: false,
          supply_lists: false
        },
        listeners = [];

    this.$onInit = () => {
      this.customer = Move.findCustomer(this.move).user;
      this.isDirectApp = Move.isDirectApp(this.move);
      // Preload the static data from the db
      let promises = {};
      promises.tasks = Task.getList();
      for(let list in itemLists) {
        promises[list] = Task.getItemList(list);
      }
      $q.all(promises).then((results) => {
        if(results.tasks) taskList = results.tasks;
        for(let list in itemLists) {
          if(results[list]) {
            itemLists[list] = angular.copy(results[list]).sort((a,b) => a.order - b.order);
            itemLists[list].forEach((item) => {
              if(item.items) item.items.sort((a,b) => a.order - b.order);
            });
          }
        }
        formatMoveTask();
        this.ready = true;
      });
    };

    // cleanup
    this.$onDestroy = () => listeners.forEach(deregister => deregister());

    // handle updates to the active move
    this.$onChanges = changes => {}

    const formatMoveTask = () => {
      this.task.task = taskList.find(task => task.id == this.task.task.id);
      if(this.task.task.name == 'book-movers') {
        this.task.data = this.task.data || {};
        this.task.show = !this.task.is_complete;

        // mark task as read if open on load
        if(this.task.show) $timeout(() =>
          Task.read(this.task.id).then((result) => this.task.seen_at = result), 2000);

        // prep data for "view route link"
        const addresses = {
          origin: Field.get('from_address','book-movers'),
          destination: Field.get('to_address','book-movers')
        };
        angular.forEach(addresses, (address) => {
          address.value = Field.getValue(address, this.task);
        });
        this.task.routeLink = (addresses.origin.value && addresses.destination.value) ?
          AddressUtil.getRouteLink(addresses.origin.value, addresses.destination.value) : false;

        // prep data for "copy to clipboard"
        this.quoteText = () => MoveQuote.getFormatted(this.move, this.task, itemLists.inventories, itemLists.boxes);
        this.clipboardSuccess = () => {
          this.quoteTextCopied = true;
          Alerts.success({msg:'Woohoo! Quote request copied to clipboard!'})
          $timeout(() => this.quoteTextCopied = false, 2000);
        };

        // prep data for partner info
        let metadata = _.get(this.move,'partner.metadata');
        if(metadata) {
          let logo = _.get(metadata, 'partner_logo');
          if(logo) this.partner_logo = logo.includes('/partners/') ?
            `${Data.s3AssetUrl}${logo}` :
            `${Data.s3AssetUrl}/partner-logos/${logo}`;
          this.partner_link = _.get(metadata, 'cx_doc_url') || false;
        }


      }
      // load the flow
      this.task.flow = this.task.flow || Task.getFlow(this.task, this.move);

      // populate the "display" list with all fields from this flow annotated with
      // labels for each screen as dividers
      if(!this.task.display) {
        this.task.display = [];
        // add coupon amount if applicable
        if(this.task.task.name == 'book-movers' && this.task.flow_variant == 'storage') {
          this.task.display.push({
            view: 'concierge',
            type: 'currency',
            label: 'Coupon Amount',
            name: 'coupon_amount_suggestion'
          });
        }
        this.task.flow.forEach((screen) => {
          // label each screen
          if(screen.label) this.task.display.push({
            view: 'concierge',
            type: 'label',
            label: screen.label,
            screen: screen.name,
            editable: screen.editable,
            task: this.task.task.name,
            name: `${screen.name}-label`
          });

          // handle special screen types that don't have explicit field lists
          if(['grouped-item-list','item-list','checkout','special_items'].includes(screen.type)) {
            itemLists[screen.model].forEach((model) => {
              if(model.items) model.items.forEach(item => {
                item.type = 'integer';
                item.parent = screen.parent || screen.name;
                this.task.display.push(angular.copy(item));
              });
              else {
                model.type = 'integer';
                model.parent = screen.name;
                this.task.display.push(angular.copy(model));
              }
            });
          }
          // add all explicitly listed fields
          if(screen.fields) screen.fields.forEach((field) => {
            let displayField = angular.extend({},field,{
              task_id: this.task.id,
              view: 'concierge',
              display: 'summary',
              complete: true
            });
            if(displayField.model == 'user') displayField.value = this.customer[field.name];
            this.task.display.push(displayField);
          });
        });
      }

    };

    this.taskClasses = () => {
      let classes = [];
      if(!this.task.seen_at || new Date(this.task.created_at) > new Date(this.task.seen_at))
        classes.push('new-task');
      if(this.task.seen_at && new Date(this.task.updated_at) > new Date(this.task.seen_at))
        classes.push('updated-task');
      return classes;
    }

    // hide/show task sections
    this.taskToggle = () => {
      this.task.show = !this.task.show;
      if(this.task.show) Task.read(this.task.id).then((result) => this.task.seen_at = result);
    };

    // get a task title (custom logic)
    this.taskTitle = () => {
      if(this.task.data && this.task.data.label) return this.task.data.label;
      else return this.task.task.label;
    };

    // get a task description
    this.taskDescription = () => {
      if(this.task.task.name == 'book-movers') {
        let description = [];
        let moveDate = Field.getValue(Field.get('move_date','book-movers'),this.task);
        if(moveDate) description.push(moment(moveDate).format('MM/DD/YYYY'));
        let coupon = _.get(this.task,'data.coupon_amount_suggestion');
        if(this.task.flow_variant == 'storage' && coupon)
          description.push(`${$filter('currency')(coupon/100,'$',0)} coupon`);
        return description.length ? description.join(', ') : 'TBD';
      }
      else if (this.task.task.name == 'finalize' && this.task.data && this.task.data.book_movers_task_id) {
        let moveDate = Field.getValue(Field.get('move_date','book-movers'),Move.findMoveTask(this.task.data.book_movers_task_id,this.move));
        return moveDate ? moment(moveDate).format('MM/DD/YYYY') : 'TBD';
      }
      else if (this.task.task.name == 'packing-materials' && this.task.is_complete) {
        if(!this.task.move_transactions || !this.task.move_transactions.length) return;
        let transaction = Task.getPrimaryTransaction(this.task, this.move.move_transactions);
        return transaction ? moment(transaction.created_at).format('MM/DD/YYYY') : '';
      }
      return false;
    }

    /* ACTIONS MENU */
    this.viewTransactions = () => Transaction.modals.viewByTask(this.task,this.move,this.deleteTransaction);
    this.viewQuotes = () => Task.modals.viewQuotes(this.move, this.task);
    this.taskComplete = () => {
      if(this.task.loading) return;
      this.task.loading = true;
      Task.update(this.task.id,{is_complete:!this.task.is_complete}).then((result) => {
        Task.read(this.task.id).then((updated) => {
          result.seen_at = updated;
          angular.merge(this.task,result);
          if(this.task.is_complete) this.task.show = false;
          this.task.loading = false;
        });
      });
    };
    this.toggleTaskMenu = () => {
      this.move.move_tasks.forEach((move_task) => {
        if(move_task.id != this.task.id || this.task.showMenu) closeTaskMenu(move_task);
        else openTaskMenu(this.task);
      });
    };
    const openTaskMenu = (task) => task.showMenu = true;
    const closeTaskMenu = (task) => task.showMenu = false;

    // function to add a new move transaction
    this.createTransaction = () => {
      closeTaskMenu(this.task);
      Transaction.modals.create(this.move, this.task).result.then((result) => {
        Alerts.success({msg:'Transaction Created', delay:3});
      }).catch(angular.noop);
    };

    // send shortcut message
    this.createTaskShortcut = () => {
      if(this.task.loading) return;
      this.task.loading = true;
      closeTaskMenu(this.task);
      Messages.send({
        move: this.move.id,
        type: 5,
        data: { move_task: this.task.id },
        auto_generated: true
      }).then((result) => {
        this.task.loading = false;
      });
    };

    /* Progress Section */
    this.progressSteps = [{
      label: 'Origin COI',
      name: 'originCoi',
      optional: true
    },{
      label: 'Origin Elevator',
      name: 'originElevator',
      optional: true
    },{
      label: 'Destination COI',
      name: 'destinationCoi',
      optional: true
    },{
      label: 'Destination Elevator',
      name: 'destinationElevator',
      optional: true
    },{
      label: 'Copy of Coupon Received',
      name: 'copyOfCoupon',
      optional: true,
      isHidden: () => {
        let transaction = Task.getPrimaryTransaction(this.task, this.move.move_transactions);
        return _.get(transaction,'coupon',0) <= 0;
      }
    },{
      label: 'Double Confirmed',
      name: 'doubleConfirmed',
      optional: true
    },{
      label: 'Additional Insurance Offered',
      name: 'additionalInsurance',
      optional: true
    },{
      label: 'Packing Materials Offered',
      name: 'packingMaterials',
      optional: true
    }];

    this.toggleProgress = (field) => {
      this.task.data.progress = this.task.data.progress || {};
      let data = angular.copy(this.task.data.progress);
      data[field] = this.task.data.progress[field] === 'N/A' ? false : 'N/A';
      Task.update(this.task.id,{data:{progress:data}});
    };

    // save progress on change
    this.updateMoveProgress = (field) => {
      this.task.data.progress = this.task.data.progress || {};
      if(this.task.data.progress[field] == 'N/A') return;
      let data = angular.copy(this.task.data.progress);
      data[field] = !this.task.data.progress[field];
      Task.update(this.task.id,{data:{progress:data}});
    };


    /* LISTENERS */
    listeners.push($rootScope.$on('move.task',(event,id,moveTask) => {
      if(this.ready && moveTask.id === this.task.id) angular.merge(this.task,moveTask);
    }));
    listeners.push($rootScope.$on('move.user',(event,id,moveUser) => {
      if(this.ready && id === this.move.id && moveUser.user.id === this.customer.id) updateCustomer(moveUser.user);
    }));
    const updateCustomer = (user) => {
      angular.merge(this.customer,user);
      if(this.task.display) this.task.display.forEach(field => {
        if(field.model == 'user') field.value = this.customer[field.name];
      });
    }

  }
});
