angular.module('move').controller('quoteRequestSummaryModalController',
function($scope, $q, $timeout, $uibModalInstance, moment,
  Alerts, Task, Field, QuoteRequest, AddressUtil, params) {
  'ngInject';

  $scope.quote = angular.copy(params.quote);
  $scope.vendor = angular.copy(params.vendor);

  $scope.priceBreakdownModal = QuoteRequest.modals.priceBreakdown;

  $scope.fields = [{
      label: 'Move date',
      name: 'move_date'
    }, {
      name: 'time_preference',
      isHidden: () => angular.isObject($scope.quote.arrival_window) &&
          !angular.equals($scope.quote.arrival_window, {})
    }, {
      label: 'Arrival window',
      name: 'arrival_window',
      value: $scope.quote.arrival_window,
      type: 'arrival_window'
    }, {
      label: 'Cubic feet',
      name: 'volume',
      type: 'calculate'
    }, {
      name: 'professional_packing'
    }, {
      label: 'Flights of stairs',
      name: 'flights_of_stairs',
      type: 'calculate'
    }, {
      label: 'Quoted Price',
      name: 'price',
      type: 'currency',
      value: $scope.quote.price
    }];

  $scope.addresses = {
    origin: Field.get(`from_address`,'book-movers'),
    stops: Field.get(`stops`,'book-movers'),
    destination: Field.get(`to_address`,'book-movers')
  };
  angular.forEach($scope.addresses, (address,name) => {
    address.display = 'condensed';
    if(name === 'stops') address.isHidden = () => {
      return !address.list.filter(item => {
        return Field.getValue(angular.merge({},address,item), $scope.quote.move_task);
      }).length;
    };
  });
  $scope.routeLink = AddressUtil.getRouteLink(
      Field.getValue($scope.addresses.origin,$scope.quote.move_task),
      Field.getValue($scope.addresses.destination,$scope.quote.move_task),
      Field.getValue($scope.addresses.stops,$scope.quote.move_task)
    );

  $scope.fields.forEach(field => {
    angular.merge(field,Field.get(field.name,'book-movers'),angular.copy(field));
  });

  const init = () => {
    let promises = {
      boxes: Task.getItemList('boxes'),
      inventories: Task.getItemList('inventories')
    };
    $q.all(promises).then(results => {
      // append the inventory_item to each move_task_inventory (to expose cubic_feet)
      $scope.inventoryCount = 0;
      $scope.quote.move_task.move_task_inventories.forEach(inventory => {
        $scope.inventoryCount += inventory.count;
        results.inventories.some(group => {
          let match = group.items.find(item => item.name == inventory.name);
          if(match) inventory.inventory_item = match;
          return match;
        })
      });
      // create box objects list that includes the db boxes model (to expose cubic_feet)
      if($scope.quote.move_task.data && $scope.quote.move_task.data.boxes) {
        let boxesMap = {};
        $scope.boxesCount = 0;
        angular.forEach($scope.quote.move_task.data.boxes, (count,name) => {
          $scope.boxesCount += count;
          boxesMap[name] = {
            count: count,
            box: results.boxes.find(b => b.name == name)
          };
        });
        $scope.quote.move_task.data.boxes = boxesMap;
      }
      // build inventory field-display data
      $scope.inventoryDisplay = [];
      results.inventories.sort(orderSort).forEach((inventory) => {
        inventory.items.sort(orderSort).forEach((item) => {
          item.type = 'integer';
          item.parent = 'inventory';
          $scope.inventoryDisplay.push(angular.copy(item));
        });
      });

      // build boxes field-display data
      $scope.boxesDisplay = [];
      results.boxes.sort(orderSort).forEach((box) => {
        box.type = 'integer';
        box.parent = 'boxes';
        $scope.boxesDisplay.push(angular.copy(box));
      });

      $scope.ready = true;
    }).catch(error);
  };
  init();

  const orderSort = (a,b) => a.order - b.order;

  const valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

  // add submit function to mark as delivered
  $scope.submit = (form) => {
    if($scope.loading) return;
    if(!valid(form)) return error();
    $scope.loading = true;
    let data = {
      price: $scope.quoteField.value,
      status: 'submitted'
    };
    if($scope.arrivalWindow.start.value && $scope.arrivalWindow.end.value)
      data.arrival_window = {
        from: $scope.arrivalWindow.start.value,
        to: $scope.arrivalWindow.end.value
      };
    QuoteRequest.update($scope.quote.id,data).then(response => {
      $scope.loading = false;
      $uibModalInstance.close(response);
    }, error);
  };

  const error = (alert) => {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    if(alert) Alerts.error();
  };

});
