angular.module('user').controller('signController',
function($state, $stateParams, $window, Data, User, Alerts) {
  'ngInject';

  if(User.get().id) {
    Alerts.info({ msg: 'Already signed in as ' + User.get().fullname + '!' });
    $state.go('dashboard');
    return;
  }

  console.log($stateParams);
  return $window.top.location = `${Data.consumerUrl}/${$stateParams.view}`;

});
