angular.module('tasks').constant('FinalizeBookingFlow', {

  finalize: {
    flows: {
      default: [
        { name: 'origin_requirements' },
        { name: 'destination_requirements' },
        { name: 'additional_insurance', markComplete: true},
      ]
    },
    screens: {
      origin_requirements: {
        title: 'What is required at the starting location?',
        editTitle: 'What is required at the starting location?',
        type: 'task-data',
        name: 'origin_requirements',
        label: 'Origin Requirements',
        editable: true,
        skip: {
          button: 'I\'m not sure'
        },
        fields: [{
          name: 'from_coi_required',
          label: 'COI required',
          type: 'toggle',
          description: `COI = Certificate of Insurance. Your building may require
            paperwork to be filled out by the movers. This is common and easy to
            handle.`
        },{
          name: 'from_reserve_elevator',
          label: 'Must reserve elevator',
          type: 'toggle'
        },{
          name: 'from_reserve_parking',
          label: 'Must reserve parking',
          type: 'toggle'
        }]
      },

      destination_requirements: {
        title: 'What is required at the ending location?',
        editTitle: 'What is required at the ending location?',
        type: 'task-data',
        name: 'destination_requirements',
        label: 'Destination Requirements',
        editable: true,
        skip: {
          button: 'I\'m not sure'
        },
        fields: [{
          name: 'to_coi_required',
          label: 'COI required',
          type: 'toggle',
          description: `COI = Certificate of Insurance. Your building may require
            paperwork to be filled out by the movers. This is common and easy to
            handle.`
        },{
          name: 'to_reserve_elevator',
          label: 'Must reserve elevator',
          type: 'toggle'
        },{
          name: 'to_reserve_parking',
          label: 'Must reserve parking',
          type: 'toggle'
        }]
      },

      additional_insurance: {
        title: 'Would you like additional moving insurance?',
        type: 'task-data',
        name: 'additional_insurance',
        label: 'Additional Insurance',
        editable: true,
        subtitle: 'What\'s this?',
        subtitleTooltip: `All quotes you receive will be from licensed and insured
          moving companies. If you'd like additional coverage beyond what they
          provide, your assistant can secure that for you. We recommend this for any
          high-value items.`,
        fields: [{
          label: 'Additional insurance',
          name: 'additional_insurance',
          type: 'select',
          options: [{
            value: 'Yes',
            label: 'Yes'
          },{
            value: 'No',
            label: 'No'
          },{
            value: 'Decide Later',
            label: 'Decide Later'
          }],
          required: true
        }]
      },

      // Review
      review: {
        title: 'Summary',
        type: 'review',
        name: 'review',
        back: false,
        markComplete: true,
        sections: [{
          label: 'Origin Building Requirements',
          fields: [{
            name: 'from_coi_required',
            alwaysShow: true,
          },{
            name: 'from_reserve_elevator',
            alwaysShow: true,
          },{
            name: 'from_reserve_parking',
            alwaysShow: true,
          }]
        },{
          label: 'Destination Building Requirements',
          fields: [{
            name: 'to_coi_required',
            alwaysShow: true,
          },{
            name: 'to_reserve_elevator',
            alwaysShow: true,
          },{
            name: 'to_reserve_parking',
            alwaysShow: true,
          }]
        },{
          label: 'Additional Insurance',
          fields: [{
            name: 'additional_insurance',
          }]
        }]
      },

      // Complete
      complete: {
        title: 'Your move is booked!',
        subtitle: 'You\'re all set. Make sure your details are correct ahead of time to ensure moving day is nice and smooth :)',
        type: 'complete',
        name: 'complete',
        button: 'Done',
        back: 'review'
      },
    }
  }

});
