angular.module('core').controller('imageViewerModalController',
($scope, $document, images, initial) => {
  'ngInject';

  $scope.imageList = images;
  $scope.current = initial || 0;

  // no need for gallery functions if only one image
  if($scope.imageList.length <= 1) return;

  $scope.next = () => {
    $scope.current++;
    if($scope.current >= $scope.imageList.length) $scope.current = 0;
  };

  $scope.previous = () => {
    $scope.current--;
    if($scope.current < 0) $scope.current = $scope.imageList.length - 1;
  };

  $document.bind('keydown.carousel', (e) => {
    switch(e.which) {
      case 37: // left
        $scope.$apply($scope.previous());
        break;
      case 39: // right
        $scope.$apply($scope.next());
        break;
      default: return; // exit this handler for other keys
    }
  });

  // view cleanup
  $scope.$on('$destroy', () => {
    $document.unbind('keydown.carousel');
  });


});
