angular.module('core').directive('stepper',
function($timeout,debounce) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      value: '=',
      onChange: '&',
      min: '<',
      max: '<'
    },
    template: `
      <div class="stepper">
        <button type="button" class="stepper-btn" ng-click="minus()"
          ng-class="{'disabled':value==min}">
          <span class="entypo-minus icon"></span>
        </button>
        <span class="stepper-value" ng-bind="value"
          ng-class="{'changed':changedClass}"></span>
        <button type="button" class="stepper-btn" ng-click="plus()"
          ng-class="{'disabled':value==max}">
          <span class="entypo-plus icon"></span>
        </button>
      </div>`,
    link: (scope, element, attrs) => {

      var step = (angular.isUndefined(attrs.step) || parseInt(attrs.step) === 0 ? 1 : parseInt(attrs.step));
      scope.min = (angular.isUndefined(scope.min) ? 0 : parseInt(scope.min));
      scope.max = (angular.isUndefined(scope.max) ? 99 : parseInt(scope.max));
      scope.value = parseInt(scope.value) || scope.min;

      /**
       * Sets the value as an integer. If the value cannot be parsed,
       * i.e. returns NaN, then the min value or 0 will be used instead.
       */
      var setValue = (val) => {
        if (val==scope.value) return val;
        $timeout(() => { scope.changedClass = false; }, 50);
        var parsedVal = parseInt(val);
        if (!isNaN(parsedVal)) {
          if (scope.min !== undefined && scope.min > parsedVal) {
            parsedVal = scope.min;
            return parsedVal;
          }
          if (scope.max !== undefined && scope.max < parsedVal) {
            parsedVal = scope.max;
            return parsedVal;
          }
          scope.changedClass = true;
          return parsedVal;
        } else {
          parsedVal = scope.min || 0;
          return parsedVal;
        }
      };

      if(angular.isDefined(scope.onChange))
        scope.$watch('value',debounce((newVal,oldVal) => {
          if(newVal != oldVal) scope.onChange();
        }),200);

      /**
       * Confirm the value attribute exists on the element
       */
      if (angular.isUndefined(scope.value)) {
        throw 'Missing the value attribute on the counter directive.';
      }

      /**
       * Set some scope wide properties
       */
      scope.readonly = (angular.isUndefined(attrs.editable) ? true : false);
      scope.addclass = (angular.isUndefined(attrs.addclass) ? null : attrs.addclass);
      scope.width = (angular.isUndefined(attrs.width) ? {} : {width:attrs.width});
      scope.value = setValue(scope.value);

      /**
       * Decrement the value and make sure we stay within the limits, if defined.
       */
      scope.minus = () => {
        scope.value = setValue(scope.value - step);
      };

      /**
       * Increment the value and make sure we stay within the limits, if defined.
       */
      scope.plus = () => {
        scope.value = setValue(scope.value + step);
      };

    }
  };
});
