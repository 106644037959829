angular.module('core').component('imageUpload', {
  bindings: {
    images: '=',
    options: '<',
    form: '<?'
  },
  controller: function(Upload,Alerts) {
    'ngInject';

    this.invalidFiles = null;
    this.$doCheck = () => {
      if(!this.invalidFiles) return;
      if(this.options.multiple) {
        this.invalidFiles.forEach((file) => {
          if(file && file.$error) handleFileError(file);
        });
        this.invalidFiles = null;
      }
      else if(this.invalidFiles && this.invalidFiles.$error) {
        handleFileError(this.invalidFiles);
        this.invalidFiles = null;
      }
    };

    const handleFileError = (file) => {
      var validateOptions = Upload.defaults.ngfValidate;
      var message;
      switch(file.$error) {
        case 'minWidth':
        case 'minHeight':
          message = 'Image must be at least ' +
            validateOptions.width.min + 'px X ' +
            validateOptions.height.min + 'px';
          break;
        case 'maxWidth':
        case 'maxHeight':
          message = 'Image must be no larger than ' +
            validateOptions.width.max + 'px X ' +
            validateOptions.height.max + 'px';
          break;
        case 'minSize':
        case 'maxSize':
          message = 'Image file size must be less than ' +
            validateOptions.size.max;
        break;
        case 'maxFiles':
          message = 'Exceeded the maximum number of images';
          break;
        default:
          message = "Woah there cowboy, only jpg & png allowed.";
      }
      if(this.form && file.$error)
        this.form[this.options.name].$setValidity(file.$error,true);
      Alerts.warn({
        msg: message
      });
    }


    this.$onInit = () => {
      angular.element(window).bind('dragover.imageUpload', function (e) {
        e.preventDefault();
      });
      angular.element(window).bind('drop.imageUpload', function (e) {
        e.preventDefault();
      });
      this.options = this.options || {};
    };

    this.$onDestroy = () => {
      angular.element(window).unbind('.imageUpload');
    };

  },
  template: `
    <div ngf-select ngf-drop class="image-drop"
      name="{{$ctrl.options.name}}"
      ng-model="$ctrl.images"
      ng-required="$ctrl.options.required"
      ngf-multiple="$ctrl.options.multiple"
      ngf-keep="$ctrl.options.multiple ? 'distinct' : false"
      ngf-max-files="$ctrl.options.maxFiles"
      ngf-resize="{width: 1000, height: 1000}"
      ngf-resize-if="$width > 1000 || $height > 1000"
      ngf-drag-over-class="dragover"
      ngf-model-invalid="$ctrl.invalidFiles"
      ngf-allow-dir="false">
      <svg role="presentation" class="upload-icon" ng-if="$ctrl.options.icon">
        <use xlink:href="/images/sprites/icons.svg#upload"/>
      </svg>
      <div class="hide-on-mobile">Drag &amp; Drop
        image{{ $ctrl.options.multiple ? 's' : '' }} here
        <br>or <span class="secondary">browse</span> to upload</div>
      <div class="hide-on-desktop">Browse for
        image{{ $ctrl.options.multiple ? 's' : '' }} to upload</div>
    </div>`
});
