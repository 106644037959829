angular.module('user').controller('passwordSetModalController',
  function ($scope, $state, $uibModalInstance, $timeout, User, Alerts, data) {
    'ngInject';

    $scope.password = '';
    $scope.confirm = '';
    $scope.required = data.required;

    $scope.matchPasswords = () => {
      if($scope.password == $scope.confirm) {
        $scope.passwordForm.confirm.$setValidity('match',true);
      } else {
        $scope.passwordForm.confirm.$setValidity('match',false);
      }
    };

    const valid = () => {
      $scope.passwordForm.$setSubmitted();
      return !$scope.passwordForm.$invalid;
    };

    $scope.submit = () => {
      if($scope.loading) return;
      if(!valid()) return error();
      $scope.loading = true;
      User.setPasswordViaToken({token:data.token,password:$scope.password})
        .then(success, () => error(true));
    };

    const success = () => {
      $scope.loading = false;
      Alerts.success({
        msg: 'Your password has been successfully created.'
      });
      $timeout(() => $uibModalInstance.close(), 1000);
    };

    const error = (alert) => {
      $scope.loading = false;
      $scope.error = true;
      $timeout(() => { $scope.error = false; }, 300);
      if(alert) Alerts.error({msg: 'Uh oh. Looks like this token has expired. Please use the Forgot Password link on the <a href="/login">sign in</a> screen to get a new one.'});
    }

});
