angular.module('vendor').controller('vendorDashboardController',
function ($scope, $state, $stateParams, vendor, vendorDetails) {
  'ngInject';

  // Initialize Data
  $scope.vendor = vendor;
  $scope.vendor.details = vendorDetails;
  $scope.active = $stateParams.active;
  $scope.selectPanel = (name) => { $state.go(`^.${name}`) };

  $scope.ready = true;

  // set default sub-view
  if($state.current.name == 'dashboard.vendor') $state.go('.jobs',$stateParams);

  // listen for vendor data broadcast
  $scope.$on('vendor.data',(event,id,message) => updateVendorData(id,message));
  function updateVendorData(id,data) {
    if(!$scope.ready || id !== $scope.vendor.id) return;
    angular.merge($scope.vendor, data);
  }

  // listen for vendor details broadcast
  $scope.$on('vendor.details', (event, id, data) => updateVendorDetails(id,data));
  const updateVendorDetails = (id,data) => {
    if(!$scope.ready || id !== $scope.vendor.id) return;
    angular.merge($scope.vendor.details, data);
  };

});
