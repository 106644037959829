angular.module('user').directive('greeting',
function(User,$interval,$timeout) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    template: `
      <span class="greeting" ng-show="user.id && ready"
        ng-bind-html="greeting"></span>`,
    link: (scope, element, attrs) => {

      scope.user = User.get();
      scope.$on('userUpdated',() => {
        scope.user = User.get();
        setGreeting();
      });


      scope.time = (new Date()).getHours();
      scope.$watch('time',(newVal,oldVal) => {
        if(newVal != oldVal) setGreeting();
      });

      let setGreeting = () => {
        $timeout(() => scope.ready = true, 1500);
        if(!User.get().id) return scope.greeting = 'Hello.';
        switch(true) {
          case(scope.time < 2):
            return scope.greeting = `Up late, ${User.get().firstname}?`;
          case(scope.time < 6):
            return scope.greeting = `Early morning, ${User.get().firstname}?`;
          case(scope.time < 10):
            return scope.greeting = `Good morning, ${User.get().firstname}!`;
          case(scope.time < 14):
            return scope.greeting = `Hello ${User.get().firstname}!`;
          case(scope.time < 18):
            return scope.greeting = `Good afternoon, ${User.get().firstname}!`;
          case(scope.time < 22):
            return scope.greeting = `Good evening, ${User.get().firstname}!`;
          default:
            return scope.greeting = `Hello ${User.get().firstname}!`;
        }
      };
      setGreeting();

      let timeSync = $interval(() => {
        scope.time = (new Date()).getHours();
      },1000*60);
      scope.$on('$destroy',() => {
        if(angular.isDefined(timeSync)) {
          $interval.cancel(timeSync);
          timeSync = undefined;
        }
      });

    }
  };
});
