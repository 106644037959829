angular.module('tasks').component('screenTaskData', {
  templateUrl: '/templates/tasks/screen-data.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,$q,User,Task,Move,Field) {
    'ngInject';

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      this.screen.data = {};
      this.screen.fields.forEach(initializeField);
    };

    const initializeField = (field) => {
      field.task = this.task;
      Field.addDefaults(field, this.screen.data, this.move);
      if(field.custom && field.custom.label) field.customLabel = () =>
        Field.customLabel(field, this.screen.fields.find(f =>
          f.name == field.custom.label.field).value);
      field.value = angular.isDefined(Field.getValue(field,this.task,this.move)) ?
        Field.getValue(field,this.task,this.move) : field.default;
      this.screen.data[field.name] = field.value;
      field.onChange = () => {
        this.screen.data[field.name] = field.value;
        if(this.screen.onChange) this.screen.onChange(buildData());
      };

    };

    this.fieldFilter = (field) => !field.hidden;

    this.next = (skip) => {
      if(this.loading) return;
      if(skip) {
        if(this.screen.skip.fields) {
          this.screen.fields = angular.copy(this.screen.skip.fields);
          this.screen.fields.forEach(initializeField);
          this.screen.title = angular.copy(this.screen.skip.title);
          this.screen.subtitle = angular.copy(this.screen.skip.subtitle);
          return this.screen.skip = false;
        } else return this.onNext();
      }
      this.loading = true;
      if(!valid(this.form)) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progressData) => {
      let data = buildData(progressData ? {task:progressData} : {});
      let promises = {};
      if(data.user) promises.user = User.update(this.user.id,data.user);
      if(data.task) promises.task = Task.update(this.task.id,data.task);
      return $q.all(promises).then(r => r.task);
    };

    const buildData = (data) => {
      data = data || {};
      this.screen.fields.filter(this.fieldFilter).forEach((field) =>
        Field.buildSaveData(field,data));
      return data;
    };

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }

});
