angular.module('move').controller('priceBreakdownModalController',
  function ($scope, $uibModalInstance, params) {
    'ngInject';

    let breakdown,
        professional_packing,
        source = params.request || params.quote;
    if(params.request) {
      breakdown = source.adjusted_price && !angular.equals(source.adjusted_price, {}) ?
        source.adjusted_price : source.data || {};
      professional_packing = source.move_task.data ? source.move_task.data.professional_packing : false;
    } else breakdown = source.data && source.data.price_breakdown ? source.data.price_breakdown : {};

    let order = [{
      name: 'volume_price',
      label: 'Volume Price'
    },{
      name: 'travel_price',
      label: 'Travel Price'
    },{
      name: 'tolls_price',
      label: 'Tolls Price'
    },{
      name: 'stairs_price',
      label: 'Stairs Price'
    },{
      name: 'storage_price',
      label: 'Storage Price'
    },{
      name: 'inventory_price',
      label: 'Special Items Price'
    },{
      name: 'packing_price',
      label: 'Packing Price'
    },{
      name: 'packing_unpacking_price',
      label: 'Packing & Unpacking Price'
    },{
      name: 'total_price',
      label: 'Total Price',
      type: 'currency',
      value: source.price || 0
    }];

    const loadBreakdown = (breakdown) => {
      $scope.fields = [];
      order.forEach(field => {
        if(breakdown && breakdown[field.name]) $scope.fields.push({
          name: field.name,
          label: field.label,
          type: 'currency',
          value: breakdown[field.name]
        });
        else if(field.name == 'total_price') $scope.fields.push(field);
      });
    };
    loadBreakdown(breakdown);

    $scope.classes = (field) => {
      let classes = [field.name];
      if(field.name.includes('packing')) {
        if(professional_packing) {
          if(professional_packing != field.name.replace('_price','')) classes.push('optional');
        } else {
          if(source.price != breakdown.base_price + breakdown[field.name]) classes.push('optional');
        }
      }
      return classes;
    };

    $scope.close = () => {
      $uibModalInstance.close('success');
    }
  });
