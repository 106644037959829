angular.module('user').controller('logoutController',
function ($scope, $state, $cookies, User, Alerts) {
  'ngInject';

  let msg = 'Someone successfully logged out, but we forgot who it was.';
  if(!User.get().id) msg = 'You are already signed out';

  User.logout().then(() => {
    Alerts.success({ msg: msg });
    $state.go('dashboard');
    return;
  });
});
