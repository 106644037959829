angular.module('tasks').controller('addressModalController',
  function ($timeout, $scope, Task, Move, Field, $uibModalInstance, params) {
    'ngInject';

    // convenience function to check if an address field has a valid value
    let hasValue = (field) => {
      return field.value && field.value.google_place_id;
    };
    $scope.address = angular.copy(params.field);
    if(hasValue(params.field)) $scope.edit = true;

    $scope.title = params.options && params.options.title ? params.options.title : false;
    $scope.subtitle = params.options && params.options.subtitle ? params.options.subtitle : false;

    $scope.submit = (form) => {
      $scope.loading = true;
      $timeout(() => {
        if(!valid(form)) return error();
        if(params.field.saveOnEdit) this.save();
        else $uibModalInstance.close($scope.address.value);
      },300);
    };

    this.save = () => {
      let data = {};
      Field.buildSaveData($scope.address,data);
      return Task.update(params.field.value.task.id,data.task).then(() => {
        $scope.loading = false;
        $uibModalInstance.close($scope.address.value);
      },error);
    };

    let valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid;
    };

    let error = () => {
      $scope.loading = false;
      $scope.error = true;
      $timeout(() => { $scope.error = false; }, 300);
    };

  });
