angular.module('field')
.component('fieldDisplayAddress', {
  template: `
    <div class="multiline" ng-if="$ctrl.field.display == 'summary'">
      <div ng-if="$ctrl.getAddressLine1($ctrl.value)">
        {{ $ctrl.getAddressLine1($ctrl.value) }}</div>
      <div ng-if="$ctrl.getAddressLine2($ctrl.value)">
        {{ $ctrl.getAddressLine2($ctrl.value) }}</div>
      <div ng-if="$ctrl.getAddressLine3($ctrl.value)">
        {{ $ctrl.getAddressLine3($ctrl.value) }}</div>
      <div ng-if="$ctrl.getAddressLine4($ctrl.value)">
        {{ $ctrl.getAddressLine4($ctrl.value) }}</div>
    </div>
    <div class="summary" ng-if="$ctrl.field.display == 'list'">
      <div ng-if="$ctrl.getAddressSummary($ctrl.value)">
        {{ $ctrl.getAddressSummary($ctrl.value) }}</div>
    </div>
    <div class="summary" ng-if="$ctrl.field.display == 'condensed'">
      <div class="address-value">{{ $ctrl.getAddressSummary($ctrl.value) }}</div>
      <div class="address-items">
        <div class="address-item" ng-if="$ctrl.value.unit">
          <h5>Unit</h5>
          <div class="value" ng-bind="$ctrl.value.unit"></div>
        </div>
        <div class="address-item" ng-if="$ctrl.value.floor">
          <h5>Floor</h5>
          <div class="value" ng-bind="$ctrl.value.floor | ordinal"></div>
        </div>
        <div class="address-item" ng-if="$ctrl.value.floor > 1">
          <h5>Elevator/Stairs</h5>
          <div class="value" ng-bind="$ctrl.getStairs($ctrl.value)"></div>
        </div>
      </div>
    </div>`,
  bindings: {
    field: '<',
    value: '='
  },
  controller: function(AddressUtil) {
    'ngInject';

    this.$onInit = () => {
      this.field.include = this.field.include || {
        floor: true,
        flights_of_stairs: true,
        type: true,
        deed: true
      }
    }

    this.getAddressSummary = (address) => AddressUtil.getFormatted(address) || this.field.placeholder || 'TBD';

    this.getAddressLine1 = (address) => {
      return AddressUtil.getFormatted(address,{
        city:false,
        state:false,
        zipcode:false
      });
    };

    this.getAddressLine2 = (address) => {
      return AddressUtil.getFormatted(address,{
        street:false,
        unit:false
      });
    };

    this.getAddressLine3 = (address) => {
      return [
        this.field.include.floor ? AddressUtil.getFloor(address) : null,
        this.field.include.flights_of_stairs ? AddressUtil.getStairs(address) : null
      ].filter((v)=>v).join(' - ');
    };

    this.getAddressLine4 = (address) => {
      return [
        this.field.include.type ? AddressUtil.getType(address) : null,
        this.field.include.deed ? AddressUtil.getOwnership(address) : null
      ].filter((v)=>v).join(' - ');
    };

    this.getOrdinal = AddressUtil.getOrdinal;

    this.getOwnership = AddressUtil.getOwnership;

    this.getType = AddressUtil.getType;

    this.getStairs = AddressUtil.getStairs;

  }
});
