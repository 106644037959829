// create module & set dependencies
angular.module('user', [
  'ui.router',
  'ui.bootstrap',
  'ui.validate',
  'ngCookies',
  'ngAnimate',
  'ngSanitize',
  'debounce',
  'constants',
]);
