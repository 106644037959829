angular.module('user').controller('editUserModalController',
function($scope,$uibModalInstance,$timeout,$cookies,$state,
  User,Alerts,data) {
  'ngInject';

  $scope.edit = data.edit ? data.edit : { name: true, phone: true, email: true };

  $scope.user = {
    firstname: User.get().firstname,
    lastname: User.get().lastname,
    phone: User.get().phone,
    email: User.get().email
  };

  var valid = (form) => {
    form.$setSubmitted();
    return !form.$invalid;
  };

  $scope.fields = {
    phone: {
      name: 'phone',
      type: 'phone_number',
      label: 'Phone Number',
      placeholder: 'Phone Number'
    }
  };

  angular.forEach($scope.fields,(field,key) => {
    field.value = $scope.user[key];
    field.onChange = (value) => $scope.user[key] = value;
  });

  $scope.submit = (form) => {
    if($scope.loading) return false;
    if(!valid(form)) return error();
    $scope.loading = true;
    User.update(User.get().id,$scope.user).then(() => {
      $scope.loading = false;
      $uibModalInstance.close('success');
    },(result) => {
      error();
      if(result.status == 409)
        return Alerts.error({msg:'This email address is already in use by another user.'});
      Alerts.error();
    });
  };

  function error(msg) {
    $scope.loading = false;
    $scope.error = true;
    $timeout(() => { $scope.error = false; }, 300);
    return false;
  }

});

