angular.module('core').factory('GoogleAPI',
function($rootScope) {
  'ngInject';

  const APIs = {
    placesService: new google.maps.places.PlacesService(document.createElement('div')),
    autocompleteService: new google.maps.places.AutocompleteService()
  };

  return {
    places: {
      getDetails: (place_id, callback) => {
        APIs.placesService.getDetails({
          placeId: place_id,
          fields: ['address_component','place_id']
        }, (place, status) => {
          if (status != google.maps.places.PlacesServiceStatus.OK) return $rootScope.$apply(callback());
          $rootScope.$apply(callback(place));
        });
      },
      getPredictions: (query,callback) => {
        APIs.autocompleteService.getPlacePredictions(query, (places, status) => {
          if(status != google.maps.places.PlacesServiceStatus.OK) return $rootScope.$apply(callback());
          $rootScope.$apply(callback(places));
        });
      }
    }
  };

});
