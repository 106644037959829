angular.module('cx').component('moveSearchControls', {
  templateUrl: '/templates/cx/move-search-controls.html',
  bindings: {
    onUpdate: '&',
    loading: '<',
  },
  controller: function($state, $stateParams, $q, _, Move, MoveTag) {
    'ngInject';

    const defaultParams = {
      status: null,
      tags: [],
      search: '',
      sort: 'last_active_date',
      sortOrder: 'desc',
    };

    this.$onInit = () => {
      // Set Search Param defaults and initial values
      const previousParams = localStorage.cxDashboardState ? JSON.parse(localStorage.cxDashboardState) : {};
      // initialize filters
      this.params = _.merge({},defaultParams,previousParams);
      // temp migration
      if(this.params.app) delete this.params.app;
      if($stateParams.active) {
        this.params.active = parseInt($stateParams.active);
      }
      if($stateParams.search) {
        this.params.search = this.searchTerm = $stateParams.search;
        this.activeFilterMenu = 'search';
      }
      // persist current view
      saveView(this.params);
      // load initial set of moves
      if(this.onUpdate) this.onUpdate({params:this.params});
    };

    this.updateParams = (updates) => {
      if(this.loading) return;
      // build new params in temp object
      const newParams = Object.assign({},this.params,{active:null},updates);
      // abort if no change from current state
      if(_.isEqual(this.params,newParams)) return;
      // update the specified values
      this.params = newParams;
      // return default order if switching sort
      if(updates.sort) this.params.sortOrder = defaultParams.sortOrder;
      saveView(this.params);
      if(this.onUpdate) this.onUpdate({params:this.params});
    };

    const saveView = (data) => {
      const params = _.cloneDeep(data);
      delete params.search; // don't save keywords
      delete params.active; // don't save move_id
      // update localStorage persistance
      localStorage.cxDashboardState = JSON.stringify(params);
      // update url persistance
      $state.go('.', _.merge({},data), {notify: false});
    }

    // status options
    this.statusList = angular.copy(Move.statusList);
    this.findStatus = (name) => this.statusList.find(status => status.name === name);
    // sorting options
    this.sortList = [{
      label: 'Last Active',
      name: 'last_active_date',
    },{
      label: 'Move Date',
      name: 'move_date',
    }];
    this.findSort = (name) => this.sortList.find(sort => sort.name === name);

    // scope function to hide/show filter & sort menu
    this.toggleFilterMenu = (filter) => {
      this.activeFilterMenu = this.activeFilterMenu === filter ? false : filter;
      // when closing search make sure its param and field are blanked
      if(this.activeFilterMenu !== 'search') {
        this.updateParams({search:''});
        this.searchTerm = this.params.search;
      }
    };

    /* TAGS */
    // tag filters
    this.tagCount = 0;
    // function to handle type-ahead suggestion
    this.findTag = (term) => {
      var defer = $q.defer();
      if (!term) {
        defer.resolve(null);
        this.tagCount = 0;
      } else {
        term = term.toLowerCase().trim();
        let data = {
          page:1,
          per_page:1,
          sort:{name:false},
          where:{name:{$startswith:term}}
        };
        MoveTag.find(data).then((result) => {
          if(result && result.length) {
            defer.resolve(result[0].name);
            this.tagCount = result[0].count;
          }
          else {
            defer.reject('No Matches Found');
            this.tagCount = 0;
          }
        });
      }
      return defer.promise;
    };
    this.filterTag = {
      value: '',
      suggested: false,
      select: () => this.filterTag.suggested = true,
      deselect: () => this.filterTag.suggested = false,
      add: () => {
        if(!this.filterTag.suggested || this.loading ||
          !this.filterTag.value) return false;
        let tagList = angular.copy(this.params.tags || []);
        // dedupe existing active tags
        if(this.params.tags &&
          this.params.tags.find(t=>t==this.filterTag.value))
          return this.filterTag.value = '';
        tagList.push(this.filterTag.value);
        this.filterTag.value = '';
        this.updateParams({tags:tagList});
      },
      remove: (tag) => {
        let index = this.params.tags.indexOf(tag);
        let tagList = angular.copy(this.params.tags || []);
        tagList.splice(index,1);
        this.updateParams({tags:tagList});
      },
      label: () => {
        if(!this.params.tags)
          return 'No';
        else if(!this.params.tags.length)
          return 'Any';
        else if(this.params.tags.length == 1)
          return this.params.tags[0];
        else return `${this.params.tags.length} Selected`;
      }
    };

    /* KEYWORDS */
    this.searchTerm = '';

  }
});
