angular.module('tasks').component('screenCheckout', {
  templateUrl: '/templates/tasks/screen-checkout.html',
  bindings: {
    screen: '<',
    move: '<',
    task: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,User,Move,Payment,Task,Field,Transaction,Alerts,Data) {
    'ngInject';

    this.$onInit = () => {
      this.user = Move.findCustomer(this.move).user;
      this.activeSection = 0;
      Task.getItemList(this.screen.model).then((sections) => {
        this.screen.sections = angular.copy(sections);
        this.screen.sections.sort((a,b) => a.order - b.order);
        this.screen.sections.forEach((section) => {
          section.items.sort((a,b) => a.order - b.order);
          section.items.forEach((field) => {
            field.type = 'checkout-item';
            field.parent = this.screen.name;
            field.value = Field.getValue(field,this.task);
            field.image_url = field.image_url ? `${Data.s3AssetUrl}${field.image_url}` : false;
          });
        });
        this.ready = true;
      });
    };

    this.toggleSection = (index) => {
      this.showSection = false;
      this.activeSection = this.activeSection !== index ? index : false;
      $timeout(() => this.showSection = true, 0);
    };

    this.totalCost = () => {
      let cost = 0;
      if(!this.screen.sections) return cost;
      this.screen.sections.forEach((section) => {
        section.items.forEach((field) => {
          cost += field.value * field.price || 0
        });
      });
      return cost;
    }

    Payment.getStripeCheckout().then(response => {
      this.stripeCheckout = response;
    });

    // trigger stripe checkout UI function
    this.submit = () => {
      if(!valid(this.form)) return error();
      if(this.loading) return;
      this.loading = true;
      let total = this.totalCost();
      save().then(() => checkout(total), error);
    }

    const save = () => {
      let data = {};
      buildData(data);
      return Task.update(this.task.id,data.task);
    };

    const buildData = (data) => {
      this.screen.sections.forEach((section) => {
        section.items.forEach((field) => {
          Field.buildSaveData(field,data);
        });
      });
    };

    const checkout = (total) => {
      if(!this.stripeCheckout) return $timeout(() => checkout(total),200);
      // trigger stripe payment overlay
      return this.stripeCheckout.open({
        name: this.screen.label,
        description: 'Abode dba Moved',
        email: this.user.email,
        amount: total
      }).then((result) => placeOrder(result[0].id, total), error);
    };

    const placeOrder = (source, total) => {
      return Payment.taskOrder(this.task.id,{
        source: source,
        transaction: Transaction.getDefault(this.task.task.name),
        total: total
      }).then(success,(response) => {
        Alerts.error({msg:`Uh Oh. There was a problem processing this card.
            Please try again, or use a different payment method.`});
        error();
      });
    };

    const success = () => {
      this.onNext({callback:(progressData) => {
        return Task.update(this.task.id,progressData)
          .then(result => this.loading = false, error);
      }});
    };

    const valid = (form) => {
      form.$setSubmitted();
      return !form.$invalid && this.totalCost() > 0;
    };

    const error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }
});
