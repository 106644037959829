angular.module('tasks').controller('editScreenModalController',
function ($scope, $uibModalInstance, $q, User, Field, Move, Task, Transaction, Messages, Alerts, params) {
  'ngInject';

  $scope.screen = Task.getScreen(params.task.task.name, params.screen);
  $scope.screen.edit = true;

  $scope.screenClasses = () => {
    let classes = [params.screen];
    return classes;
  };

  $scope.$ctrl = {
    move: params.move,
    task: params.task,
    nextScreen: (saveScreen) => saveScreen().then(() => $uibModalInstance.close('success')),
    onClose: $uibModalInstance.close
  };
});
