angular.module('vendor').component('screenAutoQuoteSpecialPricing', {
  templateUrl: '/templates/vendor/screen-special-pricing.html',
  bindings: {
    vendor: '<',
    screen: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,$q,User,Vendor,Task,Field) {
    'ngInject';

    this.$onInit = () => {
      this.user = User.get();
      this.screen.data = {};
      this.itemList = [];
      Task.getItemList('inventory_items').then((items) => {
        this.inventory = items.sort((a,b) => {
          if(a.label.toLowerCase() < b.label.toLowerCase()) return -1;
          if(a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          return 0;
        });
        initFields();
      },() => {/* silent error */});
    };

    const initFields = () => {
      this.fields = angular.copy(this.inventory.filter(item => item.has_vendor_support));
      this.fields.forEach(field => {
        angular.merge(field,{type:'toggle', name:`${field.name}_support`, inventoryName: field.name});
        Field.addDefaults(field);
        // initial values & state
        field.value = Vendor.details.getValue(field,this.screen,this.vendor);
        field.prices = this.vendor.details.vendor_inventory_pricing.find(item =>
          item.inventory_item_name == field.inventoryName);
        if(field.value !== false) field.value = true; // backward compatibility for newly added items
        if(field.value) this.addItem(findInventoryItem(field.inventoryName),field.prices);
        field.onChange = () => {
          // handle dynamic state
          this.screen.data[field.name] = field.value;
          if(field.value) this.addItem(findInventoryItem(field.inventoryName));
          else this.removeItem({name: field.inventoryName});
          if(this.screen.onChange) this.screen.onChange(buildData());
        };
      });
      // for each item from data that has key add special prices row
      if(this.vendor.details && this.vendor.details.vendor_inventory_pricing)
        this.vendor.details.vendor_inventory_pricing.forEach((data) => {
          if(data.inventory_item) delete data.inventory_item;
          if(this.fields.find(field => field.inventoryName == data.inventory_item_name)) return;
          this.addItem(findInventoryItem(data.inventory_item_name),data);
        });

    };

    const findInventoryItem = (name) => {
      if(!this.inventory) return false;
      return this.inventory.find(item => item.name == name);
    };

    let costDefaults = {
      disassembly_cost: 0,
      reassembly_cost: 0,
      handling_fee: 0,
      notes: ''
    };
    this.addItem = (item,value) => {
      if(this.itemList.find(listItem => listItem.name == item.name)) return;
      else {
        let specialItem = angular.copy(item);
        specialItem.value = angular.merge({},costDefaults,value,{
          inventory_item_name: item.name,
          inventory_item_id: item.id
        });
        specialItem.onChange = (data) => {
          if(this.screen.onChange) this.screen.onChange(buildData());
        }
        this.itemList.push(specialItem);
      }
      if(item.id) this.fields.forEach(toggle => {
        if(toggle.name == item.name) toggle.value = true;
      });
    };

    this.removeItem = (item) => {
      let index = this.itemList.findIndex(listItem => listItem.name == item.name);
      if(index !== -1) this.itemList.splice(index,1);
    };

    this.itemAutocomplete = {
      name:'vendor_pricing_item',
      placeholder: 'Search for item...',
      onChange: (item) => {
        if(item) {
          this.addItem(item);
          this.toggleItemLookup();
        }
      },
      custom: { clearOnSelect: true }
    };
    this.toggleItemLookup = () => {
      this.showItemLookup = !this.showItemLookup;
    };

    this.next = (skip) => {
      if(this.loading) return;
      if(skip) return this.onNext();
      this.loading = true;
      if(!valid()) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progress) => {
      let data = buildData();
      if(progress) data.progress = progress;
      return Vendor.details.update(this.vendor.details.id, data);
    };

    const buildData = () => {
      let data = {};
      if(this.fields && this.fields.length) {
        data[`${this.screen.name}_data`] = data[`${this.screen.name}_data`] || {};
        this.fields.forEach((field) => {
          data[`${this.screen.name}_data`][field.name] = formatData(field);
        });
      }
      if(this.itemList && this.itemList.length) {
        data.vendor_inventory_pricing = data.vendor_inventory_pricing || [];
        this.itemList.forEach((item) => {
          if(item.value && item.value.inventory_item) delete item.value.inventory_item;
          data.vendor_inventory_pricing.push(item.value);
        });
      }
      return data;
    };

    const formatData = (field) => {
      if(field.type == 'date') return moment(field.value).format('YYYY-MM-DD');
      return field.value;
    };

    let valid = () => {
      this.form.$setSubmitted();
      return !this.form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }

});
