angular.module('cx').controller('adminVendorsController',
function ($scope, $timeout, $state, $filter,
  Alerts, User, Access, Vendor) {
  'ngInject';

  if(Access.limit('concierge', () => $state.reload())) return;

  $scope.ready = false; // set to true after first page move request
  $scope.loading = false; // set to true when loading additional moves (not used yet)

  $scope.user = User.get();

  // Default rows per page
  const per_page = 25;

  // exposed object that includes state of active set of vendors
  $scope.active = {
    total: 0,
    data: []
  }

  $scope.fields = [{
    name: 'name',
    label: 'Name',
    value: vendor => vendor.name,
    sortable: true
  }, {
    name: 'date',
    label: 'Join Date',
    value: vendor => $filter('date')(vendor.created_at, 'shortDate') || '&mdash;',
    display: false
  }, {
    name: 'commission',
    label: 'Commission',
    value: vendor => vendor.commission * 100 + '%',
    sortable: true,
    invertDirection: true
  }, {
    name: 'user',
    label: 'Vendor Account',
    boolean: true,
    value: vendor => vendor.user && angular.isDefined(vendor.user.id),
    sortable: true,
  }, {
    name: 'has_stripe',
    label: 'Stripe-enabled',
    boolean: true,
    value: vendor => vendor.has_stripe,
    sortable: true,
    invertDirection: true,
  }];

  // vendor options for API
  $scope.params = {
    per_page: per_page,
    page: 1,
    sort: {},
    where: {}
  };

  // expose options for pagination
  $scope.pageOptions = {
    pageCount: 0
  };

  const loadVendors = () => {
    $scope.loading = true;
    return Vendor.getAll($scope.params).then((results) => {
      // update total count
      if(results.total !== $scope.active.total) {
        $scope.active.total = results.total || 0;
        $scope.pageOptions.pageCount = Math.ceil(results.total/$scope.params.per_page);
      }
      $scope.active.data = results.data || [];
      $scope.ready = true;
      $timeout(() => $scope.loading = false, 0);
    }, () => Alerts.error({ msg: 'Error loading vendors' }));
  };

  // expose function to edit a vendor
  $scope.editVendor = (vendor) => Vendor.modals.edit(vendor).result.then(result => {
    Alerts.success({ msg: 'Vendor Updated', delay: 3 });
    return loadVendors();
  }).catch(angular.noop);

  // expose function to proxy as vendor
  $scope.proxyAsVendor = (vendor) => {
    Alerts.success({msg:'With great power comes great responsibility.'});
    User.proxy(vendor.user.id)
      .then(() => $state.go('dashboard.vendor.calendar'));
  };

  // expose function to create a new vendor
  $scope.createVendor = () => Vendor.modals.create().result.then(result => {
    Alerts.success({ msg: 'Vendor Created', delay: 3 });
    return loadVendors();
  }).catch(angular.noop);

  // expose function for pagination
  $scope.updatePage = (page) => {
    if($scope.loading) return false;
    $scope.params.page = page;
    return loadVendors();
  };

  // function to update sort field
  const updateSort = (field) => {
    if($scope.loading) return false;
    $scope.params.page = 1;
    if($scope.active.field && field.name == $scope.active.field.name)
      $scope.active.field.direction = !$scope.active.field.direction;
    else {
      $scope.active.field = field;
      $scope.active.field.direction = field.invertDirection || false;
      $scope.params.sort[field.name] = $scope.active.field.direction;
    }
    $scope.params.sort = {};
    $scope.params.sort[$scope.active.field.name] = $scope.active.field.direction;
    return loadVendors();
  };

  // set default sort to first field (also loads first page of data)
  updateSort($scope.fields.find(f => f.name == 'name'));

  // create set of actions to pass into sort-table component
  $scope.actions = {
    sort: updateSort,
    rowClick: $scope.editVendor,
    rowOverlay: {
      label: () => 'Check Calendar',
      show: vendor => vendor.user && angular.isDefined(vendor.user.id),
      click: $scope.proxyAsVendor
    }
  };

  // Configuration of fields on mobile moves list
  $scope.cardLayout = {
    title: $scope.fields.find(f => f.name == 'name'),
    date: $scope.fields.find(f => f.name == 'commission'),
    total: $scope.fields.find(f => f.name == 'has_stripe'),
    revenue: $scope.fields.find(f => f.name == 'user'),
    button: {
      label: () => 'Edit',
      show: () => true,
      click: $scope.editVendor
    }
  };


  //function to update vendor list base on search term entered
  $scope.searchFilter = (searchTerm) => {
    $scope.params.where = {};

    if(searchTerm) $scope.params.where = {name:{$icontains: searchTerm.trim().toLowerCase()}};
    return loadVendors();
  };

  // listen for vendor data broadcast
  $scope.$on('vendor.data',(event,id,message) => updateVendorData(id,message));
  function updateVendorData(id,data) {
    if(!$scope.ready || $scope.loading) return;
    return loadVendors();
  }

});
