angular.module('vendor').component('screenAutoQuoteData', {
  templateUrl: '/templates/vendor/screen-data.html',
  bindings: {
    vendor: '<',
    screen: '<',
    form: '<',
    onNext: '&'
  },
  controller: function($timeout,$q,User,Vendor,Field) {
    'ngInject';

    this.user = User.get();

    this.$onInit = () => {
      this.screen.data = {};
      this.screen.fields.forEach((field) => {
        field.value = Vendor.details.getValue(field,this.screen,this.vendor);
        if(angular.isUndefined(field.value)) field.value = field.default;
        Field.addDefaults(field,this.screen.data);
        // keep local copy updated as values change
        this.screen.data[field.name] = field.value;
        field.onChange = () => {
          this.screen.data[field.name] = field.value;
          if(this.screen.onChange) this.screen.onChange(buildData());
        };
      });
    };

    this.fieldFilter = (field) => !field.hidden;

    this.next = (skip) => {
      if(this.loading) return;
      if(skip) return this.onNext();
      this.loading = true;
      if(!valid()) return error();
      this.onNext({callback:this.save})
        .then((result) => this.loading = false, error);
    };

    this.save = (progress) => {
      let data = buildData();
      data.progress = progress;
      return Vendor.details.update(this.vendor.details.id, data);
    };

    const buildData = () => {
      let data = {};
      this.screen.fields.forEach((field) => {
        data[field.name] = formatData(field);
      });
      if(this.screen.saveDirect) return data;
      return {[`${this.screen.name}_data`]: data};
    };

    const formatData = (field) => {
      if(field.type == 'date') return moment(field.value).format('YYYY-MM-DD');
      return field.value;
    };

    let valid = () => {
      this.form.$setSubmitted();
      return !this.form.$invalid;
    };

    let error = () => {
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);
    };
  }

});
