// create module & set dependencies
angular.module('core', [
  'ngCookies',
  'ngAnimate',
  'ngSanitize',
  'ngMessages',
  'ngFileUpload',
  'ui.router',
  'ui.bootstrap',
  'ui.scrollpoint',
  'ui.validate',
  'pubnub.angular.service',
  'ngMaterial',
  'angular-google-analytics',
  'socialshare',
  'angular-pong',
  'constants',
  'user',
  /* these should rely on core after build */
  'customer',
  'vendor',
  'partner',
  'cx'
])
// run configuration initialization
.config(function($compileProvider, $locationProvider, $httpProvider, $templateRequestProvider, $cookiesProvider,
  $mdThemingProvider, AnalyticsProvider, BugsnagProvider, Data) {
  'ngInject';
  // improve performance for production
  $compileProvider.debugInfoEnabled(Data.env === 'local');

  // allow links to app store directly
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|itms|tel):/);

  // Use real urls not hashes
  $locationProvider.html5Mode(true);

  // set default expiration for cookies to 5 years
  $cookiesProvider.defaults.expires = new Date((new Date()).setDate((new Date()).getDate() + (5*365)));
  $cookiesProvider.defaults.domain = Data.cookieDomain;

  // initialize google analytics account
  AnalyticsProvider.setAccount('UA-77806756-1');

  // send origination app data to serverside
  if(localStorage.uuid) $httpProvider.defaults.headers.common['x-uuid'] = localStorage.uuid;
  $httpProvider.defaults.headers.common.App = 'web';

  // handle token expiration && response pre-formatting
  $httpProvider.interceptors.push(($rootScope,$q,$filter,$injector) => {
    // Refresh Token Promise Holder
    let refreshing;
    return {
      'request': (config) => {
        if(config.headers.Authorization) {
          const Auth = $injector.get('Auth'); // avoids circular dependency;
          if(refreshing || Auth.hasExpiredToken()) {
            if(!refreshing) refreshing = Auth.refreshToken(); // create refresh request if one doesn't exist
            return refreshing.then(() => {
              const $http = $injector.get('$http'); // avoids circular dependency;
              config.headers.Authorization = $http.defaults.headers.common.Authorization;
              return config;
            })
            .finally(() => refreshing = undefined);
          }
        }
        return config;
      },
      'response': (response) => {
        if(response.data) $filter('formatResponse')(response.data);
        return response;
      },
      'responseError': (response) => {
        if(response.status==401 && response.data.message != 'Password incorrect')
          return $rootScope.$broadcast('invalidSession');
        return $q.reject(response);
      }
    };
  });

  // Keeps template requests from sending Authorization Headers and triggering refresh_token
  $templateRequestProvider.httpOptions({headers:{Authorization:null}});

  // configure Bugsnag
  if(Data.env !== 'local') BugsnagProvider.init({
    apiKey:'75019aa1028a389c9dad3817b021b59d',
    appVersion: Data.version,
    notifyReleaseStages: ['prod','dev','staging'],
    releaseStage: Data.env,
    autoNotifyUnhandledRejection: false
  });

  // create custom material design theme
  let movedBlue = $mdThemingProvider.extendPalette('blue',{
    '500':'#4ca9ff'
  });
  $mdThemingProvider.definePalette('moved-blue', movedBlue);

  let movedGreen = $mdThemingProvider.extendPalette('green',{
    '500':'#04D959'
  });
  $mdThemingProvider.definePalette('moved-green', movedGreen);

  let movedGrey = $mdThemingProvider.extendPalette('grey',{
      '200':'#f3f3f3',
      '300':'#e6e6e6',
      '400':'#ccc',
      '500':'#999',
      '600':'#7a7a7a',
      '700':'#515151',
      '800':'#333',
      '900':'#191919'
  });
  $mdThemingProvider.definePalette('moved-grey', movedGrey);

  $mdThemingProvider.theme('default')
    .primaryPalette('moved-blue')
    .accentPalette('moved-green')
    .backgroundPalette('moved-grey');

})
// initiate module
.run(function($rootScope, $state, $http, $window, $cookies, $templateCache, $timeout, moment,
  Data, Analytics, Pixel, Auth, Upload, Script, Konami, PubNub, FullStory, _) {
  'ngInject';

  // initialize Pubnub
  PubNub.init();

  // facebook sdk integration for oauth
  $window.fbAsyncInit = () => {
    $window.FB.init({
      appId  : Data.facebookAppId,
      version: 'v2.8',
      status : true
    });
    $rootScope.$broadcast('facebookInit');
  };
  Script.load('https://connect.facebook.net/en_US/sdk.js');

  // google sdk integration for oauth
  $window.gapiAsyncInit = () => {
    $window.gapi.load('auth2', () => $rootScope.$broadcast('gapiInit'));
  };
  Script.load('https://apis.google.com/js/client:platform.js?onload=gapiAsyncInit');
  Script.load(`https://maps.googleapis.com/maps/api/js?libraries=places&key=${Data.googleAppKey}`);

  // set upload defaults
  Upload.setDefaults({
    ngfPattern:'*.jpg,*.jpeg,*.png',
    accept:'.jpg,.jpeg,.png',
    ngfMultiple:false,
    ngfNoObjectUrl:true,
    ngfValidate: {
      size: {max: '20MB', min: '10B'},
      height: {max: 4200, min: 10},
      width: {max: 4200, min: 10}
    }
  });

  // override the bootstrap ui-select match template
  $templateCache.put("bootstrap/match.tpl.html",
    `<div class="ui-select-match" ng-hide="$select.open && $select.searchEnabled"
      ng-disabled="$select.disabled" ng-class="{'btn-default-focus':$select.focus}">
      <span tabindex="-1" class="btn btn-default form-control ui-select-toggle"
        aria-label="{{ $select.baseTitle }} activate" ng-disabled="$select.disabled"
        ng-click="$select.activate()" style="outline: 0;">
        <span ng-show="$select.isEmpty()" class="ui-select-placeholder text-muted">
          {{$select.placeholder}}</span>
        <span ng-hide="$select.isEmpty()" class="ui-select-match-text pull-left"
          ng-class="{'ui-select-allow-clear': $select.allowClear && !$select.isEmpty()}"
          ng-transclude=""></span>
        <i class="caret pull-right" ng-click="$select.toggle($event)"></i>
        <a ng-show="$select.allowClear && !$select.isEmpty() && ($select.disabled !== true)"
          aria-label="{{ $select.baseTitle }} clear"
          ng-click="$select.clear($event)" class="ui-select-clear close">
          <svg-sprite alt="close" source="material-icons" icon="close"></svg-sprite>
        </a>
      </span>
    </div>`);

  // update tracking data when state changes
  $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {

    // analytics track pageview
    if(Data.env == 'prod')
      Analytics.trackPage($state.href(toState,toParams,{relative:true}), toState.data ? toState.data.name : '');

    // facebook pixel (limit to prod in service)
    Pixel.FB.track('PageView');

  });

  $rootScope.$on('$stateChangeError',
    (event, toState, toParams, fromState, fromParams, error) => {
      if(error && error.state) {
        $state.go(error.state, error.params, error.options);
      }
  });

  // support svgs in older browsers
  svg4everybody();

  let now = moment();
  let Let = now.isAfter(moment('2022-12-25','YYYY-MM-DD'));
  let It = now.isBefore(moment('2023-01-15','YYYY-MM-DD'));
  let Snow = !$cookies.getObject('disableSnow');
  if(Let && It) Script.load('/media/snowstorm-min.js').then(() => {
    $window.snowStorm.autoStart = false;
    $window.snowStorm.targetElement = "eggs";
    $window.snowStorm.className = 'snowflake';
    $window.snowStorm.followMouse = false;
    $window.snowStorm.freezeOnBlur = false;
    $window.snowStorm.vMaxX = 1;
    $window.snowStorm.vMaxY = 1.5;
    $window.snowStorm.zIndex = 25;
    $timeout(() => {
      $window.snowStorm.start();
      if(!Snow) $window.snowStorm.freeze();
      $rootScope.$broadcast('startSnow');
    },500);
  });

  $rootScope.$on('toggleSnow', (data) => {
    Snow = !Snow;
    $cookies.putObject('disableSnow', !Snow);
    Snow ? $window.snowStorm.resume() : $window.snowStorm.freeze();
  })

});
