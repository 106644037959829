angular.module('field')
.component('fieldCurrency', {
  template: `
    <input type="text" name="{{$ctrl.field.name}}" placeholder="{{$ctrl.form ? '' : $ctrl.field.placeholder}}"
      ng-disabled="$ctrl.field.isDisabled()" ng-required="$ctrl.field.isRequired()" positive-value="$ctrl.field.positiveValue"
      ng-change="$ctrl.onChange()" ng-model="$ctrl.value" ng-readonly="$ctrl.field.readOnly"
      ng-model-options="{allowInvalid: true}" ng-trim="false"/>
    <label class="placeholder" for="{{$ctrl.field.name}}" ng-if="$ctrl.form">{{$ctrl.field.placeholder}}</label>
    <label class="error" ng-if="$ctrl.form && $ctrl.form[$ctrl.field.name].$invalid && $ctrl.form.$submitted">
      Required.
    </label>`,
  bindings: {
    field: '=',
    form: '<'
  },
  controller: function(debounce) {
    'ngInject';

    this.$onInit = () => {
      this.value = (this.field.value || this.field.value === 0) ?
        format(this.field.value/100,true) : '';
      this.save = debounce(this.field.onChange, this.field.debounce || 200);
    };

    this.onChange = () => {
      this.value = format(this.value);
      this.field.value = Math.round(parseFloat((this.value || 0)) * 100);
      if(this.field.onChange) this.save();
    };

    this.onBlur = () => {
      this.value = format(this.value,true);
    };

    const format = (value,cleanup) => {
      if(typeof value == 'number') value = value.toString();
      if(!value && value !== '0') return '';
      var segments = value.replace(/[^0-9\.]+/g, '').split('.');
      if(!this.field.allowDecimals) return segments[0];
      while(segments.length > 2) {
        segments.pop();
      }
      if(segments.length == 2) {
        if(segments[1].length > 2) segments[1] = segments[1].substring(0,2);
        else if(cleanup) segments[1] = segments[1].padEnd(2,'0');
      }
      return segments.join('.');
    };

    this.$doCheck = () => {
      if(this.field.readOnly &&
        this.value !== format(this.field.value/100)) {
        this.value = format(this.field.value/100) || '';
      }
    };

  }
});
