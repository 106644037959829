angular.module('core').service('Konami',
function($rootScope,$timeout,$window,$log,Alerts,Script) {
  'ngInject';

  var hodor = (text) => {
    // define text swap
    var replace = text || 'hodor';
    var container = document.getElementById('global-wrapper');

    const swapText = (node) => {
      var n, walk=document.createTreeWalker(node,NodeFilter.SHOW_TEXT,null,false);
      while(n=walk.nextNode()) {
        n.nodeValue = n.nodeValue.replace(/([A-Za-z0-9]+)/g, replace);
      }
      angular.forEach(angular.element(node).find('[data-placeholder]'), (elem) => {
        var text = angular.element(elem).attr('data-placeholder');
        angular.element(elem).attr('data-placeholder',text.replace(/([A-Za-z0-9]+)/g, replace));
      });
      angular.forEach(angular.element(node).find('[placeholder]'), (elem) => {
        var text = angular.element(elem).attr('placeholder');
        angular.element(elem).attr('placeholder',text.replace(/([A-Za-z0-9]+)/g, replace));
      });
    };

    // run the text swapper now and with redundency
    const runSwap = (element) => {
      var content = (element && angular.element(element).length) ? angular.element(element)[0] : container;
      $timeout(() => swapText(content), 0);
      $timeout(() => {
        content = (element && angular.element(element).length) ? angular.element(element)[0] : container;
        swapText(content);
      },100);
    };

    // run immediately
    runSwap();
    runSwap('.modal-content');

    // give an alert
    $timeout(() => {
      $rootScope.$apply(() => Alerts.info({
        msg: 'Uh oh. Now look what you\'ve done. HOLD THE DOOR!'
      }));
    },500);
    $log.log('Spoiler Alert!');

    // bind to future content
    $rootScope.$on('$viewContentLoaded', () => runSwap());

    // bind to future custom added content
    $rootScope.$on('customContentLoaded', (event,element) => runSwap(element));

  };

  var rainbow = () => {
    // load rainbow.js
    Script.load('media/rainbow.js');
    // give an alert
    $rootScope.$apply(() => Alerts.info({
      msg: 'You can do anything! Take a look, it\'s in a book...'
    }));
    $log.log('We Love Lamar Burton!');
  };

  var thejabberwock = () => {
    const jabberwocky = [
      '\&lsquo;Twas brillig, and the slithy toves, Did gyre and gimble in the wabe:',
      'All mimsy were the borogoves, And the mome raths outgrabe.',
      '"Beware the Jabberwock, my son! The jaws that bite, the claws that catch!',
      'Beware the Jubjub bird, and shun The frumious Bandersnatch!"',
      'He took his vorpal sword in hand: Long time the manxome foe he sought --',
      'So rested he by the Tumtum tree, And stood awhile in thought.',
      'And, as in uffish thought he stood, The Jabberwock, with eyes of flame,',
      'Came whiffling through the tulgey wood, And burbled as it came!',
      'One, two! One, two! And through and through The vorpal blade went snicker-snack!',
      'He left it dead, and with its head He went galumphing back.',
      '"And, has thou slain the Jabberwock? Come to my arms, my beamish boy!',
      'O frabjous day! Callooh! Callay! He chortled in his joy.',
      '\&lsquo;Twas brillig, and the slithy toves,&nbsp;Did gyre and gimble in the wabe:',
      'All mimsy were the borogoves,&nbsp;And the mome raths outgrabe.',
      '-Lewis Carroll, <em>Jabberwocky</em>'
    ];
    let delay = 4;
    $rootScope.$apply(() => angular.forEach(jabberwocky, (line) => {
      Alerts.success({msg: line, delay: delay });
    }));

    Script.load('media/jabberwock.js');

  };

  var active = false;
  var eggChooser = () => {
    if (active) return;
    var eggArray = [hodor,rainbow,thejabberwock];
    eggArray[Math.floor(Math.random() * eggArray.length)]();
    active = true;
  };

  new Konami(eggChooser,{disableTouch:true});

});
