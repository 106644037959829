angular.module('messages')
.component('messageSystem', {
  template: `
    <div class="message-timestamp hide-on-mobile"
      ng-bind="::$ctrl.message.created_at | amUtc | amLocal | amDateFormat: 'M/D/YYYY h:mma'"></div>
    <div class="message-flex">
      <div class="message-divider"></div>
      <div class="message-bubble">
        <span ng-bind-html="::$ctrl.message.body | cleanHTML | parseUrl | emojify"></span>
      </div>
      <div class="message-divider"></div>
    </div>`,
  bindings: {
    message: '<'
  },
  controller: function() {}
});
