angular.module('tasks').component('singleQuote', {
  templateUrl: '/templates/move/single-quote.html',
  bindings: {
    quote: '<',
    move: '<',
    task: '<',
    deadline: '<',
    onBook: '&',
    startBook: '&',
  },
  controller: function($filter, $timeout, MoveQuote, Field, User, Payment, Alerts, Events, Data, moment, _) {
    'ngInject';

    this.$onInit = () => {
      let metadata = _.get(this.move, 'partner.metadata');
      if(metadata) {
        let logo = _.get(metadata, 'partner_logo');
        if(logo) this.partner_logo = logo.includes('/partners/') ?
          `${Data.s3AssetUrl}${logo}` :
          `${Data.s3AssetUrl}/partner-logos/${logo}`;
        let copy = _.get(metadata, 'custom_copy', {});
        this.partner_copy = _.get(this.quote, 'coupon') ?
          _.get(copy,'booking_coupon') :
          _.get(copy,'booking_no_coupon');
      }

      if(User.get().role == 'mover') {
        // Create event that quotes have been viewed
        Events.create({
          name: 'quotes-viewed',
          move_id: this.move.id,
          time_stamp: new Date(),
          data: { move_quotes: [this.quote.id] }
        });
        // Load stripe checkout sdk
        Payment.getStripeCheckout().then(checkout => {
          this.stripeCheckout = checkout;
          this.ready = true;
        });
      }

      this.fields = [{
        name: 'rate',
        label: 'Rate',
        type: 'text',
        value: `${$filter('currency')(this.quote.rate/100)}/hr`
      },{
        name: 'coupon',
        label: 'Coupon',
        type: 'currency',
        value: this.quote.coupon
      },{
        name: 'crew_size',
        label: 'Crew Size',
        type: 'text',
        value: this.quote.data && this.quote.data.crew_size ? `${this.quote.data.crew_size} person` : false
      },{
        name: 'minimum',
        label: this.quote.coupon ? 'Minimum After Coupon Savings' : 'Minimum',
        type: 'currency',
        oldValue: this.quote.coupon ? this.quote.minimum : false,
        value: this.quote.coupon ? Math.max(this.quote.minimum - this.quote.coupon,0) : this.quote.minimum
      }];

      this.hasStripe = User.get().has_stripe;
    };

    this.cancellationPolicy = `For local moves, cancel anytime up to 48 hours
      before your move with no charge! Moving long distance? Be sure to provide
      at least 5 days notice. Cancelling without proper notice will result in a
      $150 cancellation fee for local moves, or a $300 cancellation fee for long
      distance moves.`;

    this.bookingDetailsModal = () => {
      Payment.modals.bookingTerms(this.quote,this.move);
    }

    this.book = (useExisting) => {
      if(User.get().role === 'concierge' || // cx can not book when viewing this screen
        !MoveQuote.isValid(this.quote,this.task)) return false;
      if(this.loading) return;
      this.loading = useExisting || 'stripe';
      if(this.startBook) this.startBook();

      // if booking with existing card
      if(useExisting && User.get().has_stripe) bookQuote();
      else stripeCheckout(this.quote).then(
        (result) => {
          // if replacing card
          if(User.get().has_stripe) Payment.addCard({ source: result[0].id })
            .then(() => {
              User.refresh();
              bookQuote();
            },error);
          // if adding first card
          else bookQuote({source:result[0].id});
        },
        (result) => error()
      );
    };

    // trigger stripe checkout UI function
    const stripeCheckout = (quote) => {
      // trigger stripe payment overlay
      return this.stripeCheckout.open({
        name: quote.vendor_name,
        description: 'Book Mover',
        email: User.get().email,
        amount: 0,
        panelLabel: 'Authorize'
      });
    };

    // generic book quote request for all payment methods
    const bookQuote = (data) =>
      MoveQuote.book(this.quote.id, data).then((moveTransaction) => {
        if(this.onBook) this.onBook({
          moveTransaction:moveTransaction,
          quote:this.quote
        });
        else this.loading = false;
      }, error);

    // error handler
    const error = (result) => {
      if(this.cancelBook) this.cancelBook();
      this.loading = false;
      this.error = true;
      $timeout(() => { this.error = false; }, 300);

      if(!result) return;

      if(result.data && result.data.error && result.data.error.message)
        return Alerts.error({msg:result.data.error.message});

      else if(result.data && result.data.message && result.data.message.error_code == 'card_declined')
        return Alerts.error({msg:`Uh Oh. There was a problem processing this card.
            Please try again, or use a different payment method.`});

      else if(result.data && result.data.message)
        return Alerts.error({
          msg: result.data.message
        });

      return Alerts.error();
    };

    this.quoteIsValid = () => MoveQuote.isValid(this.quote,this.task);

    this.isMoveDateSoon = () => {
      const move_date = moment(_.get(_.get(this.task, 'move_task_dates', []).find(date => date.name === 'move_date'),'date'));
      const soonCutoff = move_date.clone().subtract(3,'d');
      return soonCutoff.isBefore();
    };

  }
});
