// declare module routes
angular.module('partner').config(function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('dashboard.channel-partner', {
      views: {
        'main@layout-default': {
          templateUrl: '/templates/partner/channel-partner.html',
          controller: 'channelPartnerDashboardController'
        }
      }
    });
});
