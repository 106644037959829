angular.module('core').directive('navItem',
function($compile, $parse) {
  'ngInject';
  return {
    restrict: 'E',
    link: function(scope, element, attr) {
      scope.$watch(attr.item, function() {
        var item = $parse(attr.item)(scope);
        var markup = '<a ' + (item.class ? ` class="${item.class}"` : '') +
          (item.href ? ' ng-href="{{item.href}}"' : '') +
          (item.state ? ` ui-sref="${item.state}" ui-sref-active="active"` : '') +
          (item.onClick ? ' ng-click="item.onClick()"' : '') +
          (item.target ? ' ng-attr-target="{{item.target}}"' : '') + '>' +
          (item.icon ? `<svg-sprite alt="${item.icon.alt}" source="${item.icon.source}" icon="${item.icon.icon}"></svg-sprite>` : '') +
          '<span class="label" ng-bind-html="item.label"></span>' +
        '</a>';

        element.html(markup);
        $compile(element.contents())(scope);
      }, true);
    }
  };
});
